import { PropertyMapper } from '../../core/decorators';
import { ProjectFeedback } from '../../core/models/project/project-feedback.model';

export class projectFeedbackStatistics {

    /**
     * WARNING : PROPERTIES IN THIS MODEL DOES NO HAVE SAME MEANING THAN THOSE IN PROJECT MODEL
     */
    @PropertyMapper({ jsonProperty: 'project_id' })
    public projectId;

    @PropertyMapper({ jsonProperty: 'customer_name' })
    public customerName;

    @PropertyMapper({ jsonProperty: 'project_name' })
    public projectName;

    @PropertyMapper({ jsonProperty: 'status_name' })
    public statusName;

    @PropertyMapper({ jsonProperty: 'project_manager_name' })
    public projectManagerName;

    @PropertyMapper({ jsonProperty: 'closing_date', typeOfObject: Date })
    public closingDate: Date;

    @PropertyMapper({ jsonProperty: 'feedback', typeOfObject: ProjectFeedback })
    public feedback: ProjectFeedback;
}
