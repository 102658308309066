import { PropertyMapper } from '../../decorators';
import { MediaFile } from '../media/media-file.model';
import { Role } from '../role/role.model';

export class Profile {

    @PropertyMapper()
    public id: number;

    @PropertyMapper()
    public name: string;

    @PropertyMapper()
    public tuo: number;

    @PropertyMapper({ jsonProperty: 'role', typeOfObject: Role })
    public role: Role;

    @PropertyMapper({ jsonProperty: 'annual_production_days' })
    public annualProductionDays: number;

    @PropertyMapper({ jsonProperty: 'files', typeOfObject: MediaFile })
    public files: MediaFile[];

    public editable: boolean;
}
