import { PropertyMapper } from '../../decorators';

export class UserCar {
    @PropertyMapper()
    id: number;

    @PropertyMapper({ jsonProperty: 'model' })
    model: string;

    @PropertyMapper({ jsonProperty: 'tax_horsepower' })
    tax_horsepower: number;

    @PropertyMapper({ jsonProperty: 'active' })
    active: boolean;
}
