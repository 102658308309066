import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DeclarationTypeEnum } from '../../enums';
import { AcademicTraining, Activity, Declaration } from '../../models';
import { Absence } from '../../models/absence/absence.model';
import { ExpenseReport } from '../../models/expense-report/expense-report.model';
import { InternalActivity } from '../../models/internal-activity/internal-activity.model';

@Injectable()
export class DeclarationInteractionService {

    private activitiesSource: BehaviorSubject<Activity[]> = new BehaviorSubject([]);
    public activities$ = this.activitiesSource.asObservable();
    private expenseReportsSource: BehaviorSubject<ExpenseReport[]> = new BehaviorSubject([]);
    public expenseReports$ = this.expenseReportsSource.asObservable();
    private absencesSource: BehaviorSubject<Absence[]> = new BehaviorSubject([]);
    public absences$ = this.absencesSource.asObservable();
    private internalActivitiesSource: BehaviorSubject<InternalActivity[]> = new BehaviorSubject([]);
    public internalActivities$ = this.internalActivitiesSource.asObservable();
    private academicTrainingsSource: BehaviorSubject<AcademicTraining[]> = new BehaviorSubject([]);
    public academicTrainings$ = this.academicTrainingsSource.asObservable();

    private selectedActivitiesSource: BehaviorSubject<Activity[]> = new BehaviorSubject([]);
    public selectedActivities$ = this.selectedActivitiesSource.asObservable();
    private selectedExpenseReportsSource: BehaviorSubject<ExpenseReport[]> = new BehaviorSubject([]);
    public selectedExpenseReports$ = this.selectedExpenseReportsSource.asObservable();
    private selectedDateSource: BehaviorSubject<Date> = new BehaviorSubject(null);
    public selectedDate$ = this.selectedDateSource.asObservable();
    private selectedAbsencesSource: BehaviorSubject<Absence[]> = new BehaviorSubject([]);
    public selectedAbsences$ = this.selectedAbsencesSource.asObservable();
    private selectedInternalActivitiesSource: BehaviorSubject<InternalActivity[]> = new BehaviorSubject([]);
    public selectedInternalActivities$ = this.selectedInternalActivitiesSource.asObservable();
    private selectedAcademicTrainingsSource: BehaviorSubject<AcademicTraining[]> = new BehaviorSubject([]);
    public selectedAcademicTrainings$ = this.selectedAcademicTrainingsSource.asObservable();
    private selectedCalendarDateSource: BehaviorSubject<Date> = new BehaviorSubject(null);
    public selectedCalendarDate$ = this.selectedCalendarDateSource.asObservable();

    private refreshDeclarationSource = new Subject();
    public refreshDeclaration$ = this.refreshDeclarationSource.asObservable();


    public refreshDeclaration() {
        this.refreshDeclarationSource.next();
    }

    public setActivities(activities: Activity[]) {
        this.activitiesSource.next(activities);
    }

    public setExpenseReports(expenseReports: ExpenseReport[]) {
        this.expenseReportsSource.next(expenseReports);
    }

    public setAbsences(absences: Absence[]) {
        this.absencesSource.next(absences);
    }

    public setAcademicTrainings(academicTraining: AcademicTraining[]) {
        this.academicTrainingsSource.next(academicTraining);
    }

    public setInternalActivities(internalActivities: InternalActivity[]) {
        this.internalActivitiesSource.next(internalActivities);
    }

    public setSelectedActivities(activities: Activity[]) {
        this.selectedActivitiesSource.next(activities);
    }

    public setSelectedExpenseReports(expenseReports: ExpenseReport[]) {
        this.selectedExpenseReportsSource.next(expenseReports);
    }

    public setSelectedAbsences(absences: Absence[]) {
        this.selectedAbsencesSource.next(absences);
    }

    public setSelectedInternalActivities(internalActivities: InternalActivity[]) {
        this.selectedInternalActivitiesSource.next(internalActivities);
    }

    public setSelectedAcademicTrainings(academicTrainings: AcademicTraining[]) {
        this.selectedAcademicTrainingsSource.next(academicTrainings);
    }

    public setSelectedDate(date: Date) {
        this.selectedDateSource.next(date);
    }

    public setSelectedCalendarDate(date: Date) {
        this.selectedCalendarDateSource.next(date);
    }

    /**
     * Create functions list
     */

    public createActivity(activity: Activity) {
        const currentValues = this.activitiesSource.getValue();
        const index = currentValues.findIndex(a => a.uuid === activity.uuid);
        currentValues[index] = activity;
        if (index === -1) {
            this.setActivities([...currentValues, activity]);
        }
    }

    public createExpenseReport(expenseReport: ExpenseReport) {
        const currentValues = this.expenseReportsSource.getValue();
        const index = currentValues.findIndex(a => a.uuid === expenseReport.uuid);
        if (index === -1) {
            this.setExpenseReports([...currentValues, expenseReport]);
        }
    }

    public createAbsence(absence: Absence) {
        const currentValues = this.absencesSource.getValue();
        const index = currentValues.findIndex(a => a.uuid === absence.uuid);
        currentValues[index] = absence;
        if (index === -1) {
            this.setAbsences([...currentValues, absence]);
        }
    }

    public createInternalActivity(internalActivity: InternalActivity) {
        const currentValues = this.internalActivitiesSource.getValue();
        const index = currentValues.findIndex(a => a.uuid === internalActivity.uuid);
        currentValues[index] = internalActivity;
        if (index === -1) {
            this.setInternalActivities([...currentValues, internalActivity]);
        }
    }

    public createAcademicTraining(academicTraining: AcademicTraining) {
        const currentValues = this.academicTrainingsSource.getValue();
        const index = currentValues.findIndex(a => a.uuid === academicTraining.uuid);
        currentValues[index] = academicTraining;
        if (index === -1) {
            this.setAcademicTrainings([...currentValues, academicTraining]);
        }
    }

    /**
     * Update functions list
     */

    public updateActivity(activity: Activity) {
        const currentValues = this.activitiesSource.getValue();
        const index = currentValues.findIndex(a => a.uuid === activity.uuid);
        currentValues[index] = activity;
        if (index > -1) {
            this.setActivities(currentValues);
        }
    }

    public updateExpenseReport(expenseReport: ExpenseReport) {
        const currentValues = this.expenseReportsSource.getValue();
        const index = currentValues.findIndex(a => a.uuid === expenseReport.uuid);
        currentValues[index] = expenseReport;
        if (index > -1) {
            this.setExpenseReports(currentValues);
        }
    }

    public updateAbsence(absence: Absence) {
        const currentValues = this.absencesSource.getValue();
        const index = currentValues.findIndex(a => a.uuid === absence.uuid);
        currentValues[index] = absence;
        if (index > -1) {
            this.setAbsences(currentValues);
        }
    }

    public updateInternalActivity(internalActivity: InternalActivity) {
        const currentValues = this.internalActivitiesSource.getValue();
        const index = currentValues.findIndex(a => a.uuid === internalActivity.uuid);
        currentValues[index] = internalActivity;
        if (index > -1) {
            this.setInternalActivities(currentValues);
        }
    }

    public updateAcademicTraining(academicTraining: AcademicTraining) {
        const currentValues = this.academicTrainingsSource.getValue();
        const index = currentValues.findIndex(a => a.uuid === academicTraining.uuid);
        currentValues[index] = academicTraining;
        if (index > -1) {
            this.setAcademicTrainings(currentValues);
        }
    }

    /**
     * Delete functions list
     */

    public deleteDeclaration(declaration: Declaration) {
        if (declaration.declarationType === DeclarationTypeEnum.ABSENCE) {
            this.deleteAbsence(declaration as Absence);
        } else if (declaration.declarationType === DeclarationTypeEnum.EXPENSE_REPORT) {
            this.deleteExpenseReport(declaration as ExpenseReport);
        } else if (declaration.declarationType === DeclarationTypeEnum.ACTIVITY) {
            this.deleteActivity(declaration as Activity);
        } else if (declaration.declarationType === DeclarationTypeEnum.INTERNAL_ACTIVITY) {
            this.deleteInternalActivity(declaration as InternalActivity);
        } else if (declaration.declarationType === DeclarationTypeEnum.ACADEMIC_TRAINING) {
            this.deleteAcademicTraining(declaration as AcademicTraining);
        }
    }

    private deleteActivity(activity: Activity) {
        const currentValues = this.activitiesSource.getValue();
        const index = currentValues.findIndex(a => a.uuid === activity.uuid);
        if (index > -1) {
            currentValues.splice(index, 1);
            this.setActivities(currentValues);
        }
    }

    private deleteExpenseReport(expenseReport: ExpenseReport) {
        const currentValues = this.expenseReportsSource.getValue();
        const index = currentValues.findIndex(a => a.uuid === expenseReport.uuid);
        if (index > -1) {
            currentValues.splice(index, 1);
            this.setExpenseReports(currentValues);
        }
    }

    private deleteAbsence(absence: Absence) {
        const currentValues = this.absencesSource.getValue();
        const index = currentValues.findIndex(a => a.uuid === absence.uuid);
        if (index > -1) {
            currentValues.splice(index, 1);
            this.setAbsences(currentValues);
        }
    }

    private deleteInternalActivity(internalActivity: InternalActivity) {
        const currentValues = this.internalActivitiesSource.getValue();
        const index = currentValues.findIndex(a => a.uuid === internalActivity.uuid);
        if (index > -1) {
            currentValues.splice(index, 1);
            this.setInternalActivities(currentValues);
        }
    }

    private deleteAcademicTraining(academicTraining: AcademicTraining) {
        const currentValues = this.academicTrainingsSource.getValue();
        const index = currentValues.findIndex(a => a.uuid === academicTraining.uuid);
        if (index > -1) {
            currentValues.splice(index, 1);
            this.setAcademicTrainings(currentValues);
        }
    }
}
