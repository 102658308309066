import { AuthModel, Permission, Profile, Role } from '../../core/models';

export interface State {
    authModel: AuthModel;
    isAuthenticated: boolean;
    permissions: Permission[];
    role: Role;
    profile: Profile;
}

export const initialState: State = {
    authModel: null,
    isAuthenticated: null, // important ! to set null value at initial
    permissions: null,
    role: null,
    profile: null
};
