import { PropertyMapper } from '../../decorators';
import { DeliveryDisplayModeEnum } from '../../enums';
import { Credit } from '../credit/credit.model';
import { Invoice } from '../invoice/invoice.model';
import { MediaFile } from '../media/media-file.model';
import { Project } from '../project/project.model';
import { DeliveryStatus } from './delivery-status.model';
import { DeliveryType } from './delivery-type.model';

export class Delivery {

    @PropertyMapper()
    public id: number;

    @PropertyMapper({ jsonProperty: 'type', typeOfObject: DeliveryType })
    public type: DeliveryType;

    @PropertyMapper({ jsonProperty: 'status', typeOfObject: DeliveryStatus })
    public status: DeliveryStatus;

    @PropertyMapper({ jsonProperty: 'internal_reference' })
    public internalReference: string;

    @PropertyMapper({ jsonProperty: 'customer_reference' })
    public customerReference: string;

    @PropertyMapper({ jsonProperty: 'expected_delivery_date', typeOfObject: Date })
    public expectedDeliveryDate: Date;

    @PropertyMapper({ jsonProperty: 'reel_delivery_date', typeOfObject: Date })
    public reelDeliveryDate: Date;

    @PropertyMapper({ jsonProperty: 'delivery_slip_sending_date', typeOfObject: Date })
    public deliverySlipSendingDate: Date;

    @PropertyMapper({ jsonProperty: 'delivery_slip_signature_date', typeOfObject: Date })
    public deliverySlipSignatureDate: Date;

    @PropertyMapper({ jsonProperty: 'to_bill_without_signature' })
    public toBillWithoutSignature: boolean;

    @PropertyMapper({ jsonProperty: 'ht_amount' })
    public HTAmount: number;

    @PropertyMapper({ jsonProperty: 'invoice', typeOfObject: Invoice })
    public invoice: Invoice;

    @PropertyMapper({ jsonProperty: 'credit', typeOfObject: Credit })
    public credit: Credit;

    @PropertyMapper({ jsonProperty: 'file', typeOfObject: MediaFile })
    public file: MediaFile;

    @PropertyMapper({ jsonProperty: 'project', typeOfObject: Project })
    public project: Project;

    public displayMode = DeliveryDisplayModeEnum.SIMPLE;
}
