import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class RouterService {
    constructor(private router: Router) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    public gotoLogin() {
        this.navigateTo('/auth/login');
    }

    public gotoDashboardHome() {
        this.navigateTo('/dashboard/home');
    }

    public gotoDashboardLots() {
        this.navigateTo('/dashboard/lots');
    }

    public gotoPasswordRemind() {
        this.navigateTo('/auth/password-remind');
    }

    public gotoPasswordReset(token: string) {
        this.navigateTo(`/auth/password-reset/${ token }`);
    }

    public gotoAccountDetail(username: string) {
        this.navigateTo(`/account/${ username }`);
    }

    public gotoParameterUserList() {
        this.navigateTo('/parameter/users');
    }

    public gotoAdminUserUpdate(username: string) {
        this.navigateTo(`/parameter/users/${ username }/update`);
    }

    public gotoAdminUserProfileUpdate(profileId: number) {
        this.navigateTo(`/parameter/users/profile/${ profileId }/update`);
    }

    public gotoAdminUserCreate() {
        this.navigateTo('/parameter/users/create');
    }

    public gotoParameterRoleList() {
        this.navigateTo('/parameter/roles');
    }

    public gotoParameterProfileList() {
        this.navigateTo('/parameter/profile');
    }

    public gotoParameterBilling() {
        this.navigateTo('/parameter/billing-parameters');
    }

    public gotoParameterProfileUpdate(profileId: number) {
        this.navigateTo(`/parameter/profile/${ profileId }/update`);
    }

    public gotoParameterProfileCreate() {
        this.navigateTo(`/parameter/profile/create`);
    }

    public gotoFiscalPeriodCreate() {
        this.navigateTo('/parameter/fiscal-period/create');
    }

    public gotoFiscalPeriodUpdate(fiscalPeriodId: number) {
        this.navigateTo(`/parameter/fiscal-period/${ fiscalPeriodId }/update`);
    }

    public gotoDeclaration() {
        this.navigateTo('/declaration');
    }

    public gotoRequest() {
        this.navigateTo('/request');
    }

    public gotoProjectList() {
        this.navigateTo('/project');
    }

    public gotoCustomerList() {
        this.navigateTo('/customer/list');
    }

    public gotoSupplierList() {
        this.navigateTo('/supplier/list');
    }

    public gotoSupplierDetail(id: number) {
        this.navigateTo(`/supplier/list/${ id }`);
    }

    public gotoSupplierOrderList() {
        this.navigateTo('/supplier/order/list');
    }

    public gotoSupplierOrderDetails(id: number) {
        this.navigateTo(`/supplier/order/list/${ id }`);
    }

    public gotoSupplierInvoiceList() {
        this.navigateTo('/supplier/invoice/list');
    }

    public gotoSupplierInvoiceDetails(id: number) {
        this.navigateTo(`/supplier/invoice/list/${ id }`);
    }

    public gotoCustomerDetail(id: number) {
        this.navigateTo(`/customer/list/${ id }`);
    }

    public gotoHome() {
        this.navigateTo('/');
    }

    public gotoProjectDetail(id: number) {
        this.navigateTo(`/project/${ id }/detail`);
    }

    public gotoProjectDetailFlatRate(id: number) {
        this.navigateTo(`/project/${ id }/detail-flat-rate`);
    }

    public gotoProjectDetailTechnicalAssistance(id: number) {
        this.navigateTo(`/project/${ id }/detail-technical-assistance`);
    }

    public gotoProjectDetailTraining(id: number) {
        this.navigateTo(`/project/${ id }/detail-training`);
    }

    public gotoProjectDetailInternal(id: number) {
        this.navigateTo(`/project/${ id }/detail-internal`);
    }

    public gotoProjectUpdate(id: number) {
        this.navigateTo(`/project/${ id }/update`);
    }

    public gotoProjectFlatRateUpdate(id: number) {
        this.navigateTo(`/project/${ id }/update-flat-rate`);
    }

    public gotoProjectTechnicalAssistanceUpdate(id: number) {
        this.navigateTo(`/project/${ id }/update-technical-assistance`);
    }

    public gotoProjectTrainingUpdate(id: number) {
        this.navigateTo(`/project/${ id }/update-training`);
    }

    public gotoProjectInternalUpdate(id: number) {
        this.navigateTo(`/project/${ id }/update-internal`);
    }

    public gotoDeclarationManagement() {
        this.navigateTo('/management/declaration/users');
    }

    public gotoRequestManagement() {
        this.navigateTo('/management/request/users');
    }

    public gotoUserManagement() {
        this.navigateTo('/management/users');
    }

    public gotoUserManagementDetails(id: number) {
        this.navigateTo(`/management/users/${ id }`);
    }

    public gotoUserDeclarationManagementV2(id: number) {
        this.navigateTo(`/management/declaration-v2/users/${ id }`);
    }

    public gotoUserRequestManagement(id: number) {
        this.navigateTo(`/management/request/users/${ id }`);
    }

    public gotoPManagerUserManagement() {
        this.navigateTo('/management/pmanager/users');
    }

    public gotoStatistics() {
        this.navigateTo('/statistics');
    }

    public gotoStatisticsProjects() {
        this.navigateTo('/statistics/projects');
    }

    public gotoStatisticsDeliveries() {
        this.navigateTo('/statistics/deliveries');
    }

    public gotoStatisticsCustomers() {
        this.navigateTo('/statistics/customers');
    }

    public gotoStatisticsBusinessSector() {
        this.navigateTo('/statistics/business-sector');
    }

    public gotoStatisticsCosts() {
        this.navigateTo('/statistics/costs');
    }

    public gotoStatisticsTurnoverByProjectType() {
        this.navigateTo('/statistics/turnover-by-project-type');
    }

    public gotoStatisticsUsers() {
        this.navigateTo('/statistics/users');
    }

    public gotoStatisticsCustomerSatisfaction() {
        this.navigateTo('/statistics/customer-satisfaction');
    }

    public gotoStatisticsProjectUserSatisfaction() {
        this.navigateTo('/statistics/project-user-satisfaction');
    }

    public gotoStatisticsProjectManagerFeedback() {
        this.navigateTo('/statistics/project-manager-feedback');
    }

    public gotoStatisticsProjectFeedback() {
        this.navigateTo('/statistics/project-feedback');
    }

    public gotoReporting() {
        this.navigateTo('/reporting');
    }

    public gotoBillingDeliveries(params: any = null) {
        this.navigateTo('/billing/deliveries', params);
    }

    public gotoBillingInvoices(params: any = null) {
        this.navigateTo('/billing/invoices', params);
    }

    public gotoBillingInvoicesSchedule(params: any = null) {
        this.navigateTo('/billing/invoices-schedule', params);
    }

    public navigateTo(url: string, params: any = null): void {
        this.router.navigate([url], { queryParams: params }).then(r => r);
    }
}
