import { PropertyMapper } from '../../decorators';
import { DeclarationTypeEnum } from '../../enums';
import { Declaration } from '../declaration/declaration.model';
import { InternalActivityType } from './internal-activity-type.model';

export class InternalActivity extends Declaration {

    constructor() {
        super();
        this.declarationType = DeclarationTypeEnum.INTERNAL_ACTIVITY;
    }

    @PropertyMapper()
    public description: string;

    @PropertyMapper()
    public duration: string;

    @PropertyMapper({ jsonProperty: 'type', typeOfObject: InternalActivityType })
    public type: InternalActivityType;
}
