import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivityDeclarationForm, ActivityDeclarationMultiForm, ActivityListGetRequestForm } from '../../../declaration/forms';
import { Activity, ActivityType, Project } from '../../models';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class ActivityService {

	constructor(private httpRequestService: HttpRequestService) {
	}

	public getTypes(): Observable<any> {
		return this.httpRequestService.get('activity/type/list').pipe(map((result) => {
			return this.httpRequestService.mapToModel(ActivityType, result.activity_type_list) as ActivityType[];
		}));
	}

	public getMyActivities(requestForm: ActivityListGetRequestForm): Observable<any> {
		return this.httpRequestService.post('activity/list', requestForm.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(Activity, result.activities) as Activity[];
		}));
	}

	public getActivityByUser(userId: number, requestForm: ActivityListGetRequestForm): Observable<any> {
		return this.httpRequestService.post(`admin/activity/user/${userId}/list`, requestForm.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(Activity, result.activities) as Activity[];
		}));
	}

	public deleteActivity(id: number) {
		return this.httpRequestService.delete(`activity/${id}/delete`);
	}

	public createActivity(form: ActivityDeclarationForm): Observable<any> {
		return this.httpRequestService.post('activity/create', form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(Activity, result.activity) as Activity;
		}));
	}

	public createMultiActivity(form: ActivityDeclarationMultiForm): Observable<any> {
		return this.httpRequestService.post('activity/create/multi', form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(Activity, result.activities) as Activity[];
		}));
	}

	public updateActivity(id: number, form: ActivityDeclarationForm): Observable<any> {
		return this.httpRequestService.put(`activity/${id}/update`, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(Activity, result.activity) as Activity;
		}));
	}

	public getProjectsForDeclarations(): Observable<Project[] | null> {
		return this.httpRequestService.get('activity/project-list').pipe(map(result => {
			return this.httpRequestService.mapToModel(Project, result.projects) as Project[];
		}));
	}

	public getProjectsForExpenseReportDeclarations(): Observable<Project[] | null> {
		return this.httpRequestService.get('activity/project-list/fee').pipe(map(result => {
			return this.httpRequestService.mapToModel(Project, result.projects) as Project[];
		}));
	}
}
