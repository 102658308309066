import { FormBuilder, FormGroup } from '@angular/forms';

export abstract class BaseForm {

    public validationForm: FormGroup;
    protected formBuilder;

    protected constructor() {
        this.formBuilder = new FormBuilder();
    }

    public abstract toRequestForm(): any;

    public validate(): boolean {
        Object.keys(this.validationForm.controls).forEach(key => {
            this.validationForm.get(key).markAsDirty();
            this.validationForm.get(key).updateValueAndValidity();
        });

        return this.validationForm.valid;
    }
}
