import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { BnNgIdleService } from 'bn-ng-idle';
import moment from 'moment';
import { interval, Subscription } from 'rxjs';
import { AuthStoreActions, AuthStoreSelectors, AuthStoreState } from 'src/app/modules/root-store';
import { AuthModel } from './modules/core/models';
import { AuthService, RouterService } from './modules/core/services';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public isAuthenticated = false;
    private refreshTokenSubscription: Subscription = null;
    private isAuthenticatedSubscription: Subscription = null;

    constructor(private authService: AuthService,
                private routerService: RouterService,
                private store: Store<AuthStoreState.State>,
                private idleService: BnNgIdleService) {
        this.isAuthenticatedSubscription = this.store.pipe(select(AuthStoreSelectors.selectIsAuthenticated))
            .subscribe(isAuthenticated => {
                this.isAuthenticated = isAuthenticated;
            });
        this.idleService.startWatching(3000).subscribe((res) => {
            if (res && this.isAuthenticated) {
                this.authService.logout().subscribe((result) => {
                    this.authService.clearSession();
                    this.store.dispatch(new AuthStoreActions.UserUnauthenticated());
                    this.routerService.gotoLogin();
                });
            }
        });
        moment.locale('fr');
    }

    ngOnInit() {
        interval(1000).pipe(untilDestroyed(this)).subscribe();
        const token = this.authService.getAuthToken();
        if (token) {
            this.refreshTokenSubscription = this.authService.refreshToken().subscribe((model: AuthModel) => {
                this.authService.setAuthToken(model.token);
                this.store.dispatch(new AuthStoreActions.UserAuthenticated(model));
            }, err => {
                this.authService.clearSession();
                this.unauthenticatedHandler();
            });
        } else {
            this.unauthenticatedHandler();
        }
    }

    private unauthenticatedHandler() {
        this.store.dispatch(new AuthStoreActions.UserUnauthenticated());
        // setTimeout(() => {
        //     this.routerService.gotoLogin();
        // }, 20);
    }
}
