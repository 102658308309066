<form [formGroup]="form.validationForm" nz-form nzLayout="vertical"
      style="width: 100%;">
	<nz-form-item *ngIf="!isCreateMode && status">
		<nz-form-control>
			<div [ngClass]="{   'validated': status.id === declarationStatusEnum.VALIDATED,
                                'denied': status.id === declarationStatusEnum.DENIED,
                                'waiting': status.id === declarationStatusEnum.WAITING_VALIDATION
                            }"
			     class="item-status">{{status.name}}</div>
		</nz-form-control>
	</nz-form-item>
	<div nz-row nzGutter="15" nzJustify="center" nzAlign="middle" style="margin-bottom: 15px;">
		<div nz-col *ngIf="displayClosedLotWarning">
			<span>
				<nz-tag nzColor="warning">Attention, le lot sélectionné a déjà été livré, une notification sera envoyée au CDP.</nz-tag>
			</span>
		</div>
		<div nz-col *ngIf="selectedLot && selectedLot.lotUser">
			 {{ selectedLot.lotUser.assignedWorkload }} jours assignés / {{ selectedLot.lotUser.declaredWorkload }} jours déclarés / {{ selectedLot.lotUser.reelWorkload }} jours validés
		</div>
	</div>
	<div nz-row nzGutter="15">
		<div nz-col nzSpan="12">
			<nz-form-item>
				<nz-form-label nzRequired>Projet</nz-form-label>
				<nz-form-control nzErrorTip="Veuillez saisir un projet">
					<nz-select (ngModelChange)="projectChangedHandler($event)" *ngIf="projects"
					           [formControlName]="'projectId'"
					           [nzDisabled]="locked">
						<nz-option *ngFor="let project of projects" [nzLabel]="project.name"
						           [nzValue]="project.id"></nz-option>
					</nz-select>
				</nz-form-control>
			</nz-form-item>
		</div>
		<div nz-col nzSpan="12">
			<nz-form-item>
				<nz-form-label nzRequired>Lot</nz-form-label>
				<nz-form-control nzErrorTip="Veuillez saisir un lot de projet">
					<nz-select *ngIf="lots" [formControlName]="'lotId'" [nzDisabled]="locked" (ngModelChange)="lotChangeHandler($event)">
						<nz-option *ngFor="let lot of lots" [nzLabel]="lot.name" [nzValue]="lot.id"></nz-option>
					</nz-select>
				</nz-form-control>
			</nz-form-item>
		</div>
	</div>
	<div nz-row nzGutter="15">
		<div nz-col nzSpan="12">
			<nz-form-item>
				<nz-form-label nzRequired style="padding-top: 5px;">Durée</nz-form-label>
				<nz-form-control nzErrorTip="Veuillez préciser la durée de l'activié">
					<nz-select [formControlName]="'duration'">
						<nz-option [nzLabel]="'0.25 jour'" [nzValue]="'0.25'"></nz-option>
						<nz-option [nzLabel]="'0.50 jour'" [nzValue]="'0.50'"></nz-option>
						<nz-option [nzLabel]="'0.75 jour'" [nzValue]="'0.75'"></nz-option>
						<nz-option [nzLabel]="'1.00 jour'" [nzValue]="'1.00'"></nz-option>
					</nz-select>
				</nz-form-control>
			</nz-form-item>
		</div>
		<div nz-col nzSpan="12" *ngIf="!isCreateMode">
			<nz-form-item>
				<nz-form-label nzRequired style="padding-top: 5px;">Profil</nz-form-label>
				<input nz-input placeholder="{{ activity.profile.name }}" [disabled]="true"/>
			</nz-form-item>
		</div>
	</div>
	<nz-form-item>
		<nz-form-label style="padding-top: 5px;">Description</nz-form-label>
		<nz-form-control>
			<textarea *ngIf="locked" [formControlName]="'description'" nz-input></textarea>
			<textarea *ngIf="!locked" [formControlName]="'description'" nz-input></textarea>
		</nz-form-control>
	</nz-form-item>
	<div nz-row nzGutter="15" nzJustify="end">
		<div class="action-buttons" nz-col nzSpan="24">
			<ng-container *ngIf="!locked">
				<ng-container *ngIf="!activity.status">
					<button (click)="closeModalHandler();" *ngIf="!activity.status" [nzType]="'default'" nz-button>
						Annuler
					</button>
					<button (click)="sendDeclaration();" [nzType]="'primary'"
					        [nzLoading]="isConfirmLoading"
					        nz-button style="margin-left: 15px;">
						Confirmer
					</button>
				</ng-container>
				<ng-container
						*ngIf="activity.status && activity.status.id === declarationStatusEnum.WAITING_VALIDATION">
					<button (click)="sendDeclaration();" [nzType]="'primary'"
					        [nzLoading]="isConfirmLoading"
					        nz-button style="margin-left: 15px;">
						Mettre à jour
					</button>
				</ng-container>
			</ng-container>
		</div>
	</div>
</form>
