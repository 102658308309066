import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';
import { combineLatest, interval, Subscription } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { DeclarationStatusEnum } from '../../../core/enums';
import { AcademicTraining, Activity } from '../../../core/models';
import { Absence } from '../../../core/models/absence/absence.model';
import { ExpenseReport } from '../../../core/models/expense-report/expense-report.model';
import { InternalActivity } from '../../../core/models/internal-activity/internal-activity.model';
import { DeclarationInteractionService } from '../../../core/services';
import { HelpService } from '../../../core/services/helper/help.service';

@UntilDestroy()
@Component({
    selector: 'declaration-calendar-cell',
    templateUrl: './declaration-calendar-cell.component.html',
    styleUrls: ['./declaration-calendar-cell.component.scss']
})
export class DeclarationCalendarCellComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() date: Date;
    @Output() clickEvent: EventEmitter<any> = new EventEmitter();
    @ViewChild('calendarCell', { static: false }) calendarCell: ElementRef;

    public activities: Activity[] = [];
    public expenseReports: ExpenseReport[] = [];
    public absences: Absence[] = [];
    public internalActivities: InternalActivity[] = [];
    public academicTrainings: AcademicTraining[] = [];
    public holiday = null;
    private initSubscription: Subscription = null;
    private unselectable = false;

    constructor(private declarationInteractionService: DeclarationInteractionService) {
    }

    ngOnInit() {
        this.isHoliday(this.date);
        interval(1000).pipe(untilDestroyed(this)).subscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.isHoliday(this.date);
    }

    ngAfterViewInit() {
        if (this.date && ( this.date.getDay() === 6 || this.date.getDay() === 0 )) {
            const antFullCalendarDate = this.calendarCell.nativeElement.parentElement.parentElement.parentElement;
            antFullCalendarDate.style.backgroundColor = '#ececec';
            this.unselectable = true;
            return;
        }

        if (this.date && this.holiday) {
            // this.unselectable = true;
            // return;
        }

        this.initSubscription = combineLatest([
            this.declarationInteractionService.activities$,
            this.declarationInteractionService.absences$,
            this.declarationInteractionService.expenseReports$,
            this.declarationInteractionService.internalActivities$,
            this.declarationInteractionService.academicTrainings$,
        ]).pipe(map(([activities, absences, expenseReports, internalActivities, academicTrainings]) => {
                const tmpActivities = [];
                const tmpAbsences = [];
                const tmpExpenseReports = [];
                const tmpInternalActivities = [];
                const tmpAcademicTrainings = [];
                if (activities && activities.length > 0) {
                    for (const activity of activities) {
                        const activityDate = moment(activity.date, 'DD/MM/YYYY').toDate();
                        if (HelpService.isDatesEqual(activityDate, this.date)) {
                            tmpActivities.push(activity);
                        }
                    }
                }
                if (expenseReports && expenseReports.length > 0) {
                    for (const expenseReport of expenseReports) {
                        const activityDate = moment(expenseReport.date, 'DD/MM/YYYY').toDate();
                        if (HelpService.isDatesEqual(activityDate, this.date)) {
                            tmpExpenseReports.push(expenseReport);
                        }
                    }
                }
                if (absences && absences.length > 0) {
                    for (const absence of absences) {
                        const activityDate = moment(absence.date, 'DD/MM/YYYY').toDate();
                        if (HelpService.isDatesEqual(activityDate, this.date)) {
                            tmpAbsences.push(absence);
                        }
                    }
                }
                if (internalActivities && internalActivities.length > 0) {
                    for (const internalActivity of internalActivities) {
                        const activityDate = moment(internalActivity.date, 'DD/MM/YYYY').toDate();
                        if (HelpService.isDatesEqual(activityDate, this.date)) {
                            tmpInternalActivities.push(internalActivity);
                        }
                    }
                }
                if (academicTrainings && academicTrainings.length > 0) {
                    for (const academicTraining of academicTrainings) {
                        const activityDate = moment(academicTraining.date, 'DD/MM/YYYY').toDate();
                        if (HelpService.isDatesEqual(activityDate, this.date)) {
                            tmpAcademicTrainings.push(academicTraining);
                        }
                    }
                }
                return [tmpActivities, tmpExpenseReports, tmpAbsences, tmpInternalActivities, tmpAcademicTrainings];
            }),
            delay(200))
            .subscribe(([activities, expenseReports, absences, internalActivities, academicTrainings]) => {
                this.activities = activities || [];
                this.expenseReports = expenseReports || [];
                this.absences = absences || [];
                this.internalActivities = internalActivities || [];
                this.academicTrainings = academicTrainings || [];

                const antFullCalendarDate = this.calendarCell.nativeElement.parentElement.parentElement.parentElement;
                antFullCalendarDate.style.backgroundColor = 'transparent';
                if (this.checkIfJourneyIsComplete()) {
                    antFullCalendarDate.style.backgroundColor = '#fff4e0';
                    if (this.checkIfAllDeclarationsAreValidated()) {
                        antFullCalendarDate.style.backgroundColor = '#d4ffd4';
                    }
                }
                if (this.checkIfHasDeclarationRefused()) {
                    antFullCalendarDate.style.backgroundColor = '#ffccc7';
                }
            });
    }

    public clickOnCellHandler($event) {
        $event.preventDefault();
        $event.stopPropagation();
        if (this.unselectable) {
            return;
        }
        this.clickEvent.emit(this.date);
        this.declarationInteractionService.setSelectedDate(this.date);
        this.declarationInteractionService.setSelectedActivities(this.activities);
        this.declarationInteractionService.setSelectedExpenseReports(this.expenseReports);
        this.declarationInteractionService.setSelectedAbsences(this.absences);
        this.declarationInteractionService.setSelectedInternalActivities(this.internalActivities);
        this.declarationInteractionService.setSelectedAcademicTrainings(this.academicTrainings);
    }

    public isHoliday(date: Date) {
        this.holiday = HelpService.isHoliday(date);
    }

    private checkIfJourneyIsComplete() {
        let duration = 0;
        if (this.activities && this.activities.length > 0) {
            for (const activity of this.activities) {
                const tmp = Number(activity.duration);
                duration += tmp;
                if (duration >= 1) {
                    return true;
                }
            }
        }
        if (this.absences && this.absences.length > 0) {
            for (const absence of this.absences) {
                const tmp = Number(absence.duration);
                duration += tmp;
                if (duration >= 1) {
                    return true;
                }
            }
        }
        if (this.internalActivities && this.internalActivities.length > 0) {
            for (const internalActivity of this.internalActivities) {
                const tmp = Number(internalActivity.duration);
                duration += tmp;
                if (duration >= 1) {
                    return true;
                }
            }
        }
        if (this.academicTrainings && this.academicTrainings.length > 0) {
            for (const academicTraining of this.academicTrainings) {
                const tmp = Number(academicTraining.duration);
                duration += tmp;
                if (duration >= 1) {
                    return true;
                }
            }
        }
        return false;
    }

    private checkIfHasDeclarationRefused() {
        if (this.activities && this.activities.length > 0) {
            if (this.activities.findIndex(a => a.status.id === DeclarationStatusEnum.DENIED) > -1) {
                return true;
            }
        }
        if (this.absences && this.absences.length > 0) {
            if (this.absences.findIndex(a => a.status.id === DeclarationStatusEnum.DENIED) > -1) {
                return true;
            }
        }
        if (this.expenseReports && this.expenseReports.length > 0) {
            if (this.expenseReports.findIndex(a => a.status.id === DeclarationStatusEnum.DENIED) > -1) {
                return true;
            }
        }
        if (this.internalActivities && this.internalActivities.length > 0) {
            if (this.internalActivities.findIndex(a => a.status.id === DeclarationStatusEnum.DENIED) > -1) {
                return true;
            }
        }
        if (this.academicTrainings && this.academicTrainings.length > 0) {
            if (this.academicTrainings.findIndex(a => a.status.id === DeclarationStatusEnum.DENIED) > -1) {
                return true;
            }
        }
        return false;
    }

    private checkIfAllDeclarationsAreValidated() {
        if (this.activities && this.activities.length > 0) {
            for (const activity of this.activities) {
                if (activity.status.id !== DeclarationStatusEnum.VALIDATED) {
                    return false;
                }
            }
        }
        if (this.absences && this.absences.length > 0) {
            for (const absence of this.absences) {
                if (absence.status.id !== DeclarationStatusEnum.VALIDATED) {
                    return false;
                }
            }
        }
        if (this.expenseReports && this.expenseReports.length > 0) {
            for (const expenseReport of this.expenseReports) {
                if (expenseReport.status.id !== DeclarationStatusEnum.VALIDATED) {
                    return false;
                }
            }
        }
        if (this.internalActivities && this.internalActivities.length > 0) {
            for (const internalActivity of this.internalActivities) {
                if (internalActivity.status.id !== DeclarationStatusEnum.VALIDATED) {
                    return false;
                }
            }
        }
        if (this.academicTrainings && this.academicTrainings.length > 0) {
            for (const academicTraining of this.academicTrainings) {
                if (academicTraining.status.id !== DeclarationStatusEnum.VALIDATED) {
                    return false;
                }
            }
        }
        return true;
    }
}
