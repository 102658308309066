import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountFirstnameForm, AccountLastnameForm, AccountPasswordForm } from '../../../account/forms';
import { AccountCarCreateForm } from '../../../account/forms/account-car-create.form';
import { AccountNotificationEmailForm } from '../../../account/forms/account-notification-email.form';
import { UserListFilterForm } from '../../../parameter/forms';
import { ManagementDeclarationUser, Permission, User, UserCar } from '../../models';
import { UserNotification } from '../../models/user/user-notification.model';
import {
	__API_ADMIN_DECLARATION_GET_USER_CARS_LIST__,
	__API_ADMIN_USER_LIST_GET__,
	__API_MANAGE_USER_UPDATE_PERMISSION__,
	__API_MANAGEMENT_DECLARATION_USER_LIST_GET__,
	__API_PMANAGER_MANAGE_USER_LIST_GET__,
	__API_USER_CAR_CREATE__,
	__API_USER_CAR_UPDATE__,
	__API_USER_FIRSTNAME_UPDATE__,
	__API_USER_LASTNAME_UPDATE__,
	__API_USER_NOTIFICATION_AS_READ__,
	__API_USER_NOTIFICATION_AS_UNREAD__,
	__API_USER_NOTIFICATION_DELETE__,
	__API_USER_NOTIFICATION_EMAIL_UPDATE__,
	__API_USER_NOTIFICATION_GET__,
	__API_USER_PASSWORD_UPDATE__,
	__API_USER_PERMISSIONS__,
	__API_USER_PROFILE__
} from '../../routes/api.route';
import { HelpService } from '../helper/help.service';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class UserService {

	constructor(private httpRequestService: HttpRequestService) {
	}

	public getUserInformationByUsername(username: string): Observable<any> {
		const url = HelpService.format(__API_USER_PROFILE__, username);
		return this.httpRequestService.get(url).pipe(map(result => {
			return this.httpRequestService.mapToModel(User, result.user);
		}));
	}

	public getUserNotification(): Observable<any> {
		return this.httpRequestService.get(__API_USER_NOTIFICATION_GET__).pipe(map(result => {
			return this.httpRequestService.mapToModel(UserNotification, result.notifications);
		}));
	}

	public markNotificationAsRead(notificationId): Observable<any> {
		const url = HelpService.format(__API_USER_NOTIFICATION_AS_READ__, notificationId);
		return this.httpRequestService.put(url, {});
	}

	public markNotificationAsUnread(notificationId): Observable<any> {
		const url = HelpService.format(__API_USER_NOTIFICATION_AS_UNREAD__, notificationId);
		return this.httpRequestService.put(url, {});
	}

	public deleteNotification(notificationId): Observable<any> {
		const url = HelpService.format(__API_USER_NOTIFICATION_DELETE__, notificationId);
		return this.httpRequestService.delete(url);
	}

	public createUserCar(form: AccountCarCreateForm): Observable<any> {
		return this.httpRequestService.post(__API_USER_CAR_CREATE__, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(User, result.user);
		}));
	}

	public updateUserCar(userId: number, form: AccountCarCreateForm): Observable<any> {
		return this.httpRequestService.put(__API_USER_CAR_UPDATE__ + '/' + userId, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(User, result.user);
		}));
	}

	public updateUserFirstName(form: AccountFirstnameForm): Observable<any> {
		return this.httpRequestService.put(__API_USER_FIRSTNAME_UPDATE__, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(User, result.user);
		}));
	}

	public updateUserNotificationEmail(form: AccountNotificationEmailForm): Observable<any> {
		return this.httpRequestService.put(__API_USER_NOTIFICATION_EMAIL_UPDATE__, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(User, result.user);
		}));
	}

	public updateUserLastName(form: AccountLastnameForm): Observable<any> {
		return this.httpRequestService.put(__API_USER_LASTNAME_UPDATE__, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(User, result.user);
		}));
	}

	public updateUserPassword(form: AccountPasswordForm): Observable<any> {
		return this.httpRequestService.put(__API_USER_PASSWORD_UPDATE__, form.toRequestForm());
	}

	public getUserList(form: UserListFilterForm): Observable<any> {
		return this.httpRequestService.post(__API_ADMIN_USER_LIST_GET__, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(User, result.users) as User[];
		}));
	}

	public getUserByUsername(username: string): Observable<any> {
		const url = `admin/user/username/${username}/detail`;
		return this.httpRequestService.get(url).pipe(map((result) => {
			return this.httpRequestService.mapToModel(User, result.user);
		}));
	}

	public getUserByUserId(userId: number): Observable<any> {
		const url = `admin/user/id/${userId}/detail`;
		return this.httpRequestService.get(url).pipe(map((result) => {
			return this.httpRequestService.mapToModel(User, result.user);
		}));
	}

	public getManagementDeclarationUserList(form: UserListFilterForm): Observable<any> {
		return this.httpRequestService.post(__API_MANAGEMENT_DECLARATION_USER_LIST_GET__, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(ManagementDeclarationUser, result.users) as ManagementDeclarationUser[];
		}));
	}

	public getManagementUserCarsList(userId: number): Observable<any> {
		const url = HelpService.format(__API_ADMIN_DECLARATION_GET_USER_CARS_LIST__, userId);
		return this.httpRequestService.get(url).pipe(map((result) => {
			return this.httpRequestService.mapToModel(UserCar, result.cars) as UserCar[];
		}));
	}

	/**
	 * Permissions
	 */
	public getPermissionsByUser(userId) {
		const url = HelpService.format(__API_USER_PERMISSIONS__, userId);
		return this.httpRequestService.get(url).pipe(map(result => {
			return this.httpRequestService.mapToModel(Permission, result.permissions) as Permission[];
		}));
	}

	public updateUserPermission(userId: number, pid: number, status: boolean) {
		const url = HelpService.format(__API_MANAGE_USER_UPDATE_PERMISSION__, userId);
		return this.httpRequestService.post(url, { userId: userId, pid: pid, status: status }).pipe(map((result) => {
			return result;
		}));
	}

	/**
	 * Project manager
	 */
	public getPManagerUserList(form: UserListFilterForm): Observable<any> {
		return this.httpRequestService.post(__API_PMANAGER_MANAGE_USER_LIST_GET__, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(ManagementDeclarationUser, result.users) as ManagementDeclarationUser[];
		}));
	}
}
