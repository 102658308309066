import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzMessageService } from 'ng-zorro-antd/message';
import { interval, Subscription } from 'rxjs';
import { DeclarationModeEnum, DeclarationStatusEnum } from '../../../core/enums';
import { NotificationTypeEnum } from '../../../core/enums/notification-type.enum';
import { AcademicTraining } from '../../../core/models';
import { EventNotification } from '../../../core/models/notification/event-notification.model';
import { StatusBase } from '../../../core/models/status-base/status-base.model';
import { AcademicTrainingService, AdminDeclarationService, DeclarationInteractionService } from '../../../core/services';
import { NotificationInteractionService } from '../../../core/services/interaction-service/notification-interaction.service';
import { AcademicTrainingDeclarationForm } from '../../forms';

@UntilDestroy()
@Component({
    selector: 'academic-training-declaration',
    templateUrl: './academic-training-declaration.component.html',
    styleUrls: ['./academic-training-declaration.component.scss']
})
export class AcademicTrainingDeclarationComponent implements OnInit, OnChanges {

    @Input() academicTraining: AcademicTraining = null;
    @Output() closeEvent: EventEmitter<any> = new EventEmitter();
    @Output() public createEvent: EventEmitter<any> = new EventEmitter();
    @Input() private visible: boolean;
    @Input() public userId;
    @Input() public declarationMode = DeclarationModeEnum.SELF;

    public form: AcademicTrainingDeclarationForm = new AcademicTrainingDeclarationForm();
    public selectedDate: Date = null;
    public locked = false;
    public status: StatusBase;
    public isCreateMode = true;
    public declarationStatusEnum = DeclarationStatusEnum;
    private selectedDateSubscription: Subscription = null;
    private createSubscription: Subscription = null;
    private updateSubscription: Subscription = null;

    constructor(private academicTrainingService: AcademicTrainingService,
                private adminDeclarationService: AdminDeclarationService,
                private nzMessageService: NzMessageService,
                private notificationInteractionService: NotificationInteractionService,
                private declarationInteractionService: DeclarationInteractionService) {
    }

    ngOnInit() {
        this.selectedDateSubscription = this.declarationInteractionService.selectedDate$.subscribe((selectedDate) => {
            this.selectedDate = selectedDate;
        });
        interval(1000).pipe(untilDestroyed(this)).subscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.visible) {
            if (this.academicTraining && this.visible) {
                this.isCreateMode = !( this.academicTraining && this.academicTraining.id );
                this.status = this.academicTraining.status;
                this.form = new AcademicTrainingDeclarationForm();
                this.form.validationForm.get('duration').setValue(this.academicTraining.duration ? this.academicTraining.duration : null);
                this.form.validationForm.get('description').setValue(this.academicTraining.description);

                if (this.academicTraining.status && this.academicTraining.status.id !== DeclarationStatusEnum.WAITING_VALIDATION) {
                    this.form.validationForm.get('duration').disable();
                    this.form.validationForm.get('description').disable();
                    this.locked = true;
                } else {
                    this.locked = false;
                }
            }
        }
    }

    public closeModalHandler() {
        this.closeEvent.emit();
    }

    public sendDeclaration() {
        this.form.validate();
        if (this.selectedDate) {
            const utcDate = Date.UTC(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate());
            this.form.validationForm.get('date').setValue(new Date(utcDate).toISOString());
        }
        if (this.form.validationForm.invalid) {
            this.nzMessageService.error('Erreur de validation');
            return;
        }
        if (this.academicTraining && this.academicTraining.id && typeof this.academicTraining.id !== 'undefined') {
            this.updateDeclaration();
        } else {
            this.createDeclaration();
        }
    }

    private updateDeclaration() {
        this.updateSubscription = this.academicTrainingService.updateAcademicTraining(this.academicTraining.id, this.form)
            // tslint:disable-next-line:no-shadowed-variable
            .subscribe((academicTraining: AcademicTraining) => {
                this.nzMessageService.success('Mise à jour réussie !');
                academicTraining.uuid = this.academicTraining.uuid;
                this.declarationInteractionService.updateAcademicTraining(academicTraining);
                this.closeEvent.emit();
            }, err => {
                this.notificationInteractionService.displayErrorNotification(
                    new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', err.messages));
            });
    }

    private createDeclaration() {
        if (this.declarationMode === DeclarationModeEnum.MANAGER && this.userId) {
            this.createSubscription = this.adminDeclarationService.createAcademicTraining(this.userId, this.form)
                .subscribe((academicTraining: AcademicTraining) => {
                    this.nzMessageService.success('Création réussie !');
                    this.academicTraining = academicTraining;
                    this.declarationInteractionService.createAcademicTraining(academicTraining);
                    this.closeEvent.emit();
                    this.createEvent.emit(this.academicTraining);
                }, err => {
                    this.notificationInteractionService.displayErrorNotification(
                        new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', err.messages));
                });
        } else {
            this.createSubscription = this.academicTrainingService.createAcademicTraining(this.form).subscribe((academicTraining: AcademicTraining) => {
                this.nzMessageService.success('Création réussie !');
                this.academicTraining = academicTraining;
                this.declarationInteractionService.createAcademicTraining(academicTraining);
                this.closeEvent.emit();
                this.createEvent.emit(this.academicTraining);
            }, err => {
                this.notificationInteractionService.displayErrorNotification(
                    new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', err.messages));
            });
        }
    }
}
