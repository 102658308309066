import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { interval, Subscription } from 'rxjs';
import { NotificationTypeEnum } from '../../../../core/enums/notification-type.enum';
import { RequestError, Survey } from '../../../../core/models';
import { EventNotification } from '../../../../core/models/notification/event-notification.model';
import { NotificationInteractionService } from '../../../../core/services/interaction-service/notification-interaction.service';
import { SurveyService } from '../../../../core/services/survey/survey.service';
import { CustomerProjectSatisfactionForm } from '../../../forms';

@UntilDestroy()
@Component({
    selector: 'survey-customer-project-satisfaction',
    templateUrl: './survey-customer-project-satisfaction.component.html',
    styleUrls: ['./survey-customer-project-satisfaction.component.scss'],
})
export class SurveyCustomerProjectSatisfactionComponent implements OnInit, OnChanges {

    @Input() survey: Survey;

    private submitSubscription: Subscription = null;
    public isConfirmLoading = false;
    public displayThanks = false;

    public form = new CustomerProjectSatisfactionForm();

    public q1Question = 'Au cours de la mission, vos remarques ont-elles été prises en compte par le Chef de Projet des Consultants A2L ?';
    public q1CheckOptions = [
        { label: 'Jamais', value: 'a1' },
        { label: 'Parfois', value: 'a2' },
        { label: 'La majorité du temps', value: 'a3' },
        { label: 'Absolument', value: 'a4' },
    ];

    public q2Question = 'Avez-vous eu suffisamment de visibilité sur l\'avancée des travaux tout au long du projet ?';
    public q2CheckOptions = [
        { label: 'Jamais', value: 'a1' },
        { label: 'Parfois', value: 'a2' },
        { label: 'La majorité du temps', value: 'a3' },
        { label: 'Absolument', value: 'a4' },
    ];

    public q3Question = 'Est-ce que vous pensez que toutes les ressources ont été mises à votre disposition pour que cette mission se déroule au mieux ?';
    public q3CheckOptions = [
        { label: 'Jamais', value: 'a1' },
        { label: 'Parfois', value: 'a2' },
        { label: 'La majorité du temps', value: 'a3' },
        { label: 'Absolument', value: 'a4' },
    ];

    public q4Question = 'Avez-vous déjà fait appel à A2L Consultants ?';
    public q4CheckOptions = [
        { label: 'Oui', value: 'a1' },
        { label: 'Non', value: 'a2' },
    ];

    public q5Question = 'La certification ISO 9001 a-t-elle constitué une raison dans votre choix de confier cette mission à A2L Consultants ?';
    public q5CheckOptions = [
        { label: 'Oui', value: 'a1' },
        { label: 'Non', value: 'a2' },
    ];

    public q6Question = 'A combien estimez-vous votre satisfaction globale ?';
    public q6CheckOptions = [
        { label: 'Pas du tout satisfait', value: 'a1' },
        { label: 'Globalement pas satisfait', value: 'a2' },
        { label: 'Globalement satisfait', value: 'a3' },
        { label: 'Entièrement satisfait', value: 'a4' },
    ];

    public q7Question = 'Avez-vous une remarque à faire pour compléter vos réponses aux questions précédentes ?';

    public q8Question = 'Le livrable correspond-il à vos attentes ?';
    public q8CheckOptions = [
        { label: 'Pas du tout', value: 'a1' },
        { label: 'Globalement non', value: 'a2' },
        { label: 'Globalement oui', value: 'a3' },
        { label: 'Complètement', value: 'a4' },
    ];

    public q9Question = 'Vous êtes-vous senti impliqué et informé par les membres d\'A2L Consultants tout au long de ce projet ?';
    public q9CheckOptions = [
        { label: 'Jamais', value: 'a1' },
        { label: 'Parfois', value: 'a2' },
        { label: 'La majorité du temps', value: 'a3' },
        { label: 'Absolument', value: 'a4' },
    ];

    public q10Question = 'Recommanderiez-vous A2L à d\'autres ?';
    public q10CheckOptions = [
        { label: 'Oui', value: 'a1' },
        { label: 'Non', value: 'a2' },
    ];

    public q11Question = 'Seriez-vous prêt à faire appel à A2L Consultants à nouveau pour une mission similaire ?';
    public q11CheckOptions = [
        { label: 'Oui', value: 'a1' },
        { label: 'Non', value: 'a2' },
    ];

    public q12Question = 'Avez-vous une remarque à faire pour compléter vos réponses aux questions précédentes ?';

    constructor(private surveyService: SurveyService,
                private notificationInteractionService: NotificationInteractionService) {
    }

    ngOnInit() {
        interval(1000).pipe(untilDestroyed(this)).subscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.survey.currentValue && changes.survey.currentValue.answers) {
            this.form.recoverFormObject(changes.survey.currentValue.answers, changes.survey.currentValue.status);
        }
    }

    public submit() {
        this.isConfirmLoading = true;
        this.form.validate();
        if (this.form.validationForm.valid) {
            this.submitSubscription = this.surveyService
                .fillSurvey(this.survey.uuid, this.form.toRequestForm()).subscribe(() => {
                }, (error: RequestError) => {
                    this.notificationInteractionService.displayErrorNotification(
                        new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', error.messages));
                }, () => {
                    this.isConfirmLoading = false;
                    this.displayThanks = true;
                });
        } else {
            this.isConfirmLoading = false;
        }
    }
}
