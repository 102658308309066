import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FiscalPeriodCreateForm, FiscalPeriodUpdateForm } from '../../../parameter/forms';
import { FiscalPeriod, ProfilePostDescription } from '../../models';
import {
    __API_ADMIN_FISCAL_PERIOD_CREATE__,
    __API_ADMIN_FISCAL_PERIOD_GET__,
    __API_ADMIN_FISCAL_PERIOD_LATEST_GET__,
    __API_ADMIN_FISCAL_PERIOD_LIST_GET__,
    __API_ADMIN_FISCAL_PERIOD_SIMPLE_LIST_GET__,
    __API_ADMIN_FISCAL_PERIOD_UPDATE__,
    __API_ADMIN_PROFILE_POST_DESCRIPTION_LIST_GET__
} from '../../routes/api.route';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class FiscalPeriodService {

    constructor(private httpRequestService: HttpRequestService) {
    }

    public getFiscalPeriodList(): Observable<any> {
        return this.httpRequestService.get(__API_ADMIN_FISCAL_PERIOD_LIST_GET__).pipe(map(result => {
            return this.httpRequestService.mapToModel(FiscalPeriod, result.fiscal_periods) as FiscalPeriod[];
        }));
    }

    public getProfilePostDescriptionList(): Observable<any> {
        return this.httpRequestService.get(__API_ADMIN_PROFILE_POST_DESCRIPTION_LIST_GET__).pipe(map(result => {
            return this.httpRequestService.mapToModel(ProfilePostDescription, result.profile_post_descriptions) as ProfilePostDescription[];
        }));
    }

    public getSimpleFiscalPeriodList(): Observable<any> {
        return this.httpRequestService.get(__API_ADMIN_FISCAL_PERIOD_SIMPLE_LIST_GET__).pipe(map(result => {
            return this.httpRequestService.mapToModel(FiscalPeriod, result.fiscal_periods) as FiscalPeriod[];
        }));
    }

    public getFiscalPeriod(fiscalPeriodId): Observable<any> {
        const url = __API_ADMIN_FISCAL_PERIOD_GET__.replace('{0}', fiscalPeriodId);
        return this.httpRequestService.get(url).pipe(map(result => {
            return this.httpRequestService.mapToModel(FiscalPeriod, result.details) as FiscalPeriod;
        }));
    }

    public getLatestFiscalPeriod(): Observable<any> {
        return this.httpRequestService.get(__API_ADMIN_FISCAL_PERIOD_LATEST_GET__).pipe(map(result => {
            return this.httpRequestService.mapToModel(FiscalPeriod, result.details) as FiscalPeriod;
        }));
    }

    public createFiscalPeriod(form: FiscalPeriodCreateForm): Observable<any> {
        return this.httpRequestService.post(__API_ADMIN_FISCAL_PERIOD_CREATE__, form.toRequestForm()).pipe(map(result => {
            return this.httpRequestService.mapToModel(FiscalPeriod, result.profile) as FiscalPeriod;
        }));
    }

    public updateFiscalPeriod(form: FiscalPeriodUpdateForm): Observable<any> {
        return this.httpRequestService.put(__API_ADMIN_FISCAL_PERIOD_UPDATE__, form.toRequestForm()).pipe(map(result => {
            return this.httpRequestService.mapToModel(FiscalPeriod, result.profile) as FiscalPeriod;
        }));
    }
}
