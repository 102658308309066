import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    BillingCreditGenerationForm,
    BillingDeliveryGenerationForm,
    BillingDownPaymentGenerationForm,
    BillingInvoiceGenerationForm,
    DeliveryListForm
} from '../../../billing/form';
import { InvoiceListForm } from '../../../billing/form/invoice-list.form';
import { BillingDelivery } from '../../../billing/models/billing-delivery.model';
import { BillingInvoice } from '../../../billing/models/billing-invoice.model';
import { BillingParametersForm } from '../../../parameter/forms';
import { BillingBank } from '../../../parameter/models/billing-bank.model';
import { BillingParameters } from '../../../parameter/models/billing-parameters.model';
import { InvoiceUpdateForm } from '../../../project/forms';
import { HttpRequestService } from '../http/http-request.service';
import { InvoiceScheduleListForm } from '../../../billing/form/invoice-schedule-list.form';

@Injectable()
export class BillingService {

    constructor(private httpRequestService: HttpRequestService) {
    }

    /**
     * Deliveries
     */
    public getDelivery(deliveryId): Observable<BillingDelivery | null> {
        return this.httpRequestService.get(`billing/delivery/${ deliveryId }/details`).pipe(map(result => {
            return this.httpRequestService.mapToModel(BillingDelivery, result.delivery) as BillingDelivery;
        }));
    }

    public getDeliveries(form: DeliveryListForm): Observable<BillingDelivery[] | null> {
        return this.httpRequestService.post('billing/deliveries', form.toRequestForm()).pipe(map(result => {
            return this.httpRequestService.mapToModel(BillingDelivery, result.deliveries) as BillingDelivery[];
        }));
    }

    public generateDeliveryPdf(form: BillingDeliveryGenerationForm) {
        return this.httpRequestService.post('billing/delivery/generate', form.toRequestForm())
            .pipe(map(result => {
                return this.httpRequestService.mapToModel(BillingDelivery, result.delivery) as BillingDelivery;
            }));
    }

    public previewDeliveryPdf(form: BillingDeliveryGenerationForm) {
        return this.httpRequestService.postDownloadFile('billing/delivery/preview', form.toRequestForm(), 'pdf')
            .pipe(map(result => {
                return result;
            }));
    }

    public downloadDeliveryPdf(form: BillingDeliveryGenerationForm) {
        return this.httpRequestService.postDownloadFile('billing/delivery/generate', form.toRequestForm(), 'pdf')
            .pipe(map(result => {
                return result;
            }));
    }

    /**
     * Invoices
     */
    public getScheduleInvoices(form: InvoiceScheduleListForm): Observable<BillingInvoice[] | null> {
        return this.httpRequestService.post('billing/invoices/schedule', form.toRequestForm()).pipe(map(result => {
            return this.httpRequestService.mapToModel(BillingInvoice, result.invoices) as BillingInvoice[];
        }));
    }

    public getInvoices(form: InvoiceListForm): Observable<BillingInvoice[] | null> {
        return this.httpRequestService.post('billing/invoices', form.toRequestForm()).pipe(map(result => {
            return this.httpRequestService.mapToModel(BillingInvoice, result.invoices) as BillingInvoice[];
        }));
    }

    public previewInvoicePdf(form: BillingInvoiceGenerationForm) {
        return this.httpRequestService.postDownloadFile('billing/invoice/preview', form.toRequestForm(), 'pdf')
            .pipe(map(result => {
                return result;
            }));
    }

    public generateInvoicePdf(form: BillingInvoiceGenerationForm) {
        return this.httpRequestService.post('billing/invoice/generate', form.toRequestForm())
            .pipe(map(result => {
                return this.httpRequestService.mapToModel(BillingDelivery, result.delivery) as BillingDelivery;
            }));
    }

    public updateBillingInvoice(invoiceId: number, form: InvoiceUpdateForm): Observable<BillingInvoice | null> {
        return this.httpRequestService.post(`billing/invoice/${ invoiceId }/update`, form.toRequestForm())
            .pipe(map((result) => {
                return this.httpRequestService.mapToModel(BillingInvoice, result.invoice) as BillingInvoice;
            }));
    }

    /**
     * Billing Parameters
     */
    public getBillingParameters(): Observable<BillingParameters | null> {
        return this.httpRequestService.get('billing/parameters').pipe(map(result => {
            return this.httpRequestService.mapToModel(BillingParameters, result.billing_parameter) as BillingParameters;
        }));
    }

    public updateBillingParameters(form: BillingParametersForm): Observable<BillingParameters | null> {
        return this.httpRequestService.post('billing/parameters', form.toRequestForm())
            .pipe(map(result => {
                return this.httpRequestService.mapToModel(BillingParameters, result.billing_parameter) as BillingParameters;
            }));
    }

    /**
     * Credits
     */
    public generateCreditPdf(form: BillingCreditGenerationForm) {
        return this.httpRequestService.post('billing/credit/generate', form.toRequestForm())
            .pipe(map(result => {
                return this.httpRequestService.mapToModel(BillingInvoice, result.credit) as BillingInvoice;
            }));
    }

    public previewCreditPdf(form: BillingCreditGenerationForm) {
        return this.httpRequestService.postDownloadFile('billing/credit/preview', form.toRequestForm(), 'pdf')
            .pipe(map(result => {
                return result;
            }));
    }

    /**
     * Billing Banks
     */
    public getBillingBanks(): Observable<BillingBank[] | null> {
        return this.httpRequestService.get('billing/banks').pipe(map(result => {
            return this.httpRequestService.mapToModel(BillingBank, result.billing_banks) as BillingBank[];
        }));
    }

    /**
     * Down payments
     */
    public generateDownPaymentPdf(form: BillingDownPaymentGenerationForm) {
        return this.httpRequestService.post('billing/down-payment/generate', form.toRequestForm())
            .pipe(map(result => {
                return this.httpRequestService.mapToModel(BillingInvoice, result.down_payment) as BillingInvoice;
            }));
    }

    public previewDownPaymentPdf(form: BillingDownPaymentGenerationForm) {
        return this.httpRequestService.postDownloadFile('billing/down-payment/preview', form.toRequestForm(), 'pdf')
            .pipe(map(result => {
                return result;
            }));
    }
}
