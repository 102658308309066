import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseLayoutComponent } from './components/base-layout/base-layout.component';
import { AuthorizationNeedGuard, UnauthorizationNeedGuard } from './guards';

const routes: Routes = [
    {
        path: '',
        component: BaseLayoutComponent,
        canActivate: [AuthorizationNeedGuard],
        children: [
            {
                path: '',
                redirectTo: 'dashboard/home',
                pathMatch: 'full'
            },
            {
                path: 'dashboard',
                loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'account',
                loadChildren: () => import('../account/account.module').then(m => m.AccountModule)
            },
            {
                path: 'parameter',
                loadChildren: () => import('../parameter/parameter.module').then(m => m.ParameterModule)
            },
            {
                path: 'declaration',
                loadChildren: () => import('../declaration/declaration.module').then(m => m.DeclarationModule)
            },
            {
                path: 'project',
                loadChildren: () => import('../project/project.module').then(m => m.ProjectModule)
            },
            {
                path: 'customer',
                loadChildren: () => import('../customer/customer.module').then(m => m.CustomerModule)
            },
            {
                path: 'supplier',
                loadChildren: () => import('../supplier/supplier.module').then(m => m.SupplierModule)
            },
            {
                path: 'request',
                loadChildren: () => import('../request/request.module').then(m => m.RequestModule)
            },
            {
                path: 'management',
                loadChildren: () => import('../management/management.module').then(m => m.ManagementModule)
            },
            {
                path: 'billing',
                loadChildren: () => import('../billing/billing.module').then(m => m.BillingModule)
            },
            {
                path: 'reporting',
                loadChildren: () => import('../reporting/reporting.module').then(m => m.ReportingModule)
            },
            {
                path: 'statistics',
                loadChildren: () => import('../statistics/statistics.module').then(m => m.StatisticsModule)
            }
        ]
    },
    {
        path: 'auth',
        canActivate: [UnauthorizationNeedGuard],
        loadChildren: () => import('../auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'survey',
        loadChildren: () => import('../survey/survey.module').then(m => m.SurveyModule)
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class CoreRoutingModule {
}
