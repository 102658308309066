<div #calendarCell
     (click)="clickOnCellHandler($event);"
     *ngIf="date" class="date-cell">
	<div *ngIf="holiday">
		<span class="holiday-cell">{{ holiday.name }}</span>
	</div>
	<div *ngIf="activities && activities.length > 0">
		<nz-badge
				*ngFor="let activity of activities"
				[nzText]="activity.project.name"
				nzColor="#000000"></nz-badge>
	</div>
	<div *ngIf="expenseReports && expenseReports.length > 0">
		<nz-badge
				*ngFor="let expenseReport of expenseReports"
				[nzText]="expenseReport.category.name"
				nzColor="#fa8c16"></nz-badge>
	</div>
	<div *ngIf="absences && absences.length > 0">
		<nz-badge
				*ngFor="let absence of absences"
				[nzText]="absence.type.name"
				nzColor="#f5222d"></nz-badge>
	</div>
	<div *ngIf="internalActivities && internalActivities.length > 0">
		<nz-badge
				*ngFor="let internalActivity of internalActivities"
				[nzText]="internalActivity.type.name"
				nzColor="#bfbfbf"></nz-badge>
	</div>
	<div *ngIf="academicTrainings && academicTrainings.length > 0">
		<nz-badge
				*ngFor="let academicTraining of academicTrainings"
				nzColor="#f5222d"
				nzText="Formation académique"></nz-badge>
	</div>
</div>
