import { Validators } from '@angular/forms';
import { BaseForm } from './base.form';

export class FileUploadForm extends BaseForm {
    constructor() {
        super();
        this.validationForm = this.formBuilder.group({
            file: [null],
            type: [null, Validators.required],
        });
    }

    public toRequestForm(): any {
        const formData = new FormData();
        formData.append('file', this.validationForm.get('file').value);
        formData.append('type', this.validationForm.get('type').value);
        return formData;
    }
}
