import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzMessageService } from 'ng-zorro-antd/message';
import { interval, Subscription } from 'rxjs';
import { AbsenceType, Project } from '../../../core/models';
import { Absence } from '../../../core/models/absence/absence.model';
import { AbsenceService, DeclarationInteractionService, ProjectService } from '../../../core/services';
import { NotificationInteractionService } from '../../../core/services/interaction-service/notification-interaction.service';
import { AbsenceDeclarationMultiForm } from '../../forms';

@UntilDestroy()
@Component({
    selector: 'absence-declaration-multi',
    templateUrl: './absence-declaration-multi.component.html',
    styleUrls: ['./absence-declaration-multi.component.scss']
})
export class AbsenceDeclarationMultiComponent implements OnInit {

    @Output() public closeEvent: EventEmitter<any> = new EventEmitter();
    public form: AbsenceDeclarationMultiForm = new AbsenceDeclarationMultiForm();
    public projects: Project[] = [];
    public absenceTypes: AbsenceType[] = [];
    private projectSubscription: Subscription = null;
    private createSubscription: Subscription = null;
    private absenceTypeSubscription: Subscription = null;

    constructor(private absenceService: AbsenceService,
                private nzMessageService: NzMessageService,
                private notificationInteractionService: NotificationInteractionService,
                private declarationInteractionService: DeclarationInteractionService,
                private projectService: ProjectService) {
        this.absenceTypeSubscription = this.absenceService.getAbsenceTypeList().subscribe(types => {
            this.absenceTypes = types;
        });

        this.projectSubscription = this.projectService.getProjectsAndLots().subscribe(projects => {
            this.projects = projects;
        });
    }

    ngOnInit() {
        interval(1000).pipe(untilDestroyed(this)).subscribe();
    }

    public sendDeclaration() {
        this.form.validate();
        if (this.form.validationForm.invalid) {
            this.nzMessageService.error('Veuillez remplir tous les champs obligatoires.');
            return;
        }

        this.createDeclarationMulti();
    }

    public closeModelHandler() {
        this.closeEvent.emit();
    }

    private createDeclarationMulti() {
        this.createSubscription = this.absenceService.createMultiAbsence(this.form).subscribe((absences: Absence[]) => {
            absences.forEach((item: Absence) => {
                this.declarationInteractionService.createAbsence(item);
            });
            this.closeModelHandler();
            this.nzMessageService.success('Création réussie !');
            this.form = new AbsenceDeclarationMultiForm();
        }, err => {
            if (err && err.messages) {
                this.nzMessageService.error(err.messages.join('\n'));
            }
        });
    }
}
