import { PropertyMapper } from '../../decorators';
import { StatusBase } from '../status-base/status-base.model';
import { User } from '../user/user.model';
import { SupplierInvoiceLot } from './supplier-invoice-lot.model';
import { SupplierOrder } from './supplier-order.model';
import { Supplier } from './supplier.model';
import { MediaFile } from '../media/media-file.model';
import { SupplierInvoiceType } from './supplier-invoice-type.model';

export class SupplierInvoice {

	@PropertyMapper()
	public id: number;

	@PropertyMapper({ jsonProperty: 'type', typeOfObject: SupplierInvoiceType })
	public type: SupplierInvoiceType;

	@PropertyMapper({ jsonProperty: 'date', typeOfObject: Date })
	public date: Date;

	@PropertyMapper({ jsonProperty: 'reference' })
	public reference: string;

	@PropertyMapper({ jsonProperty: 'status', typeOfObject: StatusBase })
	public status: StatusBase;

	@PropertyMapper({ jsonProperty: 'validator', typeOfObject: User })
	public validator: User;

	@PropertyMapper({ jsonProperty: 'supplier', typeOfObject: Supplier })
	public supplier: Supplier;

	@PropertyMapper({ jsonProperty: 'supplier_order', typeOfObject: SupplierOrder })
	public order: SupplierOrder;

	@PropertyMapper({ jsonProperty: 'lots', typeOfObject: SupplierInvoiceLot })
	public lots: SupplierInvoiceLot[];

	@PropertyMapper({ jsonProperty: 'ht_amount' })
	public htAmount: number;

	@PropertyMapper({ jsonProperty: 'vat_rate' })
	public vatRate: number;

	@PropertyMapper({ jsonProperty: 'vat_amount' })
	public vatAmount: number;

	@PropertyMapper({ jsonProperty: 'ttc_amount' })
	public ttcAmount: number;

	@PropertyMapper({ jsonProperty: 'paid_amount' })
	public paidAmount: number;

	@PropertyMapper({ jsonProperty: 'refund_amount' })
	public refundAmount: number;

	@PropertyMapper({ jsonProperty: 'can_be_turned_into_credit' })
	public canBeTurnedIntoCredit: boolean;

	@PropertyMapper({ jsonProperty: 'expected_payment_date', typeOfObject: Date })
	public expectedPaymentDate: Date;

	@PropertyMapper({ jsonProperty: 'reel_payment_date', typeOfObject: Date })
	public reelPaymentDate: Date;

	@PropertyMapper({ jsonProperty: 'payments' })
	public payments;

	@PropertyMapper({ jsonProperty: 'comment' })
	public comment: string;

	@PropertyMapper({ jsonProperty: 'files', typeOfObject: MediaFile })
	public files: MediaFile[];

	@PropertyMapper({ jsonProperty: 'credit', typeOfObject: SupplierInvoice })
	public credit: SupplierInvoice;
}
