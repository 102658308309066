import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CustomerSatisfactionStatistics } from '../../../models/customerSatisfactionStatistics.model';

@UntilDestroy()
@Component({
	templateUrl: './survey-customer-satisfaction-v2.component.html',
	styleUrls: ['./survey-customer-satisfaction-v2.component.scss']
})
export class SurveyCustomerSatisfactionV2Component implements OnInit {

	@Input() statistics: CustomerSatisfactionStatistics[] = [];

	public q1Question = 'Avez-vous eu suffisamment de visibilité sur l\'avancée des travaux tout au long du projet ?';
	public q1CheckOptions = [
		{ label: 'Jamais', value: 'a1' },
		{ label: 'Parfois', value: 'a2' },
		{ label: 'La majorité du temps', value: 'a3' },
		{ label: 'Absolument', value: 'a4' },
	];

	public q2Question = 'Les ressources mises à votre disposition ont-elles été suffisantes ?';
	public q2CheckOptions = [
		{ label: 'Jamais', value: 'a1' },
		{ label: 'Parfois', value: 'a2' },
		{ label: 'La majorité du temps', value: 'a3' },
		{ label: 'Absolument', value: 'a4' },
	];

	public q3Question = 'Avez-vous déjà fait appel à A2L Consultants ?';
	public q3CheckOptions = [
		{ label: 'Oui', value: 'a1' },
		{ label: 'Non', value: 'a2' },
	];

	public q4Question = 'La certification ISO 9001 a-t-elle constitué une raison dans votre choix de confier cette mission à A2L Consultants ?';
	public q4CheckOptions = [
		{ label: 'Oui', value: 'a1' },
		{ label: 'Non', value: 'a2' },
	];

	public q5Question = 'A combien estimez-vous votre statistic globale ?';
	public q5CheckOptions = [
		{ label: 'Pas du tout satisfait', value: 'a1' },
		{ label: 'Globalement pas satisfait', value: 'a2' },
		{ label: 'Globalement satisfait', value: 'a3' },
		{ label: 'Entièrement satisfait', value: 'a4' },
	];

	public q6Question = 'Le livrable correspond-il à vos attentes ?';
	public q6CheckOptions = [
		{ label: 'Pas du tout', value: 'a1' },
		{ label: 'Globalement non', value: 'a2' },
		{ label: 'Globalement oui', value: 'a3' },
		{ label: 'Complètement', value: 'a4' },
	];

	public q7Question = 'Vous êtes-vous senti impliqué et informé par les membres d\'A2L Consultants tout au long de ce projet ?';
	public q7CheckOptions = [
		{ label: 'Jamais', value: 'a1' },
		{ label: 'Parfois', value: 'a2' },
		{ label: 'La majorité du temps', value: 'a3' },
		{ label: 'Absolument', value: 'a4' },
	];

	public q8Question = 'Recommanderiez-vous A2L à d\'autres ?';
	public q8CheckOptions = [
		{ label: 'Oui', value: 'a1' },
		{ label: 'Non', value: 'a2' },
	];

	public q9Question = 'Seriez-vous prêt à faire appel à A2L Consultants à nouveau pour une mission similaire ?';
	public q9CheckOptions = [
		{ label: 'Oui', value: 'a1' },
		{ label: 'Non', value: 'a2' },
	];

	public q10Question = 'Avez-vous une remarque à faire pour compléter vos réponses aux questions précédentes ?';

	constructor() {
	}

	ngOnInit() {
		this.updateCustomerSatisfactionData();
	}

	private updateCustomerSatisfactionData() {
		for (const statistic of this.statistics) {
			if (!statistic.answers) {
				return;
			}
			for (const element of this.q1CheckOptions) {
				if (statistic.answers.q1 === element.value) {
					statistic.formattedAnswers.q1 = element.label;
				}
			}
			for (const element of this.q2CheckOptions) {
				if (statistic.answers.q2 === element.value) {
					statistic.formattedAnswers.q2 = element.label;
				}
			}
			for (const element of this.q3CheckOptions) {
				if (statistic.answers.q3 === element.value) {
					statistic.formattedAnswers.q3 = element.label;
				}
			}
			for (const element of this.q4CheckOptions) {
				if (statistic.answers.q4 === element.value) {
					statistic.formattedAnswers.q4 = element.label;
				}
			}
			for (const element of this.q5CheckOptions) {
				if (statistic.answers.q5 === element.value) {
					statistic.formattedAnswers.q5 = element.label;
				}
			}
			for (const element of this.q6CheckOptions) {
				if (statistic.answers.q6 === element.value) {
					statistic.formattedAnswers.q6 = element.label;
				}
			}
			for (const element of this.q7CheckOptions) {
				if (statistic.answers.q7 === element.value) {
					statistic.formattedAnswers.q7 = element.label;
				}
			}
			for (const element of this.q8CheckOptions) {
				if (statistic.answers.q8 === element.value) {
					statistic.formattedAnswers.q8 = element.label;
				}
			}
			for (const element of this.q9CheckOptions) {
				if (statistic.answers.q9 === element.value) {
					statistic.formattedAnswers.q9 = element.label;
				}
			}
			statistic.formattedAnswers.q10 = statistic.answers.q10;
		}
	}
}
