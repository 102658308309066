import { PropertyMapper } from '../../decorators';

export class LotUser {

	@PropertyMapper()
	public id: number;

	@PropertyMapper({ jsonProperty: 'user_id' })
	public userId: number;

	@PropertyMapper({ jsonProperty: 'assigned_fee' })
	public assignedFee: number;

	@PropertyMapper({ jsonProperty: 'reel_fee' })
	public reelFee: number;

	@PropertyMapper({ jsonProperty: 'assigned_workload' })
	public assignedWorkload: number;

	@PropertyMapper({ jsonProperty: 'reel_workload' })
	public reelWorkload: number;

	@PropertyMapper({ jsonProperty: 'declared_workload' })
	public declaredWorkload: number;

	@PropertyMapper({ jsonProperty: 'remaining_workload' })
	public remainingWorkload: number;
}
