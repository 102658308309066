import { Injectable } from '@angular/core';
import { ProjectListPageConfig } from '../../class/projectListPageConfig';

@Injectable()
export class NavigationInteractionService {

    public projectListPageConfig: ProjectListPageConfig = new ProjectListPageConfig();

    constructor() {
    }

    public setProjectListPageConfig(projectListPageConfig: ProjectListPageConfig) {
        this.projectListPageConfig = projectListPageConfig;
    }

    public getProjectListPageConfig() {
        return this.projectListPageConfig;
    }

    public clearProjectListPageConfig() {
        this.projectListPageConfig = new ProjectListPageConfig();
    }
}
