import { PropertyMapper } from '../../decorators';
import { DeclarationTypeEnum } from '../../enums';
import { Declaration } from '../declaration/declaration.model';
import { Lot } from '../lot/lot.model';
import { Project } from '../project/project.model';
import { UserProfileHistory } from '../user-profile/user-profile-history.model';

export class Activity extends Declaration {

    constructor() {
        super();
        this.declarationType = DeclarationTypeEnum.ACTIVITY;
    }

    @PropertyMapper()
    public description: string;

    @PropertyMapper()
    public duration: string;

    @PropertyMapper()
    public lot: Lot;

    @PropertyMapper({ jsonProperty: 'project', typeOfObject: Project })
    public project: Project;

    @PropertyMapper({ jsonProperty: 'profile', typeOfObject: UserProfileHistory })
    public profile: UserProfileHistory;
}
