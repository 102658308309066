import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbsenceRequestCreateForm, ExpenseReportRequestForm, RequestListGetForm } from '../../../request/forms';
import { RequestTypeEnum } from '../../enums';
import { AbsenceRequest } from '../../models/absence/absence-request.model';
import { ExpenseReportRequest } from '../../models/expense-report/expense-report-request.model';
import { RequestBase } from '../../models/request-base/request-base.model';
import { StatusBase } from '../../models/status-base/status-base.model';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class RequestService {

    constructor(private httpRequestService: HttpRequestService) {
    }

    public getUserRequests(userId: number, form: RequestListGetForm): Observable<RequestBase[]> {
        return this.httpRequestService.post(`request/user/${ userId }/list`, form.toRequestForm()).pipe(map((result) => {
            const tmp: RequestBase[] = [];

            for (const request of result.requests) {
                if (request.request_type === RequestTypeEnum.ABSENCE) {
                    const absence = this.httpRequestService.mapToModel(AbsenceRequest, request) as AbsenceRequest;
                    tmp.push(absence);
                } else {
                    const expenseReport = this.httpRequestService.mapToModel(ExpenseReportRequest, request) as ExpenseReportRequest;
                    tmp.push(expenseReport);
                }
            }
            return tmp;
        }));
    }

    public getMyAbsenceRequests(form: RequestListGetForm): Observable<AbsenceRequest[] | null> {
        return this.httpRequestService.post('request/my-absence-requests', form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(AbsenceRequest, result.absenceRequests) as AbsenceRequest[];
        }));
    }

    public getMyExpenseReportRequests(form: RequestListGetForm): Observable<ExpenseReportRequest[] | null> {
        return this.httpRequestService.post('request/my-expense-report-requests', form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ExpenseReportRequest, result.expenseReportRequests) as ExpenseReportRequest[];
        }));
    }

    public createAbsenceRequest(form: AbsenceRequestCreateForm): Observable<any> {
        return this.httpRequestService.post('request/absence/create', form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(AbsenceRequest, result.absenceRequest) as AbsenceRequest;
        }));
    }

    public updateAbsenceRequest(id: number, form: AbsenceRequestCreateForm): Observable<any> {
        return this.httpRequestService.put(`request/absence/${ id }/update`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(AbsenceRequest, result.absenceRequest) as AbsenceRequest;
        }));
    }

    public deleteAbsenceRequest(id: number): Observable<any> {
        return this.httpRequestService.delete(`request/absence/${ id }/delete`);
    }

    public getExpenseReportRequest(id: number): Observable<any> {
        return this.httpRequestService.get(`request/expense-report/${ id }`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ExpenseReportRequest, result.expenseReportRequest) as ExpenseReportRequest;
        }));
    }

    public createExpenseReportRequest(form: ExpenseReportRequestForm): Observable<any> {
        return this.httpRequestService.post('request/expense-report/create', form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ExpenseReportRequest, result.expenseReportRequest) as ExpenseReportRequest;
        }));
    }

    public updateExpenseReportRequest(id: number, form: ExpenseReportRequestForm): Observable<any> {
        return this.httpRequestService.put(`request/expense-report/${ id }/update`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ExpenseReportRequest, result.expenseReportRequest) as ExpenseReportRequest;
        }));
    }

    public deleteExpenseReportRequest(id: number): Observable<any> {
        return this.httpRequestService.delete(`request/expense-report/${ id }/delete`);
    }

    public validateAbsenceRequest(id: number): Observable<any> {
        return this.httpRequestService.post(`admin/absence-request/${ id }/validate`, {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(StatusBase, result.status);
        }));
    }

    public denyAbsenceRequest(id: number): Observable<any> {
        return this.httpRequestService.post(`admin/absence-request/${ id }/deny`, {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(StatusBase, result.status);
        }));
    }

    public validateExpenseReportRequest(id: number): Observable<any> {
        return this.httpRequestService.post(`admin/expense-report-request/${ id }/validate`, {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(StatusBase, result.status);
        }));
    }

    public validateAndPayExpenseReportRequest(id: number): Observable<any> {
        return this.httpRequestService.post(`admin/expense-report-request/${ id }/validate-and-pay`, {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(StatusBase, result.status);
        }));
    }

    public denyExpenseReportRequest(id: number): Observable<any> {
        return this.httpRequestService.post(`admin/expense-report-request/${ id }/deny`, {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(StatusBase, result.status);
        }));
    }
}
