import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { mergeMap, skipWhile } from 'rxjs/operators';
import { AuthStoreSelectors, RootStoreState } from '../../root-store';
import { RouterService } from '../services';

@Injectable()
export class AuthorizationNeedGuard implements CanActivate {

    constructor(private store: Store<RootStoreState.State>,
                private routerService: RouterService) {
    }

    canActivate(): Observable<boolean> {
        return this.store.pipe(
            select(AuthStoreSelectors.selectIsAuthenticated),
            skipWhile(isAuthenticated => isAuthenticated === null),
            mergeMap((isAuthenticated) => {
                if (!isAuthenticated) {
                    this.routerService.gotoLogin();
                    return [false];
                }
                return [true];
            }));
    }
}
