import { PropertyMapper } from '../../core/decorators';

export class BillingParameters {

    @PropertyMapper({ jsonProperty: 'legal_form' })
    public legalForm: string;

    @PropertyMapper({ jsonProperty: 'company' })
    public company: string;

    @PropertyMapper({ jsonProperty: 'address_line_1' })
    public addressLine1: string;

    @PropertyMapper({ jsonProperty: 'address_line_2' })
    public addressLine2: string;

    @PropertyMapper({ jsonProperty: 'zipcode' })
    public zipcode: string;

    @PropertyMapper({ jsonProperty: 'city' })
    public city: string;

    @PropertyMapper({ jsonProperty: 'phone' })
    public phone: string;

    @PropertyMapper({ jsonProperty: 'fax' })
    public fax: string;

    @PropertyMapper({ jsonProperty: 'email' })
    public email: string;

    @PropertyMapper({ jsonProperty: 'bank_name' })
    public bankName: string;

    @PropertyMapper({ jsonProperty: 'iban' })
    public iban: string;

    @PropertyMapper({ jsonProperty: 'bic' })
    public bic: string;

    @PropertyMapper({ jsonProperty: 'rcs' })
    public rcs: string;

    @PropertyMapper({ jsonProperty: 'naf' })
    public naf: string;

    @PropertyMapper({ jsonProperty: 'capitale' })
    public capitale: string;

    @PropertyMapper({ jsonProperty: 'vat_number' })
    public vatNumber: string;
}
