import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzMessageService } from 'ng-zorro-antd/message';
import { interval, Subscription } from 'rxjs';
import { DeclarationModeEnum, DeclarationStatusEnum, LotStatusEnum } from '../../../core/enums';
import { NotificationTypeEnum } from '../../../core/enums/notification-type.enum';
import { Activity, Lot, Project } from '../../../core/models';
import { EventNotification } from '../../../core/models/notification/event-notification.model';
import { StatusBase } from '../../../core/models/status-base/status-base.model';
import { ActivityService, AdminDeclarationService, DeclarationInteractionService } from '../../../core/services';
import { HttpRequestService } from '../../../core/services/http/http-request.service';
import { NotificationInteractionService } from '../../../core/services/interaction-service/notification-interaction.service';
import { ActivityDeclarationForm } from '../../forms';

@UntilDestroy()
@Component({
    selector: 'activity-declaration',
    templateUrl: './activity-declaration.component.html',
    styleUrls: ['./activity-declaration.component.scss']
})
export class ActivityDeclarationComponent implements OnInit, OnChanges {

    @Output() public createEvent: EventEmitter<any> = new EventEmitter();
    @Input() public activity: Activity;
    @Output() public closeEvent: EventEmitter<any> = new EventEmitter();
    @Input() public visible: boolean;
    @Input() public userId;
    @Input() public declarationMode = DeclarationModeEnum.SELF;

    public form: ActivityDeclarationForm = new ActivityDeclarationForm();
    public projects: Project[] = [];
    public lots: Lot[] = [];
    public selectedLot: Lot = null;
    public selectedDate: Date = null;
    public locked = false;
    public status: StatusBase;
    public isCreateMode = true;
    public isConfirmLoading = false;
    public declarationStatusEnum = DeclarationStatusEnum;
    public displayClosedLotWarning = false;

    private projectSubscription: Subscription = null;
    private createSubscription: Subscription = null;
    private updateSubscription: Subscription = null;
    private selectedDateSubscription: Subscription = null;

    constructor(private activityService: ActivityService,
                private adminDeclarationService: AdminDeclarationService,
                private nzMessageService: NzMessageService,
                private notificationInteractionService: NotificationInteractionService,
                private declarationInteractionService: DeclarationInteractionService,
                private httpRequestService: HttpRequestService) {
    }

    ngOnInit() {
        this.selectedDateSubscription = this.declarationInteractionService.selectedDate$.subscribe((selectedDate) => {
            this.selectedDate = selectedDate;
        });
        if (this.declarationMode === DeclarationModeEnum.MANAGER && this.userId) {
            this.projectSubscription = this.adminDeclarationService.getProjectsForDeclarationByUserId(this.userId).subscribe(projects => {
                this.projects = projects;
                this.getLotsOfProject();
            });
        } else {
            this.projectSubscription = this.activityService.getProjectsForDeclarations().subscribe(projects => {
                this.projects = projects;
                this.getLotsOfProject();
            });
        }
        interval(1000).pipe(untilDestroyed(this)).subscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.visible) {
            if (this.visible && this.activity) {
                this.isCreateMode = !(this.activity && this.activity.id);
                this.status = this.activity.status;
                this.form = new ActivityDeclarationForm();
                this.form.validationForm.get('date').setValue(this.activity.date ? this.activity.date : null);
                this.form.validationForm.get('projectId').setValue(this.activity.project ? this.activity.project.id : null);
                this.form.validationForm.get('lotId').setValue(this.activity.lot ? this.activity.lot.id : null);
                this.form.validationForm.get('duration').setValue(this.activity.duration ? this.activity.duration : null);
                this.form.validationForm.get('description').setValue(this.activity.description);

                if (this.activity.status && this.activity.status.id !== DeclarationStatusEnum.WAITING_VALIDATION) {
                    this.form.validationForm.get('projectId').disable();
                    this.form.validationForm.get('lotId').disable();
                    this.form.validationForm.get('duration').disable();
                    this.form.validationForm.get('description').disable();
                    this.locked = true;
                } else {
                    this.locked = false;
                }

                this.getLotsOfProject();
            }
        }
    }

    public closeModalHandler() {
        this.closeEvent.emit();
    }

    public sendDeclaration() {
        this.form.validate();
        if (this.selectedDate) {
            const utcDate = Date.UTC(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate());
            this.form.validationForm.get('date').setValue(new Date(utcDate).toISOString());
        }
        if (this.form.validationForm.invalid) {
            this.nzMessageService.error('Erreur de validation');
            return;
        }
        if (this.activity && this.activity.id !== null && typeof this.activity.id !== 'undefined') {
            this.updateDeclaration();
        } else {
            this.createDeclaration();
        }
    }

    public projectChangedHandler(projectId: number) {
        this.form.validationForm.get('lotId').setValue(null);
        this.getLotsOfProject();
    }

    private updateDeclaration() {
        this.isConfirmLoading = true;
        this.updateSubscription = this.activityService.updateActivity(this.activity.id, this.form)
            .subscribe((activity: Activity) => {
                activity.uuid = this.activity.uuid;
                this.nzMessageService.success('Mise à jour réussie !');
                this.declarationInteractionService.updateActivity(activity);
                this.closeEvent.emit();
            }, err => {
                this.notificationInteractionService.displayErrorNotification(
                    new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', err.messages));
            }, () => {
                this.isConfirmLoading = false;
            });
    }

    private createDeclaration() {
        this.isConfirmLoading = true;
        if (this.declarationMode === DeclarationModeEnum.MANAGER && this.userId) {
            this.createSubscription = this.adminDeclarationService.createActivity(this.userId, this.form).subscribe((activity: Activity) => {
                this.activity = activity;
                this.nzMessageService.success('Création réussie !');
                this.declarationInteractionService.createActivity(activity);
                this.closeEvent.emit();
                this.createEvent.emit(this.activity);
            }, err => {
                this.notificationInteractionService.displayErrorNotification(
                    new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', err.messages));
                this.isConfirmLoading = false;
            }, () => {
                this.isConfirmLoading = false;
            });
        } else {
            this.createSubscription = this.activityService.createActivity(this.form).subscribe((activity: Activity) => {
                this.activity = activity;
                this.nzMessageService.success('Création réussie !');
                this.declarationInteractionService.createActivity(activity);
                this.closeEvent.emit();
                this.createEvent.emit(this.activity);
            }, err => {
                this.notificationInteractionService.displayErrorNotification(
                    new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', err.messages));
                this.isConfirmLoading = false;
            }, () => {
                this.isConfirmLoading = false;
            });
        }
    }

    private getLotsOfProject() {
        const projectId = this.form.validationForm.get('projectId').value;
        if (projectId) {
            this.projects.forEach((project: Project) => {
                if (project.id === projectId) {
                    this.lots = this.httpRequestService.mapToModel(Lot, project.lots) as Lot[];
                }
            });
        }
    }

    /**
     * Event handler
     */
    public lotChangeHandler($event) {
        if ($event && this.lots.length > 0) {
            this.selectedLot = this.lots.find((lot) => {
                return lot.id === $event;
            });
            this.displayClosedLotWarning = this.selectedLot && this.selectedLot.status.id === LotStatusEnum.FULL_DELIVERY;
        } else {
            this.displayClosedLotWarning = false;
        }
    }
}
