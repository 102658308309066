import { PropertyMapper } from '../../core/decorators';
import { Customer, ProjectStatus, ProjectType, User } from '../../core/models';

export class ProjectStatistics {

    /**
     * WARNING : PROPERTIES IN THIS MODEL DOES NO HAVE SAME MEANING THAT THOSE IN PROJECT MODEL
     */

    @PropertyMapper()
    public id: number;

    @PropertyMapper()
    public name: string;

    @PropertyMapper()
    public reference: string;

    @PropertyMapper()
    public origin: string;

    @PropertyMapper({ jsonProperty: 'origin_specify' })
    public originSpecify: string;

    @PropertyMapper({ jsonProperty: 'first_contact', typeOfObject: Date })
    public firstContact: Date;

    @PropertyMapper({ jsonProperty: 'ptf_sending_date', typeOfObject: Date })
    public ptfSendingDate: Date;

    @PropertyMapper({ jsonProperty: 'ptf_count' })
    public ptfCount;

    @PropertyMapper({ jsonProperty: 'total_order_amount' })
    public totalOrderAmount;

    @PropertyMapper({ jsonProperty: 'amendment_count' })
    public amendmentCount;

    @PropertyMapper({ jsonProperty: 'intervention_count' })
    public interventionCount;

    @PropertyMapper({ jsonProperty: 'production_date', typeOfObject: Date })
    public productionDate: Date;

    @PropertyMapper({ jsonProperty: 'closing_date', typeOfObject: Date })
    public closingDate: Date;

    @PropertyMapper({ jsonProperty: 'last_delivery_signature_date', typeOfObject: Date })
    public lastDeliverySignatureDate: Date;

    @PropertyMapper({ jsonProperty: 'name_reference' })
    public nameReference: string;

    @PropertyMapper({ jsonProperty: 'status', typeOfObject: ProjectStatus })
    public status: ProjectStatus;

    @PropertyMapper({ jsonProperty: 'type', typeOfObject: ProjectType })
    public type: ProjectType;

    @PropertyMapper({ jsonProperty: 'customer_direct', typeOfObject: Customer })
    public customerDirect: Customer;

    @PropertyMapper({ jsonProperty: 'manager_name' })
    public managerName: User;

    @PropertyMapper({ jsonProperty: 'start', typeOfObject: Date })
    public start: Date;

    @PropertyMapper({ jsonProperty: 'end', typeOfObject: Date })
    public end: Date;

    @PropertyMapper({ jsonProperty: 'expected_turnover' })
    public expectedTurnover;

    @PropertyMapper({ jsonProperty: 'expected_turnover_optional' })
    public expectedTurnoverOptional;

    @PropertyMapper({ jsonProperty: 'reel_turnover' })
    public reelTurnover;

    @PropertyMapper({ jsonProperty: 'reel_turnover_optional' })
    public reelTurnoverOptional;

    @PropertyMapper({ jsonProperty: 'turnover_on_completion' })
    public turnoverOnCompletion;

    @PropertyMapper({ jsonProperty: 'expected_cost' })
    public expectedCost;

    @PropertyMapper({ jsonProperty: 'expected_cost_optional' })
    public expectedCostOptional;

    @PropertyMapper({ jsonProperty: 'reel_cost' })
    public reelCost;

    @PropertyMapper({ jsonProperty: 'reel_cost_optional' })
    public reelCostOptional;

    @PropertyMapper({ jsonProperty: 'cost_on_completion' })
    public costOnCompletion;

    @PropertyMapper({ jsonProperty: 'expected_margin' })
    public expectedMargin;

    @PropertyMapper({ jsonProperty: 'reel_margin' })
    public reelMargin;

    @PropertyMapper({ jsonProperty: 'margin_on_completion' })
    public marginOnCompletion;

    @PropertyMapper({ jsonProperty: 'expected_target_profit' })
    public expectedTargetProfit;

    @PropertyMapper({ jsonProperty: 'expected_profit' })
    public expectedProfit;

    @PropertyMapper({ jsonProperty: 'reel_profit' })
    public reelProfit;

    @PropertyMapper({ jsonProperty: 'profit_on_completion' })
    public profitOnCompletion;

    @PropertyMapper({ jsonProperty: 'profit_indicator' })
    public profitIndicator;
}
