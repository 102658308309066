import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DashboardLot } from '../../../dashboard/models/lot.model';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class DashboardService {

    constructor(private httpRequestService: HttpRequestService) {
    }

    public getDashboardMainStats(): Observable<[] | null> {
        return this.httpRequestService.get('dashboard/main-stats').pipe(map(result => {
            return result.data;
        }));
    }

    public getLateDeliveryLot(statusArray): Observable<any> {
        return this.httpRequestService.post(`dashboard/late-delivery-lot`, { status: statusArray }).pipe(map((result) => {
            return this.httpRequestService.mapToModel(DashboardLot, result.lots);
        }));
    }
}
