import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerSatisfactionStatistics } from '../../../statistics/models/customerSatisfactionStatistics.model';
import { CustomerStatistics } from '../../../statistics/models/customerStatistics.model';
import { DeliveryStatistics } from '../../../statistics/models/deliveryStatistics.model';
import { projectFeedbackStatistics } from '../../../statistics/models/projectFeedbackStatistics.model';
import { ProjectStatistics } from '../../../statistics/models/projectStatistics.model';
import { UserStatistics } from '../../../statistics/models/userStatistics.model';
import { HttpRequestService } from '../http/http-request.service';
import { ProjectUserSatisfactionStatistics } from '../../../statistics/models/projectUserSatisfactionStatistics.model';
import { SurveyStatisticsItem } from '../../../statistics/models/surveyStatisticsItem';
import {
	StatisticsProjectManagerFeedbackV1Component
} from '../../../statistics/components/feedback/statistics-project-manager-feedback-v1/statistics-project-manager-feedback-v1.component';
import {
	StatisticsProjectManagerFeedbackV2Component
} from '../../../statistics/components/feedback/statistics-project-manager-feedback-v2/statistics-project-manager-feedback-v2.component';
import {
	StatisticsProjectManagerFeedbackV3Component
} from '../../../statistics/components/feedback/statistics-project-manager-feedback-v3/statistics-project-manager-feedback-v3.component';
import {
	SurveyCustomerSatisfactionV1Component
} from '../../../statistics/components/survey/survey-customer-satisfaction-v1/survey-customer-satisfaction-v1.component';
import {
	SurveyCustomerSatisfactionV2Component
} from '../../../statistics/components/survey/survey-customer-satisfaction-v2/survey-customer-satisfaction-v2.component';

@Injectable()
export class StatisticService {

	private surveyStatisticsDict = [
		{
			reference: 1,
			component: StatisticsProjectManagerFeedbackV1Component
		},
		{
			reference: 2,
			component: StatisticsProjectManagerFeedbackV2Component
		},
		{
			reference: 3,
			component: StatisticsProjectManagerFeedbackV3Component
		},
		{
			reference: 'public.customer_project_satisfaction',
			component: SurveyCustomerSatisfactionV1Component
		},
		{
			reference: 'public.customer_project_satisfaction_v2',
			component: SurveyCustomerSatisfactionV2Component
		}
	];

	constructor(private httpRequestService: HttpRequestService) {
	}

	public getSurveyStatistics(reference, statistics) {
		let survey = this.surveyStatisticsDict.find((item, index) => {
			return item.reference === reference;
		});
		return new SurveyStatisticsItem(survey.component, statistics);
	}

	public getProjectTurnoverAndCostStatistics(projectId: number, frequency: number): Observable<any | null> {
		return this.httpRequestService
			.post(`statistics/project/turnover-cost`, {
				project_id: projectId,
				frequency: frequency
			})
			.pipe(map((result) => {
				return result;
			}));
	}

	public getProjectStatistics(start: Date, end: Date): Observable<any> {
		return this.httpRequestService
			.post(`statistics/project/stats`, {
				start: start,
				end: end,
			})
			.pipe(map((result) => {
				return this.httpRequestService.mapToModel(ProjectStatistics, result.statistics) as ProjectStatistics[];
			}));
	}

	public getDeliveriesStatistics(start: Date, end: Date): Observable<any> {
		return this.httpRequestService
			.post(`statistics/deliveries/stats`, {
				start: start,
				end: end,
			})
			.pipe(map((result) => {
				return this.httpRequestService.mapToModel(DeliveryStatistics, result.statistics) as ProjectStatistics[];
			}));
	}

	public getCustomerStatistics(start: Date, end: Date): Observable<any> {
		return this.httpRequestService
			.post(`statistics/customer/stats`, {
				start: start,
				end: end,
			})
			.pipe(map((result) => {
				return {
					direct: this.httpRequestService.mapToModel(CustomerStatistics, result.statistics_direct) as CustomerStatistics[],
					final: this.httpRequestService.mapToModel(CustomerStatistics, result.statistics_final) as CustomerStatistics[],
					all: this.httpRequestService.mapToModel(CustomerStatistics, result.statistics_all) as CustomerStatistics[],
				};
			}));
	}

	public getBusinessSectorStatistics(start: Date, end: Date): Observable<any> {
		return this.httpRequestService
			.post(`statistics/business-sector/stats`, {
				start: start,
				end: end,
			})
			.pipe(map((result) => {
				return {
					direct: result.statistics_direct,
					final: result.statistics_final,
					all: result.statistics_all,
				};
			}));
	}

	public getCostsStatistics(start: Date, end: Date): Observable<any> {
		return this.httpRequestService
			.post(`statistics/cost/stats`, {
				start: start,
				end: end,
			})
			.pipe(map((result) => {
				return result.statistics;
			}));
	}

	public getTurnoverByProjectTypeStatistics(start: Date, end: Date): Observable<any> {
		return this.httpRequestService
			.post(`statistics/turnover-by-project-type/stats`, {
				start: start,
				end: end,
			})
			.pipe(map((result) => {
				return result.statistics;
			}));
	}

	public getUsersStatistics(start: Date, end: Date): Observable<any> {
		return this.httpRequestService
			.post(`statistics/users/stats`, {
				start: start,
				end: end,
			})
			.pipe(map((result) => {
				return this.httpRequestService.mapToModel(UserStatistics, result.statistics) as UserStatistics[];
			}));
	}

	public getCustomerSatisfactionStatistics(start: Date, end: Date): Observable<any> {
		return this.httpRequestService
			.post(`statistics/survey/customer-satisfaction`, {
				start: start,
				end: end,
			})
			.pipe(map((result) => {
				return this.httpRequestService.mapToModel(CustomerSatisfactionStatistics, result.statistics) as CustomerSatisfactionStatistics[];
			}));
	}

	public getProjectUserSatisfactionStatistics(start: Date, end: Date): Observable<any> {
		return this.httpRequestService
			.post(`statistics/survey/project-user-satisfaction`, {
				start: start,
				end: end,
			})
			.pipe(map((result) => {
				return this.httpRequestService.mapToModel(ProjectUserSatisfactionStatistics, result.statistics) as ProjectUserSatisfactionStatistics[];
			}));
	}

	public getProjectManagerFeedbackStatistics(start: Date, end: Date): Observable<any> {
		return this.httpRequestService
			.post(`statistics/project/manager-feedback`, {
				start: start,
				end: end,
			})
			.pipe(map((result) => {
				return this.httpRequestService.mapToModel(projectFeedbackStatistics, result.statistics) as projectFeedbackStatistics[];
			}));
	}

	public getProjectFeedbackStatistics(start: Date, end: Date): Observable<any> {
		return this.httpRequestService
			.post(`statistics/project/feedback`, {
				start: start,
				end: end,
			})
			.pipe(map((result) => {
				return this.httpRequestService.mapToModel(projectFeedbackStatistics, result.statistics) as projectFeedbackStatistics[];
			}));
	}
}
