import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { forkJoin, interval } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { AuthStoreSelectors, AuthStoreState } from 'src/app/modules/root-store';
import { NotificationTypeEnum } from '../../../core/enums/notification-type.enum';
import { Activity, AuthModel, RequestError } from '../../../core/models';
import { EventNotification } from '../../../core/models/notification/event-notification.model';
import {
    AbsenceService,
    AcademicTrainingService,
    ActivityService,
    DeclarationInteractionService,
    ExpenseReportService,
    InternalActivityService,
    ReportingService,
    RouterService
} from '../../../core/services';
import { HelpService } from '../../../core/services/helper/help.service';
import { NotificationInteractionService } from '../../../core/services/interaction-service/notification-interaction.service';
import {
    AbsenceListGetRequestForm,
    AcademicTrainingListGetRequestForm,
    ActivityListGetRequestForm,
    ExpenseReportListGetRequestForm,
    InternalActivityListGetRequestForm
} from '../../forms';

@UntilDestroy()
@Component({
    templateUrl: './declaration-calendar.component.html',
    styleUrls: ['./declaration-calendar.component.scss']
})
export class DeclarationCalendarComponent implements OnInit, AfterViewInit {

    public displayCreateModal = false;
    public selectedDate: Date = null;
    public activities: Activity[] = [];
    public displayCodeColorInfo = false;
    public displayMultiDeclaration = false;

    private activityListGetRequest = new ActivityListGetRequestForm();
    private expenseReportListGetRequest = new ExpenseReportListGetRequestForm();
    private absenceListGetRequest = new AbsenceListGetRequestForm();
    private internalActivityListGetRequest = new InternalActivityListGetRequestForm();
    private academicTrainingListGetRequest = new AcademicTrainingListGetRequestForm();

    constructor(public routerService: RouterService,
                private activityService: ActivityService,
                private expenseReportService: ExpenseReportService,
                private absenceService: AbsenceService,
                private internalActivityService: InternalActivityService,
                private academicTrainingService: AcademicTrainingService,
                private authStore: Store<AuthStoreState.State>,
                private reportingService: ReportingService,
                private notificationInteractionService: NotificationInteractionService,
                private declarationInteractionService: DeclarationInteractionService) {
        this.selectedDate = new Date();
        this.declarationInteractionService.setSelectedDate(this.selectedDate);
    }

    ngOnInit() {
        interval(1000).pipe(untilDestroyed(this)).subscribe();
    }

    ngAfterViewInit() {
        this.loadDeclarationsOfMonth();
    }

    public clickOnDateCellHandler(date: Date) {
        if (HelpService.isHoliday(date)) {
            return;
        }
        this.displayCreateModal = true;
        this.selectedDate = date;
    }

    public selectedDateChanged(date: Date) {
        if (this.selectedDate.getMonth() !== date.getMonth()) {
            this.selectedDate = date;
            this.loadDeclarationsOfMonth();
        }
        this.selectedDate = date;
        this.declarationInteractionService.setSelectedDate(date);
    }

    public generateActivitiesReporting() {
        this.reportingService.generateSelfActivitiesReporting(this.selectedDate).subscribe(
            (result) => {
                saveAs(result, 'activities-mensuelle-'
                    + moment(this.selectedDate).format('MMMM')
                    + '-' + this.selectedDate.getFullYear() + '.xlsx');
                this.notificationInteractionService.displaySuccessNotification(
                    new EventNotification(NotificationTypeEnum.SUCCESS, 'Success',
                        ['Le reporting a été généré']));
            }, (error: RequestError) => {
                this.notificationInteractionService.displayErrorNotification(
                    new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', error.messages));
            }
        );
    }

    private loadDeclarationsOfMonth() {
        const startDate = Date.UTC(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1, 0, 0, 0);
        const endDate = Date.UTC(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 0, 23, 59, 59);
        const startDateIsoString = new Date(startDate).toISOString();
        const endDateIsoString = new Date(endDate).toISOString();

        this.authStore.pipe(select(AuthStoreSelectors.selectAuthModel),
            first(authModel => authModel !== null),
            mergeMap((authModel: AuthModel) => {
                this.activityListGetRequest.validationForm.get('start').setValue(startDateIsoString);
                this.activityListGetRequest.validationForm.get('end').setValue(endDateIsoString);

                this.expenseReportListGetRequest.validationForm.get('start').setValue(startDateIsoString);
                this.expenseReportListGetRequest.validationForm.get('end').setValue(endDateIsoString);

                this.absenceListGetRequest.validationForm.get('userId').setValue(authModel.id);
                this.absenceListGetRequest.validationForm.get('start').setValue(startDateIsoString);
                this.absenceListGetRequest.validationForm.get('end').setValue(endDateIsoString);

                this.internalActivityListGetRequest.validationForm.get('start').setValue(startDateIsoString);
                this.internalActivityListGetRequest.validationForm.get('end').setValue(endDateIsoString);

                this.academicTrainingListGetRequest.validationForm.get('start').setValue(startDateIsoString);
                this.academicTrainingListGetRequest.validationForm.get('end').setValue(endDateIsoString);

                return forkJoin([
                    this.activityService.getMyActivities(this.activityListGetRequest),
                    this.expenseReportService.getMyExpenseReports(this.expenseReportListGetRequest),
                    this.absenceService.getAbsenceList(this.absenceListGetRequest),
                    this.internalActivityService.getInternalActivityList(this.internalActivityListGetRequest),
                    this.academicTrainingService.getAcademicTrainingList(this.academicTrainingListGetRequest)]);
            })).subscribe(([activities, expenseReports, absences, internalActivities, academicTrainings]) => {
            this.declarationInteractionService.setActivities(activities);
            this.declarationInteractionService.setExpenseReports(expenseReports);
            this.declarationInteractionService.setAbsences(absences);
            this.declarationInteractionService.setInternalActivities(internalActivities);
            this.declarationInteractionService.setAcademicTrainings(academicTrainings);
            this.preventClickOnHeader();
        });
    }

    private preventClickOnHeader() {
        const cells = document.querySelectorAll('.ant-fullcalendar-value');
        cells.forEach((cell) => {
            cell.addEventListener('click', ($event) => {
                $event.preventDefault();
                $event.stopPropagation();
            });
        });
    }
}
