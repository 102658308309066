import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeclarationListGetForm } from '../../../management/forms';
import { DeclarationTypeEnum } from '../../enums';
import { AcademicTraining, Activity, Declaration } from '../../models';
import { Absence } from '../../models/absence/absence.model';
import { ExpenseReport } from '../../models/expense-report/expense-report.model';
import { InternalActivity } from '../../models/internal-activity/internal-activity.model';
import { StatusBase } from '../../models/status-base/status-base.model';
import {
    __API_ADMIN_DECLARATION_ANNUAL_COUNT_GET_,
    __API_ADMIN_DECLARATION_DAILY_VALIDATE__,
    __API_ADMIN_DECLARATION_MONTHLY_REPORT_GET_,
    __API_ADMIN_DECLARATION_MONTHLY_VALIDATE__
} from '../../routes/api.route';
import { HelpService } from '../helper/help.service';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class DeclarationService {

    constructor(private httpRequestService: HttpRequestService) {

    }

    public getUserDeclarations(userId: number, form: DeclarationListGetForm): Observable<Declaration[] | null> {
        return this.httpRequestService.post(`declaration/user/${ userId }/list`, form.toRequestForm()).pipe(map((result) => {
            const declarations: Declaration[] = [];
            for (const declaration of result.declarations) {
                if (declaration.declaration_type === DeclarationTypeEnum.ABSENCE) {
                    declarations.push(this.httpRequestService.mapToModel(Absence, declaration) as Absence);
                } else if (declaration.declaration_type === DeclarationTypeEnum.ACTIVITY) {
                    declarations.push(this.httpRequestService.mapToModel(Activity, declaration) as Activity);
                } else if (declaration.declaration_type === DeclarationTypeEnum.EXPENSE_REPORT) {
                    declarations.push(this.httpRequestService.mapToModel(ExpenseReport, declaration) as ExpenseReport);
                } else if (declaration.declaration_type === DeclarationTypeEnum.INTERNAL_ACTIVITY) {
                    declarations.push(this.httpRequestService.mapToModel(InternalActivity, declaration) as InternalActivity);
                } else if (declaration.declaration_type === DeclarationTypeEnum.ACADEMIC_TRAINING) {
                    declarations.push(this.httpRequestService.mapToModel(AcademicTraining, declaration) as AcademicTraining);
                }
            }
            return declarations;
        }));
    }

    public getUserMonthlyReport(userId: number, date: Date): Observable<any | null> {
        const url = HelpService.format(__API_ADMIN_DECLARATION_MONTHLY_REPORT_GET_, userId);
        return this.httpRequestService.post(url, {
            date: date
        }).pipe(map((result) => {
            return result;
        }));
    }

    public getUserDeclarationAnnualCount(userId: number, year: number) {
        const url = HelpService.format(__API_ADMIN_DECLARATION_ANNUAL_COUNT_GET_, userId);
        return this.httpRequestService.post(url, {
            year: year
        }).pipe(map((result) => {
            return result;
        }));
    }

    public validateUserDeclarationDaily(userId: number, date): Observable<StatusBase | null> {
        const url = HelpService.format(__API_ADMIN_DECLARATION_DAILY_VALIDATE__, userId);
        return this.httpRequestService.post(url, {
            date: date
        }).pipe(map((result) => {
            return result;
        }));
    }

    public validateUserDeclarationMonthly(userId: number, date: Date): Observable<StatusBase | null> {
        const url = HelpService.format(__API_ADMIN_DECLARATION_MONTHLY_VALIDATE__, userId);
        return this.httpRequestService.post(url, {
            date: date
        }).pipe(map((result) => {
            return result;
        }));
    }

    public validateUserActivityDeclaration(id: number): Observable<StatusBase | null> {
        return this.httpRequestService.post(`admin/activity/${ id }/validate`, {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(StatusBase, result.status) as StatusBase;
        }));
    }

    public denyUserActivityDeclaration(id: number): Observable<StatusBase | null> {
        return this.httpRequestService.post(`admin/activity/${ id }/deny`, {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(StatusBase, result.status) as StatusBase;
        }));
    }

    public validateUserExpenseReportDeclaration(id: number): Observable<StatusBase | null> {
        return this.httpRequestService.post(`admin/expense-report/${ id }/validate`, {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(StatusBase, result.status) as StatusBase;
        }));
    }

    public denyUserExpenseReportDeclaration(id: number): Observable<StatusBase | null> {
        return this.httpRequestService.post(`admin/expense-report/${ id }/deny`, {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(StatusBase, result.status) as StatusBase;
        }));
    }

    public validateUserAbsenceDeclaration(id: number): Observable<StatusBase | null> {
        return this.httpRequestService.post(`admin/absence/${ id }/validate`, {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(StatusBase, result.status) as StatusBase;
        }));
    }

    public denyUserAbsenceDeclaration(id: number): Observable<StatusBase | null> {
        return this.httpRequestService.post(`admin/absence/${ id }/deny`, {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(StatusBase, result.status) as StatusBase;
        }));
    }

    public validateUserInternalActivityDeclaration(id: number): Observable<StatusBase | null> {
        return this.httpRequestService.post(`admin/internal-activity/${ id }/validate`, {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(StatusBase, result.status) as StatusBase;
        }));
    }

    public denyUserInternalActivityDeclaration(id: number): Observable<StatusBase | null> {
        return this.httpRequestService.post(`admin/internal-activity/${ id }/deny`, {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(StatusBase, result.status) as StatusBase;
        }));
    }

    public validateUserAcademicTrainingDeclaration(id: number): Observable<StatusBase | null> {
        return this.httpRequestService.post(`admin/academic-training/${ id }/validate`, {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(StatusBase, result.status) as StatusBase;
        }));
    }

    public denyUserAcademicTrainingDeclaration(id: number): Observable<StatusBase | null> {
        return this.httpRequestService.post(`admin/academic-training/${ id }/deny`, {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(StatusBase, result.status) as StatusBase;
        }));
    }
}
