<form *ngIf="form" [formGroup]="form.validationForm" nz-form nzLayout="vertical">
	<nz-form-item *ngIf="!isCreateMode && status">
		<nz-form-control>
			<div [ngClass]="{   'validated': status.id === declarationStatusEnum.VALIDATED,
                                'denied': status.id === declarationStatusEnum.DENIED,
                                'waiting': status.id === declarationStatusEnum.WAITING_VALIDATION
                            }"
			     class="item-status">{{status.name}}</div>
		</nz-form-control>
	</nz-form-item>
	<div nz-row nzGutter="15">
		<div nz-col nzSpan="12">
			<nz-form-item>
				<nz-form-label nzRequired>Type</nz-form-label>
				<nz-form-control>
					<nz-select [formControlName]="'type'" [nzDisabled]="locked">
						<nz-option *ngFor="let type of absenceTypes"
						           [nzLabel]="type.name" [nzValue]="type.id"></nz-option>
					</nz-select>
				</nz-form-control>
			</nz-form-item>
		</div>
		<div nz-col nzSpan="12">
			<nz-form-item>
				<nz-form-label nzRequired>Durée</nz-form-label>
				<nz-form-control>
					<nz-select [formControlName]="'duration'" [nzDisabled]="locked">
						<nz-option [nzLabel]="'0.50 jour'" [nzValue]="'0.50'"></nz-option>
						<nz-option [nzLabel]="'1.00 jour'" [nzValue]="'1.00'"></nz-option>
					</nz-select>
				</nz-form-control>
			</nz-form-item>
		</div>
	</div>
	<nz-form-item style="margin-bottom: 5px;">
		<nz-form-label style="padding-top: 5px;">Description</nz-form-label>
		<nz-form-control>
			<textarea [formControlName]="'description'" nz-input></textarea>
		</nz-form-control>
	</nz-form-item>
	<div nz-row nzGutter="15" *ngIf="isCreateMode === false">
		<div nz-col nzSpan="24">
			<nz-card class="expense-report-create-card" [nzTitle]="titleTemplate"
			         [nzExtra]="extraTemplate">
				<ng-template #titleTemplate>
					<span nz-tooltip nzTooltipTitle="Optionnel, format PDF ou PNG/JPEG">
						Documents justificatifs (optionnel, format PDF ou PNG/JPEG)
					</span>
				</ng-template>
				<ng-template #extraTemplate>
					<form [formGroup]="fileUploadForm.validationForm" nz-form nzLayout="horizontal"
					      *ngIf="status.id === declarationStatusEnum.WAITING_VALIDATION">
						<div nz-row nzGutter="8">
							<div nz-col nzSpan="8">
								<button (click)="openFileDiagUpdate()" [nzTooltipTitle]="uploadToolTip" nz-button nz-tooltip nzType="default"
								        style="cursor: pointer;">
									<fa-icon [icon]="['far', 'upload']" class="gaia-highlight-primary" style="padding-right: 10px"></fa-icon>
									<span>Déposer</span>
									<input (change)="uploadFileSelectForUpdate($event)" accept="application/pdf, application/pnb, application/jpeg"
									       id="fileUploadInputAbsenceUpdate" name="file" nz-button
									       type="file"/>
									<ng-template #uploadToolTip>
										<span>Déposer un document</span>
									</ng-template>
								</button>
							</div>
						</div>
					</form>
				</ng-template>
				<nz-list *ngIf="absence.files" nzBordered="false" nzSize="small">
					<nz-list-item *ngFor="let file of absence.files" style="padding: 5px 0 5px 0;">
						<div nz-row nzJustify="start" style="width: 100%;">
							<div nz-col nz-tooltip nzTooltipTitle="{{file.fileName}}" nzSpan="14">
								<span>{{ file.name }}</span>
							</div>
							<div>
								<a href="{{ file.url }}" nz-tooltip nzTooltipTitle="Voir"
								   style="color: rgba(0, 0, 0, 0.65);" target="_blank">
									<fa-icon [icon]="['far', 'eye']" class="gaia-highlight-primary" style="padding-right: 10px;cursor: pointer;"></fa-icon>
								</a>

								<fa-icon (click)="downloadPdf(file)" [icon]="['far', 'download']"
								         class="gaia-highlight-primary"
								         nz-tooltip nzTooltipTitle="Télécharger" style="padding-right: 10px;cursor: pointer;"></fa-icon>

								<fa-icon (click)="deleteFile(file)" [icon]="['far', 'trash']"
								         *ngIf="status.id === declarationStatusEnum.WAITING_VALIDATION"
								         style="cursor: pointer;"
								         class="gaia-highlight-primary" nz-tooltip nzTooltipTitle="Supprimer"></fa-icon>
							</div>
						</div>
					</nz-list-item>
				</nz-list>
				<div nz-row nzJustify="center">
					<span *ngIf="absence.files.length === 0" style="text-align: center;">Aucun document</span>
				</div>
			</nz-card>
		</div>
	</div>
	<div nz-row nzGutter="15" *ngIf="isCreateMode">
		<div nz-col nzSpan="24">
			<nz-card class="expense-report-create-card" [nzTitle]="titleTemplate"
			         [nzExtra]="extraTemplate">
				<ng-template #titleTemplate>
					<span nz-tooltip nzTooltipTitle="Optionnel, format PDF ou PNG/JPEG">
						Documents justificatifs (optionnel, format PDF ou PNG/JPEG)
					</span>
				</ng-template>
				<ng-template #extraTemplate>
					<form [formGroup]="fileUploadForm.validationForm" nz-form nzLayout="horizontal">
						<div nz-row nzGutter="8">
							<div nz-col nzSpan="8">
								<button (click)="openFileDiagCreate()" [nzTooltipTitle]="uploadToolTip" nz-button nz-tooltip nzType="default"
								        style="cursor: pointer;">
									<fa-icon [icon]="['far', 'upload']" class="gaia-highlight-primary" style="padding-right: 10px"></fa-icon>
									<span>Déposer</span>
									<input (change)="uploadFileSelectForCreate($event)" accept="application/pdf, application/pnb, application/jpeg"
									       id="fileUploadInputAbsenceCreate" name="file" nz-button
									       type="file"/>
									<ng-template #uploadToolTip>
										<span>Déposer un document</span>
									</ng-template>
								</button>
							</div>
						</div>
					</form>
				</ng-template>
				<nz-list *ngIf="filesToUpload" nzBordered="false" nzSize="small">
					<nz-list-item *ngFor="let item of filesToUpload" style="padding: 5px 0 5px 0;">
						<div nz-row nzJustify="start" style="width: 100%;">
							<div nz-col nzSpan="14">
								<span>{{ item.file.name }}</span>
							</div>
							<div nz-col nzSpan="10" style="text-align: left;">
								<fa-icon (click)="deleteFileToUpload(item.file.name)" [icon]="['far', 'trash']" style="cursor: pointer;"
								         class="gaia-highlight-primary" nz-tooltip nzTooltipTitle="Supprimer"></fa-icon>
							</div>
						</div>
					</nz-list-item>
				</nz-list>
				<div nz-row nzJustify="center">
					<span *ngIf="filesToUpload.length === 0" style="text-align: center;">Aucun document</span>
				</div>
			</nz-card>
		</div>
	</div>
	<div nz-row nzGutter="15" nzJustify="end">
		<div class="action-buttons" nz-col nzSpan="24">
			<ng-container *ngIf="!locked">
				<ng-container *ngIf="!absence.status">
					<button (click)="closeModalHandler();" *ngIf="!absence.status" [nzType]="'default'" nz-button>
						Annuler
					</button>
					<button (click)="sendDeclaration();" [nzType]="'primary'"
					        nz-button style="margin-left: 15px;">
						Confirmer
					</button>
				</ng-container>
				<ng-container
						*ngIf="absence.status && absence.status.id === declarationStatusEnum.WAITING_VALIDATION">
					<button (click)="sendDeclaration();" [nzType]="'primary'"
					        nz-button style="margin-left: 15px;">
						Mettre à jour
					</button>
				</ng-container>
			</ng-container>
		</div>
	</div>
</form>
