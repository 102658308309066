import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileCreateForm } from '../../../parameter/forms';
import { ProfileUpdateForm } from '../../../parameter/forms/profile/profile-update.form';
import { FiscalPeriod, Profile } from '../../models';
import {
    __API_ADMIN_FISCAL_PERIOD_CURRENT_GET__,
    __API_ADMIN_PROFILE_CREATE__,
    __API_ADMIN_PROFILE_LIST_BY_DATE__,
    __API_ADMIN_PROFILE_LIST_CURRENT_GET__
} from '../../routes/api.route';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class ProfileService {

    constructor(private httpRequestService: HttpRequestService) {
    }

    public getProfilesByDate(date: Date): Observable<any> {
        return this.httpRequestService.post(__API_ADMIN_PROFILE_LIST_BY_DATE__, { date: date }).pipe(map(result => {
            return this.httpRequestService.mapToModel(Profile, result.profiles) as Profile[];
        }));
    }

    public getCurrentFiscalPeriodProfilesList(): Observable<any> {
        return this.httpRequestService.get(__API_ADMIN_PROFILE_LIST_CURRENT_GET__).pipe(map(result => {
            return this.httpRequestService.mapToModel(Profile, result.profiles) as Profile[];
        }));
    }

    public getCurrentFiscalPeriod(): Observable<any> {
        return this.httpRequestService.get(__API_ADMIN_FISCAL_PERIOD_CURRENT_GET__).pipe(map(result => {
            return this.httpRequestService.mapToModel(FiscalPeriod, result.details) as FiscalPeriod;
        }));
    }

    public getProfile(id: number): Observable<any> {
        const url = `admin/profile/${ id }/detail`;
        return this.httpRequestService.get(url).pipe(map(result => {
            return this.httpRequestService.mapToModel(Profile, result.details) as Profile;
        }));
    }

    public updateProfile(id: number, form: ProfileUpdateForm): Observable<any> {
        const url = `admin/profile/${ id }/update`;
        return this.httpRequestService.put(url, form.toRequestForm()).pipe(map(result => {
            return this.httpRequestService.mapToModel(Profile, result.profile) as Profile;
        }));
    }

    public createProfile(form: ProfileCreateForm): Observable<any> {
        return this.httpRequestService.post(__API_ADMIN_PROFILE_CREATE__, form.toRequestForm()).pipe(map(result => {
            return this.httpRequestService.mapToModel(Profile, result.profile) as Profile;
        }));
    }

    public deleteProfile(id: number): Observable<any> {
        const url = `admin/profile/${ id }/delete`;
        return this.httpRequestService.delete(url);
    }
}
