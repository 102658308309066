import { BaseForm } from '../../core/forms/base.form';
import { Validators } from '@angular/forms';

export class InternalActivityDeclarationForm extends BaseForm {

    constructor() {
        super();
        this.validationForm = this.formBuilder.group({
            date: [null, Validators.required],
            type: [null, Validators.required],
            duration: [null, Validators.required],
            description: [null]
        });
    }

    public toRequestForm() {
        return {
            date: this.validationForm.get('date').value,
            type: this.validationForm.get('type').value,
            duration: this.validationForm.get('duration').value,
            description: this.validationForm.get('description').value,
        };
    }
}
