import { PropertyMapper } from '../../decorators';

export class ActivityLog {
    @PropertyMapper()
    public id: number;

    @PropertyMapper()
    public type: number;

    @PropertyMapper({ jsonProperty: 'sub_type' })
    public subType: number;

    @PropertyMapper()
    public description: string;

    @PropertyMapper()
    public properties: [];

    @PropertyMapper({ jsonProperty: 'created_at', typeOfObject: Date })
    public createdAt: Date;
}
