import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {projectFeedbackStatistics} from '../../../models/projectFeedbackStatistics.model';

@UntilDestroy()
@Component({
    templateUrl: './statistics-project-manager-feedback-v1.component.html',
    styleUrls: ['./statistics-project-manager-feedback-v1.component.scss']
})
export class StatisticsProjectManagerFeedbackV1Component implements OnInit, OnChanges {

    @Input() statistics: projectFeedbackStatistics[] = [];

    public q1Question = 'Retour d\'expérience';
    public q2Question = 'Satisfaction client';
    public q3Question = 'Note de satisfaction client';

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
    }
}
