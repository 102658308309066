import { PropertyMapper } from '../../decorators';

export class Credit {

    @PropertyMapper({ jsonProperty: 'id' })
    public id: number;

    @PropertyMapper({ jsonProperty: 'reference' })
    public reference: string;

    @PropertyMapper({ jsonProperty: 'date', typeOfObject: Date })
    public date: Date;

    @PropertyMapper({ jsonProperty: 'sending_date', typeOfObject: Date })
    public sendingDate: Date;
}
