import { PropertyMapper } from '../../decorators';
import { MediaFile } from '../media/media-file.model';

export class UserAnnualAssessmentInterview {
    @PropertyMapper()
    id: number;

    @PropertyMapper({ jsonProperty: 'date', typeOfObject: Date })
    date: Date;

    @PropertyMapper({ jsonProperty: 'file', typeOfObject: MediaFile })
    public file: MediaFile;
}
