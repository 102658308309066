import { FormControl, Validators } from '@angular/forms';
import { BaseForm } from '../../core/forms/base.form';

export class AcademicTrainingListGetRequestForm extends BaseForm {

    constructor() {
        super();
        this.validationForm = this.formBuilder.group({
            userId: new FormControl(null),
            start: new FormControl(null, [Validators.required]),
            end: new FormControl(null, [Validators.required]),
            status: new FormControl(null),
            skip: new FormControl(null),
            take: new FormControl(null)
        });
    }

    public toRequestForm() {
        return {
            user_id: this.validationForm.get('userId').value,
            start: this.validationForm.get('start').value,
            end: this.validationForm.get('end').value,
            status: this.validationForm.get('status').value,
            skip: this.validationForm.get('skip').value,
            take: this.validationForm.get('take').value
        };
    }
}
