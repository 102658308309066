import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
	faAddressCard, faArchive,
	faBell,
	faBox,
	faBriefcase,
	faCalendarAlt,
	faChartBar,
	faCheck,
	faCheckCircle,
	faChevronDoubleDown,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCircle,
	faCog,
	faDownload,
	faEdit,
	faEllipsisH,
	faEnvelope,
	faEnvelopeOpen,
	faEnvelopeOpenDollar,
	faEquals,
	faEye,
	faEyeSlash,
	faFileAlt,
	faFileInvoiceDollar,
	faFilter,
	faFolders,
	faFolderTree,
	faHistory,
	faHome,
	faInboxIn,
	faInboxOut,
	faInfoCircle,
	faListAlt,
	faLongArrowLeft,
	faLongArrowRight,
	faLongArrowUp,
	faMapMarked,
	faMinus,
	faPaperPlane,
	faPlus,
	faQuestionCircle,
	faSearch,
	faSignOutAlt,
	faSortAlphaDownAlt,
	faSortAlphaUp,
	faSortAmountDown,
	faSortAmountUpAlt,
	faSpinner,
	faSync,
	faTasks,
	faTimes,
	faTrash,
	faUnlockAlt,
	faUpload,
	faUserEdit,
	faUserHardHat,
	faUserMinus,
	faUserPlus,
	faUsers, faUsersSlash,
	faUserTie,
	faWallet,
} from '@fortawesome/pro-regular-svg-icons';
import { faPlusCircle, faSquare, faTh, faThLarge, faUser } from '@fortawesome/pro-solid-svg-icons';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { fr_FR, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule, NzNotificationServiceModule } from 'ng-zorro-antd/notification';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { MonthPickerComponent } from './components/month-picker/monthpicker.component';
import { NotificationComponent } from './components/notification/notification.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';

@NgModule({
	declarations: [
		UserAvatarComponent,
		NotificationComponent,
		MonthPickerComponent,
	],
	imports: [
		CommonModule,
		FontAwesomeModule,
		FormsModule,
		ReactiveFormsModule,
		// ant zorro module
		NzIconModule,
		NzNotificationModule,
		NzNotificationServiceModule,
		NzMessageModule,
		NzFormModule,
		NzBreadCrumbModule,
		NzInputModule,
		NzInputNumberModule,
		NzSelectModule,
		NzModalModule,
		NzDividerModule,
		NzListModule,
		NzTableModule,
		NzTabsModule,
		NzCardModule,
		NzCalendarModule,
		NzCollapseModule,
		NzDatePickerModule,
		NzButtonModule,
		NzAlertModule,
		NzDrawerModule,
		NzLayoutModule,
		NzAvatarModule,
		NzSpinModule,
		NzToolTipModule,
		NzMenuModule,
		NzDropDownModule,
		NzStatisticModule,
		NzSwitchModule,
		NzEmptyModule,
		NzSpaceModule,
		NzRadioModule,
		NzRateModule,
		NzInputNumberModule,
		NzBadgeModule,
		NzTimelineModule,
		NzUploadModule,
		NzTagModule,
		NzPopoverModule,
		NzCheckboxModule,
	],
	exports: [
		CommonModule,
		FontAwesomeModule,
		FormsModule,
		ReactiveFormsModule,
		UserAvatarComponent,
		NotificationComponent,
		MonthPickerComponent,
		// ant zorro module
		NzIconModule,
		NzNotificationModule,
		NzNotificationServiceModule,
		NzMessageModule,
		NzFormModule,
		NzBreadCrumbModule,
		NzInputModule,
		NzInputNumberModule,
		NzSelectModule,
		NzModalModule,
		NzDividerModule,
		NzListModule,
		NzTableModule,
		NzTabsModule,
		NzCardModule,
		NzCalendarModule,
		NzCollapseModule,
		NzDatePickerModule,
		NzButtonModule,
		NzAlertModule,
		NzDrawerModule,
		NzLayoutModule,
		NzAvatarModule,
		NzSpinModule,
		NzToolTipModule,
		NzMenuModule,
		NzDropDownModule,
		NzStatisticModule,
		NzSwitchModule,
		NzEmptyModule,
		NzSpaceModule,
		NzRadioModule,
		NzRateModule,
		NzInputNumberModule,
		NzBadgeModule,
		NzTimelineModule,
		NzUploadModule,
		NzTagModule,
		NzPopoverModule,
		NzCheckboxModule,
	],
	providers: [
		{ provide: NZ_I18N, useValue: fr_FR },
	]
})
export class SharedModule {
	constructor(private faIconLibrary: FaIconLibrary) {
		faIconLibrary.addIcons(
			faPlus,
			faLongArrowLeft,
			faLongArrowRight,
			faEdit,
			faTrash,
			faInfoCircle,
			faWallet,
			faCalendarAlt,
			faUser,
			faUserPlus,
			faUserMinus,
			faUserEdit,
			faCheck,
			faTimes,
			faEnvelopeOpenDollar,
			faBriefcase,
			faChevronDown,
			faChevronUp,
			faFileInvoiceDollar,
			faSearch,
			faHome,
			faSignOutAlt,
			faFolders,
			faFolderTree,
			faTasks,
			faCog,
			faUsers,
			faUserTie,
			faSpinner,
			faUnlockAlt,
			faAddressCard,
			faFileAlt,
			faInboxIn,
			faInboxOut,
			faEllipsisH,
			faCheckCircle,
			faChevronDown,
			faChevronUp,
			faHistory,
			faDownload,
			faEye,
			faEyeSlash,
			faUpload,
			faQuestionCircle,
			faChartBar,
			faPlusCircle,
			faChevronLeft,
			faChevronRight,
			faChevronDoubleDown,
			faThLarge,
			faTh,
			faSquare,
			faFilter,
			faSortAmountUpAlt,
			faSortAmountDown,
			faSortAlphaUp,
			faSortAlphaDownAlt,
			faBell,
			faCircle,
			faMapMarked,
			faFileInvoiceDollar,
			faSync,
			faMinus,
			faQuestionCircle,
			faLongArrowUp,
			faEquals,
			faListAlt,
			faEnvelope,
			faEnvelopeOpen,
			faPaperPlane,
			faUserHardHat,
			faUsersSlash,
			faBox,
			faArchive,
		);
	}
}
