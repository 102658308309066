import { PropertyMapper } from '../../decorators';
import { MediaFile } from './media-file.model';

export class MediaFileProjectPtf extends MediaFile {

    @PropertyMapper({ jsonProperty: 'ptf_date', typeOfObject: Date })
    public ptfDate: Date;

    @PropertyMapper({ jsonProperty: 'version' })
    public version: number;

    @PropertyMapper({ jsonProperty: 'custom_name' })
    public customName: String;
}
