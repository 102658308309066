import { Validators } from '@angular/forms';
import { BaseForm } from '../../core/forms/base.form';

export class UserProjectSatisfactionV1Form extends BaseForm {
    constructor() {
        super();

        this.validationForm = this.formBuilder.group({
            q1: [null, Validators.required],
            q2: [null, Validators.required],
            q3: [null, Validators.required],
            q4: [null, Validators.required],
            q5: [null, Validators.required],
            q6: [null, Validators.required],
            q7: [null, Validators.required],
            q8: [null],
            q9: [null, Validators.required],
            q10: [null],
            q11: [null, Validators.required],
            q12: [null, Validators.required],
            q13: [null],
        });
    }

    public toRequestForm() {
        return {
            q1: this.validationForm.get('q1').value,
            q2: this.validationForm.get('q2').value,
            q3: this.validationForm.get('q3').value,
            q4: this.validationForm.get('q4').value,
            q5: this.validationForm.get('q5').value,
            q6: this.validationForm.get('q6').value,
            q7: this.validationForm.get('q7').value,
            q8: this.validationForm.get('q8').value,
            q9: this.validationForm.get('q9').value,
            q10: this.validationForm.get('q10').value,
            q11: this.validationForm.get('q11').value,
            q12: this.validationForm.get('q12').value,
            q13: this.validationForm.get('q13').value,
        };
    }

    public recoverFormObject(answer, status) {
        this.validationForm.get('q1').setValue(answer.q1);
        this.validationForm.get('q2').setValue(answer.q2);
        this.validationForm.get('q3').setValue(answer.q3);
        this.validationForm.get('q4').setValue(answer.q4);
        this.validationForm.get('q5').setValue(answer.q5);
        this.validationForm.get('q6').setValue(answer.q6);
        this.validationForm.get('q7').setValue(answer.q7);
        this.validationForm.get('q8').setValue(answer.q8);
        this.validationForm.get('q9').setValue(answer.q9);
        this.validationForm.get('q10').setValue(answer.q10);

        if (status === 2) {
            this.validationForm.get('q1').disable();
            this.validationForm.get('q2').disable();
            this.validationForm.get('q3').disable();
            this.validationForm.get('q4').disable();
            this.validationForm.get('q5').disable();
            this.validationForm.get('q6').disable();
            this.validationForm.get('q7').disable();
            this.validationForm.get('q8').disable();
            this.validationForm.get('q9').disable();
            this.validationForm.get('q10').disable();
        }
    }
}
