import { registerLocaleData } from '@angular/common';
import frLocale from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BnNgIdleService } from 'bn-ng-idle';
import { fr } from 'date-fns/locale';
/** config ng-zorro-antd i18n **/
import { fr_FR, NZ_DATE_LOCALE, NZ_I18N, NzI18nService } from 'ng-zorro-antd/i18n';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { SharedModule } from './modules/shared/shared.module';


registerLocaleData(frLocale);

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		CoreModule,
		SharedModule
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'fr-FR' },
		{ provide: NZ_DATE_LOCALE, useValue: fr },
		{ provide: NZ_I18N, useValue: fr_FR },
		BnNgIdleService
	],
	bootstrap: [AppComponent],
})
export class AppModule {

	constructor(private i18n: NzI18nService) {
		this.switchLanguage();
	}

	switchLanguage() {
		this.i18n.setDateLocale(fr); // Switch language to French at runtime
	}
}
