import { PropertyMapper } from '../../decorators';
import { CustomerContactTypeEnum } from '../../enums';

export class CustomerContact {
    @PropertyMapper()
    public id: number;

    @PropertyMapper({ jsonProperty: 'customer_id' })
    public customerId: number;

    @PropertyMapper({ jsonProperty: 'first_name' })
    public firstName: string;

    @PropertyMapper({ jsonProperty: 'last_name' })
    public lastName: string;

    @PropertyMapper()
    public email: string;

    @PropertyMapper()
    public company: string;

    @PropertyMapper()
    public job: string;

    @PropertyMapper({ jsonProperty: 'mobile_phone' })
    public mobilePhone: string;

    @PropertyMapper()
    public phone: string;

    @PropertyMapper()
    public type: CustomerContactTypeEnum;

    @PropertyMapper()
    public disabled: Boolean;

    public searchValueFormatted: string;
}
