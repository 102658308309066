import { PropertyMapper } from '../../decorators';

export class Survey {
    @PropertyMapper()
    uuid: string;

    @PropertyMapper()
    name: string;

    @PropertyMapper({ jsonProperty: 'project_name' })
    projectName: string;

    @PropertyMapper()
    reference: string;

    @PropertyMapper()
    type: number;

    @PropertyMapper()
    status: number;

    @PropertyMapper()
    receivers;

    @PropertyMapper()
    answers;

    @PropertyMapper()
    editable: boolean;

    @PropertyMapper({ jsonProperty: 'end', typeOfObject: Date })
    end: Date;
}
