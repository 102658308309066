import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    LotAssignUserForm, LotAssociateSupplierLotForm,
    ProjectCancelFeedbackForm,
    ProjectCreateForm,
    ProjectCustomerFeedbackV2Form, ProjectCustomerFeedbackV3Form,
    ProjectListGetRequestForm,
    ProjectOrderCreateForm,
    ProjectOrderDownPaymentCreateForm,
    ProjectOrderDownPaymentUpdateForm
} from '../../../project/forms';
import { ProjectLotUpdateForm } from '../../../project/forms/project-lot-update.form';
import { ProjectManagerUpdateForm } from '../../../project/forms/project-manager-update.form';
import { ProjectLotStatusEnum } from '../../enums';
import {
    ActivityLog,
    Customer,
    CustomerSite,
    Lot, LotSupplierLot,
    Project,
    ProjectAssignedUser, ProjectAssociatedSupplier,
    ProjectNotification,
    ProjectOrder,
    ProjectOrderDownPayment,
    ProjectSearchResult,
    ProjectStatus,
    ProjectType,
    User
} from '../../models';
import { ExpenseReport } from '../../models/expense-report/expense-report.model';
import {
    __API_PROJECT_ORDER_DETAIL__,
    __API_PROJECT_ORDER_REMAINING_FEE_AND_WORKLOAD,
    __API_PROJECT_SEARCH__
} from '../../routes/api.route';
import { HelpService } from '../helper/help.service';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class ProjectService {

    constructor(private httpRequestService: HttpRequestService) {
    }

    public getProjectsAndLots(): Observable<Project[] | null> {
        return this.httpRequestService.get('project/user-access/list').pipe(map(result => {
            return this.httpRequestService.mapToModel(Project, result.projects) as Project[];
        }));
    }

    public getMyProjects(form: ProjectListGetRequestForm): Observable<Project[] | null> {
        return this.httpRequestService.post('project/list', form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Project, result.projects) as Project[];
        }));
    }

    public searchProjects(form: ProjectListGetRequestForm): Observable<ProjectSearchResult | null> {
        return this.httpRequestService.post(__API_PROJECT_SEARCH__, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectSearchResult, result.data) as ProjectSearchResult;
        }));
    }

    public getProjectTypes(): Observable<ProjectType[] | null> {
        return this.httpRequestService.get('project/type/list').pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectType, result.types) as ProjectType[];
        }));
    }

    public initProject(form: ProjectCreateForm): Observable<Project | null> {
        return this.httpRequestService.post('project/init', form.toRequestForm()).pipe(map(result => {
            return this.httpRequestService.mapToModel(Project, result.details) as Project;
        }));
    }

    public getProjectDetail(projectId: number): Observable<Project | null> {
        return this.httpRequestService.get(`project/${ projectId }/detail`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Project, result.detail) as Project;
        }));
    }

    public deleteProject(projectId: number): Observable<any> {
        return this.httpRequestService.delete(`project/${ projectId }/delete`);
    }

    /**
     * Project Customer
     */

    public getProjectCustomers(): Observable<Customer[] | null> {
        return this.httpRequestService.get('project/customer/list').pipe(map(result => {
            return this.httpRequestService.mapToModel(Customer, result.customers) as Customer[];
        }));
    }

    /**
     * Project Lot
     */

    public createProjectLot(projectId: number, form): Observable<Lot | null> {
        return this.httpRequestService.post(`project/${ projectId }/lot/create`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Lot, result.details) as Lot;
        }));
    }

    public updateProjectLot(projectId: number, lotId: number, form: ProjectLotUpdateForm): Observable<Lot | null> {
        return this.httpRequestService.put(`project/${ projectId }/lot/${ lotId }/update`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Lot, result.details) as Lot;
        }));
    }

    public getProjectLots(projectId: number): Observable<Lot[] | null> {
        return this.httpRequestService.get(`project/${ projectId }/lot/list`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Lot, result.lots) as Lot[];
        }));
    }

    public getProjectLotDetail(projectId: number, lotId: number): Observable<Lot> {
        return this.httpRequestService.get(`project/${ projectId }/lot/${ lotId }/detail`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Lot, result.details) as Lot;
        }));
    }

    public updateProjectLotStatus(projectId: number, lotId: number, status: ProjectLotStatusEnum): Observable<any> {
        return this.httpRequestService.put(`project/${ projectId }/lot/${ lotId }/status/update`, {
            status: status
        }).pipe(map(result => {
            return result.status;
        }));
    }

    public deleteProjectLot(projectId: number, lotId: number): Observable<any> {
        return this.httpRequestService.delete(`project/${ projectId }/lot/${ lotId }/delete`);
    }

    /**
     * Project Order
     */

    public createProjectOrder(projectId: number, form: ProjectOrderCreateForm): Observable<ProjectOrder | null> {
        return this.httpRequestService.post(`project/${ projectId }/order/create`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectOrder, result.order) as ProjectOrder;
        }));
    }

    public updateProjectOrder(projectId: number, orderId: number, form: ProjectOrderCreateForm): Observable<ProjectOrder | null> {
        return this.httpRequestService.put(`project/${ projectId }/order/${ orderId }/update`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectOrder, result.order) as ProjectOrder;
        }));
    }

    public getProjectOrder(projectId: number, orderId: number): Observable<ProjectOrder | null> {
        const url = HelpService.format(__API_PROJECT_ORDER_DETAIL__, projectId, orderId);
        return this.httpRequestService.get(url).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectOrder, result.order) as ProjectOrder;
        }));
    }

    public getProjectOrders(projectId: number): Observable<ProjectOrder[] | null> {
        return this.httpRequestService.get(`project/${ projectId }/order/list`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectOrder, result.orders) as ProjectOrder[];
        }));
    }

    public deleteProjectOrder(projectId: number, orderId: number): Observable<any> {
        return this.httpRequestService.delete(`project/${ projectId }/order/${ orderId }/delete`);
    }

    /**
     * Order DownPayment
     */
    public createProjectOrderDownPayment(orderId: number, form: ProjectOrderDownPaymentCreateForm): Observable<ProjectOrderDownPayment | null> {
        return this.httpRequestService.post(`project/order/${ orderId }/down-payment/create`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectOrderDownPayment, result.down_payment) as ProjectOrderDownPayment;
        }));
    }

    public updateProjectOrderDownPayment(downPaymentId: number, form: ProjectOrderDownPaymentUpdateForm): Observable<ProjectOrderDownPayment | null> {
        return this.httpRequestService.put(`project/order/down-payment/${ downPaymentId }/update`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectOrderDownPayment, result.down_payment) as ProjectOrderDownPayment;
        }));
    }

    public deleteProjectOrderDownPayment(downPaymentId: number): Observable<any> {
        return this.httpRequestService.delete(`project//order/down-payment/${ downPaymentId }/delete`);
    }

    /**
     * Project Status update
     */
    public updateProjectInProspecting(projectId: number, form) {
        return this.httpRequestService.put(`project/${ projectId }/prospecting/update`, form.toRequestForm());
    }

    public updateProjectInProduction(projectId: number, form) {
        return this.httpRequestService.put(`project/${ projectId }/production/update`, form.toRequestForm());
    }

    public updateProjectInProspectingToProduction(projectId: number): Observable<any> {
        return this.httpRequestService.post(`project/${ projectId }/production`, {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectStatus, result.status) as ProjectStatus;
        }));
    }

    public updateProjectToProspecting(projectId: number): Observable<any> {
        return this.httpRequestService.post(`project/${ projectId }/prospecting`, {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectStatus, result.status) as ProjectStatus;
        }));
    }

    public updateProjectInProspectingToCanceled(projectId: number, form: ProjectCancelFeedbackForm) {
        return this.httpRequestService.post(`project/${ projectId }/cancel`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectStatus, result.status);
        }));
    }

    public updateProjectInProspectingToAbandoned(projectId: number, form: ProjectCancelFeedbackForm) {
        return this.httpRequestService.post(`project/${ projectId }/abandoned`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectStatus, result.status);
        }));
    }

    public updateProjectInProspectingToLost(projectId: number, form: ProjectCancelFeedbackForm) {
        return this.httpRequestService.post(`project/${ projectId }/lose`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectStatus, result.status);
        }));
    }

    public updateProjectInProductionToCompleted(projectId: number, form: ProjectCustomerFeedbackV3Form): Observable<any> {
        return this.httpRequestService.post(`project/${ projectId }/completed`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectStatus, result.status);
        }));
    }

    public updateProjectInCompletedToClosed(projectId: number): Observable<any> {
        return this.httpRequestService.post(`project/${ projectId }/close`, { }).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Project, result.detail);
        }));
    }

    public checkProjectCloseRequirement(projectId: number): Observable<any> {
        return this.httpRequestService.post(`project/${ projectId }/complete-check-requirement`, {}).pipe(map((result) => {
            return result;
        }));
    }

    public getProjectCompleteInformations(projectId: number): Observable<any> {
        return this.httpRequestService.get(`project/${ projectId }/complete-info`)
            .pipe(map((result) => {
                return this.httpRequestService.mapToModel(Lot, result.lots) as Lot[];
            }));
    }

    /**
     * Project Expense reports
     */
    public getProjectExpenseReport(projectId: number) {
        return this.httpRequestService.get(`project/${ projectId }/expense-report/list`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ExpenseReport, result.expense_reports) as ExpenseReport[];
        }));
    }

    /**
     * Project Customer sites
     */
    public associateProjectCustomerSite(projectId: number, customer_site_ids) {
        return this.httpRequestService.post(`project/${ projectId }/customer-site/associate`, { ids: customer_site_ids })
            .pipe(map((result) => {
                return this.httpRequestService.mapToModel(CustomerSite, result.sites) as CustomerSite[];
            }));
    }

    public dissociateProjectCustomerSite(projectId: number, customer_site_id: number) {
        return this.httpRequestService.delete(`project/${ projectId }/customer-site/${ customer_site_id }/dissociate`)
            .pipe(map((result) => {
                return this.httpRequestService.mapToModel(CustomerSite, result.sites) as CustomerSite[];
            }));
    }

    /**
     * Others
     */
    public getProjectManagers(): Observable<any> {
        return this.httpRequestService.get('project/project-manager/list').pipe(map((result) => {
            return this.httpRequestService.mapToModel(User, result.managers);
        }));
    }

    public getProjectCreators(): Observable<any> {
        return this.httpRequestService.get('project/creator/list').pipe(map((result) => {
            return this.httpRequestService.mapToModel(User, result.managers);
        }));
    }

    public updateProjectManager(projectId, form: ProjectManagerUpdateForm): Observable<any> {
        return this.httpRequestService.put(`project/${ projectId }/project-manager`, form.toRequestForm())
            .pipe(map((result) => {
                return this.httpRequestService.mapToModel(Project, result.details) as Project;
            }));
    }

    public getProjectHistory(projectId): Observable<any> {
        return this.httpRequestService.get(`project/${ projectId }/history`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ActivityLog, result.activities) as ActivityLog[];
        }));
    }

    public getProjectNotifications(projectId): Observable<any> {
        return this.httpRequestService.get(`project/${ projectId }/notification`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectNotification, result.notifications) as ProjectNotification[];
        }));
    }

    public getRemainingFeeAndWorkload(projectId): Observable<any> {
        const url = HelpService.format(__API_PROJECT_ORDER_REMAINING_FEE_AND_WORKLOAD, projectId);
        return this.httpRequestService.get(url).pipe(map((result) => {
            return result.details;
        }));
    }

    public sendProjectUserSatisfactionSurvey(projectId: number, userId: number): Observable<any> {
        return this.httpRequestService.post(`project/${ projectId }/associate/user/${ userId }`, {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectAssignedUser, result.user) as ProjectAssignedUser;
        }));
    }

    /**
     * Project User management
     */

    public getProjectAssignedUsers(projectId: number): Observable<ProjectAssignedUser[] | null> {
        return this.httpRequestService.get(`project/${ projectId }/user/assigned/list`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectAssignedUser, result.users) as ProjectAssignedUser[];
        }));
    }

    public getAvailableUsers(projectId: number): Observable<ProjectAssignedUser[] | null> {
        return this.httpRequestService.get(`project/${ projectId }/user/available/list`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectAssignedUser, result.users) as ProjectAssignedUser[];
        }));
    }

    public assignUserToProject(projectId: number, userId: number): Observable<any> {
        return this.httpRequestService.post(`project/${ projectId }/associate/user/${ userId }`, {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectAssignedUser, result.user) as ProjectAssignedUser;
        }));
    }

    public unAssignUserFromProject(projectId: number, userId: number): Observable<any> {
        return this.httpRequestService.delete(`project/${ projectId }/dissociate/user/${ userId }`);
    }

    /**
     * Project Lot User management
     */

    public getAvailableUsersForLot(projectId: number, lotId: number) {
        return this.httpRequestService.get(`project/${ projectId }/lot/${ lotId }/user/available/list`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectAssignedUser, result.users) as ProjectAssignedUser[];
        }));
    }

    public getAssignedUsersOfLot(projectId: number, lotId: number) {
        return this.httpRequestService.get(`project/${ projectId }/lot/${ lotId }/user/associated/list`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectAssignedUser, result.users) as ProjectAssignedUser[];
        }));
    }

    public assignUserToProjectLot(projectId: number, lotId: number, userId: number, form: LotAssignUserForm): Observable<any> {
        return this.httpRequestService.post(`project/${ projectId }/lot/${ lotId }/associate/user/${ userId }`, form.toRequestForm())
            .pipe(map(result => {
                return this.httpRequestService.mapToModel(ProjectAssignedUser, result.lot_user) as ProjectAssignedUser;
            }));
    }

    public unAssignUserFromProjectLot(projectId: number, lotId: number, userId: number): Observable<any> {
        return this.httpRequestService.delete(`project/${ projectId }/lot/${ lotId }/dissociate/user/${ userId }`);
    }

    public updateLotUserAssignation(projectId: number, lotId: number, userId: number, form: LotAssignUserForm): Observable<any> {
        return this.httpRequestService.put(`project/${ projectId }/lot/${ lotId }/associate/user/${ userId }`, form.toRequestForm())
            .pipe(map(result => {
                return this.httpRequestService.mapToModel(ProjectAssignedUser, result.lot_user) as ProjectAssignedUser;
            }));
    }

    /**
     * Project Supplier management
     */
    public getProjectAvailableSuppliers(projectId: number): Observable<ProjectAssociatedSupplier[] | null> {
        return this.httpRequestService.get(`project/${ projectId }/supplier/available/list`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectAssociatedSupplier, result.suppliers) as ProjectAssociatedSupplier[];
        }));
    }

    public getProjectAssociatedSuppliers(projectId: number): Observable<ProjectAssociatedSupplier[] | null> {
        return this.httpRequestService.get(`project/${ projectId }/supplier/assigned/list`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectAssociatedSupplier, result.suppliers) as ProjectAssociatedSupplier[];
        }));
    }

    public associateSupplierToProject(projectId: number, userId: number): Observable<any> {
        return this.httpRequestService.post(`project/${ projectId }/associate/supplier/${ userId }`, {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ProjectAssociatedSupplier, result.supplier) as ProjectAssociatedSupplier;
        }));
    }

    public dissociateSupplierFromProject(projectId: number, userId: number): Observable<any> {
        return this.httpRequestService.delete(`project/${ projectId }/dissociate/supplier/${ userId }`);
    }

    /**
     * Project Lot Supplier LOt management
     */

    public getAvailableSupplierLotForLot(projectId: number, lotId: number) {
        return this.httpRequestService.get(`project/${ projectId }/lot/${ lotId }/supplier-lot/available/list`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(LotSupplierLot, result.supplier_lots) as LotSupplierLot[];
        }));
    }

    public getAssociatedSupplierLotOfLot(projectId: number, lotId: number) {
        return this.httpRequestService.get(`project/${ projectId }/lot/${ lotId }/supplier-lot/associated/list`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(LotSupplierLot, result.supplier_lots) as LotSupplierLot[];
        }));
    }

    public associateSupplierLotToProjectLot(projectId: number, lotId: number, supplierLotId: number, form: LotAssociateSupplierLotForm): Observable<any> {
        return this.httpRequestService.post(`project/${ projectId }/lot/${ lotId }/associate/supplier-lot/${ supplierLotId }`, form.toRequestForm())
            .pipe(map(result => {
                return this.httpRequestService.mapToModel(LotSupplierLot, result.supplier_lot) as LotSupplierLot;
            }));
    }

    public dissociateSupplierLotFromProjectLot(projectId: number, lotId: number, userId: number): Observable<any> {
        return this.httpRequestService.delete(`project/${ projectId }/lot/${ lotId }/dissociate/supplier-lot/${ userId }`);
    }

    public updateLotSupplierLotAssociation(projectId: number, lotId: number, supplierLotId: number, form: LotAssociateSupplierLotForm): Observable<any> {
        return this.httpRequestService.put(`project/${ projectId }/lot/${ lotId }/associate/supplier-lot/${ supplierLotId }`, form.toRequestForm())
            .pipe(map(result => {
                return this.httpRequestService.mapToModel(LotSupplierLot, result.supplier_lot) as LotSupplierLot;
            }));
    }

}
