import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { RequestError } from '../../models';

@Injectable()
export class HttpRequestService {
    constructor(private http: HttpClient) {
    }

    private static handleResponse(response: any): Observable<any> {
        if (response.success) {
            return response;
        } else {
            const requestError = new RequestError();
            requestError.code = response.code;
            requestError.name = response.name;
            requestError.messages = response.messages || [];
            return throwError(requestError);
        }
    }

    private static handleErrorResponse(data: any): Observable<any> {
        const requestError = new RequestError();
        if (data && data.status) {
            requestError.httpCode = data.status;
            if (data.error) {
                requestError.code = data.error.code;
                requestError.name = data.error.name;
                requestError.messages = data.error.messages || [];
            }
        } else {
            requestError.httpCode = 500;
        }
        return throwError(requestError);
    }

    public get(route: string): Observable<any> {
        const url = `${ environment.api_base_url }/${ route }`;
        return this.http.get(url).pipe(map((response) => {
            return HttpRequestService.handleResponse(response);
        }), catchError(error => {
            return HttpRequestService.handleErrorResponse(error);
        }));
    }

    public getDownloadFile(route: string): Observable<any> {
        const url = `${ environment.api_base_url }/${ route }`;
        return this.http.get(url, {
            responseType: 'blob',
        }).pipe(map((response) => {
            return response;
        }), catchError(error => {
            return HttpRequestService.handleErrorResponse(error);
        }));
    }

    public post(route: string, params: any): Observable<any> {
        const url = `${ environment.api_base_url }/${ route }`;
        return this.http.post(url, params).pipe(map(response => {
            return HttpRequestService.handleResponse(response);
        }), catchError(err => {
            return HttpRequestService.handleErrorResponse(err);
        }));
    }

    public postDownloadFile(route: string, params: any, type: string): Observable<any> {
        const url = `${ environment.api_base_url }/${ route }`;
        return this.http.post(url, params, {
            responseType: 'blob',
        }).pipe(map(response => {
            return response;
        }), catchError(err => {
            return HttpRequestService.handleErrorResponse(err);
        }));
    }

    public put(route: string, params: any): Observable<any> {
        const url = `${ environment.api_base_url }/${ route }`;
        return this.http.put(url, params).pipe(map(response => {
            return HttpRequestService.handleResponse(response);
        }), catchError(err => {
            return HttpRequestService.handleErrorResponse(err);
        }));
    }

    public patch(route: string, params: any): Observable<any> {
        const url = `${ environment.api_base_url }/${ route }`;
        return this.http.patch(url, params).pipe(map(response => {
            return HttpRequestService.handleResponse(response);
        }), catchError(err => {
            return HttpRequestService.handleErrorResponse(err);
        }));
    }

    public delete(route: string): Observable<any> {
        const url = `${ environment.api_base_url }/${ route }`;
        return this.http.delete(url).pipe(map(response => {
            return HttpRequestService.handleResponse(response);
        }), catchError(err => {
            return HttpRequestService.handleErrorResponse(err);
        }));
    }

    public mapToModel<T, Y>(type: new () => T, data: any, depth: number = 0, typeResolver?: (data) => Y) {
        if (!data) {
            return null;
        }
        if (Array.isArray(data)) {
            const entities = [];
            data.forEach(dataObject => {
                const entityType = typeResolver ? typeResolver(dataObject) as any : type;
                const entity = this.mapPropertiesToModel(entityType, dataObject, depth);
                entities.push(entity);
            });
            return entities;
        }
        return this.mapPropertiesToModel(type, data, depth);
    }

    private mapPropertiesToModel<T>(type: new () => T, dataObject: object, depth: number) {
        const entity = new type();
        if (Array.isArray(entity.constructor.prototype._propertiesMapperMeta) && depth <= 7) {
            entity.constructor.prototype._propertiesMapperMeta.forEach(property => {
                property.jsonProperties.forEach(jsonProperties => {
                    if (dataObject.hasOwnProperty(jsonProperties.jsonProperty)) {
                        entity[property.entityProperty] = null;
                        if (jsonProperties.typeOfObject && Array.isArray(dataObject[jsonProperties.jsonProperty])) {
                            entity[property.entityProperty] = this.mapToModel<any[], any>(
                                jsonProperties.typeOfObject,
                                dataObject[jsonProperties.jsonProperty],
                                depth + 1,
                                jsonProperties.typeResolver
                            );
                        } else if (jsonProperties.typeOfObject && dataObject[jsonProperties.jsonProperty] instanceof Object) {

                            entity[property.entityProperty] = this.mapToModel(
                                jsonProperties.typeOfObject,
                                dataObject[jsonProperties.jsonProperty],
                                depth + 1
                            );

                        } else if (jsonProperties.typeOfObject && jsonProperties.typeOfObject.name === 'Date') {
                            if (dataObject[jsonProperties.jsonProperty]) {
                                if (!jsonProperties.dateFormat) {
                                    jsonProperties.dateFormat = 'YYYY-MM-DD HH:mm:ss';
                                }
                                entity[property.entityProperty] = moment(dataObject[jsonProperties.jsonProperty], jsonProperties.dateFormat).toDate();
                            }
                        } else {
                            entity[property.entityProperty] = dataObject[jsonProperties.jsonProperty];
                        }
                    }
                });
            });
        }
        return entity;
    }
}
