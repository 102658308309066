import { PropertyMapper } from '../../decorators';
import { MediaFile } from '../media/media-file.model';
import { CustomerBillingAddress } from './customer-billing-address.model';
import { CustomerContact } from './customer-contact.model';
import { CustomerSite } from './customer-site.model';

export class Customer {
    @PropertyMapper()
    public bank: string;

    @PropertyMapper({ jsonProperty: 'bank_id' })
    public bankId: string;

    @PropertyMapper({ jsonProperty: 'business_sector' })
    public businessSector: string;

    @PropertyMapper()
    public id: number;

    @PropertyMapper()
    public name: string;

    @PropertyMapper({ jsonProperty: 'payment_deadline' })
    public paymentDeadline: number;

    @PropertyMapper({ jsonProperty: 'payment_deadline_eom' })
    public paymentDeadlineEom: boolean;

    @PropertyMapper({ jsonProperty: 'vat_number' })
    public vatNumber: number;

    @PropertyMapper({ jsonProperty: 'vat_rate' })
    public vatRate: number;

    @PropertyMapper({ jsonProperty: 'created_at', typeOfObject: Date })
    public createdAt: Date;

    @PropertyMapper({ jsonProperty: 'updated_at', typeOfObject: Date })
    public updatedAt: Date;

    @PropertyMapper({ jsonProperty: 'contacts_count' })
    public contactsCount: number;

    @PropertyMapper({ jsonProperty: 'contacts', typeOfObject: CustomerContact })
    public contacts: CustomerContact[];

    @PropertyMapper({ jsonProperty: 'sites', typeOfObject: CustomerSite })
    public sites: CustomerSite[];

    @PropertyMapper({ jsonProperty: 'billing_address', typeOfObject: CustomerBillingAddress })
    public billingAddress: CustomerBillingAddress[];

    @PropertyMapper({ jsonProperty: 'files', typeOfObject: MediaFile })
    public files: MediaFile[];

    public purchaseContacts: CustomerContact[];
    public technicalContacts: CustomerContact[];
    public accountingContacts: CustomerContact[];
}
