<form *ngIf="form" [formGroup]="form.validationForm" nz-form nzLayout="vertical">
	<nz-form-item>
		<nz-form-label nzRequired>Date</nz-form-label>
		<nz-form-control nzErrorTip=" Veuillez saisir la date de début et de fin">
			<nz-range-picker [formControlName]="'range'" nzFormat="dd/MM/yyyy"></nz-range-picker>
		</nz-form-control>
	</nz-form-item>
	<div nz-row nzGutter="15">
		<div nz-col nzSpan="12">
			<nz-form-item>
				<nz-form-label nzRequired>Catégorie</nz-form-label>
				<nz-form-control nzErrorTip="Veuillez saisir la catégorie de dépense">
					<nz-select (ngModelChange)="categoryChangedHandler()" [formControlName]="'category'">
						<nz-option [nzLabel]="'Frais structurel'" [nzValue]="1"></nz-option>
						<nz-option [nzLabel]="'Frais de projet'" [nzValue]="2"></nz-option>
					</nz-select>
				</nz-form-control>
			</nz-form-item>
		</div>
		<div nz-col nzSpan="12">
			<nz-form-item>
				<nz-form-label nzRequired>Type</nz-form-label>
				<nz-form-control nzErrorTip="Veuillez saisir un type de dépense">
					<nz-select (ngModelChange)="typeChangeHandler()" [formControlName]="'type'">
						<ng-container *ngFor="let type of expenseReportTypes">
							<nz-option [nzHide]="type.name === 'Frais de panier repas' && form.validationForm.get('category').value === 1"
							           [nzLabel]="type.name"
							           [nzValue]="type.id"></nz-option>
						</ng-container>
					</nz-select>
				</nz-form-control>
			</nz-form-item>
		</div>
	</div>
	<ng-container *ngIf="this.form.validationForm.get('category').value === 2">
		<div nz-row nzGutter="15">
			<div nz-col nzSpan="12">
				<nz-form-item>
					<nz-form-label nzRequired>Projet</nz-form-label>
					<nz-form-control [nzErrorTip]="projectErrorTips">
						<nz-select (ngModelChange)="projectChangedHandler($event)" *ngIf="projects"
						           [formControlName]="'projectId'">
							<nz-option *ngFor="let project of projects" [nzLabel]="project.name"
							           [nzValue]="project.id"></nz-option>
						</nz-select>
						<ng-template #projectErrorTips>
							Veuillez saisir un projet
						</ng-template>
					</nz-form-control>
				</nz-form-item>
			</div>
			<div nz-col nzSpan="12">
				<nz-form-item *ngIf="lots">
					<nz-form-label nzRequired>Lot</nz-form-label>
					<nz-form-control nzErrorTip="Veuillez saisir un lot de projet">
						<nz-select [formControlName]="'lotId'">
							<nz-option *ngFor="let lot of lots" [nzLabel]="lot.name" [nzValue]="lot.id"></nz-option>
						</nz-select>
					</nz-form-control>
				</nz-form-item>
			</div>
		</div>
	</ng-container>
	<div *ngIf="form.validationForm.get('type').value === 1">
		<ng-container *ngIf="activeCars && activeCars.length > 0;">
			<div nz-row nzGutter="15" *ngIf="this.form.validationForm.get('category').value === 2">
				<div nz-col nzSpan="12">
					<nz-form-item>
						<nz-form-label nzRequired>Site client</nz-form-label>
						<nz-form-control nzErrorTip="Veuillez sélectionner un site client">
							<nz-select formControlName="siteId"
							           (ngModelChange)="siteChangeHandler($event)">
								<nz-option *ngFor="let site of projectCustomerSites" [nzLabel]="site.name" [nzValue]="site.id"></nz-option>
							</nz-select>
						</nz-form-control>
					</nz-form-item>
				</div>
			</div>
			<div nz-row nzGutter="15">
				<div nz-col nzSpan="12">
					<nz-form-item>
						<nz-form-label nzRequired>Voiture</nz-form-label>
						<nz-form-control nzErrorTip="Veuillez saisir une voiture">
							<nz-select [formControlName]="'carId'">
								<nz-option *ngFor="let car of activeCars" [nzLabel]="car.model" [nzValue]="car.id"></nz-option>
							</nz-select>
						</nz-form-control>
					</nz-form-item>
				</div>
				<div nz-col nzSpan="12">
					<nz-form-item>
						<nz-form-label nzRequired>
						<span nz-tooltip nzTooltipTitle="Cliquer sur l'icone ? pour connaitre les méthodes de calculs des frais kilométriques"
						      style="padding-right: 8px;">
							Distance en KM</span>
							<fa-icon (click)="carTipsModalVisible = true;" [icon]="['far', 'question-circle']"
							         class="hover-pointer"></fa-icon>
						</nz-form-label>
						<nz-form-control nzErrorTip="Veuillez saisir la distance en km">
							<input [formControlName]="'mileage'" nz-input/>
						</nz-form-control>
					</nz-form-item>
				</div>
			</div>
		</ng-container>
		<div *ngIf="activeCars && activeCars.length == 0;" nz-col nzSpan="24">
			<nz-alert
					[nzDescription]="noCarWarning"
					nzMessage="Aucune voiture associé à votre profile"
					nzType="warning"
			></nz-alert>
			<ng-template #noCarWarning>
				<p>Vous devez posséder au moins une voiture pour pouvoir faire une déclaration de frais de déplacement personnel en voiture.</p>
				<p>Vous pouvez ajouter une voiture depuis votre profile, accéder à votre profile en cliquant sur votre nom d'utilisateur en haut à droite.</p>
			</ng-template>
		</div>
	</div>
	<div *ngIf="form.validationForm.get('type').value !== 1" nz-row nzGutter="15">
		<div nz-col nzSpan="12">
			<nz-form-item>
				<nz-form-label nzRequired>Dépense</nz-form-label>
				<nz-form-control nzErrorTip="Veuillez saisir le cout de dépense">
					<input [formControlName]="'amount'" nz-input/>
				</nz-form-control>
			</nz-form-item>
		</div>
		<div nz-col nzSpan="12">
			<nz-form-item>
				<nz-form-label>TVA récupérable</nz-form-label>
				<nz-form-control nzErrorTip="Veuillez saisir le montant de la tva récupérable">
					<input [formControlName]="'recoverableVat'" nz-input/>
				</nz-form-control>
			</nz-form-item>
		</div>
	</div>
	<nz-form-item>
		<nz-form-label>Description</nz-form-label>
		<nz-form-control>
			<textarea [formControlName]="'description'" nz-input></textarea>
		</nz-form-control>
	</nz-form-item>
	<div class="action-buttons" nz-row nzGutter="15" nzJustify="end">
		<div nz-col>
			<nz-space>
				<button *nzSpaceItem (click)="closeModelHandler();" [nzType]="'default'" nz-button>Annuler</button>
				<button *nzSpaceItem (click)="sendDeclaration();" [nzType]="'primary'" nz-button style="margin-left: 15px;">
					Confirmer
				</button>
			</nz-space>
		</div>
	</div>
</form>

<nz-modal (nzOnCancel)="carTipsModalVisible = false" (nzOnOk)="carTipsModalVisible = false;"
          [(nzVisible)]="carTipsModalVisible"
          [nzCancelText]="null"
          nzTitle="Barème de calcul des frais kilométriques">
	<ng-container *nzModalContent>
		<h4>Prix de revient kilométriques applicables aux autos.
			<br>
			En euros par kilomètre, Frais de garage exclus. (d = distance parcourue)
			<br><br>
			<nz-alert [nzDescription]="howToCalculateMyScaleTpl" nzMessage="Quel est mon barème ?"
			          nzType="info">
			</nz-alert>
			<ng-template #howToCalculateMyScaleTpl>
				Votre barème est déterminée par le total des distances parcourues au cours de l'année.
				<br>
				Si au cours de l'année, vous dépasser le barème des 5000km ou 20000km,
				le calcul des frais sera ré-ajuster automatiquement
				sur votre prochaine déclaration des frais kilométriques sous réserve de validation par votre manageur.
			</ng-template>
		</h4>
		<nz-table nzShowPagination="false" nzSize="small" nzTemplateMode>
			<thead>
			<tr>
				<th>CV fiscaux</th>
				<th>d <= 5000 km</th>
				<th>de 5001 à 20000 km</th>
				<th>d >= 20001 km</th>
			</tr>
			</thead>
			<tbody>
			<tr>
				<td>3</td>
				<td>0,405 x km</td>
				<td>0,242 x km</td>
				<td>0,283 x km</td>
			</tr>
			<tr>
				<td>4</td>
				<td>0,487 x km</td>
				<td>0,274 x km</td>
				<td>0,327 x km</td>
			</tr>
			<tr>
				<td>5</td>
				<td>0,536 x km</td>
				<td>0,300 x km</td>
				<td>0,359 x km</td>
			</tr>
			<tr>
				<td>6</td>
				<td>0,561 x km</td>
				<td>0,316 x km</td>
				<td>0,377 x km</td>
			</tr>
			<tr>
				<td>7</td>
				<td>0,587 x km</td>
				<td>0,332 x km</td>
				<td>0,396 x km</td>
			</tr>
			</tbody>
		</nz-table>
	</ng-container>
</nz-modal>
