import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import Holidays from 'date-holidays';
import moment from 'moment';

@Injectable()
export class HelpService {

    constructor() {
    }

    public static deepCopy<T>(source: T): T {
        return Array.isArray(source)
            ? source.map(item => this.deepCopy(item))
            : source instanceof Date
                ? new Date(source.getTime())
                : source && typeof source === 'object'
                    ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
                        Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop));
                        o[prop] = this.deepCopy(source[prop]);
                        return o;
                    }, Object.create(Object.getPrototypeOf(source)))
                    : source as T;
    }

    public static getDateToString(date: Date): string {
        if (!date) {
            return null;
        }
        const utcDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
        return new Date(utcDate).toISOString();
    }

    public static getDateMonthName(date: Date): string {
        const monthNames = [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Août',
            'Septembre',
            'Octobre',
            'Novembre',
            'Décembre'
        ];

        return monthNames[date.getMonth()];
    }

    public static compareDates(date1, date2): boolean {
        const d1 = moment(date1).toDate();
        const d2 = moment(date2).toDate();
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    }

    public static isDatesEqual(date1: Date, date2: Date): boolean {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }

    public static convertStringToDate(dateString: string | null): Date | null {
        if (!dateString) {
            return null;
        }
        return moment(dateString, 'DD/MM/YYYY hh:mm:ss').toDate();
    }

    public static format(protocol: string, ...params): string {
        return protocol.replace(/{(\d+)}/g, function (match, number) {
            return typeof params[number] !== 'undefined' ? params[number] : match;
        });
    }

    public static isHoliday(date: Date) {
        const hd = new Holidays('FR');

        const notHoliday = [
            '2021-05-24',
            '2023-05-29',
            '2025-06-09',
            '2027-05-17',
            '2029-05-21',
            '2031-06-02',
        ];
        let isIncluded = false;

        notHoliday.every(value => {
            if (moment(value).isSame(date)) {
                isIncluded = true;
                return;
            }
        });
        if (isIncluded) {
            return false;
        }
        return hd.isHoliday(date);
    }

    /**
     * Display validation error for any form in console
     */
    public static displayValidationError(validationForm) {
        Object.keys(validationForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = validationForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
    }

    // public static pluck = key => array => Array.from(new Set(array.map(obj => obj[key])));

    public static pluck(array, key) {
        return array.map(o => o[key]);
    }
}
