import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreditCreateForm } from '../../../project/forms';
import { Invoice } from '../../models';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class CreditService {

    constructor(private httpRequestService: HttpRequestService) {
    }

    public createCredit(projectId: number, deliveryId: number, invoiceId: number, form: CreditCreateForm): Observable<Invoice | null> {
        return this.httpRequestService
            .post(`project/${ projectId }/delivery/${ deliveryId }/invoice/${ invoiceId }/credit/create`, form.toRequestForm())
            .pipe(map((result) => {
                return this.httpRequestService.mapToModel(Invoice, result.details) as Invoice;
            }));
    }
}
