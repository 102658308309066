import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzMessageService } from 'ng-zorro-antd/message';
import { interval, Subscription } from 'rxjs';
import { DeclarationModeEnum, DeclarationStatusEnum } from '../../../core/enums';
import { InternalActivityTypeEnum } from '../../../core/enums/internal-activity-type.enum';
import { NotificationTypeEnum } from '../../../core/enums/notification-type.enum';
import { InternalActivityType } from '../../../core/models/internal-activity/internal-activity-type.model';
import { InternalActivity } from '../../../core/models/internal-activity/internal-activity.model';
import { EventNotification } from '../../../core/models/notification/event-notification.model';
import { StatusBase } from '../../../core/models/status-base/status-base.model';
import { AdminDeclarationService, DeclarationInteractionService, InternalActivityService } from '../../../core/services';
import { NotificationInteractionService } from '../../../core/services/interaction-service/notification-interaction.service';
import { InternalActivityDeclarationForm } from '../../forms';

@UntilDestroy()
@Component({
    selector: 'internal-activity-declaration',
    templateUrl: './internal-activity-declaration.component.html',
    styleUrls: ['./internal-activity-declaration.component.scss']
})
export class InternalActivityDeclarationComponent implements OnInit, OnChanges {

    @Input() internalActivity: InternalActivity = null;
    @Output() public createEvent: EventEmitter<any> = new EventEmitter();
    @Output() closeEvent: EventEmitter<any> = new EventEmitter();
    @Input() private visible: boolean;
    @Input() public userId;
    @Input() public declarationMode = DeclarationModeEnum.SELF;

    public form: InternalActivityDeclarationForm = new InternalActivityDeclarationForm();
    public internalActivityTypes: InternalActivityType[] = [];
    public selectedDate: Date = null;
    public locked = false;
    public status: StatusBase;
    public isCreateMode = true;
    public declarationStatusEnum = DeclarationStatusEnum;
    public internalActivityTypeEnum = InternalActivityTypeEnum;
    private internalActivityTypeListSubscription: Subscription = null;
    private selectedDateSubscription: Subscription = null;
    private createSubscription: Subscription = null;
    private updateSubscription: Subscription = null;

    constructor(private internalActivityService: InternalActivityService,
                private adminDeclarationService: AdminDeclarationService,
                private nzMessageService: NzMessageService,
                private notificationInteractionService: NotificationInteractionService,
                private declarationInteractionService: DeclarationInteractionService) {
    }

    ngOnInit() {
        this.internalActivityTypeListSubscription = this.internalActivityService.getInternalActivityTypeList()
            .subscribe(internalActivityTypes => {
                this.internalActivityTypes = internalActivityTypes;
            });
        this.selectedDateSubscription = this.declarationInteractionService.selectedDate$.subscribe((selectedDate) => {
            this.selectedDate = selectedDate;
        });
        interval(1000).pipe(untilDestroyed(this)).subscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.visible) {
            if (this.internalActivity && this.visible) {
                this.isCreateMode = !( this.internalActivity && this.internalActivity.id );
                this.status = this.internalActivity.status;
                this.form = new InternalActivityDeclarationForm();
                this.form.validationForm.get('type').setValue(this.internalActivity.type ? this.internalActivity.type.id : null);
                this.form.validationForm.get('duration').setValue(this.internalActivity.duration ? this.internalActivity.duration : null);
                this.form.validationForm.get('description').setValue(this.internalActivity.description);

                if (this.internalActivity.status && this.internalActivity.status.id !== DeclarationStatusEnum.WAITING_VALIDATION) {
                    this.form.validationForm.get('type').disable();
                    this.form.validationForm.get('duration').disable();
                    this.form.validationForm.get('description').disable();
                    this.locked = true;
                } else {
                    this.locked = false;
                }
            }
        }
    }

    public closeModalHandler() {
        this.closeEvent.emit();
    }

    public sendDeclaration() {
        this.form.validationForm.get('description').clearValidators();
        if (this.form.validationForm.get('type').value === this.internalActivityTypeEnum.OTHERS) {
            this.form.validationForm.get('description').setValidators([Validators.required]);
        } else {
            this.form.validationForm.get('description').setValidators([]);
        }
        this.form.validationForm.get('description').updateValueAndValidity();

        this.form.validate();
        if (this.selectedDate) {
            const utcDate = Date.UTC(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate());
            this.form.validationForm.get('date').setValue(new Date(utcDate).toISOString());
        }
        if (this.form.validationForm.invalid) {
            this.nzMessageService.error('Erreur de validation');
            return;
        }
        if (this.internalActivity && this.internalActivity.id && typeof this.internalActivity.id !== 'undefined') {
            this.updateDeclaration();
        } else {
            this.createDeclaration();
        }
    }

    private updateDeclaration() {
        this.updateSubscription = this.internalActivityService.updateInternalActivity(this.internalActivity.id, this.form)
            .subscribe((internalActivity: InternalActivity) => {
                this.nzMessageService.success('Mise à jour réussie !');
                internalActivity.uuid = this.internalActivity.uuid;
                this.declarationInteractionService.updateInternalActivity(internalActivity);
                this.closeEvent.emit();
            }, err => {
                this.notificationInteractionService.displayErrorNotification(
                    new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', err.messages));
            });
    }

    private createDeclaration() {
        if (this.declarationMode === DeclarationModeEnum.MANAGER && this.userId) {
            this.createSubscription = this.adminDeclarationService.createInternalActivity(this.userId, this.form)
                .subscribe((internalActivity: InternalActivity) => {
                    this.nzMessageService.success('Création réussie !');
                    this.internalActivity = internalActivity;
                    this.declarationInteractionService.createInternalActivity(internalActivity);
                    this.closeEvent.emit();
                    this.createEvent.emit(this.internalActivity);
                }, err => {
                    this.notificationInteractionService.displayErrorNotification(
                        new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', err.messages));
                });
        } else {
            this.createSubscription = this.internalActivityService.createInternalActivity(this.form)
                .subscribe((internalActivity: InternalActivity) => {
                    this.nzMessageService.success('Création réussie !');
                    this.internalActivity = internalActivity;
                    this.declarationInteractionService.createInternalActivity(internalActivity);
                    this.closeEvent.emit();
                    this.createEvent.emit(this.internalActivity);
                }, err => {
                    this.notificationInteractionService.displayErrorNotification(
                        new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', err.messages));
                });
        }
    }
}
