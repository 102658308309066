import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthStoreModule } from './auth-store';
import { StoreModule } from '@ngrx/store';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        AuthStoreModule,
        StoreModule.forRoot({})
    ]
})
export class RootStoreModule {
}
