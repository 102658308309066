import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdminUserPasswordForm, UserCreateForm, UserListFilterForm, UserUpdateForm } from '../../../parameter/forms';
import { User } from '../../models';
import { __API_ADMIN_MANAGER_LIST_GET__, __API_ADMIN_USER_CREATE__ } from '../../routes/api.route';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class AdminUserService {

    constructor(private httpRequestService: HttpRequestService) {
    }

    public adminUpdateUserPassword(userId: number, form: AdminUserPasswordForm): Observable<any> {
        const url = `admin/user/${ userId }/password`;
        return this.httpRequestService.put(url, form.toRequestForm()).pipe(map(result => {
            return this.httpRequestService.mapToModel(User, result.user);
        }));
    }

    public adminUpdateUser(userId: number, form: UserUpdateForm): Observable<any> {
        const url = `admin/user/${ userId }/update`;
        return this.httpRequestService.put(url, form.toRequestForm()).pipe(map(result => {
            return this.httpRequestService.mapToModel(User, result.user);
        }));
    }

    public adminCreateUser(form: UserCreateForm): Observable<any> {
        return this.httpRequestService.post(__API_ADMIN_USER_CREATE__, form.toRequestForm()).pipe(map(result => {
            return this.httpRequestService.mapToModel(User, result.user);
        }));
    }

    public getManagerList(form: UserListFilterForm): Observable<any> {
        return this.httpRequestService.post(__API_ADMIN_MANAGER_LIST_GET__, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(User, result.managers) as User[];
        }));
    }
}
