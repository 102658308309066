import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { saveAs } from 'file-saver';
import { NzMessageService } from 'ng-zorro-antd/message';
import { interval, Subscription } from 'rxjs';
import { DeclarationModeEnum, DeclarationStatusEnum } from '../../../core/enums';
import { FileTypeEnum } from '../../../core/enums/file-type.enum';
import { NotificationTypeEnum } from '../../../core/enums/notification-type.enum';
import { FileUploadForm } from '../../../core/forms/file-upload.form';
import { AbsenceType, MediaFile, UploadFile } from '../../../core/models';
import { Absence } from '../../../core/models/absence/absence.model';
import { EventNotification } from '../../../core/models/notification/event-notification.model';
import { StatusBase } from '../../../core/models/status-base/status-base.model';
import { AbsenceService, AdminDeclarationService, DeclarationInteractionService } from '../../../core/services';
import { FileService } from '../../../core/services/file/file.service';
import { NotificationInteractionService } from '../../../core/services/interaction-service/notification-interaction.service';
import { AbsenceDeclarationForm } from '../../forms';

@UntilDestroy()
@Component({
    selector: 'absence-declaration',
    templateUrl: './absence-declaration.component.html',
    styleUrls: ['./absence-declaration.component.scss']
})
export class AbsenceDeclarationComponent implements OnInit, OnChanges {

    @Input() absence: Absence = null;
    @Input() private visible: boolean;
    @Output() closeEvent: EventEmitter<any> = new EventEmitter();
    @Output() public createEvent: EventEmitter<any> = new EventEmitter();
    @Input() public userId;
    @Input() public declarationMode = DeclarationModeEnum.SELF;

    public form: AbsenceDeclarationForm = new AbsenceDeclarationForm();
    public absenceTypes: AbsenceType[] = [];
    public selectedDate: Date = null;
    public locked = false;
    public status: StatusBase;
    public isCreateMode = true;
    public declarationStatusEnum = DeclarationStatusEnum;

    public fileUploadForm = new FileUploadForm();
    public filesToUpload: UploadFile[] = [];

    private absenceTypeListSubscription: Subscription = null;
    private selectedDateSubscription: Subscription = null;
    private createSubscription: Subscription = null;
    private updateSubscription: Subscription = null;
    private getSubscription: Subscription = null;

    constructor(private absenceService: AbsenceService,
                private adminDeclarationService: AdminDeclarationService,
                private nzMessageService: NzMessageService,
                private fileService: FileService,
                private notificationInteractionService: NotificationInteractionService,
                private declarationInteractionService: DeclarationInteractionService) {
    }

    ngOnInit() {
        this.absenceTypeListSubscription = this.absenceService.getAbsenceTypeList().subscribe(absenceTypes => {
            this.absenceTypes = absenceTypes;
        });
        this.selectedDateSubscription = this.declarationInteractionService.selectedDate$.subscribe((selectedDate) => {
            this.selectedDate = selectedDate;
        });
        interval(1000).pipe(untilDestroyed(this)).subscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.visible) {
            if (this.absence && this.visible) {
                this.isCreateMode = !( this.absence && this.absence.id );
                this.status = this.absence.status;
                this.resetAndFillForm();
                if (this.absence.status && this.absence.status.id !== DeclarationStatusEnum.WAITING_VALIDATION) {
                    this.form.validationForm.get('type').disable();
                    this.form.validationForm.get('duration').disable();
                    this.form.validationForm.get('description').disable();
                    this.locked = true;
                } else {
                    this.locked = false;
                }
            }
        }
    }

    public closeModalHandler() {
        this.closeEvent.emit();
    }

    private resetAndFillForm() {
        this.form = new AbsenceDeclarationForm();
        this.form.validationForm.get('type').setValue(this.absence.type ? this.absence.type.id : null);
        this.form.validationForm.get('duration').setValue(this.absence.duration ? this.absence.duration : null);
        this.form.validationForm.get('description').setValue(this.absence.description);
    }

    public sendDeclaration() {
        this.form.validate();
        if (this.selectedDate) {
            const utcDate = Date.UTC(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate());
            this.form.validationForm.get('date').setValue(new Date(utcDate).toISOString());
        }
        if (this.form.validationForm.invalid) {
            this.nzMessageService.error('Erreur de validation');
            return;
        }
        if (this.absence && this.absence.id && typeof this.absence.id !== 'undefined') {
            this.updateDeclaration();
        } else {
            this.createDeclaration();
        }
    }

    private updateDeclaration() {
        this.updateSubscription = this.absenceService.updateAbsence(this.absence.id, this.form)
            .subscribe((absence: Absence) => {
                this.nzMessageService.success('Mise à jour réussie !');
                absence.uuid = this.absence.uuid;
                this.declarationInteractionService.updateAbsence(absence);
                this.closeEvent.emit();
                this.resetAndFillForm();
            }, err => {
                this.notificationInteractionService.displayErrorNotification(
                    new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', err.messages));
            });
    }

    private refreshDeclaration() {
        this.getSubscription = this.absenceService.getAbsence(this.absence.id).subscribe((absence) => {
            absence.uuid = this.absence.uuid;
            this.absence = absence;
            this.resetAndFillForm();
            this.declarationInteractionService.updateAbsence(absence);
        });
    }

    private createDeclaration() {
        if (this.declarationMode === DeclarationModeEnum.MANAGER && this.userId) {
            this.createSubscription = this.adminDeclarationService.createAbsence(this.userId, this.form).subscribe((absence: Absence) => {
                this.nzMessageService.success('Création réussie !');
                this.absence = absence;
                this.uploadAllAssociatedFiles();
                this.declarationInteractionService.createAbsence(absence);
                this.closeEvent.emit();
                this.createEvent.emit(this.absence);
            }, err => {
                this.notificationInteractionService.displayErrorNotification(
                    new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', err.messages));
            });
        } else {
            this.createSubscription = this.absenceService.createAbsence(this.form).subscribe((absence: Absence) => {
                this.nzMessageService.success('Création réussie !');
                this.absence = absence;
                this.uploadAllAssociatedFiles();
                // this.declarationInteractionService.createAbsence(absence);
                // this.closeEvent.emit();
                // this.createEvent.emit(this.absence);
            }, err => {
                this.notificationInteractionService.displayErrorNotification(
                    new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', err.messages));
            });
        }
    }

    /**
     * FILE UPLOAD FUNCTIONS FOR UPLOAD WHILE CREATING NEW EXPENSE REPORT
     */
    public openFileDiagCreate() {
        this.fileUploadForm.validationForm.get('type').setValue(FileTypeEnum.EXPENSE_REPORT_PROOF);
        this.fileUploadForm.validate();
        if (this.fileUploadForm.validationForm.valid) {
            document.getElementById('fileUploadInputAbsenceCreate').click();
        }
    }

    public uploadFileSelectForCreate(event) {
        if (event.target) {
            const file = new UploadFile();
            file.type = FileTypeEnum.EXPENSE_REPORT_PROOF;
            file.file = event.target.files[0];
            this.filesToUpload.push(file);
        }
    }

    public uploadAllAssociatedFiles() {
        if (this.filesToUpload.length === 0) {
            this.fileUploadForm = new FileUploadForm();
            this.nzMessageService.success('Création réussie !');
            this.declarationInteractionService.createAbsence(this.absence);
            this.closeModalHandler();
            this.createEvent.emit(this.absence);
        } else {
            this.filesToUpload.forEach((item: UploadFile) => {
                this.fileUploadForm = new FileUploadForm();
                this.fileUploadForm.validationForm.get('type').setValue(item.type);
                this.fileUploadForm.validationForm.get('file').setValue(item.file);
                this.fileService.uploadAbsenceFile(this.absence.id, this.fileUploadForm).subscribe((result) => {
                    this.notificationInteractionService.displaySuccessNotification(
                        new EventNotification(NotificationTypeEnum.SUCCESS, 'Succes', ['Les fichiers ont été uploadés avec succès']));
                    this.fileUploadForm = new FileUploadForm();
                    this.nzMessageService.success('Création réussie !');
                    this.getSubscription = this.absenceService.getAbsence(this.absence.id).subscribe((absence) => {
                        this.absence = absence;
                        this.declarationInteractionService.createAbsence(this.absence);
                        this.closeModalHandler();
                        this.createEvent.emit(this.absence);
                    });
                }, error => {
                    this.fileUploadForm = new FileUploadForm();
                    this.notificationInteractionService.displayErrorNotification(
                        new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', error.messages));
                });
            });
            this.filesToUpload = [];
        }
    }

    public deleteFileToUpload(filename) {
        this.filesToUpload = this.filesToUpload.filter(file => file.file.name !== filename);
    }

    /**
     * FILE UPLOAD FUNCTIONS FOR UPLOAD WHILE UPDATING EXPENSE REPORT
     */
    public openFileDiagUpdate() {
        this.fileUploadForm.validationForm.get('type').setValue(FileTypeEnum.EXPENSE_REPORT_PROOF);
        this.fileUploadForm.validate();
        if (this.fileUploadForm.validationForm.valid) {
            document.getElementById('fileUploadInputAbsenceUpdate').click();
        }
    }

    public uploadFileSelectForUpdate(event) {
        if (event.target) {
            const file = event.target.files[0];
            this.fileUploadForm.validationForm.get('file').setValue(file);
            this.uploadFile();
        }
    }

    public uploadFile() {
        this.fileService.uploadAbsenceFile(this.absence.id, this.fileUploadForm).subscribe((result) => {
            this.notificationInteractionService.displaySuccessNotification(
                new EventNotification(NotificationTypeEnum.SUCCESS, 'Succes', ['Le fichier a été uploadé avec succès']));
            this.fileUploadForm = new FileUploadForm();
            this.refreshDeclaration();
        }, error => {
            this.fileUploadForm = new FileUploadForm();
            this.notificationInteractionService.displayErrorNotification(
                new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', error.messages));
        });
        this.fileUploadForm.validationForm.get('file').setValue(null);
    }

    public deleteFile(file: MediaFile) {
        this.fileService.deleteAbsenceFile(this.absence.id, file.uuid).subscribe((result) => {
            this.notificationInteractionService.displaySuccessNotification(
                new EventNotification(NotificationTypeEnum.SUCCESS, 'Succes', ['Le fichier a été supprimé avec succès']));
            this.refreshDeclaration();
        }, error => {
            this.notificationInteractionService.displayErrorNotification(
                new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', error.messages));
        });
    }

    public downloadPdf(file: MediaFile) {
        fetch(file.url, { mode: 'cors' })
            .then(res => res.blob())
            .then(blob => saveAs(blob, file.fileName));
    }
}
