import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { NzMessageService } from 'ng-zorro-antd/message';
import { interval, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { REGEX_POSITIVE_DECIMAL_WITH_TWO_DIGITS } from '../../../core/const';
import { NotificationTypeEnum } from '../../../core/enums/notification-type.enum';
import { AuthModel, CustomerSite, Lot, Project, User, UserCar } from '../../../core/models';
import { ExpenseReportType } from '../../../core/models/expense-report/expense-report-type.model';
import { ExpenseReport } from '../../../core/models/expense-report/expense-report.model';
import { EventNotification } from '../../../core/models/notification/event-notification.model';
import { ActivityService, DeclarationInteractionService, ExpenseReportService, RouterService, UserService } from '../../../core/services';
import { HttpRequestService } from '../../../core/services/http/http-request.service';
import { NotificationInteractionService } from '../../../core/services/interaction-service/notification-interaction.service';
import { AuthStoreSelectors, AuthStoreState } from '../../../root-store/auth-store';
import { ExpenseReportDeclarationMultiForm } from '../../forms';

@UntilDestroy()
@Component({
    selector: 'expense-report-declaration-multi',
    templateUrl: './expense-report-declaration-multi.component.html',
    styleUrls: ['./expense-report-declaration-multi.component.scss']
})
export class ExpenseReportDeclarationMultiComponent implements OnInit {

    @Output() public closeEvent: EventEmitter<any> = new EventEmitter();
    public form: ExpenseReportDeclarationMultiForm = new ExpenseReportDeclarationMultiForm();
    public projects: Project[] = [];
    public expenseReportTypes: ExpenseReportType[] = [];
    public lots: Lot[] = [];
    public projectCustomerSites: CustomerSite[] = [];
    private projectSubscription: Subscription = null;
    private createSubscription: Subscription = null;
    private expenseReportTypeSubscription: Subscription = null;
    public carTipsModalVisible = false;

    public authModel: AuthModel = null;
    public user: User = null;
    public activeCars: UserCar[] = null;
    private initSubscription: Subscription = null;

    constructor(private expenseReportService: ExpenseReportService,
                private nzMessageService: NzMessageService,
                private notificationInteractionService: NotificationInteractionService,
                private declarationInteractionService: DeclarationInteractionService,
                private activityService: ActivityService,
                private userService: UserService,
                private store: Store<AuthStoreState.State>,
                private routerService: RouterService,
                private httpRequestService: HttpRequestService) {
        this.initSubscription = this.store.pipe(select(AuthStoreSelectors.selectAuthModel))
            .pipe(mergeMap((model) => {
                this.authModel = model;
                return this.userService.getUserInformationByUsername(model.username);
            })).subscribe((user: User) => {
                if (!user) {
                    this.notificationInteractionService.displayErrorNotification(
                        new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', ['L\'utilisateur est introuvable']));
                }
                this.user = user;
                this.activeCars = this.user.cars.filter((car: UserCar) => {
                    return car.active;
                });
            }, error => {
                this.notificationInteractionService.displayErrorNotification(
                    new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', ['L\'utilisateur est introuvable']));
            });

        this.expenseReportTypeSubscription = this.expenseReportService.getExpenseReportTypeList().subscribe(types => {
            this.expenseReportTypes = types;
        });

        this.projectSubscription = this.activityService.getProjectsForDeclarations().subscribe(projects => {
            this.projects = projects;
        });
        this.updateFormValidators();
    }

    ngOnInit() {
        interval(1000).pipe(untilDestroyed(this)).subscribe();
    }

    public sendDeclaration() {
        this.form.validate();
        if (this.form.validationForm.invalid) {
            this.nzMessageService.error('Veuillez remplir tous les champs obligatoires.');
            return;
        }

        if (this.form.validationForm.get('category').value === 2
            && this.form.validationForm.get('type').value === 1
            && this.form.validationForm.get('mileage').value === null) {
            this.notificationInteractionService.displayErrorNotification(
                new EventNotification(NotificationTypeEnum.ERROR, 'Erreur',
                    ['Vous ne pouvez pas faire une déclaration sur un site client dont la distance avec A2L ' +
                    'n\'est pas encore définis, merci de contacter votre chef de projet']));
            return;
        }

        this.createDeclarationMulti();
    }

    public closeModelHandler() {
        this.closeEvent.emit();
    }

    public projectChangedHandler(projectId: number) {
        if (!projectId) {
            return;
        }
        this.lots = [];
        this.form.validationForm.get('lotId').setValue(null);
        this.form.validationForm.get('siteId').setValue(null);
        if (projectId) {
            this.projects.forEach((project: Project) => {
                if (project.id === projectId) {
                    this.lots = this.httpRequestService.mapToModel(Lot, project.lots) as Lot[];
                    this.projectCustomerSites = project.customerSites;
                }
            });
        }
    }

    public siteChangeHandler($event) {
        this.projectCustomerSites.forEach((site: CustomerSite) => {
            if (site.id === $event) {
                this.form.validationForm.get('mileage').setValue(site.mileage);
            }
        });
    }

    public typeChangeHandler() {
        this.updateFormValue();
        this.updateFormValidators();
    }

    public categoryChangedHandler() {
        this.updateFormValue();
        this.updateFormValidators();
    }

    public updateFormValue() {
        const type = this.form.validationForm.get('type').value;
        const category = this.form.validationForm.get('category').value;

        this.form.validationForm.get('projectId').setValue(null);
        this.form.validationForm.get('lotId').setValue(null);
        this.form.validationForm.get('amount').setValue(0);
        this.form.validationForm.get('mileage').setValue(null);
        this.form.validationForm.get('carId').setValue(null);
        this.form.validationForm.get('siteId').setValue(null);
        this.form.validationForm.get('recoverableVat').setValue(null);
        this.form.validationForm.get('description').setValue(null);

        if (category === 2 && type === 5) {
            this.form.validationForm.get('amount').setValue(6);
        }
    }

    public updateFormValidators() {
        const type = this.form.validationForm.get('type').value;
        const category = this.form.validationForm.get('category').value;

        if (category === 1) {
            this.form.validationForm.get('projectId').setValidators(null);
            this.form.validationForm.get('lotId').setValidators(null);
            this.form.validationForm.get('siteId').setValidators(null);
            this.form.validationForm.get('mileage').enable();

            if (type === 1) {
                this.form.validationForm.get('carId').setValidators([Validators.required]);
                this.form.validationForm.get('mileage').setValidators([Validators.required, Validators.pattern(REGEX_POSITIVE_DECIMAL_WITH_TWO_DIGITS)]);
            } else {
                this.form.validationForm.get('carId').setValidators(null);
                this.form.validationForm.get('mileage').setValidators(null);
            }
        } else if (category === 2) {
            this.form.validationForm.get('projectId').setValidators([Validators.required]);
            this.form.validationForm.get('lotId').setValidators([Validators.required]);

            if (type === 1) {
                this.form.validationForm.get('carId').setValidators([Validators.required]);
                this.form.validationForm.get('siteId').setValidators([Validators.required]);
                this.form.validationForm.get('mileage').setValidators([Validators.required, Validators.pattern(REGEX_POSITIVE_DECIMAL_WITH_TWO_DIGITS)]);
                this.form.validationForm.get('mileage').disable();
            } else {
                this.form.validationForm.get('carId').setValidators(null);
                this.form.validationForm.get('siteId').setValidators(null);
                this.form.validationForm.get('mileage').setValidators(null);
                this.form.validationForm.get('mileage').enable();
            }
        }
        this.form.validationForm.updateValueAndValidity();
    }

    private createDeclarationMulti() {
        this.createSubscription = this.expenseReportService.createMultiExpenseReport(this.form)
            .subscribe((expenseReports: ExpenseReport[]) => {
                expenseReports.forEach((item: ExpenseReport) => {
                    this.declarationInteractionService.createExpenseReport(item);
                });
                this.closeModelHandler();
                this.nzMessageService.success('Création réussie !');
                this.form = new ExpenseReportDeclarationMultiForm();
            }, error => {
                this.notificationInteractionService.displayErrorNotification(
                    new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', error.messages));
            });
    }
}
