import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AbsenceDeclarationMultiComponent } from './components/absence-declaration-multi/absence-declaration-multi.component';
import { AbsenceDeclarationComponent } from './components/absence-declaration/absence-declaration.component';
import { AcademicTrainingDeclarationMultiComponent } from './components/academic-training-declaration-multi/academic-training-declaration-multi.component';
import { AcademicTrainingDeclarationComponent } from './components/academic-training-declaration/academic-training-declaration.component';
import { ActivityDeclarationMultiComponent } from './components/activity-declaration-multi/activity-declaration-multi.component';
import { ActivityDeclarationComponent } from './components/activity-declaration/activity-declaration.component';
import { DeclarationCalendarCellComponent } from './components/calendar-cell/declaration-calendar-cell.component';
import { DeclarationCalendarComponent } from './components/calendar/declaration-calendar.component';
import { DeclarationCreateModalComponent } from './components/create-modal/declaration-create-modal.component';
import { DeclarationCreateMultiModalComponent } from './components/create-multi-modal/declaration-create-multi-modal.component';
import { ExpenseReportDeclarationMultiComponent } from './components/expense-report-multi/expense-report-declaration-multi.component';
import { ExpenseReportDeclarationComponent } from './components/expense-report/expense-report-declaration.component';
import { InternalActivityDeclarationComponent } from './components/internal-activity-declaration/internal-activity-declaration.component';
import { RangePickerCellComponent } from './components/range-picker-cell/range-picker-cell.component';

import { DeclarationRoutingModule } from './declaration-routing.module';

@NgModule({
    declarations: [
        DeclarationCalendarComponent,
        DeclarationCalendarCellComponent,
        DeclarationCreateModalComponent,
        DeclarationCreateMultiModalComponent,
        ActivityDeclarationComponent,
        ActivityDeclarationMultiComponent,
        ExpenseReportDeclarationComponent,
        ExpenseReportDeclarationMultiComponent,
        AbsenceDeclarationComponent,
        AbsenceDeclarationMultiComponent,
        AcademicTrainingDeclarationComponent,
        AcademicTrainingDeclarationMultiComponent,
        InternalActivityDeclarationComponent,
        RangePickerCellComponent
    ],
    exports: [
        DeclarationCreateModalComponent
    ],
    imports: [
        SharedModule,
        DeclarationRoutingModule
    ]
})
export class DeclarationModule {
}
