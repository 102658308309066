import { PropertyMapper } from '../../decorators';
import { Lot } from '../lot/lot.model';

export class DeliveryLot {

    @PropertyMapper({ jsonProperty: 'delivery_percent' })
    public deliveryPercent: number;

    @PropertyMapper({ jsonProperty: 'lot', typeOfObject: Lot })
    public lot: Lot;

    @PropertyMapper({ jsonProperty: 'ht_amount' })
    public HTAmount: number;

    @PropertyMapper({ jsonProperty: 'ht_amount_fee' })
    public HTAmountFee: number;
}
