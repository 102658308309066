import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { forkJoin, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthStoreActions, AuthStoreSelectors, AuthStoreState } from '../../../root-store';
import { PermissionTypeEnum } from '../../enums';
import { AuthModel, Permission, Profile, Role } from '../../models';
import { AuthService } from '../auth/auth.service';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class PermissionService {

    public authModel: AuthModel = null;
    public permissions: Permission[] = null;
    public role: Role = null;
    public profile: Profile = null;

    private initSubscription: Subscription = null;

    constructor(private httpRequestService: HttpRequestService,
                private store: Store<AuthStoreState.State>,
                private authService: AuthService) {

        this.initSubscription = this.store
            .pipe(select(AuthStoreSelectors.selectAuthModel))
            .pipe(
                mergeMap((model) => {
                    this.authModel = model;
                    if (this.authModel && this.authModel.token) {
                        this.store.dispatch(new AuthStoreActions.SetProfile(this.authModel.profile.profile));
                        this.profile = this.authModel.profile.profile;

                        return forkJoin([
                            this.authService.getPermissions(),
                            this.authService.getRole(),
                        ]);
                    }
                    return forkJoin([Promise.resolve(null), Promise.resolve(null)]);
                })
            ).subscribe(([permissions, role]) => {
                if (permissions && role) {
                    this.store.dispatch(new AuthStoreActions.SetPermissions(permissions));
                    this.store.dispatch(new AuthStoreActions.SetRole(role));
                }
                this.permissions = permissions;
                this.role = role;
            });
    }

    public hasPermissionTo(permissionType: PermissionTypeEnum) {
        if (!this.permissions || this.permissions.length === 0) {
            return false;
        } else {
            const index = this.permissions.findIndex(p => p.type === permissionType);
            if (index > -1) {
                return true;
            }
        }
        return false;
    }

    public hasRole(roleName) {
        if (this.role) {
            return this.role.name === roleName;
        }
        return false;
    }

    public hasAnyRoleOf(roles) {
        const index = roles.findIndex(p => this.role.name === p);
        return index > -1;
    }

    public hasProfile(profileName) {
        if (this.profile) {
            return this.profile.name === profileName;
        }
        return false;
    }

    public hasAnyProfileOf(profiles) {
        const index = profiles.findIndex(p => this.profile.name === p);
        return index > -1;
    }
}
