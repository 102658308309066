import { PropertyMapper } from '../../core/decorators';

export class DeliveryStatistics {

    /**
     * WARNING : PROPERTIES IN THIS MODEL DOES NO HAVE SAME MEANING THAT THOSE IN PROJECT MODEL
     */

    @PropertyMapper()
    public id: number;

    @PropertyMapper({ jsonProperty: 'project_name' })
    public projectName: string;

    @PropertyMapper({ jsonProperty: 'status_name' })
    public statusName: string;

    @PropertyMapper()
    public reference: string;

    @PropertyMapper({ jsonProperty: 'expected_delivery_date' })
    public expectedDeliveryDate;

    @PropertyMapper({ jsonProperty: 'reel_delivery_date' })
    public reelDeliveryDate;

    @PropertyMapper({ jsonProperty: 'delivery_slip_sending_date' })
    public deliverySlipSendingDate;

    @PropertyMapper({ jsonProperty: 'delivery_slip_signature_date' })
    public deliverySlipSignatureDate;

    @PropertyMapper({ jsonProperty: 'invoice_date' })
    public invoiceDate;

    @PropertyMapper({ jsonProperty: 'invoice_sent_date' })
    public invoiceSentDate;

    @PropertyMapper({ jsonProperty: 'invoice_payment_date_estimated' })
    public invoicePaymentDateEstimated;

    @PropertyMapper({ jsonProperty: 'invoice_payment_date_actual' })
    public invoicePaymentDateActual;
}
