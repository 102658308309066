import { Validators } from '@angular/forms';
import { REGEX_POSITIVE_DECIMAL_WITH_TWO_DIGITS } from '../../core/const';
import { BaseForm } from '../../core/forms/base.form';

export class ExpenseReportDeclarationMultiForm extends BaseForm {

    constructor() {
        super();
        this.validationForm = this.formBuilder.group({
            range: [null, Validators.required],
            category: [null, Validators.required],
            amount: [0, [Validators.required, Validators.pattern(REGEX_POSITIVE_DECIMAL_WITH_TWO_DIGITS)]],
            type: [null, Validators.required],
            carId: [null],
            mileage: [null],
            recoverableVat: [0],
            projectId: [null, Validators.required],
            lotId: [null, Validators.required],
            siteId: [null],
            description: [null],
            paid: [false, Validators.required]
        });
    }

    public toRequestForm() {
        return {
            start: this.validationForm.get('range').value[0],
            end: this.validationForm.get('range').value[1],
            category: this.validationForm.get('category').value,
            amount: this.validationForm.get('amount').value,
            car_id: this.validationForm.get('carId').value,
            mileage: this.validationForm.get('mileage').value,
            recoverable_vat: this.validationForm.get('recoverableVat').value,
            type: this.validationForm.get('type').value,
            project_id: this.validationForm.get('projectId').value,
            lot_id: this.validationForm.get('lotId').value,
            site_id: this.validationForm.get('siteId').value,
            description: this.validationForm.get('description').value,
            paid: this.validationForm.get('paid').value,
        };
    }
}
