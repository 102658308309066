import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BillingReportingForm } from '../../../billing/form';
import { ActivityReportingForm } from '../../../reporting/forms/activity-reporting.form';
import { CustomerReportingForm } from '../../../reporting/forms/customer-reporting.form';
import { ProjectReportingForm } from '../../../reporting/forms/project-reporting.form';
import {
	__API_REPORTING_BILLING_DELIVERIES__,
	__API_REPORTING_BILLING_INVOICES__,
	__API_REPORTING_DECLARATION_ALL__,
	__API_REPORTING_DECLARATION_SELF__,
	__API_REPORTING_LOTS_TO_INVOICE__,
	__API_REPORTING_MONTHLY_DECLARATIONS__,
	__API_REPORTING_MONTHLY_DECLARATIONS_FOR_ALL__,
	__API_REPORTING_MONTHLY_EXPENSE_FILES_DOWNLOAD__,
	__API_REPORTING_MONTHLY_EXPENSE_FILES_DOWNLOAD_ALL__,
	__API_REPORTING_SILAE_ABSENCE_FOR_ALL__,
	__API_REPORTING_STATISTICS_CUSTOMER__,
	__API_REPORTING_STATISTICS_DELIVERY__,
	__API_REPORTING_STATISTICS_PROJECTS__,
	__API_REPORTING_STATISTICS_USER__
} from '../../routes/api.route';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class ReportingService {

	constructor(private httpRequestService: HttpRequestService) {
	}

	/**
	 * Statistics reporting
	 */

	public generateDeliveryReporting(form: ProjectReportingForm): Observable<any | null> {
		return this.httpRequestService
			.postDownloadFile(__API_REPORTING_STATISTICS_DELIVERY__, form.toRequestForm(), 'xlsx')
			.pipe(map((result) => {
				return result;
			}));
	}

	public generateCustomersReporting(form: CustomerReportingForm): Observable<any | null> {
		return this.httpRequestService
			.postDownloadFile(__API_REPORTING_STATISTICS_CUSTOMER__, form.toRequestForm(), 'xlsx')
			.pipe(map((result) => {
				return result;
			}));
	}

	public generateProjectsReporting(form: ProjectReportingForm): Observable<any | null> {
		return this.httpRequestService
			.postDownloadFile(__API_REPORTING_STATISTICS_PROJECTS__, form.toRequestForm(), 'xlsx')
			.pipe(map((result) => {
				return result;
			}));
	}

	public generateUsersReporting(): Observable<any | null> {
		return this.httpRequestService
			.postDownloadFile(__API_REPORTING_STATISTICS_USER__, { }, 'xlsx')
			.pipe(map((result) => {
				return result;
			}));
	}

	/**
	 * ------------------- END STATISTICS -------------------
	 */

	public generateLotsToInvoiceReporting(): Observable<any | null> {
		return this.httpRequestService
			.getDownloadFile(__API_REPORTING_LOTS_TO_INVOICE__)
			.pipe(map((result) => {
				return result;
			}));
	}

	public generateActivitiesReporting(form: ActivityReportingForm): Observable<any | null> {
		return this.httpRequestService
			.postDownloadFile(__API_REPORTING_DECLARATION_ALL__, form.toRequestForm(), 'xlsx')
			.pipe(map((result) => {
				return result;
			}));
	}

	public generateMonthlyDeclarationsReporting(userId, date): Observable<any | null> {
		return this.httpRequestService
			.postDownloadFile(__API_REPORTING_MONTHLY_DECLARATIONS__, { user_id: userId, date: date }, 'xlsx')
			.pipe(map((result) => {
				return result;
			}));
	}

	public generateMonthlyDeclarationsReportingForALl(date): Observable<any | null> {
		return this.httpRequestService
			.postDownloadFile(__API_REPORTING_MONTHLY_DECLARATIONS_FOR_ALL__, { date: date }, 'xlsx')
			.pipe(map((result) => {
				return result;
			}));
	}

	public generateSelfActivitiesReporting(date): Observable<any | null> {
		return this.httpRequestService
			.postDownloadFile(__API_REPORTING_DECLARATION_SELF__, { date: date }, 'xlsx')
			.pipe(map((result) => {
				return result;
			}));
	}

	public downloadMonthlyDeclarationsReportingFilesByUser(userId, date): Observable<any | null> {
		return this.httpRequestService
			.postDownloadFile(__API_REPORTING_MONTHLY_EXPENSE_FILES_DOWNLOAD__, { user_id: userId, date: date }, 'zip')
			.pipe(map((result) => {
				return result;
			}));
	}

	public downloadAllMonthlyDeclarationsReportingFiles(date): Observable<any | null> {
		return this.httpRequestService
			.postDownloadFile(__API_REPORTING_MONTHLY_EXPENSE_FILES_DOWNLOAD_ALL__, { date: date }, 'zip')
			.pipe(map((result) => {
				return result;
			}));
	}

	/**
	 * Billing reporting
	 */
	public generateDeliveriesReporting(form: BillingReportingForm): Observable<any | null> {
		return this.httpRequestService
			.postDownloadFile(__API_REPORTING_BILLING_DELIVERIES__, form.toRequestForm(), 'xlsx')
			.pipe(map((result) => {
				return result;
			}));
	}

	public generateInvoicesReporting(form: BillingReportingForm): Observable<any | null> {
		return this.httpRequestService
			.postDownloadFile(__API_REPORTING_BILLING_INVOICES__, form.toRequestForm(), 'xlsx')
			.pipe(map((result) => {
				return result;
			}));
	}

	/**
	 * Accounting reporting
	 */
	public downloadSilaeAbsenceReportingForAll(date): Observable<any | null> {
		return this.httpRequestService
			.postDownloadFile(__API_REPORTING_SILAE_ABSENCE_FOR_ALL__, { date: date }, 'csv')
			.pipe(map((result) => {
				return result;
			}));
	}
}
