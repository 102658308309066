import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpRequestService } from '../http/http-request.service';
import { HelpService } from '../helper/help.service';
import { __API_PROJECT_AMENDMENT_DELETE__ } from '../../routes/api.route';

@Injectable()
export class AmendmentService {

	constructor(private httpRequestService: HttpRequestService) {
	}

	public deleteProjectAmendment(projectId: number, amendmentId: number): Observable<any | null> {
		const url = HelpService.format(__API_PROJECT_AMENDMENT_DELETE__, projectId, amendmentId);
		return this.httpRequestService
			.delete(url)
			.pipe(map((result) => {
				return result;
			}));
	}
}
