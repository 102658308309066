import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbsenceDeclarationForm, AbsenceDeclarationMultiForm, AbsenceListGetRequestForm } from '../../../declaration/forms';
import { AbsenceType, Activity } from '../../models';
import { Absence } from '../../models/absence/absence.model';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class AbsenceService {

    constructor(private httpRequestService: HttpRequestService) {
    }

    public getAbsenceTypeList(): Observable<any> {
        return this.httpRequestService.get('absence/type/list').pipe(map((result) => {
            return this.httpRequestService.mapToModel(AbsenceType, result.absent_type_list) as AbsenceType[];
        }));
    }

    public createAbsence(form: AbsenceDeclarationForm): Observable<Absence> {
        return this.httpRequestService.post('absence', form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Absence, result.absence) as Absence;
        }));
    }

    public createMultiAbsence(form: AbsenceDeclarationMultiForm): Observable<any> {
        return this.httpRequestService.post('absence/create/multi', form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Activity, result.absences) as Absence[];
        }));
    }

    public getAbsence(id: number): Observable<any> {
        return this.httpRequestService.get(`absence/${ id }`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Absence, result.absence) as Absence;
        }));
    }

    public getAbsenceList(form: AbsenceListGetRequestForm): Observable<any> {
        return this.httpRequestService.post('absence/list', form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Absence, result.absences) as Absence[];
        }));
    }

    public getAbsenceByUser(userId: number, form: AbsenceListGetRequestForm): Observable<any> {
        return this.httpRequestService.post(`admin/absence/user/${ userId }/list`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Absence, result.absences) as Absence[];
        }));
    }

    public updateAbsence(id: number, form: AbsenceDeclarationForm): Observable<any> {
        return this.httpRequestService.put(`absence/${ id }/update`, form.toRequestForm()).pipe(map(result => {
            return this.httpRequestService.mapToModel(Absence, result.absence);
        }));
    }

    public deleteAbsence(id: number): Observable<any> {
        return this.httpRequestService.delete(`absence/${ id }/delete`);
    }
}
