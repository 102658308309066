import { PropertyMapper } from '../../decorators';
import { Profile } from '../profile/profile.model';
import { Role } from '../role/role.model';

export class UserProfileHistory {

    @PropertyMapper()
    public id: number;

    @PropertyMapper({ jsonProperty: 'start', typeOfObject: Date })
    public start: Date;

    @PropertyMapper({ jsonProperty: 'end', typeOfObject: Date })
    public end: Date;

    @PropertyMapper()
    public name: string;

    @PropertyMapper({ jsonProperty: 'default_auth' })
    public defaultAuth: number;

    @PropertyMapper({ jsonProperty: 'default_declaration' })
    public defaultDeclaration: number;

    @PropertyMapper({ jsonProperty: 'status' })
    public status: number;

    @PropertyMapper({ jsonProperty: 'profile', typeOfObject: Profile })
    public profile: Profile;

    @PropertyMapper({ jsonProperty: 'editable' })
    public editable: boolean;

    @PropertyMapper({ jsonProperty: 'endable' })
    public endable: boolean;

    @PropertyMapper({ jsonProperty: 'role', typeOfObject: Role })
    public role: Role;
}
