import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { interval } from 'rxjs';
import { DeclarationTypeEnum } from '../../../core/enums';

@UntilDestroy()
@Component({
    selector: 'declaration-create-multi-modal',
    templateUrl: './declaration-create-multi-modal.component.html',
    styleUrls: ['./declaration-create-multi-modal.component.scss']
})
export class DeclarationCreateMultiModalComponent implements OnChanges, OnInit {

    @Input() public display = false;
    @Output() public closeEvent: EventEmitter<any> = new EventEmitter();

    public visible: boolean = null;
    public declarationTypeEnum = DeclarationTypeEnum;

    public currentDeclarationType = null;

    constructor() {
    }

    ngOnInit() {
        interval(1000).pipe(untilDestroyed(this)).subscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.display && !changes.display.isFirstChange()) {
            this.visible = this.display;
        }
    }

    public onCancelHandler() {
        this.visible = false;
        this.closeEvent.emit();
    }
}
