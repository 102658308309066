import { PropertyMapper } from '../../decorators';

export class SupplierInvoiceLot {
    @PropertyMapper()
    public id: number;

    @PropertyMapper({ jsonProperty: 'supplier_order_id' })
    public supplierOrderId: number;

    @PropertyMapper()
    public name: string;

    @PropertyMapper({ jsonProperty: 'ht_amount' })
    public htAmount: number;

    @PropertyMapper({ jsonProperty: 'vat_amount' })
    public vatAmount: number;

    @PropertyMapper({ jsonProperty: 'ttc_amount' })
    public ttcAmount: number;

    @PropertyMapper({ jsonProperty: 'delivery_date' })
    public deliveryDate: Date;
}
