<nz-layout id="base-layout">
	<nz-header class="main-header">
		<div (click)="routerService.gotoDashboardHome();" class="logo">
			A2L GAIA
			<span class="version">V2.9.10</span>
			<span class="refresh-warning">En cas de disfonctionnement, merci de rafraîchir avec CTRL + R </span>
		</div>
		<div class="right-sub-content">
			<div (click)="routerService.gotoAccountDetail(authModel.username)" *ngIf="authModel"
			     class="current-user">
				<fa-icon [icon]="['far', 'user-tie']" style="padding-right: 10px"></fa-icon>
				{{ authModel.username }}
			</div>
			<div class="separator">|</div>
			<div *ngIf="authModel && authModel.currentProfiles.length > 1">
				<button nz-button nz-dropdown [nzDropdownMenu]="profileList">
					{{ authModel.profile.profile.name }}
					<i nz-icon nzType="down"></i>
				</button>
			</div>
			<div *ngIf="authModel && authModel.currentProfiles.length === 1">
				<button nz-button>
					{{ authModel.profile.name }}
				</button>
			</div>
			<div class="separator">|</div>
			<div (click)="showNotificationModal()">
				<nz-badge [nzCount]="notificationsUnreadCount" [nzShowZero]="false">
				<span class="notification-icon">
					<fa-icon [icon]="['far', 'bell']"></fa-icon>
				</span>
				</nz-badge>
			</div>
			<div class="separator">|</div>
			<span class="help-button" (click)="showModal()">
				<fa-icon [icon]="['far', 'question-circle']"></fa-icon>
			</span>
			<div class="separator">|</div>
			<div class="log-out">
                <span (click)="logout();" nz-tooltip nzTooltipTitle="se déconnecter">
                    <fa-icon [icon]="['far', 'sign-out-alt']" style="padding-right: 10px"></fa-icon>
                </span>
			</div>
		</div>
	</nz-header>
	<nz-layout>
		<nz-sider [nzWidth]="200" nzTheme="light" style="padding-top: 5px;">
			<ul nz-menu nzMode="inline" style="height: 100%;">
				<li (click)="routerService.gotoHome();" nz-menu-item>
                    <span title>
						<fa-icon [icon]="['far', 'home']" style="padding-right: 10px"></fa-icon>
						Tableau de bord
                    </span>
				</li>
				<li (click)="routerService.gotoDeclaration()"
				    *ngIf="hasAccessToDeclaration"
				    nz-menu-item>
                    <span title>
						<fa-icon [icon]="['far', 'calendar-alt']" style="padding-right: 10px"></fa-icon>
						Mes déclarations
                    </span>
				</li>
				<li *ngIf="hasAccessToManagement"
				    nz-submenu>
                    <span title>
                        <fa-icon [icon]="['far', 'folder-tree']" style="padding-right: 10px"></fa-icon>
						Gestion
                    </span>
					<ul>
						<li (click)="routerService.gotoDeclarationManagement()"
						    *ngIf="hasAccessToDeclarationManage"
						    nz-menu-item>
							Déclarations
						</li>
						<li (click)="routerService.gotoUserManagement()"
						    nz-menu-item>
							Utilisateurs
						</li>
					</ul>
				</li>
				<li *ngIf="hasAccessToManagement"
				    nz-submenu>
                    <span title>
                        <fa-icon [icon]="['far', 'file-invoice-dollar']" style="padding-right: 10px"></fa-icon>
						Facturation
                    </span>
					<ul>
						<li (click)="routerService.gotoBillingDeliveries()"
						    *ngIf="hasAccessToDeclarationManage"
						    nz-menu-item>
							Bons de livraisons
						</li>
						<li (click)="routerService.gotoBillingInvoices()"
						    *ngIf="hasAccessToRequestManage"
						    nz-menu-item>
							Factures
						</li>
						<li (click)="routerService.gotoBillingInvoicesSchedule()"
						    *ngIf="hasAccessToRequestManage"
						    nz-menu-item>
							Echéancier
						</li>
					</ul>
				</li>
				<li *ngIf="authModel && authModel.profile.role.name === 'Chef de projet'"
				    nz-submenu>
                    <span title>
                        <fa-icon [icon]="['far', 'folder-tree']" style="padding-right: 10px"></fa-icon>
						Gestion
                    </span>
					<ul>
						<li (click)="routerService.gotoPManagerUserManagement()"
						    nz-menu-item>
							Utilisateurs
						</li>
					</ul>
				</li>
				<li (click)="routerService.gotoProjectList();"
				    *ngIf="hasAccessToProject"
				    nz-menu-item>
                    <span title>
						<fa-icon [icon]="['far', 'folders']" style="padding-right: 10px"></fa-icon>
						Projet
					</span>
				</li>
				<li (click)="routerService.gotoCustomerList();"
				    *ngIf="hasAccessToCustomer"
				    nz-menu-item>
                    <span title>
						<fa-icon [icon]="['far', 'users']" style="padding-right: 10px"></fa-icon>
                        Clients
                    </span>
				</li>
				<li *ngIf="hasAccessToManagement"
				    nz-submenu>
                    <span title>
                        <fa-icon [icon]="['far', 'user-hard-hat']" style="padding-right: 10px"></fa-icon>
						Fournisseurs
                    </span>
					<ul>
						<li (click)="routerService.gotoSupplierList()"
						    nz-menu-item>
							Liste
						</li>
						<li (click)="routerService.gotoSupplierOrderList()"
						    nz-menu-item>
							Commandes
						</li>
						<li (click)="routerService.gotoSupplierInvoiceList()"
						    nz-menu-item>
							Factures
						</li>
					</ul>
				</li>
				<li (click)="routerService.gotoStatistics();"
				    *ngIf="hasAccessToStatistics" nz-menu-item>
                    <span title>
						<fa-icon [icon]="['far', 'chart-bar']" style="padding-right: 10px"></fa-icon>
                        Statistiques
                    </span>
				</li>
				<!--				<li (click)="routerService.gotoReporting();"-->
				<!--				    *ngIf="hasAccessToReporting" nz-menu-item>-->
				<!--                    <span title>-->
				<!--						<fa-icon [icon]="['far', 'chart-bar']" style="padding-right: 10px"></fa-icon>-->
				<!--                        Reporting-->
				<!--                    </span>-->
				<!--				</li>-->
				<li *ngIf="hasAccessToParameter" nz-submenu>
                    <span title>
						<fa-icon [icon]="['far', 'cog']" style="padding-right: 10px"></fa-icon>
                        Paramètres
                    </span>
					<ul>
						<li (click)="routerService.gotoParameterProfileList();"
						    *ngIf="hasAccessToProfileManage"
						    nz-menu-item>
							Profils
						</li>
						<li (click)="routerService.gotoParameterBilling();"
						    *ngIf="hasAccessToProfileManage"
						    nz-menu-item>
							Facturation
						</li>
					</ul>
				</li>
			</ul>
		</nz-sider>
		<nz-layout class="content-layout">
			<nz-content class="content-container">
				<router-outlet></router-outlet>
			</nz-content>
		</nz-layout>
	</nz-layout>
<!--	<nz-footer></nz-footer>-->
</nz-layout>

<nz-modal [(nzVisible)]="isNotificationsVisible" nzTitle="Notifications"
          (nzOnCancel)="isNotificationsVisible=false;"
          [nzFooter]="modalFooters"
          [nzWidth]="800">
	<ng-container *nzModalContent>
		<nz-list nzItemLayout="vertical">
			<nz-list-item *ngFor="let notification of notifications">
				<nz-list-item-meta>
					<!--					<nz-list-item-meta-avatar>-->
					<!--						<fa-icon [icon]="['far', 'paper-plane']"></fa-icon>-->
					<!--					</nz-list-item-meta-avatar>-->
					<nz-list-item-meta-title>
						<a (click)="redirectToNotificationHref(notification)"
						   nz-tooltip nzTooltipTitle="Voir"
						   [ngClass]="{'bold' : notification.read === 0}">
							{{ notification.title }}
						</a>
						-
						<span [ngClass]="{'bold' : notification.read === 0}">
							{{ notification.createdAt | date: 'dd/MM/yyyy' }}
						</span>
					</nz-list-item-meta-title>
					<nz-list-item-meta-description>
						<span [innerText]="notification.description"></span>
					</nz-list-item-meta-description>
				</nz-list-item-meta>
				{{ notification.content }}
				<nz-list-item-extra>
					<ul nz-list-item-actions>
						<nz-list-item-action>
							<fa-icon (click)="redirectToNotificationHref(notification)" [icon]="['far', 'eye']"
							         class="gaia-highlight-primary hover-pointer" nz-tooltip nzTooltipTitle="Voir">
							</fa-icon>
						</nz-list-item-action>
						<nz-list-item-action *ngIf="notification.read === 0">
							<fa-icon (click)="markNotificationAsRead(notification)" [icon]="['far', 'envelope-open']"
							         class="gaia-highlight-primary hover-pointer" nz-tooltip
							         nzTooltipTitle="Marquer comme lu">
							</fa-icon>
						</nz-list-item-action>
						<nz-list-item-action *ngIf="notification.read === 1">
							<fa-icon (click)="markNotificationAsUnread(notification)" [icon]="['far', 'envelope']"
							         class="gaia-highlight-primary hover-pointer" nz-tooltip
							         nzTooltipTitle="Marquer comme non lu">
							</fa-icon>
						</nz-list-item-action>
						<nz-list-item-action>
							<fa-icon (click)="deleteNotification(notification)" [icon]="['far', 'trash']"
							         class="gaia-highlight-danger hover-pointer" nz-tooltip nzTooltipTitle="Supprimer">
							</fa-icon>
						</nz-list-item-action>
					</ul>
				</nz-list-item-extra>
			</nz-list-item>
		</nz-list>
		<ng-container *ngIf="notifications && notifications.length > 0; else elseBlock">

		</ng-container>
		<ng-template #elseBlock>
			<nz-empty nzNotFoundContent="Aucune notification"></nz-empty>
		</ng-template>
	</ng-container>
	<ng-template #modalFooters>
		<button (click)="isNotificationsVisible = false;" nz-button nzType="default">Fermer</button>
	</ng-template>
</nz-modal>


<nz-modal [(nzVisible)]="isHelpVisible" nzTitle="Aide en ligne"
          nzClassName="user-guide"
          (nzOnCancel)="handleCancel()"
          (nzOnOk)="handleOk()"
          [nzCancelText]="null"
          [nzWidth]="800">
	<ng-container *nzModalContent>
		<nz-collapse nzGhost *ngIf="userGuidePages && userGuidePages.length > 0; else elseBlock">
			<nz-collapse-panel
					*ngFor="let page of userGuidePages"
					[nzHeader]="headerTpl"
					[nzActive]="page.active"
			>
				<div [innerHTML]="page.html"></div>
				<ng-template #headerTpl>
					<span style="font-size: 15px;">{{ page.name }}</span>
				</ng-template>
			</nz-collapse-panel>
		</nz-collapse>
		<ng-template #elseBlock>
			<nz-empty nzNotFoundContent="Aucune aide disponible."></nz-empty>
		</ng-template>
	</ng-container>
</nz-modal>

<nz-dropdown-menu #profileList="nzDropdownMenu">
	<ul nz-menu *ngIf="authModel && authModel.currentProfiles">
		<ng-container *ngFor="let profile of authModel.currentProfiles">
			<li *ngIf="profile.defaultAuth === 0" nz-menu-item
			    (click)="switchProfile(profile)">
				{{ profile.profile.name }}
			</li>
		</ng-container>
	</ul>
</nz-dropdown-menu>
