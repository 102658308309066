<div nz-row nzGutter="16" nzJustify="space-between" class="table-container">
    <nz-table #table [nzData]="statistics"
              nzSize="small"
              nzPageSize="50"
              nzPaginationPosition="both"
              class="project-list-table">
        <thead>
        <tr>
            <th>Client</th>
            <th>Projet</th>
            <th>Chef de projet</th>
            <th>Date de clôture</th>
            <th>{{ q1Question }}</th>
            <th>{{ q2Question }}</th>
            <th>{{ q3Question }}</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let data of table.data">
            <tr *ngIf="data.feedback.version === 1">
                <td>{{ data.customerName }}</td>
                <td>{{ data.projectName }}</td>
                <td>{{ data.projectManagerName }}</td>
                <td>{{ data.closingDate | date: 'dd/MM/yyyy'}}</td>
                <ng-container *ngIf="data.feedback.formattedAnswers">
                    <td>{{ data.feedback.answers.feedback }}</td>
                    <td>{{ data.feedback.answers.customer_satisfaction }}</td>
                    <td>{{ data.feedback.answers.customer_satisfaction_note }}</td>
                </ng-container>
                <ng-container *ngIf="!data.feedback.formattedAnswers">
                    <td></td>
                    <td></td>
                    <td></td>
                </ng-container>
            </tr>
        </ng-container>
        </tbody>
    </nz-table>
</div>

