<div class="app">
	<div *ngIf="isAuthenticated === null" class="a2l-loader">
		<ng-template #indicatorTemplate>
			<fa-icon [icon]="['far', 'spinner']" [size]="'lg'"></fa-icon>
		</ng-template>
		<nz-spin [nzIndicator]="indicatorTemplate">
		</nz-spin>
	</div>
	<router-outlet></router-outlet>
	<app-notification></app-notification>
</div>

