import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {interval, Subscription} from 'rxjs';
import {NotificationTypeEnum} from '../../../../core/enums/notification-type.enum';
import {RequestError, Survey} from '../../../../core/models';
import {EventNotification} from '../../../../core/models/notification/event-notification.model';
import {NotificationInteractionService} from '../../../../core/services/interaction-service/notification-interaction.service';
import {SurveyService} from '../../../../core/services/survey/survey.service';
import {UserProjectSatisfactionV1Form} from '../../../forms/user-project-satisfaction-v1.form';

@UntilDestroy()
@Component({
    selector: 'survey-customer-project-satisfaction-v2',
    templateUrl: './survey-user-project-satisfaction-v1.component.html',
    styleUrls: ['./survey-user-project-satisfaction-v1.component.scss'],
})
export class SurveyUserProjectSatisfactionV1Component implements OnInit, OnChanges {

    @Input() survey: Survey;

    private submitSubscription: Subscription = null;
    public isConfirmLoading = false;
    public displayThanks = false;

    public form = new UserProjectSatisfactionV1Form();

    public q3Question = 'Vous êtes-vous senti techniquement compétent sur cette mission ?';
    public q3CheckOptions = [
        {label: 'Pas assez (j\'ai dû me former)', value: 'a1'},
        {label: 'Suffisamment', value: 'a2'},
        {label: 'Largement (mes compétences n\'ont pas été exploitées suffisamment)', value: 'a3'},
    ];

    public q4Question = 'Avez-vous disposé de l\'encadrement et des ressources suffisantes au cours de cette mission ?';
    public q4CheckOptions = [
        {label: 'Non, manque d\'encadrement et de ressources', value: 'a1'},
        {label: 'Non, manque d\'encadrement ou de ressources', value: 'a2'},
        {label: 'Oui, encadrement et ressources suffisantes', value: 'a3'},
    ];

    public q5Question = 'Les besoins du client ont-ils été clairement définis par le Client ? Ont-ils été suffisamment cadrés Chef de Projet ?';
    public q5CheckOptions = [
        {label: 'Besoins pas du tout clairement définis et cadrés', value: 'a1'},
        {label: 'Besoins pas assez définis et cadrés', value: 'a2'},
        {label: 'Besoins suffisamment cadrés et définis', value: 'a3'},
        {label: 'Besoins très bien cadrés et définis', value: 'a4'},
    ];

    public q6Question = 'Vous êtes-vous senti progresser sur un plan technique au cours de la mission ?';
    public q6CheckOptions = [
        {label: 'Oui, beaucoup', value: 'a1'},
        {label: 'Oui, un peu', value: 'a2'},
        {label: 'Pas du tout', value: 'a3'},
    ];

    public q7Question = 'Quelles ont été les difficultés principales rencontrées ?';
    public q7CheckOptions = [
        {label: 'Clients pas réactif', value: 'a1'},
        {label: 'Informations manquantes de la part du client', value: 'a2'},
        {label: 'Délais insuffisants', value: 'a3'},
        {label: 'Problèmes techniques', value: 'a4'},
        {label: 'Problèmes humains (non-coopération, récalcitrance, ...)', value: 'a5'},
        {label: 'Problèmes administratifs', value: 'a6'},
        {label: 'Autre', value: 'a7'},
    ];

    public q8Question = 'Si autre, veuillez précisez ?';

    public q9Question = 'Êtes-vous d\'accord avec la phrase suivante : "Mon équipe était adaptée et cohérente quant à la mission" ?';
    public q9CheckOptions = [
        {label: 'Non, je ne suis pas du tout d\'accord', value: 'a1'},
        {label: 'Pas vraiment, certains points n\'étaient pas adaptés', value: 'a2'},
        {label: 'Oui, globalement le dispositif était suffisant', value: 'a3'},
        {label: 'Complètement, l\'équipe était parfaitement adaptée à la mission', value: 'a4'},
    ];

    public q10Question = 'Avez-vous pu accorder le temps nécessaire à votre mission ? ';
    public q10CheckOptions = [
        {label: 'Je n\'ai pas pu accorder le temps que je voulais à cette mission', value: 'a1'},
        {label: 'J\'ai tout juste eu le temps nécessaire à consacrer à cette mission', value: 'a2'},
        {label: 'J\'ai pu me pencher sur cette mission et y investir suffisamment de temps', value: 'a3'},
    ];

    public q11Question = 'Quelle a été votre principale source de formation au cours de cette mission ? Celle-ci était-elle interne ou externe à A2L ?';
    public q12Question = 'Existe-t-il une formation en particulier que vous auriez aimé trouver en interne dans les ressources d\'A2L ?';
    public q13Question = 'Avez-vous des remarques supplémentaires ?';

    constructor(private surveyService: SurveyService,
                private notificationInteractionService: NotificationInteractionService) {
    }

    ngOnInit() {
        interval(1000).pipe(untilDestroyed(this)).subscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.survey.currentValue && changes.survey.currentValue.answers) {
            this.form.recoverFormObject(changes.survey.currentValue.answers, changes.survey.currentValue.status);
        }
    }

    public submit() {
        this.isConfirmLoading = true;
        this.form.validate();
        if (this.form.validationForm.valid) {
            this.submitSubscription = this.surveyService
                .fillSurvey(this.survey.uuid, this.form.toRequestForm()).subscribe(() => {
                }, (error: RequestError) => {
                    this.notificationInteractionService.displayErrorNotification(
                        new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', error.messages));
                }, () => {
                    this.isConfirmLoading = false;
                    this.displayThanks = true;
                });
        } else {
            this.isConfirmLoading = false;
        }
    }
}
