import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

    private readonly __TOKEN__ = '__token__';
    private localStorage = window.localStorage;

    public set(key: string, value: string): void {

        this.localStorage.setItem(key, value);
    }

    public get(key: string): string {

        return this.localStorage.getItem(key);
    }

    public remove(key: string): void {

        this.localStorage.removeItem(key);
    }

    public getToken() {

        return this.localStorage.getItem(this.__TOKEN__);
    }

    public setToken(token: string) {

        this.localStorage.setItem(this.__TOKEN__, token);
    }

    public removeToken() {
        this.localStorage.removeItem(this.__TOKEN__);
    }

    public clear() {
        this.localStorage.clear();
    }
}
