import { PropertyMapper } from '../../decorators';

export class ProjectAssociatedSupplier {
	@PropertyMapper()
	public id: number;

	@PropertyMapper({ jsonProperty: 'name' })
	public name: string;

	@PropertyMapper({ jsonProperty: 'assigned_fee' })
	public assignedFee: number;

	@PropertyMapper({ jsonProperty: 'reel_fee' })
	public reelFee: number;

	public assigned: boolean;
}
