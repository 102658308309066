import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { NzMessageService } from 'ng-zorro-antd/message';
import { interval, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { REGEX_POSITIVE_DECIMAL_WITH_TWO_DIGITS } from '../../../core/const';
import { DeclarationModeEnum, DeclarationStatusEnum } from '../../../core/enums';
import { FileTypeEnum } from '../../../core/enums/file-type.enum';
import { NotificationTypeEnum } from '../../../core/enums/notification-type.enum';
import { FileUploadForm } from '../../../core/forms/file-upload.form';
import { AuthModel, CustomerSite, Lot, MediaFile, Project, UploadFile, User, UserCar } from '../../../core/models';
import { ExpenseReportType } from '../../../core/models/expense-report/expense-report-type.model';
import { ExpenseReport } from '../../../core/models/expense-report/expense-report.model';
import { EventNotification } from '../../../core/models/notification/event-notification.model';
import { StatusBase } from '../../../core/models/status-base/status-base.model';
import {
    ActivityService,
    AdminDeclarationService,
    DeclarationInteractionService,
    ExpenseReportService,
    RouterService,
    UserService
} from '../../../core/services';
import { FileService } from '../../../core/services/file/file.service';
import { HttpRequestService } from '../../../core/services/http/http-request.service';
import { NotificationInteractionService } from '../../../core/services/interaction-service/notification-interaction.service';
import { AuthStoreSelectors, AuthStoreState } from '../../../root-store/auth-store';
import { ExpenseReportDeclarationForm } from '../../forms';

@UntilDestroy()
@Component({
    selector: 'expense-report-declaration',
    templateUrl: './expense-report-declaration.component.html',
    styleUrls: ['./expense-report-declaration.component.scss']
})
export class ExpenseReportDeclarationComponent implements OnInit, OnChanges {

    @Input() expenseReport: ExpenseReport = null;
    @Output() public closeEvent: EventEmitter<any> = new EventEmitter();
    @Output() public createEvent: EventEmitter<any> = new EventEmitter();
    @Input() private visible: boolean;
    @Input() public userId;
    @Input() public declarationMode = DeclarationModeEnum.SELF;

    public form: ExpenseReportDeclarationForm = new ExpenseReportDeclarationForm();
    public expenseReportTypes: ExpenseReportType[] = [];
    public projects: Project[] = [];
    public lots: Lot[] = [];
    public projectCustomerSites: CustomerSite[] = [];
    public selectedDate: Date = null;
    public locked = false;
    public status: StatusBase;
    public isCreateMode = true;
    public declarationStatusEnum = DeclarationStatusEnum;
    public declarationModeEnum = DeclarationModeEnum;
    public authModel: AuthModel = null;
    public user: User = null;
    public activeCars: UserCar[] = null;
    public carTipsModalVisible = false;
    public fileUploadForm = new FileUploadForm();
    public filesToUpload: UploadFile[] = [];
    public displayForm = true;
    public displayLoader = false;

    private initSubscription: Subscription = null;
    private projectSubscription: Subscription = null;
    private expenseReportTypeSubscription: Subscription = null;
    private expenseReportGetSubscription: Subscription = null;
    private createSubscription: Subscription = null;
    private updateSubscription: Subscription = null;
    private selectedDateSubscription: Subscription = null;
    private userCarListSubscription: Subscription = null;

    constructor(private expenseReportService: ExpenseReportService,
                private nzMessageService: NzMessageService,
                private adminDeclarationService: AdminDeclarationService,
                private notificationInteractionService: NotificationInteractionService,
                private declarationInteractionService: DeclarationInteractionService,
                private activityService: ActivityService,
                private userService: UserService,
                private store: Store<AuthStoreState.State>,
                private routerService: RouterService,
                private fileService: FileService,
                private httpRequestService: HttpRequestService) {
    }

    ngOnInit() {
        interval(1000).pipe(untilDestroyed(this)).subscribe();
        if (this.declarationMode === DeclarationModeEnum.MANAGER && this.userId) {
            this.userCarListSubscription = this.userService.getManagementUserCarsList(this.userId).subscribe(cars => {
                this.activeCars = cars;
            });
            this.projectSubscription = this.adminDeclarationService.getProjectsForDeclarationByUserId(this.userId).subscribe(projects => {
                this.projects = projects;
                if (this.expenseReport.project) {
                    this.projectChangedHandler(this.expenseReport.project.id);
                    this.form.validationForm.get('lotId').setValue(this.expenseReport.lot ? this.expenseReport.lot.id : 0);
                    this.form.validationForm.get('siteId').setValue(this.expenseReport.site ? this.expenseReport.site.id : null);
                }
            });
        } else {
            this.initSubscription = this.store.pipe(select(AuthStoreSelectors.selectAuthModel))
                .pipe(mergeMap((model) => {
                    this.authModel = model;
                    return this.userService.getUserInformationByUsername(model.username);
                })).subscribe((user: User) => {
                    if (!user) {
                        this.notificationInteractionService.displayErrorNotification(
                            new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', ['L\'utilisateur est introuvable']));
                    }
                    this.user = user;
                    this.activeCars = this.user.cars.filter((car: UserCar) => {
                        return car.active;
                    });
                }, err => {
                    this.notificationInteractionService.displayErrorNotification(
                        new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', ['L\'utilisateur est introuvable']));
                });

            this.projectSubscription = this.activityService.getProjectsForDeclarations().subscribe(projects => {
                this.projects = projects;
                if (this.expenseReport.project) {
                    this.projectChangedHandler(this.expenseReport.project.id);
                    this.form.validationForm.get('lotId').setValue(this.expenseReport.lot ? this.expenseReport.lot.id : 0);
                    this.form.validationForm.get('siteId').setValue(this.expenseReport.site ? this.expenseReport.site.id : null);
                }
            });
        }

        this.expenseReportTypeSubscription = this.expenseReportService.getExpenseReportTypeList().subscribe(types => {
            this.expenseReportTypes = types;
        });

        this.selectedDateSubscription = this.declarationInteractionService.selectedDate$.subscribe((selectedDate) => {
            this.selectedDate = selectedDate;
        });

        this.updateFormValidators();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.visible) {
            if (this.expenseReport && this.visible) {
                this.isCreateMode = !( this.expenseReport && this.expenseReport.id );
                this.status = this.expenseReport.status;
                if (this.expenseReport.project) {
                    this.projectChangedHandler(this.expenseReport.project.id);
                }

                this.resetAndFillForm();
                // if expense report type is travel by car, we disable amount field
                if (this.expenseReport.type && this.expenseReport.type.id === 1) {
                    this.form.validationForm.get('amount').disable();
                }

                if (this.expenseReport.status && this.expenseReport.status.id !== DeclarationStatusEnum.WAITING_VALIDATION) {
                    this.form.validationForm.get('category').disable();
                    this.form.validationForm.get('type').disable();
                    this.form.validationForm.get('amount').disable();
                    this.form.validationForm.get('projectId').disable();
                    this.form.validationForm.get('lotId').disable();
                    this.form.validationForm.get('siteId').disable();
                    this.form.validationForm.get('description').disable();
                    this.form.validationForm.get('carId').disable();
                    this.form.validationForm.get('mileage').disable();
                    this.form.validationForm.get('recoverableVat').disable();
                    this.locked = true;
                } else {
                    this.locked = false;
                }
                if (this.form.validationForm.get('category').value === 1) {
                    this.form.validationForm.get('mileage').enable();
                } else {
                    this.form.validationForm.get('mileage').disable();
                }
            }
        }
    }

    private resetAndFillForm() {
        this.form = new ExpenseReportDeclarationForm();
        this.form.validationForm.get('category').setValue(this.expenseReport.category ? this.expenseReport.category.id : null);
        this.form.validationForm.get('type').setValue(this.expenseReport.type ? this.expenseReport.type.id : null);
        this.form.validationForm.get('date').setValue(this.expenseReport.date ? this.expenseReport.date : null);
        this.form.validationForm.get('amount').setValue(this.expenseReport.amount);
        this.form.validationForm.get('projectId').setValue(this.expenseReport.project ? this.expenseReport.project.id : null);
        this.form.validationForm.get('lotId').setValue(this.expenseReport.lot ? this.expenseReport.lot.id : 0);
        this.form.validationForm.get('siteId').setValue(this.expenseReport.site ? this.expenseReport.site.id : null);
        this.form.validationForm.get('description').setValue(this.expenseReport.description);
        this.form.validationForm.get('carId').setValue(this.expenseReport.car ? this.expenseReport.car.id : null);
        this.form.validationForm.get('mileage').setValue(this.expenseReport.mileage);
        this.form.validationForm.get('recoverableVat').setValue(this.expenseReport.recoverableVat);
        this.form.validationForm.get('paid').setValue(this.expenseReport.paid ? this.expenseReport.paid : false);
        this.form.validationForm.get('chargeable').setValue(this.expenseReport.chargeable ? this.expenseReport.chargeable : false);
        this.updateFormValidators();
    }

    public projectChangedHandler(projectId: number) {
        if (!projectId || !this.projects) {
            return;
        }
        this.lots = [];
        this.form.validationForm.get('lotId').setValue(null);
        this.form.validationForm.get('siteId').setValue(null);
        this.projects.forEach((project: Project) => {
            if (project.id === projectId) {
                this.lots = this.httpRequestService.mapToModel(Lot, project.lots) as Lot[];
                this.projectCustomerSites = project.customerSites;
            }
        });
    }

    public siteChangeHandler($event) {
        this.projectCustomerSites.forEach((site: CustomerSite) => {
            if (site.id === $event) {
                this.form.validationForm.get('mileage').setValue(site.mileage);
            }
        });
    }

    public typeChangeHandler() {
        this.updateFormValue();
        this.updateFormValidators();
    }

    public categoryChangedHandler() {
        this.updateFormValue();
        this.updateFormValidators();
    }

    public updateFormValue() {
        const type = this.form.validationForm.get('type').value;
        const category = this.form.validationForm.get('category').value;

        this.form.validationForm.get('projectId').setValue(null);
        this.form.validationForm.get('lotId').setValue(null);
        this.form.validationForm.get('amount').setValue(0);
        this.form.validationForm.get('mileage').setValue(null);
        this.form.validationForm.get('carId').setValue(null);
        this.form.validationForm.get('siteId').setValue(null);
        this.form.validationForm.get('recoverableVat').setValue(null);
        this.form.validationForm.get('description').setValue(null);

        if (category === 2 && type === 5) {
            this.form.validationForm.get('amount').setValue(6);
        }
    }

    public updateFormValidators() {
        const type = this.form.validationForm.get('type').value;
        const category = this.form.validationForm.get('category').value;

        if (category === 1) {
            this.form.validationForm.get('projectId').setValidators(null);
            this.form.validationForm.get('lotId').setValidators(null);
            this.form.validationForm.get('siteId').setValidators(null);
            this.form.validationForm.get('mileage').enable();

            if (type === 1) {
                this.form.validationForm.get('carId').setValidators([Validators.required]);
                this.form.validationForm.get('mileage').setValidators([Validators.required, Validators.pattern(REGEX_POSITIVE_DECIMAL_WITH_TWO_DIGITS)]);
            } else {
                this.form.validationForm.get('carId').setValidators(null);
                this.form.validationForm.get('mileage').setValidators(null);
            }
        } else if (category === 2) {
            this.form.validationForm.get('projectId').setValidators([Validators.required]);
            this.form.validationForm.get('lotId').setValidators([Validators.required]);

            if (type === 1) {
                this.form.validationForm.get('carId').setValidators([Validators.required]);
                this.form.validationForm.get('siteId').setValidators([Validators.required]);
                this.form.validationForm.get('mileage').setValidators([Validators.required, Validators.pattern(REGEX_POSITIVE_DECIMAL_WITH_TWO_DIGITS)]);
                this.form.validationForm.get('mileage').disable();
            } else {
                this.form.validationForm.get('carId').setValidators(null);
                this.form.validationForm.get('siteId').setValidators(null);
                this.form.validationForm.get('mileage').setValidators(null);
                this.form.validationForm.get('mileage').enable();
            }
        }
        this.form.validationForm.updateValueAndValidity();
    }

    public closeModalHandler() {
        this.closeEvent.emit();
    }

    public sendDeclaration() {
        this.form.validate();

        if (this.selectedDate) {
            const utcDate = Date.UTC(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate());
            this.form.validationForm.get('date').setValue(new Date(utcDate).toISOString());
        }
        if (this.form.validationForm.invalid) {
            this.nzMessageService.error('Erreur de validation');
            return;
        }

        if (this.form.validationForm.get('category').value === 2
            && this.form.validationForm.get('type').value === 1
            && this.form.validationForm.get('mileage').value === null) {
            this.notificationInteractionService.displayErrorNotification(
                new EventNotification(NotificationTypeEnum.ERROR, 'Erreur',
                    ['Vous ne pouvez pas faire une déclaration sur un site client dont la distance avec A2L ' +
                    'n\'est pas encore définis, merci de contacter votre chef de projet']));
            return;
        }

        if (this.expenseReport && this.expenseReport.id && typeof this.expenseReport.id !== 'undefined') {
            this.updateDeclaration();
        } else {
            this.displayForm = false;
            this.displayLoader = true;
            this.createDeclaration();
        }
    }

    private createDeclaration() {
        if (this.declarationMode === DeclarationModeEnum.MANAGER && this.userId) {
            this.createSubscription = this.adminDeclarationService.createExpenseReport(this.userId, this.form).subscribe((expenseReport: ExpenseReport) => {
                this.expenseReport = expenseReport;
                this.uploadAllAssociatedFiles();
            }, err => {
                this.notificationInteractionService.displayErrorNotification(
                    new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', err.messages));
                this.displayForm = true;
                this.displayLoader = false;
            });
        } else {
            this.createSubscription = this.expenseReportService.createExpenseReport(this.form).subscribe((expenseReport: ExpenseReport) => {
                this.expenseReport = expenseReport;
                this.uploadAllAssociatedFiles();
            }, err => {
                this.notificationInteractionService.displayErrorNotification(
                    new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', err.messages));
                this.displayForm = true;
                this.displayLoader = false;
            });
        }
    }

    private updateDeclaration() {
        this.updateSubscription = this.expenseReportService.updateExpenseReport(this.expenseReport.id, this.form)
            .subscribe((expenseReport: ExpenseReport) => {
                this.nzMessageService.success('Mise à jour réussie !');
                this.refreshExpenseReport();
            }, err => {
                this.notificationInteractionService.displayErrorNotification(
                    new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', err.messages));
            });
    }

    private refreshExpenseReport() {
        this.expenseReportGetSubscription = this.expenseReportService.getExpenseReport(this.expenseReport.id).subscribe((expenseReport) => {
            expenseReport.uuid = this.expenseReport.uuid;
            this.expenseReport = expenseReport;
            this.resetAndFillForm();
            this.declarationInteractionService.updateExpenseReport(expenseReport);
        });
    }

    /**
     * FILE UPLOAD FUNCTIONS FOR UPLOAD WHILE CREATING NEW EXPENSE REPORT
     */
    public openFileDiagCreate() {
        this.fileUploadForm.validationForm.get('type').setValue(FileTypeEnum.EXPENSE_REPORT_PROOF);
        this.fileUploadForm.validate();
        if (this.fileUploadForm.validationForm.valid) {
            document.getElementById('fileUploadInputExpenseReportCreate').click();
        }
    }

    public uploadFileSelectForCreate(event) {
        if (event.target) {
            const file = new UploadFile();
            file.type = FileTypeEnum.EXPENSE_REPORT_PROOF;
            file.file = event.target.files[0];
            this.filesToUpload.push(file);
        }
    }

    public uploadAllAssociatedFiles() {
        if (this.filesToUpload.length === 0) {
            this.fileUploadForm = new FileUploadForm();
            this.nzMessageService.success('Création réussie !');
            this.declarationInteractionService.createExpenseReport(this.expenseReport);
            this.closeModalHandler();
            this.createEvent.emit(this.expenseReport);
        } else {
            this.filesToUpload.forEach((item: UploadFile) => {
                this.fileUploadForm = new FileUploadForm();
                this.fileUploadForm.validationForm.get('type').setValue(item.type);
                this.fileUploadForm.validationForm.get('file').setValue(item.file);
                this.fileService.uploadExpenseReportFile(this.expenseReport.id, this.fileUploadForm).subscribe((result) => {
                    this.notificationInteractionService.displaySuccessNotification(
                        new EventNotification(NotificationTypeEnum.SUCCESS, 'Succes', ['Les fichiers ont été uploadés avec succès']));
                    this.fileUploadForm = new FileUploadForm();
                    this.nzMessageService.success('Création réussie !');
                    this.expenseReportGetSubscription = this.expenseReportService.getExpenseReport(this.expenseReport.id).subscribe((expenseReport) => {
                        this.expenseReport = expenseReport;
                        this.declarationInteractionService.createExpenseReport(this.expenseReport);
                        this.closeModalHandler();
                        this.createEvent.emit(this.expenseReport);
                    });
                }, error => {
                    this.fileUploadForm = new FileUploadForm();
                    this.notificationInteractionService.displayErrorNotification(
                        new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', error.messages));
                });
            });
            this.filesToUpload = [];
        }
    }

    public deleteFileToUpload(filename) {
        this.filesToUpload = this.filesToUpload.filter(file => file.file.name !== filename);
    }

    /**
     * FILE UPLOAD FUNCTIONS FOR UPLOAD WHILE UPDATING EXPENSE REPORT
     */
    public openFileDiagUpdate() {
        this.fileUploadForm.validationForm.get('type').setValue(FileTypeEnum.EXPENSE_REPORT_PROOF);
        this.fileUploadForm.validate();
        if (this.fileUploadForm.validationForm.valid) {
            document.getElementById('fileUploadInputExpenseReportUpdate').click();
        }
    }

    public uploadFileSelectForUpdate(event) {
        if (event.target) {
            const file = event.target.files[0];
            this.fileUploadForm.validationForm.get('file').setValue(file);
            this.uploadFile();
        }
    }

    public uploadFile() {
        this.fileService.uploadExpenseReportFile(this.expenseReport.id, this.fileUploadForm).subscribe((result) => {
            this.notificationInteractionService.displaySuccessNotification(
                new EventNotification(NotificationTypeEnum.SUCCESS, 'Succes', ['Le fichier a été uploadé avec succès']));
            this.fileUploadForm = new FileUploadForm();
            this.refreshExpenseReport();
        }, error => {
            this.fileUploadForm = new FileUploadForm();
            this.notificationInteractionService.displayErrorNotification(
                new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', error.messages));
        });
        this.fileUploadForm.validationForm.get('file').setValue(null);
    }

    public deleteFile(file: MediaFile) {
        this.fileService.deleteExpenseReportFile(this.expenseReport.id, file.uuid).subscribe((result) => {
            this.notificationInteractionService.displaySuccessNotification(
                new EventNotification(NotificationTypeEnum.SUCCESS, 'Succes', ['Le fichier a été supprimé avec succès']));
            this.refreshExpenseReport();
        }, error => {
            this.notificationInteractionService.displayErrorNotification(
                new EventNotification(NotificationTypeEnum.ERROR, 'Erreur', error.messages));
        });
    }

    public downloadPdf(file: MediaFile) {
        fetch(file.url, { mode: 'cors' })
            .then(res => res.blob())
            .then(blob => saveAs(blob, file.fileName));
    }
}
