import { PropertyMapper } from '../../decorators';
import { DeclarationTypeEnum } from '../../enums';
import { Declaration } from '../declaration/declaration.model';
import { MediaFile } from '../media/media-file.model';
import { User } from '../user/user.model';
import { AbsenceType } from './absence-type.model';

export class Absence extends Declaration {

    @PropertyMapper()
    public user: User;

    @PropertyMapper()
    public duration: string;

    @PropertyMapper({ jsonProperty: 'type', typeOfObject: AbsenceType })
    public type: AbsenceType;

    @PropertyMapper()
    public description: string;

    @PropertyMapper({ jsonProperty: 'files', typeOfObject: MediaFile })
    public files: MediaFile[];

    constructor() {
        super();
        this.declarationType = DeclarationTypeEnum.ABSENCE;
    }
}
