import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { State } from './state';
import { AuthModel, Permission, Profile, Role } from '../../core/models';

const getAuthModel = (state: State): AuthModel | null => state.authModel;
const getIsAuthenticated = (state: State): boolean => state.isAuthenticated;
const getPermissions = (state: State): Permission[] | null => state.permissions;
const getRole = (state: State): Role | null => state.role;
const getProfile = (state: State): Profile | null => state.profile;

export const selectAuthFeatureState: MemoizedSelector<object, any> = createFeatureSelector<State>('auth');
export const selectAuthModel: MemoizedSelector<object, AuthModel> = createSelector(selectAuthFeatureState, getAuthModel);
export const selectIsAuthenticated: MemoizedSelector<object, boolean> = createSelector(selectAuthFeatureState, getIsAuthenticated);
export const selectPermissions: MemoizedSelector<object, Permission[]> = createSelector(selectAuthFeatureState, getPermissions);
export const selectRole: MemoizedSelector<object, Role> = createSelector(selectAuthFeatureState, getRole);
export const selectProfile: MemoizedSelector<object, Profile> = createSelector(selectAuthFeatureState, getProfile);
