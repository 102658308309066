import { Action } from '@ngrx/store';
import { AuthModel, Permission, Profile, Role } from 'src/app/modules/core/models';

export const enum AuthActionTypes {
    USER_AUTHENTICATED = 'AUTH MANAGE: UserAuthenticated',
    USER_UNAUTHENTICATED = 'AUTH MANAGE: UserUnauthenticated',
    SET_PERMISSIONS = 'AUTH MANAGE: SET_PERMISSIONS',
    SET_ROLE = 'AUTH MANAGE: SET_ROLE',
    SET_PROFILE = 'AUTH MANAGE: SET_PROFILE',
}

export class UserAuthenticated implements Action {

    readonly type = AuthActionTypes.USER_AUTHENTICATED;

    constructor(public authModel: AuthModel | null) {
    }
}

export class SetPermissions implements Action {
    readonly type = AuthActionTypes.SET_PERMISSIONS;

    constructor(public permissions: Permission[] | null) {
    }
}

export class SetRole implements Action {
    readonly type = AuthActionTypes.SET_ROLE;

    constructor(public role: Role | null) {
    }
}

export class SetProfile implements Action {
    readonly type = AuthActionTypes.SET_PROFILE;

    constructor(public profile: Profile | null) {
    }
}

export class UserUnauthenticated implements Action {
    readonly type: string = AuthActionTypes.USER_UNAUTHENTICATED;
}

export type AuthAction = UserAuthenticated | UserUnauthenticated | SetPermissions | SetRole;
