import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    AbsenceDeclarationForm,
    AcademicTrainingDeclarationForm,
    ActivityDeclarationForm,
    ExpenseReportDeclarationForm,
    InternalActivityDeclarationForm
} from '../../../declaration/forms';
import { AcademicTraining, Activity, Project } from '../../models';
import { Absence } from '../../models/absence/absence.model';
import { ExpenseReport } from '../../models/expense-report/expense-report.model';
import { InternalActivity } from '../../models/internal-activity/internal-activity.model';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class AdminDeclarationService {

    constructor(private httpRequestService: HttpRequestService) {
    }

    public getProjectsForDeclarationByUserId(userId: number): Observable<Project[] | null> {
        return this.httpRequestService.get(`admin/declaration/user/${ userId }/project-list`).pipe(map(result => {
            return this.httpRequestService.mapToModel(Project, result.projects) as Project[];
        }));
    }

    public createActivity(userId: number, form: ActivityDeclarationForm): Observable<any> {
        return this.httpRequestService.post(`admin/activity/user/${ userId }/create`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Activity, result.activity) as Activity;
        }));
    }

    public updateActivity(activityId: number, form: ActivityDeclarationForm): Observable<any> {
        return this.httpRequestService.put(`admin/activity/${ activityId }/update`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Activity, result.activity) as Activity;
        }));
    }

    public createExpenseReport(userId: number, form: ExpenseReportDeclarationForm): Observable<any> {
        return this.httpRequestService.post(`admin/expense-report/user/${ userId }/create`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ExpenseReport, result.expense_report) as ExpenseReport;
        }));
    }

    public updateExpenseReport(id: number, form: ExpenseReportDeclarationForm): Observable<any> {
        return this.httpRequestService.put(`admin/expense-report/${ id }/update`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ExpenseReport, result.expense_report) as ExpenseReport;
        }));
    }

    public createAbsence(userId: number, form: AbsenceDeclarationForm): Observable<Absence> {
        return this.httpRequestService.post(`admin/absence/user/${ userId }/create`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Absence, result.absence) as Absence;
        }));
    }

    public updateAbsence(id: number, form: AbsenceDeclarationForm): Observable<any> {
        return this.httpRequestService.put(`admin/absence/${ id }/update`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Absence, result.absence) as Absence;
        }));
    }

    public createInternalActivity(userId: number, form: InternalActivityDeclarationForm): Observable<InternalActivity> {
        return this.httpRequestService.post(`admin/internal-activity/user/${ userId }/create`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(InternalActivity, result.internal_activity) as InternalActivity;
        }));
    }

    public updateInternalActivity(id: number, form: InternalActivityDeclarationForm): Observable<any> {
        return this.httpRequestService.put(`admin/internal-activity/${ id }/update`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(InternalActivity, result.internal_activity) as InternalActivity;
        }));
    }

    public createAcademicTraining(userId: number, form: AcademicTrainingDeclarationForm): Observable<AcademicTraining> {
        return this.httpRequestService.post(`admin/academic-training/user/${ userId }/create`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(AcademicTraining, result.academic_training) as AcademicTraining;
        }));
    }

    public updateAcademicTraining(id: number, form: AcademicTrainingDeclarationForm): Observable<any> {
        return this.httpRequestService.put(`admin/academic-training/${ id }/update`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(AcademicTraining, result.academic_training) as AcademicTraining;
        }));
    }
}
