import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { forkJoin, interval, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthStoreActions, AuthStoreSelectors, AuthStoreState } from 'src/app/modules/root-store';
import { PermissionTypeEnum } from '../../enums';
import { NotificationTypeEnum } from '../../enums/notification-type.enum';
import { AuthModel, Permission, UserGuidePage } from '../../models';
import { EventNotification } from '../../models/notification/event-notification.model';
import { UserNotification } from '../../models/user/user-notification.model';
import { AuthService, PermissionService, RouterService, UserGuideService, UserService } from '../../services';
import { NotificationInteractionService } from '../../services/interaction-service/notification-interaction.service';

@UntilDestroy()
@Component({
	selector: 'base-layout',
	templateUrl: 'base-layout.component.html',
	styleUrls: ['./base-layout.component.scss']
})
export class BaseLayoutComponent implements OnInit {

	public authModel: AuthModel = null;
	public hasAccessToParameter = false;
	public hasAccessToDeclaration = false;
	public hasAccessToManagement = false;
	public hasAccessToDeclarationManage = false;
	public hasAccessToRequestManage = false;
	public hasAccessToRequest = false;
	public hasAccessToProject = false;
	public hasAccessToStatistics = false;
	public hasAccessToCustomer = false;
	public hasAccessToUsersManage = false;
	public hasAccessToRoleListRead = false;
	public hasAccessToProfileManage = false;
	public hasAccessToReporting = false;
	public isHelpVisible = false;
	public isNotificationsVisible = false;
	public userGuidePages: UserGuidePage[] = [];
	public notifications: UserNotification[] = [];
	public notificationsUnreadCount = 0;

	private initSubscription: Subscription = null;
	private notificationSubscription: Subscription = null;
	private permissions: Permission[] = null;

	constructor(private store: Store<AuthStoreState.State>,
	            public router: Router,
	            public routerService: RouterService,
	            private userGuideService: UserGuideService,
	            private userService: UserService,
	            private permissionService: PermissionService,
	            private notificationInteractionService: NotificationInteractionService,
	            private authService: AuthService) {
		this.initSubscription = this.store.pipe(select(AuthStoreSelectors.selectAuthModel)).pipe(mergeMap((model) => {
			this.authModel = model;
			if (this.authModel && this.authModel.token) {
				return this.authService.getPermissions();
			}
			return [null];
		})).subscribe((permissions: Permission[]) => {
			this.store.dispatch(new AuthStoreActions.SetPermissions(permissions));
			this.permissions = permissions;
			this.checkoutAccess();
		});
		this.refreshNotifications();
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				this.userGuidePages = this.userGuideService.getUserGuideByUrl(event.url);
			}
		});
	}

	ngOnInit() {
		interval(1000).pipe(untilDestroyed(this)).subscribe();
	}

	private refreshNotifications() {
		this.notificationSubscription = this.userService.getUserNotification().subscribe((notifications) => {
			this.notifications = notifications;
			this.notificationsUnreadCount = this.notifications.filter((notification) => {
				return notification.read === 0;
			}).length;
			if (this.notificationsUnreadCount > 0) {
				this.notificationInteractionService.displaySuccessNotification(
					new EventNotification(NotificationTypeEnum.SUCCESS, this.notificationsUnreadCount + ' notifications',
						['Vous avez ' + this.notificationsUnreadCount + ' notifications non lu.']));
			}
		});
	}

	public logout() {
		this.authService.logout().subscribe(() => {
			this.authService.clearSession();
			this.store.dispatch(new AuthStoreActions.UserUnauthenticated());
			this.routerService.gotoLogin();
		});
	}

	public requirePermissionOf(permissionType: PermissionTypeEnum) {
		if (!this.permissions || this.permissions.length === 0) {
			return false;
		} else {
			const index = this.permissions.findIndex(p => p.type === permissionType);
			if (index > -1) {
				return true;
			}
		}
		return false;
	}

	private checkoutAccess() {
		if (this.permissions) {
			this.hasAccessToUsersManage = this.requirePermissionOf(PermissionTypeEnum.ADMIN_USER_MANAGE);
			this.hasAccessToProfileManage = this.requirePermissionOf(PermissionTypeEnum.ADMIN_PROFILE_MANAGE);
			this.hasAccessToRoleListRead = this.requirePermissionOf(PermissionTypeEnum.ADMIN_USER_MANAGE);
			this.hasAccessToDeclaration = this.requirePermissionOf(PermissionTypeEnum.SELF_DECLARATION_MANAGE);
			this.hasAccessToRequest = this.requirePermissionOf(PermissionTypeEnum.SELF_REQUEST_MANAGE);
			this.hasAccessToManagement = this.requirePermissionOf(PermissionTypeEnum.ADMIN_USER_MANAGE);
			this.hasAccessToDeclarationManage = this.requirePermissionOf(PermissionTypeEnum.ADMIN_DECLARATION_MANAGE);
			this.hasAccessToRequestManage = this.requirePermissionOf(PermissionTypeEnum.ADMIN_REQUEST_MANAGE);
			this.hasAccessToProject =
				this.requirePermissionOf(PermissionTypeEnum.ADMIN_PROJECT_MANAGE) ||
				this.requirePermissionOf(PermissionTypeEnum.ADMIN_PROJECT_READ) ||
				this.requirePermissionOf(PermissionTypeEnum.SELF_PROJECT_MANAGE) ||
				this.requirePermissionOf(PermissionTypeEnum.SELF_PROJECT_READ);
			this.hasAccessToStatistics =
				this.requirePermissionOf(PermissionTypeEnum.ADMIN_STATISTICS_PROJECT) ||
				this.requirePermissionOf(PermissionTypeEnum.ADMIN_STATISTICS_CUSTOMER) ||
				this.requirePermissionOf(PermissionTypeEnum.ADMIN_STATISTICS_BUSINESSSECTOR) ||
				this.requirePermissionOf(PermissionTypeEnum.ADMIN_STATISTICS_COST) ||
				this.requirePermissionOf(PermissionTypeEnum.ADMIN_STATISTICS_TURNOVERBYPROJECTTYPE) ||
				this.requirePermissionOf(PermissionTypeEnum.ADMIN_STATISTICS_DELIVERYINVOICE) ||
				this.requirePermissionOf(PermissionTypeEnum.ADMIN_STATISTICS_USER) ||
				this.requirePermissionOf(PermissionTypeEnum.ADMIN_STATISTICS_CUSTOMERSATISFACTION) ||
				this.requirePermissionOf(PermissionTypeEnum.ADMIN_STATISTICS_PROJECTUSERSATISFACTION) ||
				this.requirePermissionOf(PermissionTypeEnum.ADMIN_STATISTICS_PROJECTMANAGERCOMMENT) ||
				this.requirePermissionOf(PermissionTypeEnum.ADMIN_STATISTICS_PROJECTCOMMENT);
			this.hasAccessToCustomer = this.requirePermissionOf(PermissionTypeEnum.ADMIN_CUSTOMER_MANAGE) ||
				this.requirePermissionOf(PermissionTypeEnum.ADMIN_CUSTOMER_READ);
			this.hasAccessToReporting = this.requirePermissionOf(PermissionTypeEnum.ADMIN_REPORTING_MANAGE);
			this.hasAccessToParameter = this.hasAccessToUsersManage ||
				this.hasAccessToRoleListRead ||
				this.hasAccessToProfileManage;
		} else {
			this.hasAccessToParameter = false;
			this.hasAccessToProfileManage = false;
			this.hasAccessToRoleListRead = false;
			this.hasAccessToUsersManage = false;
		}
	}

	public showNotificationModal(): void {
		this.isNotificationsVisible = true;
	}

	public hideNotificationModal(): void {
		this.isNotificationsVisible = false;
	}

	public redirectToNotificationHref(notification): void {
		this.markNotificationAsRead(notification);
		this.hideNotificationModal();
		this.routerService.navigateTo(notification.href);
	}

	public markNotificationAsRead(notification): void {
		this.userService.markNotificationAsRead(notification.id).subscribe(() => {
			this.refreshNotifications();
		});
	}

	public markNotificationAsUnread(notification): void {
		this.userService.markNotificationAsUnread(notification.id).subscribe(() => {
			this.refreshNotifications();
		});
	}

	public deleteNotification(notification): void {
		this.userService.deleteNotification(notification.id).subscribe(() => {
			this.refreshNotifications();
		});
	}

	public switchProfile(profileHistory): void {
		this.authService.switchProfile(profileHistory.id).subscribe(() => {
			forkJoin([
				this.authService.getPermissions(),
				this.authService.getRole(),
			]).subscribe(([permissions, role]) => {
				this.store.dispatch(new AuthStoreActions.SetPermissions(permissions));
				this.store.dispatch(new AuthStoreActions.SetRole(role));
				this.permissions = permissions;
				this.checkoutAccess();
				location.reload();
			});

		}, () => {
			this.authService.clearSession();
			this.store.dispatch(new AuthStoreActions.UserUnauthenticated());
		});
	}

	/**
	 * Help modal
	 */
	public showModal(): void {
		this.isHelpVisible = true;
	}

	public handleOk(): void {
		this.isHelpVisible = false;
	}

	public handleCancel(): void {
		this.isHelpVisible = false;
	}
}

