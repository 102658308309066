import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserProfileAddForm } from '../../../parameter/forms/user-profile-add.form';
import { UserProfileUpdateForm } from '../../../parameter/forms/user-profile-update.form';
import { User, UserProfileHistory, UserProfilePreFill } from '../../models';
import { __API_ADMIN_USER_PROFILE_ADD_AVAILABLE_PROFILE__ } from '../../routes/api.route';
import { HelpService } from '../helper/help.service';
import { HttpRequestService } from '../http/http-request.service';
import { UserProfileEndForm } from '../../../parameter/forms/user-profile-end.form';

@Injectable()
export class AdminUserProfileService {

	constructor(private httpRequestService: HttpRequestService) {
	}

	/**
	 * HANDLE ALL FUNCTIONS RELATED TO USER PROFILE HISTORY
	 * PROFILE HISTORY CONTAINS ALL PROFILES RELATED TO USER
	 * OLD, CURRENT or NEXT
	 */

	public getUserProfileHistoryById(profileHistoryId: number): Observable<any> {
		const url = `admin/user/profile-history/${profileHistoryId}/detail`;
		return this.httpRequestService.get(url).pipe(map(result => {
			return this.httpRequestService.mapToModel(UserProfileHistory, result.details) as UserProfileHistory;
		}));
	}

	public userProfileHistoryAdd(userId: number, form: UserProfileAddForm): Observable<any> {
		const url = `admin/user/${userId}/profile-history`;
		return this.httpRequestService.post(url, form.toRequestForm()).pipe(map(result => {
			return this.httpRequestService.mapToModel(User, result.user);
		}));
	}

	public userProfileHistoryUpdate(profileHistoryId: number, form: UserProfileUpdateForm): Observable<any> {
		const url = `admin/user/profile-history/${profileHistoryId}`;
		return this.httpRequestService.put(url, form.toRequestForm()).pipe(map(result => {
			return this.httpRequestService.mapToModel(User, result.user);
		}));
	}

	public userProfileHistoryEnd(profileHistoryId: number, form: UserProfileEndForm): Observable<any> {
		const url = `admin/user/profile-history/${profileHistoryId}/end`;
		return this.httpRequestService.put(url, form.toRequestForm()).pipe(map(result => {
			return this.httpRequestService.mapToModel(User, result.user);
		}));
	}

	public getAvailableProfile(userId: number): Observable<any> {
		const url = HelpService.format(__API_ADMIN_USER_PROFILE_ADD_AVAILABLE_PROFILE__, userId);
		return this.httpRequestService.get(url).pipe(map(result => {
			return this.httpRequestService.mapToModel(UserProfilePreFill, result.details) as UserProfilePreFill;
		}));
	}

	public setDeclarationProfile(profileHistoryId: number): Observable<any> {
		const url = `admin/user/profile-history/${profileHistoryId}/default-declaration`;
		return this.httpRequestService.put(url, {}).pipe(map(result => {
			return this.httpRequestService.mapToModel(User, result.user);
		}));
	}
}
