import { initialState, State } from './state';
import { AuthAction, AuthActionTypes, SetPermissions, SetProfile, SetRole, UserAuthenticated } from './action';

export function reducer(state = initialState, action: AuthAction): State {
    switch (action.type) {
        case AuthActionTypes.USER_AUTHENTICATED: {
            return {
                ...state,
                isAuthenticated: true,
                authModel: (action as UserAuthenticated).authModel
            };
        }
        case AuthActionTypes.USER_UNAUTHENTICATED: {
            return {
                ...state,
                isAuthenticated: false,
                authModel: null,
                permissions: null
            };
        }
        case AuthActionTypes.SET_PERMISSIONS: {
            return {
                ...state,
                permissions: ( action as SetPermissions ).permissions
            };
        }
        case AuthActionTypes.SET_ROLE: {
            return {
                ...state,
                role: ( action as SetRole ).role
            };
        }
        case AuthActionTypes.SET_PROFILE: {
            return {
                ...state,
                profile: ( action as SetProfile ).profile
            };
        }
        default: {
            return state;
        }
    }
}
