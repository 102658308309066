import { PropertyMapper } from '../../decorators';

export class ProjectOrderDownPayment {
    @PropertyMapper()
    public id: number;

    @PropertyMapper({ jsonProperty: 'internal_reference' })
    public internalReference: string;

    @PropertyMapper({ jsonProperty: 'customer_reference' })
    public customerReference: string;

    @PropertyMapper({ jsonProperty: 'sending_date', typeOfObject: Date })
    public sendingDate: Date;

    @PropertyMapper({ jsonProperty: 'percentage' })
    public percentage: number;

    @PropertyMapper({ jsonProperty: 'editable' })
    public editable: boolean;

    @PropertyMapper({ jsonProperty: 'deletable' })
    public deletable: boolean;
}
