import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InvoiceCreateForm, InvoiceUpdateForm } from '../../../project/forms';
import { Invoice } from '../../models';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class InvoiceService {

    constructor(private httpRequestService: HttpRequestService) {
    }

    public createInvoice(projectId: number, deliveryId: number, form: InvoiceCreateForm): Observable<Invoice | null> {
        return this.httpRequestService.post(`project/${ projectId }/delivery/${ deliveryId }/invoice/create`, form.toRequestForm())
            .pipe(map((result) => {
                return this.httpRequestService.mapToModel(Invoice, result.details) as Invoice;
            }));
    }

    public updateInvoice(projectId: number, deliveryId: number, invoiceId: number, form: InvoiceUpdateForm): Observable<Invoice | null> {
        return this.httpRequestService.put(`project/${ projectId }/delivery/${ deliveryId }/invoice/${ invoiceId }/update`, form.toRequestForm())
            .pipe(map((result) => {
                return this.httpRequestService.mapToModel(Invoice, result.details) as Invoice;
            }));
    }

    public getInvoiceDetails(projectId: number, invoiceId: number): Observable<Invoice | any> {
        return this.httpRequestService.get(`project/${ projectId }/invoice/${ invoiceId }/detail`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Invoice, result.details) as Invoice;
        }));
    }

    public getInvoiceListByProject(projectId: number): Observable<any> {
        return this.httpRequestService.get(`project/${ projectId }/invoice/list`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Invoice, result.deliveries) as Invoice;
        }));
    }
}
