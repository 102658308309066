import { PropertyMapper } from '../../decorators';
import { MediaFile } from '../media/media-file.model';
import { UserProfileHistory } from '../user-profile/user-profile-history.model';
import { UserAnnualAssessmentInterview } from './user-annual-assessment-interview.model';
import { UserCar } from './user-car.model';

export class User {
    @PropertyMapper()
    id: number;

    @PropertyMapper()
    email: string;

    @PropertyMapper({ jsonProperty: 'notification_email' })
    notificationEmail: string;

    @PropertyMapper({ jsonProperty: 'first_name' })
    firstName: string;

    @PropertyMapper({ jsonProperty: 'last_name' })
    lastName: string;

    @PropertyMapper({ jsonProperty: 'registration_number' })
    registrationNumber: string;

    @PropertyMapper({ jsonProperty: 'last_connection', typeOfObject: Date })
    lastConnection: Date;

    @PropertyMapper({ jsonProperty: 'profile', typeOfObject: UserProfileHistory })
    profile: UserProfileHistory;

    @PropertyMapper({ jsonProperty: 'profile_history', typeOfObject: UserProfileHistory })
    profileHistory: UserProfileHistory[];

    @PropertyMapper({ jsonProperty: 'current_profiles', typeOfObject: UserProfileHistory })
    currentProfiles: UserProfileHistory[];

    @PropertyMapper({ jsonProperty: 'manager', typeOfObject: User })
    manager: User;

    @PropertyMapper({ jsonProperty: 'cars', typeOfObject: UserCar })
    cars: UserCar[];

    @PropertyMapper({ jsonProperty: 'entry_date', typeOfObject: Date })
    entryDate: Date;

    @PropertyMapper({ jsonProperty: 'leaving_date', typeOfObject: Date })
    leavingDate: Date;

    @PropertyMapper({ jsonProperty: 'annual_assessment_interview_date', typeOfObject: Date })
    annualAssessmentInterviewDate: Date;

    @PropertyMapper({ jsonProperty: 'annual_assessment_interviews', typeOfObject: UserAnnualAssessmentInterview })
    annualAssessmentInterviews: UserAnnualAssessmentInterview[];

    @PropertyMapper()
    username: string;

    @PropertyMapper()
    active: boolean;

    @PropertyMapper({ jsonProperty: 'files', typeOfObject: MediaFile })
    public files: MediaFile[];
}
