import { PropertyMapper } from '../../core/decorators';

export class BillingBank {

    @PropertyMapper()
    public id: number;

    @PropertyMapper({ jsonProperty: 'name' })
    public name: string;

    @PropertyMapper({ jsonProperty: 'iban' })
    public iban: string;

    @PropertyMapper({ jsonProperty: 'bic' })
    public bic: string;
}
