import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeclarationCalendarComponent } from './components/calendar/declaration-calendar.component';
import { PermissionCheckGuard } from '../core/guards';
import { PermissionTypeEnum } from '../core/enums';

const routes: Routes = [{
    path: '',
    component: DeclarationCalendarComponent,
    canActivate: [PermissionCheckGuard],
    data: {
        expectedPermissions: [PermissionTypeEnum.SELF_DECLARATION_MANAGE]
    }
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DeclarationRoutingModule {
}
