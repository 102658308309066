<nz-modal
		(nzOnCancel)="onCancelHandler();"
		[nzFooter]="null"
		[nzVisible]="visible"
		[nzWidth]="650">
	<ng-template #newDeclarationTitle>
		<div>
			Nouvelle déclaration
		</div>
	</ng-template>
	<ng-template #listDeclarationTitle>
		<div>
			Mes déclarations
		</div>
	</ng-template>
	<ng-container *nzModalContent>
		<nz-card [nzBordered]="false" class="declaration-create-modal-card" style="width: 100%;">
			<nz-card-tab>
				<nz-tabset [(nzSelectedIndex)]="selectedTab" nzSize="large" nzCentered>
					<nz-tab [nzTitle]="newDeclarationTitle"></nz-tab>
					<nz-tab *ngIf="declarationMode === declarationModeEnum.SELF" [nzTitle]="listDeclarationTitle"></nz-tab>
				</nz-tabset>
			</nz-card-tab>
			<div *ngIf="selectedTab === 0">
				<div nz-row nzJustify="center" style="margin-bottom: 15px;">
					<nz-card style="width: 100%;">
						<div (click)="showNewActivityForm()"
						     [class.selected-new-declaration]="newDeclaration && newDeclaration.declarationType === declarationTypeEnum.ACTIVITY"
						     [ngStyle]="gridStyle"
						     nz-card-grid>
							Activité
						</div>
						<div (click)="showNewExpenseReportForm()"
						     [class.selected-new-declaration]="newDeclaration && newDeclaration.declarationType === declarationTypeEnum.EXPENSE_REPORT"
						     [ngStyle]="gridStyle"
						     nz-card-grid>
							Note de frais
						</div>
						<div (click)="showNewInternalActivityForm()"
						     [class.selected-new-declaration]="newDeclaration && newDeclaration.declarationType === declarationTypeEnum.INTERNAL_ACTIVITY"
						     [ngStyle]="gridStyle"
						     nz-card-grid>
							Interne A2L
						</div>
						<div (click)="showNewAcademicTrainingForm()"
						     [class.selected-new-declaration]="newDeclaration && newDeclaration.declarationType === declarationTypeEnum.ACADEMIC_TRAINING"
						     [ngStyle]="gridStyle2"
						     nz-card-grid>
							Formation Académique
						</div>
						<div (click)="showNewAbsenceForm()"
						     [class.selected-new-declaration]="newDeclaration && newDeclaration.declarationType === declarationTypeEnum.ABSENCE"
						     [ngStyle]="gridStyle"
						     nz-card-grid>
							Absence
						</div>
					</nz-card>
				</div>
				<div *ngIf="displayNewDeclarationForm && newDeclaration" nz-row nzGutter="6" nzJustify="center">
					<div class="new-declaration-title">Faire une déclaration
						<span *ngIf="newDeclaration.declarationType === declarationTypeEnum.ACTIVITY"> d'activité</span>
						<span *ngIf="newDeclaration.declarationType === declarationTypeEnum.ABSENCE"> d'absence</span>
						<span *ngIf="newDeclaration.declarationType === declarationTypeEnum.EXPENSE_REPORT"> de note de frais</span>
						<span *ngIf="newDeclaration.declarationType === declarationTypeEnum.INTERNAL_ACTIVITY"> d'activité interne A2L</span>
						<span *ngIf="newDeclaration.declarationType === declarationTypeEnum.ACADEMIC_TRAINING"> de formation Académique</span>
						pour le <span *ngIf="selectedDate">{{selectedDate | date: 'dd/MM/yyyy'}}</span>
					</div>
					<nz-divider></nz-divider>
					<ng-container *ngIf="declarationMode === declarationModeEnum.SELF">
						<activity-declaration (closeEvent)="hideNewDeclarationForm();"
						                      (createEvent)="addNewlyCreatedDeclaration($event)"
						                      *ngIf="newDeclaration.declarationType === declarationTypeEnum.ACTIVITY"
						                      [activity]="newDeclaration"
						                      [visible]="visible"
						                      style="width: 100%;"></activity-declaration>
						<expense-report-declaration (closeEvent)="hideNewDeclarationForm();"
						                            (createEvent)="addNewlyCreatedDeclaration($event)"
						                            *ngIf="newDeclaration.declarationType === declarationTypeEnum.EXPENSE_REPORT"
						                            [expenseReport]="newDeclaration"
						                            [visible]="visible"
						                            style="width: 100%;"></expense-report-declaration>
						<absence-declaration (closeEvent)="hideNewDeclarationForm();"
						                     (createEvent)="addNewlyCreatedDeclaration($event)"
						                     *ngIf="newDeclaration.declarationType === declarationTypeEnum.ABSENCE"
						                     [absence]="newDeclaration"
						                     [visible]="visible"
						                     style="width: 100%;"></absence-declaration>
						<academic-training-declaration (closeEvent)="hideNewDeclarationForm();"
						                               (createEvent)="addNewlyCreatedDeclaration($event)"
						                               *ngIf="newDeclaration.declarationType === declarationTypeEnum.ACADEMIC_TRAINING"
						                               [academicTraining]="newDeclaration"
						                               [visible]="visible"
						                               style="width: 100%;"></academic-training-declaration>
						<internal-activity-declaration (closeEvent)="hideNewDeclarationForm()"
						                               (createEvent)="addNewlyCreatedDeclaration($event)"
						                               *ngIf="newDeclaration.declarationType === declarationTypeEnum.INTERNAL_ACTIVITY"
						                               [internalActivity]="newDeclaration"
						                               [visible]="visible"
						                               style="width: 100%;"></internal-activity-declaration>
					</ng-container>
					<ng-container *ngIf="declarationMode === declarationModeEnum.MANAGER && userId">
						<activity-declaration (closeEvent)="hideNewDeclarationForm();"
						                      (createEvent)="addNewlyCreatedDeclaration($event)"
						                      *ngIf="newDeclaration.declarationType === declarationTypeEnum.ACTIVITY"
						                      [activity]="newDeclaration"
						                      [declarationMode]="declarationMode"
						                      [userId]="userId"
						                      [visible]="visible"
						                      style="width: 100%;"></activity-declaration>
						<expense-report-declaration (closeEvent)="hideNewDeclarationForm();"
						                            (createEvent)="addNewlyCreatedDeclaration($event)"
						                            *ngIf="newDeclaration.declarationType === declarationTypeEnum.EXPENSE_REPORT"
						                            [declarationMode]="declarationMode"
						                            [expenseReport]="newDeclaration"
						                            [userId]="userId"
						                            [visible]="visible"
						                            style="width: 100%;"></expense-report-declaration>
						<absence-declaration (closeEvent)="hideNewDeclarationForm();"
						                     (createEvent)="addNewlyCreatedDeclaration($event)"
						                     *ngIf="newDeclaration.declarationType === declarationTypeEnum.ABSENCE"
						                     [absence]="newDeclaration"
						                     [declarationMode]="declarationMode"
						                     [userId]="userId"
						                     [visible]="visible"
						                     style="width: 100%;"></absence-declaration>
						<academic-training-declaration (closeEvent)="hideNewDeclarationForm();"
						                               (createEvent)="addNewlyCreatedDeclaration($event)"
						                               *ngIf="newDeclaration.declarationType === declarationTypeEnum.ACADEMIC_TRAINING"
						                               [academicTraining]="newDeclaration"
						                               [declarationMode]="declarationMode"
						                               [userId]="userId"
						                               [visible]="visible"
						                               style="width: 100%;"></academic-training-declaration>
						<internal-activity-declaration (closeEvent)="hideNewDeclarationForm()"
						                               (createEvent)="addNewlyCreatedDeclaration($event)"
						                               *ngIf="newDeclaration.declarationType === declarationTypeEnum.INTERNAL_ACTIVITY"
						                               [declarationMode]="declarationMode"
						                               [internalActivity]="newDeclaration"
						                               [userId]="userId"
						                               [visible]="visible"
						                               style="width: 100%;"></internal-activity-declaration>
					</ng-container>
				</div>
			</div>
			<div *ngIf="selectedTab === 1">
				<nz-tabset (nzSelectedIndexChange)="selectedIndexChanged($event)" [nzSelectedIndex]="selectedIndex"
				           [nzSize]="'small'" [nzType]="'card'">
					<nz-tab *ngFor="let declaration of declarations"
					        [nzTitle]="titleTemplate">
						<ng-template #titleTemplate>
							<div *ngIf="declaration">
								<ng-container *ngIf="declaration.declarationType === declarationTypeEnum.ACTIVITY">Activité
								</ng-container>
								<ng-container *ngIf="declaration.declarationType === declarationTypeEnum.EXPENSE_REPORT">
									Note de
									frais
								</ng-container>
								<ng-container *ngIf="declaration.declarationType === declarationTypeEnum.ABSENCE">Absence
								</ng-container>
								<ng-container *ngIf="declaration.declarationType === declarationTypeEnum.INTERNAL_ACTIVITY">
									Interne
									A2L
								</ng-container>
								<ng-container *ngIf="declaration.declarationType === declarationTypeEnum.ACADEMIC_TRAINING">
									Formation académique
								</ng-container>
								<fa-icon
										(click)="deleteDeclarationRequest(declaration)"
										*ngIf="declaration.status && declaration.status.id === declarationStatusEnum.WAITING_VALIDATION"
										[icon]="['far', 'trash']"
										class="gaia-highlight-danger"
										size="lg"
										style="padding-left: 10px;color: rgba(0, 0, 0, 0.65);"></fa-icon>
							</div>
						</ng-template>
						<activity-declaration
								(closeEvent)="onCancelHandler();"
								*ngIf="declaration.declarationType === declarationTypeEnum.ACTIVITY"
								[activity]="declaration"
								[visible]="visible"></activity-declaration>
						<expense-report-declaration
								(closeEvent)="onCancelHandler();"
								*ngIf="declaration.declarationType === declarationTypeEnum.EXPENSE_REPORT"
								[expenseReport]="declaration"
								[visible]="visible"></expense-report-declaration>
						<absence-declaration
								(closeEvent)="onCancelHandler();"
								*ngIf="declaration.declarationType === declarationTypeEnum.ABSENCE"
								[absence]="declaration"
								[visible]="visible"></absence-declaration>
						<internal-activity-declaration
								(closeEvent)="onCancelHandler()"
								*ngIf="declaration.declarationType === declarationTypeEnum.INTERNAL_ACTIVITY"
								[internalActivity]="declaration"
								[visible]="visible"></internal-activity-declaration>
						<academic-training-declaration
								(closeEvent)="onCancelHandler()"
								*ngIf="declaration.declarationType === declarationTypeEnum.ACADEMIC_TRAINING"
								[academicTraining]="declaration"
								[visible]="visible"></academic-training-declaration>
					</nz-tab>
				</nz-tabset>
			</div>
		</nz-card>
	</ng-container>
</nz-modal>


<nz-modal
		(nzOnCancel)="closeDeleteDeclarationModal()"
		(nzOnOk)="deleteDeclaration()"
		[(nzVisible)]="displayDeleteDeclarationModal"
		[nzTitle]="deleteDeclarationTitleTemplate">
	<ng-container *nzModalContent>
		<div>
			Êtes vous sur de vouloir supprimer cette <span
				*ngIf="selectedDeclaration &&
                selectedDeclaration.declarationType === declarationTypeEnum.ACTIVITY">activité ?</span>
			<span *ngIf="selectedDeclaration &&
                selectedDeclaration.declarationType === declarationTypeEnum.EXPENSE_REPORT">note de frais ?</span>
			<span *ngIf="selectedDeclaration &&
                selectedDeclaration.declarationType === declarationTypeEnum.ABSENCE">abscence ?</span>
			<span *ngIf="selectedDeclaration &&
                selectedDeclaration.declarationType === declarationTypeEnum.INTERNAL_ACTIVITY">déclaration ?</span>
			<span *ngIf="selectedDeclaration &&
                selectedDeclaration.declarationType === declarationTypeEnum.ACADEMIC_TRAINING">formation académique ?</span>
		</div>
	</ng-container>
	<ng-template #deleteDeclarationTitleTemplate>
		<div>Suppression</div>
	</ng-template>
</nz-modal>

