import { v4 as uuid } from 'uuid';
import { PropertyMapper } from '../../decorators';
import { DeclarationTypeEnum } from '../../enums';
import { StatusBase } from '../status-base/status-base.model';

export class Declaration {
    public declarationType: DeclarationTypeEnum;

    public uuid: string;

    @PropertyMapper({ jsonProperty: 'date', typeOfObject: Date })
    public date: Date;

    @PropertyMapper()
    public id: number;

    @PropertyMapper({ jsonProperty: 'status', typeOfObject: StatusBase })
    public status: StatusBase;

    constructor() {
        this.uuid = uuid();
    }

    @PropertyMapper({ jsonProperty: 'user_id' })
    public userId: number;
}
