import { PropertyMapper } from '../../decorators';
import { MediaFile } from '../media/media-file.model';
import { SupplierContact } from './supplier-contact.model';

export class Supplier {
	@PropertyMapper()
	public id: number;

	@PropertyMapper()
	public name: string;

	@PropertyMapper({ jsonProperty: 'status' })
	public status: number;

	@PropertyMapper({ jsonProperty: 'status_name' })
	public statusName: string;

	@PropertyMapper({ jsonProperty: 'address_line_1' })
	public addressLine1: string;

	@PropertyMapper({ jsonProperty: 'address_line_2' })
	public addressLine2: string;

	@PropertyMapper({ jsonProperty: 'zipcode' })
	public zipcode: string;

	@PropertyMapper({ jsonProperty: 'city' })
	public city: string;

	@PropertyMapper({ jsonProperty: 'iban' })
	public iban: string;

	@PropertyMapper({ jsonProperty: 'bic' })
	public bic: string;

	@PropertyMapper({ jsonProperty: 'vat_number' })
	public vatNumber: number;

	@PropertyMapper({ jsonProperty: 'vat_rate' })
	public vatRate: number;

	@PropertyMapper({ jsonProperty: 'created_at', typeOfObject: Date })
	public createdAt: Date;

	@PropertyMapper({ jsonProperty: 'updated_at', typeOfObject: Date })
	public updatedAt: Date;

	@PropertyMapper({ jsonProperty: 'contacts', typeOfObject: SupplierContact })
	public contacts: SupplierContact[];

	@PropertyMapper({ jsonProperty: 'files', typeOfObject: MediaFile })
	public files: MediaFile[];

	public purchaseContacts: SupplierContact[];
	public technicalContacts: SupplierContact[];
	public accountingContacts: SupplierContact[];
}
