import { PropertyMapper } from '../../decorators';

export class UserGuidePage {
    @PropertyMapper()
    id: number;

    @PropertyMapper({ jsonProperty: 'name' })
    name: string;

    @PropertyMapper({ jsonProperty: 'html' })
    html;

    @PropertyMapper({ jsonProperty: 'created_at', typeOfObject: Date })
    createdAt: Date;

    active = false;
}
