import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    AcademicTrainingDeclarationForm,
    AcademicTrainingDeclarationMultiForm,
    AcademicTrainingListGetRequestForm
} from '../../../declaration/forms';
import { AcademicTraining, Activity } from '../../models';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class AcademicTrainingService {

    constructor(private httpRequestService: HttpRequestService) {
    }

    public createAcademicTraining(form: AcademicTrainingDeclarationForm): Observable<AcademicTraining> {
        return this.httpRequestService.post('academic-training', form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(AcademicTraining, result.academic_training) as AcademicTraining;
        }));
    }

    public createMultiAcademicTraining(form: AcademicTrainingDeclarationMultiForm): Observable<any> {
        return this.httpRequestService.post('academic-training/create/multi', form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Activity, result.academic_trainings) as AcademicTraining[];
        }));
    }

    public getAcademicTrainingList(form: AcademicTrainingListGetRequestForm): Observable<any> {
        return this.httpRequestService.post('academic-training/list', form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(AcademicTraining, result.academic_trainings) as AcademicTraining[];
        }));
    }

    public getAcademicTrainingByUser(userId: number, form: AcademicTrainingListGetRequestForm): Observable<any> {
        return this.httpRequestService.post(`admin/academic-training/user/${ userId }/list`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(AcademicTraining, result.academic_trainings) as AcademicTraining[];
        }));
    }

    public updateAcademicTraining(id: number, form: AcademicTrainingDeclarationForm): Observable<any> {
        return this.httpRequestService.put(`academic-training/${ id }/update`, form.toRequestForm()).pipe(map(result => {
            return this.httpRequestService.mapToModel(AcademicTraining, result.academic_training);
        }));
    }

    public deleteAcademicTraining(id: number): Observable<any> {
        return this.httpRequestService.delete(`academic-training/${ id }/delete`);
    }
}
