import { PropertyMapper } from '../../decorators';
import { Profile } from '../profile/profile.model';
import { MediaFile } from '../media/media-file.model';

export class ManagementDeclarationUser {
    @PropertyMapper()
    id: number;

    @PropertyMapper({ jsonProperty: 'profile', typeOfObject: Profile })
    profile: Profile;

    @PropertyMapper({ jsonProperty: 'first_name' })
    firstName: string;

    @PropertyMapper({ jsonProperty: 'last_name' })
    lastName: string;

    @PropertyMapper({ jsonProperty: 'active' })
    active: boolean;

    @PropertyMapper({ jsonProperty: 'cv', typeOfObject: MediaFile })
    public cv: MediaFile[];

    /**
     * Waiting validation declaration count
     */
    @PropertyMapper({ jsonProperty: 'wv_activity_count' })
    wvActivityCount: number;

    @PropertyMapper({ jsonProperty: 'wv_absence_count' })
    wvAbsenceCount: number;

    @PropertyMapper({ jsonProperty: 'wv_expense_report_count' })
    wvExpenseReportCount: number;

    @PropertyMapper({ jsonProperty: 'wv_internal_activity_count' })
    wvInternalActivityCount: number;

    @PropertyMapper({ jsonProperty: 'wv_absence_request_count' })
    wvAbsenceRequestCount: number;

    @PropertyMapper({ jsonProperty: 'wv_expense_report_request_count' })
    wvExpenseReportRequestCount: number;

    wvDeclarationCount: number;
    wvRequestCount: number;

    /**
     * Waiting validation declaration count
     */
    @PropertyMapper({ jsonProperty: 'denied_activity_count' })
    deniedActivityCount: number;

    @PropertyMapper({ jsonProperty: 'denied_absence_count' })
    deniedAbsenceCount: number;

    @PropertyMapper({ jsonProperty: 'denied_expense_report_count' })
    deniedExpenseReportCount: number;

    @PropertyMapper({ jsonProperty: 'denied_internal_activity_count' })
    deniedInternalActivityCount: number;

    @PropertyMapper({ jsonProperty: 'denied_absence_request_count' })
    deniedAbsenceRequestCount: number;

    @PropertyMapper({ jsonProperty: 'denied_expense_report_request_count' })
    deniedExpenseReportRequestCount: number;

    deniedDeclarationCount: number;
    deniedRequestCount: number;
}
