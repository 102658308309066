import { PropertyMapper } from '../../decorators';

export class ProjectAssignedUser {
    @PropertyMapper()
    public id: number;

    @PropertyMapper({ jsonProperty: 'first_name' })
    public firstName: string;

    @PropertyMapper({ jsonProperty: 'last_name' })
    public lastName: string;

    @PropertyMapper({ jsonProperty: 'profile' })
    public profile: string;

    @PropertyMapper({ jsonProperty: 'assigned_fee' })
    public assignedFee: number;

    @PropertyMapper({ jsonProperty: 'reel_fee' })
    public reelFee: number;

    @PropertyMapper({ jsonProperty: 'assigned_workload' })
    public assignedWorkload: number;

    @PropertyMapper({ jsonProperty: 'declared_workload' })
    public declaredWorkload: number;

    @PropertyMapper({ jsonProperty: 'reel_workload' })
    public reelWorkload: number;

    @PropertyMapper({ jsonProperty: 'active' })
    public active: boolean;

    @PropertyMapper({ jsonProperty: 'survey_notified' })
    public surveyNotified: boolean;

    public assigned: boolean;
}
