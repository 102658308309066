import { PropertyMapper } from '../../decorators';
import { MediaFile } from '../media/media-file.model';
import { InvoiceStatus } from './invoice-status.model';

export class Invoice {

    @PropertyMapper({ jsonProperty: 'id' })
    public id: number;

    @PropertyMapper({ jsonProperty: 'reference' })
    public reference: string;

    @PropertyMapper({ jsonProperty: 'date', typeOfObject: Date })
    public date: Date;

    @PropertyMapper({ jsonProperty: 'sent_date', typeOfObject: Date })
    public sentDate: Date;

    @PropertyMapper({ jsonProperty: 'status', typeOfObject: InvoiceStatus })
    public status: InvoiceStatus;

    @PropertyMapper({ jsonProperty: 'payment_date_estimated', typeOfObject: Date })
    public paymentDateEstimated: Date;

    @PropertyMapper({ jsonProperty: 'payment_date_actual', typeOfObject: Date })
    public paymentDateActual: Date;

    @PropertyMapper({ jsonProperty: 'payment_delay' })
    public paymentDelay: number;

    @PropertyMapper({ jsonProperty: 'file', typeOfObject: MediaFile })
    public file: MediaFile;
}
