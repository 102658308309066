import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Role } from '../../models';
import { __API_ADMIN_ROLE_LIST_GET__ } from '../../routes/api.route';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class RoleService {

    constructor(private httpRequestService: HttpRequestService) {
    }

    public getRoleList(): Observable<any> {
        return this.httpRequestService.get(__API_ADMIN_ROLE_LIST_GET__).pipe(map(result => {
            return this.httpRequestService.mapToModel(Role, result.roles);
        }));
    }
}
