import { PropertyMapper } from '../../decorators';
import { User } from '../user/user.model';
import { SupplierLot } from './supplier-lot.model';
import { Supplier } from './supplier.model';

export class SupplierOrder {

    @PropertyMapper()
    public id: number;

    @PropertyMapper({ jsonProperty: 'status' })
    public status: number;

    @PropertyMapper({ jsonProperty: 'status_name' })
    public statusName: string;

    @PropertyMapper({ jsonProperty: 'reference' })
    public reference: string;

    @PropertyMapper({ jsonProperty: 'date', typeOfObject: Date })
    public date: Date;

    @PropertyMapper({ jsonProperty: 'validator', typeOfObject: User })
    public validator: User;

    @PropertyMapper({ jsonProperty: 'supplier', typeOfObject: Supplier })
    public supplier: Supplier;

    @PropertyMapper({ jsonProperty: 'lots', typeOfObject: SupplierLot })
    public lots: SupplierLot[];

    @PropertyMapper({ jsonProperty: 'can_archive' })
    public canArchive: boolean;
}
