import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DeclarationModule } from '../declaration/declaration.module';
import { RootStoreModule } from '../root-store/root-store.module';
import { SharedModule } from '../shared/shared.module';
import { BaseLayoutComponent } from './components/base-layout/base-layout.component';
import { CoreRoutingModule } from './core-routing.module';
import { AuthorizationNeedGuard, PermissionCheckGuard, UnauthorizationNeedGuard } from './guards';
import { httpInterceptorProviders } from './http-interceptors';
import {
	AbsenceService,
	AcademicTrainingService,
	ActivityService,
	AdminDeclarationService,
	AdminUserProfileService,
	AmendmentService,
	AuthService,
	CreditService,
	CustomerService,
	DashboardService,
	DeclarationInteractionService,
	DeclarationService,
	DeliveryService,
	ExpenseReportService,
	InternalActivityService,
	InvoiceService,
	LocalStorageService,
	LotService,
	OrderService,
	PermissionService,
	ProfileService,
	ProjectService,
	ReportingService,
	RequestService,
	RoleService,
	RouterService,
	StatisticService,
	UserGuideService,
	UserService
} from './services';
import { AdminUserService } from './services/admin/admin-user.service';
import { FiscalPeriodService } from './services/admin/fiscal-period.service';
import { BillingService } from './services/billing/billing.service';
import { FileService } from './services/file/file.service';
import { HelpService } from './services/helper/help.service';
import { HttpRequestService } from './services/http/http-request.service';
import { NavigationInteractionService } from './services/interaction-service/navigation-interaction.service';
import { NotificationInteractionService } from './services/interaction-service/notification-interaction.service';
import { SurveyService } from './services/survey/survey.service';
import { SupplierService } from './services/supplier/supplier.service';

@NgModule({
	imports: [
		SharedModule,
		HttpClientModule,
		RootStoreModule,
		CoreRoutingModule,
		DeclarationModule
	],
	exports: [
		RouterModule
	],
	providers: [
		LocalStorageService,
		AuthService,
		RouterService,
		HttpRequestService,
		AuthorizationNeedGuard,
		UnauthorizationNeedGuard,
		PermissionCheckGuard,
		httpInterceptorProviders,
		UserService,
		RoleService,
		ProfileService,
		FiscalPeriodService,
		PermissionService,
		ActivityService,
		ProjectService,
		LotService,
		DeclarationInteractionService,
		ExpenseReportService,
		AbsenceService,
		InternalActivityService,
		AcademicTrainingService,
		CustomerService,
		RequestService,
		HelpService,
		DeclarationService,
		OrderService,
		DeliveryService,
		InvoiceService,
		CreditService,
		NotificationInteractionService,
		FileService,
		ReportingService,
		StatisticService,
		NavigationInteractionService,
		UserGuideService,
		DashboardService,
		SurveyService,
		SupplierService,
		AmendmentService,
		/**
		 * Billing service
		 */
		BillingService,
		/**
		 * Admin Services
		 */
		AdminUserService,
		AdminUserProfileService,
		AdminDeclarationService
	],
	declarations: [
		BaseLayoutComponent
	]
})
export class CoreModule {
}
