export enum FileTypeEnum {
    ORDER_FORM = 0,
    DELIVERY_FORM = 1,
    PROJECT_PTF = 2,
    FRAMEWORK_CONTRACT = 3,
    CONFIDENTIALITY_AGREEMENT = 4,
    FEE_SCHEDULE = 5,
    PREVENTION_PLAN = 6,
    VEHICLE_REGISTRATION_CERTIFICATE = 7,
    IDENTITY_CARD = 8,
    PASSPORT = 9, // not used
    HEALTH_INSURANCE_CARD = 10,
    DIPLOMA = 11,
    CURRICULUM_VITAE = 12,
    RIB = 13,
    DRIVING_LICENSE = 14,
    EXPENSE_REPORT_PROOF = 15,
    INVOICE = 16,
    PROJECT_AMENDMENT = 17,
    USER_ANNUAL_ASSESSMENT_INTEVIEW = 18,
    PROFILE_POST_DESCRIPTION = 19,
    SERVICE_CONTRACT = 20,
    KBIS = 21,
    FOREIGN_WORKER_DECLARATION = 22,
    SUPPLIER_INVOICE = 23,
    INSURANCE_DOCS = 24,
    BILLING_PROCESS = 25,
    OTHERS = 26,
}

export const FileTypeTranslator: string[] = [
    'Bon de commande',
    'Bon de livraison',
    'PTF du projet',
    'Contrat cadre',
    'Accord de confidentialité',
    'Grille tarifaire',
    'Plan de prévention',
    'Carte grise',
    'Carte d\'identité',
    'Passeport',
    'Carte Vitale',
    'Diplôme',
    'CV',
    'Rib',
    'Permis de conduire',
    'Justificatif des frais',
    'Facture',
    'Avenant de projet',
    'Compte rendu d\'entretien annuel',
    'Fiche de poste',
    'Contrat de prestation',
    'Kbis',
    'Déclaration de travailleur étranger',
    'Facture fournisseur',
    'Assurance RC Pro',
	'Process de facturation',
	'Autres',
];
