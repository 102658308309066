import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzMessageService } from 'ng-zorro-antd/message';
import { combineLatest, interval, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeclarationModeEnum, DeclarationStatusEnum, DeclarationTypeEnum } from '../../../core/enums';
import { AcademicTraining, Activity, Declaration } from '../../../core/models';
import { Absence } from '../../../core/models/absence/absence.model';
import { ExpenseReport } from '../../../core/models/expense-report/expense-report.model';
import { InternalActivity } from '../../../core/models/internal-activity/internal-activity.model';
import {
    AbsenceService,
    AcademicTrainingService,
    ActivityService,
    DeclarationInteractionService,
    ExpenseReportService,
    InternalActivityService
} from '../../../core/services';
import { NotificationInteractionService } from '../../../core/services/interaction-service/notification-interaction.service';

@UntilDestroy()
@Component({
    selector: 'declaration-create-modal',
    templateUrl: './declaration-create-modal.component.html',
    styleUrls: ['./declaration-create-modal.component.scss']
})
export class DeclarationCreateModalComponent implements OnChanges, OnInit {

    gridStyle = {
        width: '20%',
        height: '45px',
        padding: '12px',
        textAlign: 'center'
    };

    gridStyle2 = {
        width: '20%',
        height: '45px',
        padding: '2px',
        textAlign: 'center'
    };

    @Input() public display = false;
    @Input() public declarationMode = DeclarationModeEnum.SELF;
    @Input() public userId;
    @Output() public closeEvent: EventEmitter<any> = new EventEmitter();
    @Output() public updateEvent: EventEmitter<any> = new EventEmitter();

    public visible: boolean = null;
    public selectedDate: Date = null;
    public declarations: Declaration[] = [];
    public selectedIndex = 0;
    public selectedDeclaration: Declaration = null;
    public declarationTypeEnum = DeclarationTypeEnum;
    public declarationStatusEnum = DeclarationStatusEnum;
    public displayDeleteDeclarationModal = false;

    private selectedDateSubscription: Subscription = null;
    private deleteActivitySubscription: Subscription = null;
    private deleteAbsenceSubscription: Subscription = null;
    private deleteInternalActivitySubscription: Subscription = null;
    private deleteExpenseReportSubscription: Subscription = null;
    private deleteAcademicTrainingSubscription: Subscription = null;
    private selectedDeclarationsSubscription: Subscription = null;

    public selectedTab = 0;
    public displayNewDeclarationForm = false;
    public newDeclaration: Declaration = null;
    public declarationModeEnum = DeclarationModeEnum;

    constructor(private declarationInteractionService: DeclarationInteractionService,
                private activityService: ActivityService,
                private absenceService: AbsenceService,
                private internalActivityService: InternalActivityService,
                private academicTrainingService: AcademicTrainingService,
                private notificationInteractionService: NotificationInteractionService,
                private nzMessageService: NzMessageService,
                private expenseReportService: ExpenseReportService) {
    }

    ngOnInit() {
        this.selectedDateSubscription = this.declarationInteractionService.selectedDate$.subscribe(date => {
            this.selectedDate = date;
        });
        this.selectedDeclarationsSubscription = combineLatest([
            this.declarationInteractionService.selectedActivities$,
            this.declarationInteractionService.selectedAbsences$,
            this.declarationInteractionService.selectedExpenseReports$,
            this.declarationInteractionService.selectedInternalActivities$,
            this.declarationInteractionService.selectedAcademicTrainings$
        ]).pipe(map(([activities, absences, expenseReports, internalActivities, academicTrainings]) => {
            this.declarations = [...activities, ...absences, ...expenseReports, ...internalActivities, ...academicTrainings];
        })).subscribe(() => {
        });
        interval(1000).pipe(untilDestroyed(this)).subscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.display && !changes.display.isFirstChange()) {
            this.visible = this.display;
        }
    }

    public onCancelHandler() {
        this.visible = false;
        this.selectedTab = 0;
        this.displayNewDeclarationForm = false;
        this.closeEvent.emit();
    }

    public hideNewDeclarationForm() {
        this.displayNewDeclarationForm = false;
        this.newDeclaration = null;
    }

    public addNewlyCreatedDeclaration(declaration) {
        this.declarations.push(declaration);
        this.declarationInteractionService.refreshDeclaration();
    }

    public showNewActivityForm() {
        this.displayNewDeclarationForm = true;
        this.newDeclaration = new Activity();
    }

    public showNewAbsenceForm() {
        this.displayNewDeclarationForm = true;
        this.newDeclaration = new Absence();
    }

    public showNewExpenseReportForm() {
        this.displayNewDeclarationForm = true;
        this.newDeclaration = new ExpenseReport();
    }

    public showNewInternalActivityForm() {
        this.displayNewDeclarationForm = true;
        this.newDeclaration = new InternalActivity();
    }

    public showNewAcademicTrainingForm() {
        this.displayNewDeclarationForm = true;
        this.newDeclaration = new AcademicTraining();
    }

    public deleteDeclarationRequest(declaration: Declaration) {
        this.selectedDeclaration = declaration;
        this.displayDeleteDeclarationModal = true;
    }

    public deleteDeclaration() {
        if (!this.selectedDeclaration) {
            this.closeDeleteDeclarationModal();
            return;
        }
        // check if it's an empty declaration.
        if (this.selectedDeclaration.id !== null && typeof this.selectedDeclaration.id !== 'undefined') {
            if (this.selectedDeclaration.declarationType === DeclarationTypeEnum.ACTIVITY) {
                this.deleteActivity(this.selectedDeclaration);
            } else if (this.selectedDeclaration.declarationType === DeclarationTypeEnum.EXPENSE_REPORT) {
                this.deleteExpenseReport(this.selectedDeclaration);
            } else if (this.selectedDeclaration.declarationType === DeclarationTypeEnum.ABSENCE) {
                this.deleteAbsence(this.selectedDeclaration);
            } else if (this.selectedDeclaration.declarationType === DeclarationTypeEnum.INTERNAL_ACTIVITY) {
                this.deleteInternalActivity(this.selectedDeclaration);
            } else if (this.selectedDeclaration.declarationType === DeclarationTypeEnum.ACADEMIC_TRAINING) {
                this.deleteAcademicTraining(this.selectedDeclaration);
            }
        }
    }

    public closeDeleteDeclarationModal() {
        this.selectedDeclaration = null;
        this.displayDeleteDeclarationModal = false;
    }

    public selectedIndexChanged(value: number) {
        this.selectedIndex = value;
    }

    private deleteSelectedDeclaration() {
        this.declarations.splice(this.selectedIndex, 1);
        this.declarationInteractionService.deleteDeclaration(this.selectedDeclaration);
        this.closeDeleteDeclarationModal();
    }

    private deleteActivity(activity: Declaration) {
        this.deleteActivitySubscription = this.activityService.deleteActivity(activity.id).subscribe(() => {
            this.deleteSelectedDeclaration();
            // this.onCancelHandler();
        }, err => {
            if (err && err.messages) {
                this.nzMessageService.error(err.messages.join('\n'));
            }
        });
    }

    private deleteAbsence(absence: Declaration) {
        this.deleteAbsenceSubscription = this.absenceService.deleteAbsence(absence.id).subscribe(() => {
            this.deleteSelectedDeclaration();
            // this.onCancelHandler();
        }, err => {
            if (err && err.messages) {
                this.nzMessageService.error(err.messages.join('\n'));
            }
        });
    }

    private deleteExpenseReport(expenseReport: Declaration) {
        this.deleteExpenseReportSubscription = this.expenseReportService.deleteExpenseReport(expenseReport.id).subscribe(() => {
            this.deleteSelectedDeclaration();
            // this.onCancelHandler();
        }, err => {
            if (err && err.messages) {
                this.nzMessageService.error(err.messages.join('\n'));
            }
        });
    }

    private deleteInternalActivity(internalActivity: Declaration) {
        this.deleteInternalActivitySubscription = this.internalActivityService.deleteInternalActivity(internalActivity.id).subscribe(() => {
            this.deleteSelectedDeclaration();
            // this.onCancelHandler();
        }, err => {
            if (err && err.messages) {
                this.nzMessageService.error(err.messages.join('\n'));
            }
        });
    }

    private deleteAcademicTraining(academicTraining: Declaration) {
        this.deleteAcademicTrainingSubscription = this.academicTrainingService.deleteAcademicTraining(academicTraining.id).subscribe(() => {
            this.deleteSelectedDeclaration();
            // this.onCancelHandler();
        }, err => {
            if (err && err.messages) {
                this.nzMessageService.error(err.messages.join('\n'));
            }
        });
    }

}
