<div nz-row nzJustify="center" style="margin-top: 30px;">
	<div nz-col nzSpan="12" style="text-align: center;">
		<img src="https://www.a2l.net/wp-content/uploads/2014/06/A2L-logo-156x340.png"
		     alt="A2L LOGO">
		<h1>Enquête de satisfaction utilisateur</h1>
	</div>
</div>
<div nz-row nzJustify="center" class="form" *ngIf="!displayThanks">
	<div nz-col nzSpan="14">
		<p>
			Nous te remercions de prendre le temps de participer à cette enquête qui nous permettras d'améliorer nos processus.
		</p>
	</div>
</div>
<div nz-row nzJustify="center" class="form" *ngIf="!displayThanks">
	<div nz-col nzSpan="14">
		<form (keydown.enter)="$event.preventDefault()" *ngIf="form"
		      [formGroup]="form.validationForm"
		      nz-form nzLayout="vertical">
			<nz-card>
				<nz-form-item>
					<nz-form-label>{{ q3Question }}</nz-form-label>
					<nz-form-control nzErrorTip="Le champ est obligatoire">
						<div style="">
							<nz-radio-group formControlName="q3">
								<label nz-radio [nzValue]="o.value" *ngFor="let o of q3CheckOptions">{{ o.label }}</label>
							</nz-radio-group>
						</div>
					</nz-form-control>
				</nz-form-item>
			</nz-card>
			<nz-card>
				<nz-form-item>
					<nz-form-label>{{ q4Question }}</nz-form-label>
					<nz-form-control nzErrorTip="Le champ est obligatoire">
						<div style="">
							<nz-radio-group formControlName="q4">
								<label nz-radio [nzValue]="o.value" *ngFor="let o of q4CheckOptions">{{ o.label }}</label>
							</nz-radio-group>
						</div>
					</nz-form-control>
				</nz-form-item>
			</nz-card>
			<nz-card>
				<nz-form-item>
					<nz-form-label>{{ q5Question }}</nz-form-label>
					<nz-form-control nzErrorTip="Le champ est obligatoire">
						<div style="">
							<nz-radio-group formControlName="q5">
								<label nz-radio [nzValue]="o.value" *ngFor="let o of q5CheckOptions">{{ o.label }}</label>
							</nz-radio-group>
						</div>
					</nz-form-control>
				</nz-form-item>
			</nz-card>
			<nz-card>
				<nz-form-item>
					<nz-form-label>{{ q6Question }}</nz-form-label>
					<nz-form-control nzErrorTip="Le champ est obligatoire">
						<div style="">
							<nz-radio-group formControlName="q6">
								<label nz-radio [nzValue]="o.value" *ngFor="let o of q6CheckOptions">{{ o.label }}</label>
							</nz-radio-group>
						</div>
					</nz-form-control>
				</nz-form-item>
			</nz-card>
			<nz-card>
				<nz-form-item>
					<nz-form-label>{{ q7Question }}</nz-form-label>
					<nz-form-control nzErrorTip="Le champ est obligatoire">
						<div style="">
							<nz-radio-group formControlName="q7">
								<label nz-radio [nzValue]="o.value" *ngFor="let o of q7CheckOptions">{{ o.label }}</label>
							</nz-radio-group>
						</div>
					</nz-form-control>
				</nz-form-item>
			</nz-card>
			<nz-card>
				<nz-form-item>
					<nz-form-label>{{ q8Question }}</nz-form-label>
					<nz-form-control>
						<textarea formControlName="q8" nz-input></textarea>
					</nz-form-control>
				</nz-form-item>
			</nz-card>
			<nz-card>
				<nz-form-item>
					<nz-form-label>{{ q9Question }}</nz-form-label>
					<nz-form-control nzErrorTip="Le champ est obligatoire">
						<div style="">
							<nz-radio-group formControlName="q9">
								<label nz-radio [nzValue]="o.value" *ngFor="let o of q9CheckOptions">{{ o.label }}</label>
							</nz-radio-group>
						</div>
					</nz-form-control>
				</nz-form-item>
			</nz-card>
			<nz-card>
				<nz-form-item>
					<nz-form-label>{{ q10Question }}</nz-form-label>
					<nz-form-control nzErrorTip="Le champ est obligatoire">
						<div style="">
							<nz-radio-group formControlName="q10">
								<label nz-radio [nzValue]="o.value" *ngFor="let o of q10CheckOptions">{{ o.label }}</label>
							</nz-radio-group>
						</div>
					</nz-form-control>
				</nz-form-item>
			</nz-card>
			<nz-card>
				<nz-form-item>
					<nz-form-label>{{ q11Question }}</nz-form-label>
					<nz-form-control>
						<textarea formControlName="q11" nz-input></textarea>
					</nz-form-control>
				</nz-form-item>
			</nz-card>
			<nz-card>
				<nz-form-item>
					<nz-form-label>{{ q12Question }}</nz-form-label>
					<nz-form-control>
						<textarea formControlName="q12" nz-input></textarea>
					</nz-form-control>
				</nz-form-item>
			</nz-card>
			<nz-card>
				<nz-form-item>
					<nz-form-label>{{ q13Question }}</nz-form-label>
					<nz-form-control>
						<textarea formControlName="q13" nz-input></textarea>
					</nz-form-control>
				</nz-form-item>
			</nz-card>
		</form>
		<div nz-row nzJustify="end" class="buttons">
			<button nz-button nzType="primary"
			        *ngIf="survey.status === 1"
			        (click)="submit()" [nzLoading]="isConfirmLoading" nzSize="large">Valider
			</button>
		</div>
	</div>
</div>

<div nz-row nzJustify="center" style="margin-top: 30px;" *ngIf="displayThanks">
	Merci d'avoir participer à l'enquête.
	<br>
	Vous pouvez à présent fermer cette fenêtre.
</div>
