import { PropertyMapper } from '../../core/decorators';

export class CustomerSatisfactionStatistics {

    /**
     * WARNING : PROPERTIES IN THIS MODEL DOES NO HAVE SAME MEANING THAT THOSE IN PROJECT MODEL
     */

    @PropertyMapper()
    public uuid;

    @PropertyMapper()
    public editable;

    @PropertyMapper()
    public reference;

    @PropertyMapper()
    public status;

    @PropertyMapper()
    public type;

    @PropertyMapper({ jsonProperty: 'project_id' })
    public projectId;

    @PropertyMapper({ jsonProperty: 'customer_name' })
    public customerName;

    @PropertyMapper({ jsonProperty: 'project_name' })
    public projectName;

    @PropertyMapper({ jsonProperty: 'project_manager_name' })
    public projectManagerName;

    @PropertyMapper({ jsonProperty: 'answers' })
    public answers;

    @PropertyMapper({ jsonProperty: 'answers' })
    public formattedAnswers;

    @PropertyMapper({ jsonProperty: 'receivers' })
    public receivers;

    @PropertyMapper({ jsonProperty: 'created_at', typeOfObject: Date })
    public createdAt: Date;

    @PropertyMapper({ jsonProperty: 'end', typeOfObject: Date })
    public end: Date;
}
