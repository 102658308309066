import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { projectFeedbackStatistics } from '../../../models/projectFeedbackStatistics.model';

@UntilDestroy()
@Component({
	templateUrl: './statistics-project-manager-feedback-v2.component.html',
	styleUrls: ['./statistics-project-manager-feedback-v2.component.scss']
})
export class StatisticsProjectManagerFeedbackV2Component implements OnInit, OnChanges {

	@Input() statistics: projectFeedbackStatistics[] = [];

	public q1Question = 'Vous êtes-vous senti suffisamment accompagné et/ou formé pour ce projet ?';
	public q2Question = 'Le cahier des charges a-t-il été respecté ?';
	public q3Question = 'Le client était-t-il à l\'écoute et disponible ?';
	public q4Question = 'Comment jugeriez-vous la coordination / communication interne dans l\'entreprise client ? Était-ce un obstacle au bon déroulement de la mission ?';
	public q5Question = 'Comment auriez-vous pu améliorer la relation avec le Client ?';
	public q6Question = 'Le dispositif de consultants était-il complet et suffisant ?';
	public q7Question = 'Les délais ont-ils été respectés par l\'ensemble des consultants ?';
	public q8Question = 'Votre équipe possédait-elle les compétences techniques nécessaires ?';
	public q9Question = 'La communication entre / avec les consultants était-elle suffisante ?';
	public q10Question = 'Comment auriez-vous pu améliorer votre management de la mission ?';
	public q11Question = 'Comment évaluez-vous votre charge de travail en ce moment ?';
	public q12Question = 'Avez-vous disposé de ressources suffisantes au cours de cette mission ?';
	public q13Question = 'A votre avis, relativement à la quantité de travail à fournir, la mission était...';

	constructor() {
	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges) {

	}
}
