import { PropertyMapper } from '../../core/decorators';
import { Customer, InvoiceStatus, MediaFile, Project } from '../../core/models';

export class BillingInvoice {

    @PropertyMapper({ jsonProperty: 'id' })
    public id: number;

    @PropertyMapper({ jsonProperty: 'type' })
    public type: number;

    @PropertyMapper({ jsonProperty: 'reference' })
    public reference: string;

    @PropertyMapper({ jsonProperty: 'status', typeOfObject: InvoiceStatus })
    public status: InvoiceStatus;

    @PropertyMapper({ jsonProperty: 'customer', typeOfObject: Customer })
    public customer: Customer;

    @PropertyMapper({ jsonProperty: 'project', typeOfObject: Project })
    public project: Project;

    @PropertyMapper({ jsonProperty: 'date', typeOfObject: Date })
    public date: Date;

    @PropertyMapper({ jsonProperty: 'orders' })
    public orders;

    @PropertyMapper({ jsonProperty: 'ht_amount' })
    public htAmount: number;

    @PropertyMapper({ jsonProperty: 'vat_amount' })
    public vatAmount: number;

    @PropertyMapper({ jsonProperty: 'ttc_amount' })
    public ttcAmount: number;

    @PropertyMapper({ jsonProperty: 'paid_amount' })
    public paidAmount: number;

    @PropertyMapper({ jsonProperty: 'sent_date', typeOfObject: Date })
    public sentDate: Date;

    @PropertyMapper({ jsonProperty: 'payment_delay' })
    public paymentDelay: number;

    @PropertyMapper({ jsonProperty: 'payments' })
    public payments;

    @PropertyMapper({ jsonProperty: 'reminders' })
    public reminders;

    @PropertyMapper({ jsonProperty: 'payment_date_estimated', typeOfObject: Date })
    public paymentDateEstimated: Date;

    @PropertyMapper({ jsonProperty: 'payment_date_actual', typeOfObject: Date })
    public paymentDateActual: Date;

    @PropertyMapper({ jsonProperty: 'notes' })
    public notes: string;

    @PropertyMapper({ jsonProperty: 'file', typeOfObject: MediaFile })
    public file: MediaFile;

    @PropertyMapper({ jsonProperty: 'formatted' })
    public formatted: any;

    @PropertyMapper({ jsonProperty: 'can_be_turned_into_credit' })
    public canBeTurnedIntoCredit;

    @PropertyMapper({ jsonProperty: 'refund_amount' })
    public refundAmount: number;

    public expand = false;
}
