import { PropertyMapper } from '../../decorators';
import { Supplier } from '../supplier/supplier.model';

export class LotSupplierLot {

	@PropertyMapper()
	public id: number;

	@PropertyMapper()
	public name: string;

	@PropertyMapper({ jsonProperty: 'assigned_fee' })
	public assignedFee: number;

	@PropertyMapper({ jsonProperty: 'reel_fee' })
	public reelFee: number;

	@PropertyMapper({ jsonProperty: 'ht_amount' })
	public htAmount: number;

	@PropertyMapper({ jsonProperty: 'supplier' })
	public supplier: Supplier;
}
