<nz-modal (nzOnCancel)="onCancelHandler();"
          [nzFooter]="null"
          [nzWidth]="650"
          [nzTitle]="modalTitleTemplate"
          [nzVisible]="visible">
	<ng-container *nzModalContent>
		<p>Une déclaration multiple permet de déclarer une activité avec la même durée sur un ou plusieurs jours.</p>
		<div class="declaration-type-container" nz-row style="margin-bottom: 15px;">
			<nz-radio-group [(ngModel)]="currentDeclarationType" class="declaration-radio-group" nzButtonStyle="solid">
				<label [nzValue]="this.declarationTypeEnum.ACTIVITY" class="declaration-type"
				       nz-radio-button>Activité</label>
				<label [nzValue]="this.declarationTypeEnum.EXPENSE_REPORT" class="declaration-type" nz-radio-button>
					Note de frais</label>
				<label [nzValue]="this.declarationTypeEnum.ABSENCE" class="declaration-type" nz-radio-button>Absence</label>
				<label [nzValue]="this.declarationTypeEnum.ACADEMIC_TRAINING" class="declaration-type" nz-radio-button>Formation Ac.</label>
			</nz-radio-group>
		</div>
		<activity-declaration-multi (closeEvent)="onCancelHandler();" *ngIf="currentDeclarationType === this.declarationTypeEnum.ACTIVITY">
		</activity-declaration-multi>
		<expense-report-declaration-multi (closeEvent)="onCancelHandler();" *ngIf="currentDeclarationType === this.declarationTypeEnum.EXPENSE_REPORT">
		</expense-report-declaration-multi>
		<absence-declaration-multi (closeEvent)="onCancelHandler();" *ngIf="currentDeclarationType === this.declarationTypeEnum.ABSENCE">
		</absence-declaration-multi>
		<academic-training-declaration-multi (closeEvent)="onCancelHandler()" *ngIf="currentDeclarationType === this.declarationTypeEnum.ACADEMIC_TRAINING">
		</academic-training-declaration-multi>
	</ng-container>

</nz-modal>
<ng-template #modalTitleTemplate>
	<div>
		<span>Faire une déclaration multiple</span>
	</div>
</ng-template>

