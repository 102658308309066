import { PropertyMapper } from '../../decorators';
import { DeclarationTypeEnum } from '../../enums';
import { Declaration } from '../declaration/declaration.model';

export class AcademicTraining extends Declaration {

    @PropertyMapper()
    public description: string;
    @PropertyMapper()
    public duration: string;

    constructor() {
        super();
        this.declarationType = DeclarationTypeEnum.ACADEMIC_TRAINING;
    }
}
