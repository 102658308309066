import { PropertyMapper } from '../../core/decorators';

export class CustomerStatistics {

    /**
     * WARNING : PROPERTIES IN THIS MODEL DOES NO HAVE SAME MEANING THAT THOSE IN PROJECT MODEL
     */

    @PropertyMapper()
    public id: number;

    @PropertyMapper()
    public name: string;

    @PropertyMapper()
    public bank: string;

    @PropertyMapper({ jsonProperty: 'business_sector' })
    public businessSector: string;

    @PropertyMapper({ jsonProperty: 'total_invoice_amount' })
    public totalInvoiceAmount: number;

    @PropertyMapper({ jsonProperty: 'total_credit_amount' })
    public totalCreditAmount: number;

    @PropertyMapper({ jsonProperty: 'total_turnover' })
    public totalTurnover: number;

    @PropertyMapper({ jsonProperty: 'total_expense' })
    public totalExpense: number;

    @PropertyMapper({ jsonProperty: 'expected_profit_on_completion' })
    public expectedProfitOnCompletion: number;
}
