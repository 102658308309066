import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { mergeMap, skipWhile } from 'rxjs/operators';
import { RouterService } from 'src/app/modules/core/services';
import { AuthStoreSelectors } from '../../root-store';
import { State } from '../../root-store/auth-store/state';

@Injectable()
export class UnauthorizationNeedGuard implements CanActivate {

    constructor(private routerService: RouterService,
                private store: Store<State>) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store.pipe(
            select(AuthStoreSelectors.selectIsAuthenticated),
            skipWhile(isAuthenticated => isAuthenticated === null),
            mergeMap((isAuthenticated) => {
                if (isAuthenticated) {
                    this.routerService.gotoDashboardHome();
                    return [false];
                }
                return [true];
            }));
    }
}
