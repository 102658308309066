<div nz-row nzJustify="space-between">
	<nz-breadcrumb nz-col nzSpan="16">
		<nz-breadcrumb-item>
			<a (click)="routerService.gotoDashboardHome();">Tableau de bord</a>
		</nz-breadcrumb-item>
		<nz-breadcrumb-item>
			<span>Mes déclarations</span>
		</nz-breadcrumb-item>
	</nz-breadcrumb>
</div>
<div nz-row nzJustify="space-between" style="margin-top: 10px;">
	<nz-alert (click)="displayCodeColorInfo = true;" class="calendar-tips"
	          nzMessage="Info sur les codes couleurs : Cliquez ici pour voir plus d'informations."
	          nzShowIcon nzType="info"></nz-alert>
	<div nz-row nzJustify="end" style="margin-right: 15px;">
		<nz-space>
			<button *nzSpaceItem (click)="displayMultiDeclaration = true;" nz-button
			        nz-tooltip nzTooltipTitle="Faire une déclaration multiple qui se répète plusieurs fois"
			        nzType="primary">
				<fa-icon [icon]="['far', 'plus']"></fa-icon>
				<span style="padding-left: 5px;">Déclaration multiple</span>
			</button>
			<button *nzSpaceItem (click)="generateActivitiesReporting()" nz-button
			        nz-tooltip nzTooltipTitle="Télécharger le reporting des activités du mois actuel"
			        nzType="primary">
				<fa-icon [icon]="['far', 'download']"></fa-icon>
				<span style="padding-left: 5px;">Reporting mensuel</span>
			</button>
		</nz-space>
	</div>
</div>
<nz-divider style="margin: 12px 0 0 0;"></nz-divider>
<nz-calendar (ngModelChange)="selectedDateChanged($event);"
             [ngModel]="selectedDate"
             [nzDateCell]="dateContentTemplate"
             class="declaration-calendar">
</nz-calendar>
<declaration-create-modal
		(closeEvent)="displayCreateModal = false;"
		[display]="displayCreateModal"></declaration-create-modal>

<ng-template #dateContentTemplate let-date>
	<declaration-calendar-cell (clickEvent)="clickOnDateCellHandler($event);" [date]="date"></declaration-calendar-cell>
</ng-template>
<declaration-create-multi-modal (closeEvent)="displayMultiDeclaration = false;"
                                [display]="displayMultiDeclaration"></declaration-create-multi-modal>
<nz-modal (nzOnCancel)="displayCodeColorInfo = false;"
          [(nzVisible)]="displayCodeColorInfo"
          [nzFooter]="null"
          [nzTitle]="'Informations sur les codes couleurs'"
          nzClassName="code-color-modal">
	<ng-container *nzModalContent>
		<div class="code-color-info">
			<div style="display: flex;padding: 10px 0;">
				<div class="color-code-square" style="background: #fff4e0;width: 30px;height: 30px;"></div>
				<div style="line-height: 30px;margin-left: 20px;">
					Declarations completes en attente de validation.
				</div>
			</div>
			<div style="display: flex;padding: 10px 0;">
				<div class="color-code-square" style="background: #d4ffd4;width: 30px;height: 30px;"></div>
				<div style="line-height: 30px;margin-left: 20px;">
					Toutes les declarations sont validés.
				</div>
			</div>
			<div style="display: flex;padding: 10px 0;">
				<div class="color-code-square" style="background: #ffccc7;width: 30px;height: 30px;"></div>
				<div style="line-height: 30px;margin-left: 20px;">Il y a au moins une declaration refusée.</div>
			</div>

			<div style="display: flex;padding: 10px 0;">
				<div class="color-code-circle"
				     style="background: #000000;width: 10px;height: 10px;margin: 10px;border-radius: 50%;"></div>
				<div style="line-height: 30px;margin-left: 20px;">
					Declaration d'une activite
				</div>
			</div>
			<div style="display: flex;padding: 10px 0;">
				<div class="color-code-circle"
				     style="background: #fa8c16;width: 10px;height: 10px;margin: 10px;border-radius: 50%;"></div>
				<div style="line-height: 30px;margin-left: 20px;">
					Declaration d'une note de frais
				</div>
			</div>
			<div style="display: flex;padding: 10px 0;">
				<div class="color-code-circle"
				     style="background: #f5222d;width: 10px;height: 10px;margin: 10px;border-radius: 50%;"></div>
				<div style="line-height: 30px;margin-left: 20px;">
					Declaration d'une absence
				</div>
			</div>
			<div style="display: flex;padding: 10px 0;">
				<div class="color-code-circle"
				     style="background: #bfbfbf;width: 10px;height: 10px;margin: 10px;border-radius: 50%;"></div>
				<div style="line-height: 30px;margin-left: 20px;">
					Declaration d'une activité interne A2L
				</div>
			</div>
		</div>
	</ng-container>
</nz-modal>
