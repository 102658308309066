import { PropertyMapper } from '../../decorators';

export class ProjectFeedback {

    @PropertyMapper()
    public id: number;

    @PropertyMapper()
    public version: number;

    @PropertyMapper()
    public answers;

    @PropertyMapper({ jsonProperty: 'answers' })
    public formattedAnswers;
}
