import { PropertyMapper } from '../../decorators';
import { MediaFile } from '../media/media-file.model';

export class ProjectAmendment {

    @PropertyMapper()
    id: number;

    @PropertyMapper()
    name: string;

    @PropertyMapper()
    type: string;

    @PropertyMapper({ jsonProperty: 'type_specify' })
    typeSpecify: string;

    @PropertyMapper({ jsonProperty: 'signature_date', typeOfObject: Date })
    signatureDate: Date;

    @PropertyMapper({ jsonProperty: 'file', typeOfObject: MediaFile })
    public file: MediaFile;
}
