<ng-template #notificationTemplate>
	<div class="ant-notification-notice-content">
		<div class="ant-notification-notice-with-icon">
			<span class="ant-notification-notice-icon">
				<fa-icon *ngIf="eventNotification.type === notificationTypeEnum.SUCCESS" [icon]="['far', 'check']" [size]="'lg'"
						 style="color: #52c41a"></fa-icon>
				<fa-icon *ngIf="eventNotification.type === notificationTypeEnum.ERROR" [icon]="['far', 'times']" [size]="'lg'"
						 style="color: #ff4d4f"></fa-icon>
			</span>
			<div class="ant-notification-notice-message">{{ eventNotification.title }}</div>
			<div class="ant-notification-notice-description">
				<ng-container *ngFor="let message of eventNotification.messages">
					<span>{{ message }}</span>
					<br>
				</ng-container>
			</div>
		</div>
	</div>
</ng-template>
