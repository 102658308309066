import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    ExpenseReportDeclarationForm,
    ExpenseReportDeclarationMultiForm,
    ExpenseReportListGetRequestForm
} from '../../../declaration/forms';
import { ExpenseReportType } from '../../models/expense-report/expense-report-type.model';
import { ExpenseReport } from '../../models/expense-report/expense-report.model';
import {
    __API_EXPENSE_REPORT_CREATE__,
    __API_EXPENSE_REPORT_CREATE_MULTI__,
    __API_EXPENSE_REPORT_DELETE__,
    __API_EXPENSE_REPORT_GET__,
    __API_EXPENSE_REPORT_LIST_BY_USER__,
    __API_EXPENSE_REPORT_MY_REPORT_LIST__,
    __API_EXPENSE_REPORT_TYPE_LIST__,
    __API_EXPENSE_REPORT_UPDATE__
} from '../../routes/api.route';
import { HelpService } from '../helper/help.service';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class ExpenseReportService {
    constructor(private httpRequestService: HttpRequestService) {
    }

    public getExpenseReportTypeList(): Observable<any> {
        return this.httpRequestService.get(__API_EXPENSE_REPORT_TYPE_LIST__).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ExpenseReportType, result.expense_report_type_list) as ExpenseReportType[];
        }));
    }

    public getMyExpenseReports(requestForm: ExpenseReportListGetRequestForm): Observable<any> {
        return this.httpRequestService.post(__API_EXPENSE_REPORT_MY_REPORT_LIST__, requestForm.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ExpenseReport, result.expense_reports) as ExpenseReport[];
        }));
    }

    public getMyExpenseReportsByUser(userId: number, requestForm: ExpenseReportListGetRequestForm): Observable<any> {
        const url = HelpService.format(__API_EXPENSE_REPORT_LIST_BY_USER__, userId);
        return this.httpRequestService.post(url, requestForm.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ExpenseReport, result.expense_reports) as ExpenseReport[];
        }));
    }

    public deleteExpenseReport(id: number): Observable<any> {
        const url = HelpService.format(__API_EXPENSE_REPORT_DELETE__, id);
        return this.httpRequestService.delete(url);
    }

    public getExpenseReport(id: number): Observable<any> {
        const url = HelpService.format(__API_EXPENSE_REPORT_GET__, id);
        return this.httpRequestService.get(url).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ExpenseReport, result.expense_report) as ExpenseReport;
        }));
    }

    public createExpenseReport(form: ExpenseReportDeclarationForm): Observable<any> {
        return this.httpRequestService.post(__API_EXPENSE_REPORT_CREATE__, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ExpenseReport, result.expense_report) as ExpenseReport;
        }));
    }

    public createMultiExpenseReport(form: ExpenseReportDeclarationMultiForm): Observable<any> {
        return this.httpRequestService.post(__API_EXPENSE_REPORT_CREATE_MULTI__, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ExpenseReport, result.expense_reports) as ExpenseReport[];
        }));
    }

    public updateExpenseReport(id: number, form: ExpenseReportDeclarationForm): Observable<any> {
        const url = HelpService.format(__API_EXPENSE_REPORT_UPDATE__, id);
        return this.httpRequestService.put(url, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(ExpenseReport, result.expense_report) as ExpenseReport;
        }));
    }
}
