import { PropertyMapper } from '../../decorators';
import { MediaFile } from '../media/media-file.model';

export class ProfilePostDescription {

    @PropertyMapper()
    public id: number;

    @PropertyMapper()
    public name: string;

    @PropertyMapper()
    public description: string;

    @PropertyMapper({ jsonProperty: 'files', typeOfObject: MediaFile })
    public files: MediaFile[];
}
