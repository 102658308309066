<form *ngIf="form" [formGroup]="form.validationForm" nz-form nzLayout="vertical">
	<nz-form-item *ngIf="!isCreateMode && status">
		<nz-form-control>
			<div [ngClass]="{   'validated': status.id === declarationStatusEnum.VALIDATED,
                                'denied': status.id === declarationStatusEnum.DENIED,
                                'waiting': status.id === declarationStatusEnum.WAITING_VALIDATION
                            }"
			     class="item-status">{{status.name}}</div>
		</nz-form-control>
	</nz-form-item>
	<div nz-row nzGutter="15">
		<div nz-col nzSpan="12">
			<nz-form-item>
				<nz-form-label nzRequired>Type</nz-form-label>
				<nz-form-control [nzErrorTip]="typeErrorTip">
					<nz-select [formControlName]="'type'" [nzDisabled]="locked">
						<nz-option *ngFor="let type of internalActivityTypes"
						           [nzLabel]="type.name" [nzValue]="type.id"></nz-option>
					</nz-select>
				</nz-form-control>
				<ng-template #typeErrorTip>
					<div *ngIf="form.validationForm.get('type').errors.required">
						Veuillez sélectionner le type d'activité
					</div>
				</ng-template>
			</nz-form-item>
		</div>
		<div nz-col nzSpan="12">
			<nz-form-item>
				<nz-form-label nzRequired>Durée</nz-form-label>
				<nz-form-control [nzErrorTip]="durationErrorTip">
					<nz-select [formControlName]="'duration'" [nzDisabled]="locked">
						<nz-option [nzLabel]="'0.25 jour'" [nzValue]="'0.25'"></nz-option>
						<nz-option [nzLabel]="'0.50 jour'" [nzValue]="'0.50'"></nz-option>
						<nz-option [nzLabel]="'0.75 jour'" [nzValue]="'0.75'"></nz-option>
						<nz-option [nzLabel]="'1.00 jour'" [nzValue]="'1.00'"></nz-option>
					</nz-select>
				</nz-form-control>
				<ng-template #durationErrorTip>
					<div *ngIf="form.validationForm.get('duration').errors.required">
						Veuillez sélectionner une durée
					</div>
				</ng-template>
			</nz-form-item>
		</div>
	</div>
	<nz-form-item style="padding-top: 5px;">
		<nz-form-label *ngIf="this.form.validationForm.get('type').value === this.internalActivityTypeEnum.OTHERS"
		               nzRequired>Description
		</nz-form-label>
		<nz-form-label *ngIf="this.form.validationForm.get('type').value !== this.internalActivityTypeEnum.OTHERS">
			Description
		</nz-form-label>
		<nz-form-control [nzErrorTip]="descriptionErrorTip">
			<textarea *ngIf="locked" [formControlName]="'description'" nz-input></textarea>
			<textarea *ngIf="!locked" [formControlName]="'description'" nz-input></textarea>
		</nz-form-control>
		<ng-template #descriptionErrorTip>
			<div *ngIf="form.validationForm.get('type').value === internalActivityTypeEnum.OTHERS && form.validationForm.get('description').errors.required">
				Veuillez saisir une description
			</div>
		</ng-template>
	</nz-form-item>
	<div nz-row nzGutter="15" nzJustify="end">
		<div class="action-buttons" nz-col nzSpan="24">
			<ng-container *ngIf="!locked">
				<ng-container *ngIf="!internalActivity.status">
					<button (click)="closeModalHandler();" *ngIf="!internalActivity.status" [nzType]="'default'"
					        nz-button>
						Annuler
					</button>
					<button (click)="sendDeclaration();" [nzType]="'primary'"
					        nz-button style="margin-left: 15px;">
						Confirmer
					</button>
				</ng-container>
				<ng-container
						*ngIf="internalActivity.status && internalActivity.status.id === declarationStatusEnum.WAITING_VALIDATION">
					<button (click)="sendDeclaration();" [nzType]="'primary'"
					        nz-button style="margin-left: 15px;">
						Mettre à jour
					</button>
				</ng-container>
			</ng-container>
		</div>
	</div>
</form>
