import { PropertyMapper } from '../../decorators';

export class MediaFile {
    @PropertyMapper()
    public id: number;

    @PropertyMapper()
    public uuid: string;

    @PropertyMapper()
    public type: number;

    @PropertyMapper()
    public name: string;

    @PropertyMapper()
    public url: string;

    @PropertyMapper({ jsonProperty: 'file_name' })
    public fileName: string;

    @PropertyMapper({ jsonProperty: 'mime_type' })
    public mimeType: string;

    @PropertyMapper({ jsonProperty: 'updated_at', typeOfObject: Date })
    public updatedAt: Date;
}
