import { PropertyMapper } from '../../decorators';

export class Role {

    @PropertyMapper()
    public id: number;

    @PropertyMapper()
    public name: string;
}
