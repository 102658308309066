export function PropertyMapper<T, Y>(...property: { dateFormat?: string, typeOfObject?: new () => T, typeResolver?: (data: any) => Y, jsonProperty: string }[]) {
    return function (target: any, propertyKey: string) {
        parent = Object.getPrototypeOf(target);
        if (parent.constructor.prototype.hasOwnProperty('_propertiesMapperMeta')
            && !target.constructor.prototype.hasOwnProperty('_propertiesMapperMeta')) {
            target.constructor.prototype._propertiesMapperMeta = parent.constructor.prototype._propertiesMapperMeta;
        }
        if (!target.constructor.prototype.hasOwnProperty('_propertiesMapperMeta')) {
            target.constructor.prototype._propertiesMapperMeta = [];
        }
        if (property.length <= 0) {
            property.push({ dateFormat: null, typeOfObject: null, jsonProperty: propertyKey, typeResolver: null });
        }
        target.constructor.prototype._propertiesMapperMeta
            .push({
                entityProperty: propertyKey,
                jsonProperties: property
            });
    };
}
