import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeliveryCreateForm, DeliveryLotUpdateForm, DeliveryUpdateForm } from '../../../project/forms';
import { DeliveryAssociateLotForm } from '../../../project/forms/delivery-associate-lot.form';
import { Delivery, DeliveryLot, Lot } from '../../models';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class DeliveryService {

    constructor(private httpRequestService: HttpRequestService) {
    }

    public createDelivery(projectId: number, form: DeliveryCreateForm): Observable<Delivery | null> {
        return this.httpRequestService.post(`project/${ projectId }/delivery/create`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Delivery, result.details) as Delivery;
        }));
    }

    public updateDelivery(projectId: number, deliveryId: number, form: DeliveryUpdateForm): Observable<Delivery | null> {
        return this.httpRequestService.put(`project/${ projectId }/delivery/${ deliveryId }/update`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Delivery, result.details) as Delivery;
        }));
    }

    public getDeliveryDetails(projectId: number, deliveryId: number): Observable<Delivery | any> {
        return this.httpRequestService.get(`project/${ projectId }/delivery/${ deliveryId }/detail`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Delivery, result.details) as Delivery;
        }));
    }

    public getDeliveryListByProject(projectId: number): Observable<any> {
        return this.httpRequestService.get(`project/${ projectId }/delivery/list`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Delivery, result.deliveries) as Delivery;
        }));
    }

    public getDeliveryListByLot(projectId: number, lotId: number): Observable<Delivery[] | any> {
        return this.httpRequestService.get(`project/${ projectId }/lot/${ lotId }delivery/list`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Delivery, result.deliveries) as Delivery;
        }));
    }

    public updateDeliveryStatus(projectId: number, deliveryId: number, status: number) {
        return this.httpRequestService.put(`project/${ projectId }/delivery/${ deliveryId }/status/update`, { 'status': status })
            .pipe(map((result) => {
                return this.httpRequestService.mapToModel(Delivery, result.details) as Delivery;
            }));
    }

    public associateLotToDelivery(projectId: number, deliveryId: number, lotId: number, form: DeliveryAssociateLotForm) {
        return this.httpRequestService.post(`project/${ projectId }/delivery/${ deliveryId }/associate/lot/${ lotId }`, form.toRequestForm())
            .pipe(map((result) => {
                return this.httpRequestService.mapToModel(Delivery, result.details) as Delivery;
            }));
    }

    public dissociateLotFromDelivery(projectId: number, deliveryId: number, lotId: number) {
        return this.httpRequestService.delete(`project/${ projectId }/delivery/${ deliveryId }/dissociate/lot/${ lotId }`)
            .pipe(map((result) => {
                return this.httpRequestService.mapToModel(Delivery, result.details) as Delivery;
            }));
    }

    public updateDeliveryLot(projectId: number, deliveryId: number, lotId: number, form: DeliveryLotUpdateForm) {
        return this.httpRequestService.put(`project/${ projectId }/delivery/${ deliveryId }/update/lot/${ lotId }`, form.toRequestForm())
            .pipe(map((result) => {
                return this.httpRequestService.mapToModel(Delivery, result.details) as Delivery;
            }));
    }

    public getAvailableLotsForDelivery(projectId: number, deliveryId: number) {
        return this.httpRequestService.get(`project/${projectId}/delivery/${deliveryId}/lot/available/list`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Lot, result.lots) as Lot[];
        }));
    }

    public getAssociatedLotsOfDelivery(projectId: number, deliveryId: number) {
        return this.httpRequestService.get(`project/${projectId}/delivery/${deliveryId}/lot/associated/list`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(DeliveryLot, result.lots) as DeliveryLot[];
        }));
    }

    public sendInvoiceInfoToAdministrativeUsers(projectId: number, deliveryId: number) {
        return this.httpRequestService.post(`project/${projectId}/delivery/${deliveryId}/send-invoice-request`, null).pipe(map((result) => {
            return result;
        }));
    }
}
