import { PropertyMapper } from '../../decorators';
import { Delivery } from '../delivery/delivery.model';
import { Lot } from '../lot/lot.model';
import { Project } from '../project/project.model';

export class ProjectSearchResult {

    @PropertyMapper({ jsonProperty: 'projects', typeOfObject: Project })
    public projects: Project[];

    @PropertyMapper({ jsonProperty: 'customers_projects', typeOfObject: Project })
    public customersProjects: Project[];

    @PropertyMapper({ jsonProperty: 'lots', typeOfObject: Lot })
    public lots: Lot[];

    @PropertyMapper({ jsonProperty: 'deliveries', typeOfObject: Delivery })
    public deliveries: Delivery[];
}
