import { NotificationTypeEnum } from '../../enums/notification-type.enum';

export class EventNotification {
    title: string;
    type: number;
    messages: string[];

    constructor(type: number = NotificationTypeEnum.SUCCESS, title: string, messages: string[] = []) {
        this.type = type;
        this.title = title;
        this.messages = messages;
    }
}
