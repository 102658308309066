import { v4 as uuid } from 'uuid';
import { PropertyMapper } from '../../decorators';
import { RequestTypeEnum } from '../../enums';
import { StatusBase } from '../status-base/status-base.model';

export class RequestBase {
    /**/
    public requestType: RequestTypeEnum;

    public uuid: string;

    @PropertyMapper({ jsonProperty: 'date', typeOfObject: Date })
    public date: Date;

    @PropertyMapper()
    public id: number;

    @PropertyMapper({ jsonProperty: 'status', typeOfObject: StatusBase })
    public status: StatusBase;

    constructor() {
        this.uuid = uuid();
    }

}
