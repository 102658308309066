import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { mergeMap, skipWhile } from 'rxjs/operators';
import { AuthStoreSelectors, AuthStoreState } from 'src/app/modules/root-store';
import { AuthService } from '../services';

@Injectable()
export class PermissionCheckGuard implements CanActivate {

    constructor(private authService: AuthService,
                private store: Store<AuthStoreState.State>) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.store.pipe(
            select(AuthStoreSelectors.selectIsAuthenticated),
            skipWhile(isAuthenticated => isAuthenticated === null),
            mergeMap(() => {
                const expectedPermissions = route.data.expectedPermissions;
                return this.authService.checkIfUserHavePermissionOf(expectedPermissions);
            })
        );
    }
}
