import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { interval, Subscription } from 'rxjs';
import { NotificationTypeEnum } from '../../../core/enums/notification-type.enum';
import { EventNotification } from '../../../core/models/notification/event-notification.model';
import { NotificationInteractionService } from '../../../core/services/interaction-service/notification-interaction.service';

@UntilDestroy()
@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

    @ViewChild('notificationTemplate', { static: true }) notificationTemplate: TemplateRef<{}>;
    public eventNotification: EventNotification = null;
    public notificationTypeEnum = NotificationTypeEnum;
    private successNotificationSubscription: Subscription = null;
    private errorNotificationSubscription: Subscription = null;

    constructor(private notificationService: NotificationInteractionService,
                private notification: NzNotificationService) {
    }

    ngOnInit(): void {
        interval(1000).pipe(untilDestroyed(this)).subscribe();
        this.successNotificationSubscription = this.notificationService.successNotification$.subscribe(
            (eventNotification: EventNotification) => {
                this.eventNotification = eventNotification;
                this.displayNotification(this.notificationTemplate);
            }
        );
        this.errorNotificationSubscription = this.notificationService.errorNotification$.subscribe(
            (eventNotification: EventNotification) => {
                this.eventNotification = eventNotification;
                this.displayNotification(this.notificationTemplate);
            }
        );
    }

    private displayNotification(template: TemplateRef<{}>): void {
        this.notification.template(template, { nzDuration: 3500 });
    }
}
