<form [formGroup]="form.validationForm" nz-form nzLayout="vertical">
	<nz-form-item>
		<nz-form-label nzRequired>Date</nz-form-label>
		<nz-form-control nzErrorTip=" Veuillez saisir la date de début et de fin">
			<nz-range-picker [formControlName]="'range'" nzFormat="dd/MM/yyyy"></nz-range-picker>
		</nz-form-control>
	</nz-form-item>
	<div nz-row nzGutter="15">
		<div nz-col nzSpan="12">
			<nz-form-item>
				<nz-form-label nzRequired>Projet</nz-form-label>
				<nz-form-control nzErrorTip="Veuillez saisir un projet">
					<nz-select (ngModelChange)="projectChangedHandler($event)" *ngIf="projects"
					           [formControlName]="'projectId'">
						<nz-option *ngFor="let project of projects" [nzLabel]="project.name"
						           [nzValue]="project.id"></nz-option>
					</nz-select>
				</nz-form-control>
			</nz-form-item>
		</div>
		<div nz-col nzSpan="12">
			<nz-form-item>
				<nz-form-label nzRequired>Lot</nz-form-label>
				<nz-form-control nzErrorTip="Veuillez saisir un lot de projet">
					<nz-select *ngIf="lots" [formControlName]="'lotId'">
						<nz-option *ngFor="let lot of lots" [nzLabel]="lot.name" [nzValue]="lot.id"></nz-option>
					</nz-select>
				</nz-form-control>
			</nz-form-item>
		</div>
	</div>
	<div nz-row nzGutter="15">
		<div nz-col nzSpan="12">
			<nz-form-item>
				<nz-form-label nzRequired>Durée</nz-form-label>
				<nz-form-control nzErrorTip="Veuillez préciser la durée de l'activié">
					<nz-select [formControlName]="'duration'">
						<nz-option [nzLabel]="'0.25 jour'" [nzValue]="'0.25'"></nz-option>
						<nz-option [nzLabel]="'0.50 jour'" [nzValue]="'0.50'"></nz-option>
						<nz-option [nzLabel]="'0.75 jour'" [nzValue]="'0.75'"></nz-option>
						<nz-option [nzLabel]="'1.00 jour'" [nzValue]="'1.00'"></nz-option>
					</nz-select>
				</nz-form-control>
			</nz-form-item>
		</div>
	</div>
	<nz-form-item>
		<nz-form-label>Description</nz-form-label>
		<nz-form-control>
			<textarea [formControlName]="'description'" nz-input></textarea>
		</nz-form-control>
	</nz-form-item>
	<div class="action-buttons" nz-row nzGutter="15" nzJustify="end">
		<div nz-col>
			<nz-space>
				<button *nzSpaceItem (click)="closeModelHandler();" [nzType]="'default'" nz-button>Annuler</button>
				<button *nzSpaceItem (click)="sendDeclaration();" [nzType]="'primary'"
				        [nzLoading]="isConfirmLoading" nz-button style="margin-left: 15px;">
					Confirmer
				</button>
			</nz-space>
		</div>
	</div>
</form>
