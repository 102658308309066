import { PropertyMapper } from '../../decorators';

export class CustomerBillingAddress {
    @PropertyMapper()
    public id: number;

    @PropertyMapper({ jsonProperty: 'customer_id' })
    public customerId: number;

    @PropertyMapper({ jsonProperty: 'legal_form' })
    public legalForm: number;

    @PropertyMapper({ jsonProperty: 'company' })
    public company: number;

    @PropertyMapper({ jsonProperty: 'name' })
    public name: number;

    @PropertyMapper({ jsonProperty: 'service' })
    public service: number;

    @PropertyMapper({ jsonProperty: 'address_line_1' })
    public addressLine1: string;

    @PropertyMapper({ jsonProperty: 'address_line_2' })
    public addressLine2: string;

    @PropertyMapper({ jsonProperty: 'zipcode' })
    public zipcode: string;

    @PropertyMapper({ jsonProperty: 'city' })
    public city: string;

    @PropertyMapper({ jsonProperty: 'country' })
    public country: string;
}
