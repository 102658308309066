import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzMessageService } from 'ng-zorro-antd/message';
import { interval, Subscription } from 'rxjs';
import { AcademicTraining, Project } from '../../../core/models';
import { AcademicTrainingService, DeclarationInteractionService, ProjectService } from '../../../core/services';
import { NotificationInteractionService } from '../../../core/services/interaction-service/notification-interaction.service';
import { AcademicTrainingDeclarationMultiForm } from '../../forms';

@UntilDestroy()
@Component({
    selector: 'academic-training-declaration-multi',
    templateUrl: './academic-training-declaration-multi.component.html',
    styleUrls: ['./academic-training-declaration-multi.component.scss']
})
export class AcademicTrainingDeclarationMultiComponent implements OnInit {

    @Output() public closeEvent: EventEmitter<any> = new EventEmitter();
    public form: AcademicTrainingDeclarationMultiForm = new AcademicTrainingDeclarationMultiForm();
    public projects: Project[] = [];
    private projectSubscription: Subscription = null;
    private createSubscription: Subscription = null;

    constructor(private academicTrainingService: AcademicTrainingService,
                private nzMessageService: NzMessageService,
                private notificationInteractionService: NotificationInteractionService,
                private declarationInteractionService: DeclarationInteractionService,
                private projectService: ProjectService) {

        this.projectSubscription = this.projectService.getProjectsAndLots().subscribe(projects => {
            this.projects = projects;
        });
    }

    ngOnInit() {
        interval(1000).pipe(untilDestroyed(this)).subscribe();
    }

    public sendDeclaration() {
        this.form.validate();
        if (this.form.validationForm.invalid) {
            this.nzMessageService.error('Veuillez remplir tous les champs obligatoires.');
            return;
        }

        this.createDeclarationMulti();
    }

    public closeModelHandler() {
        this.closeEvent.emit();
    }

    private createDeclarationMulti() {
        this.createSubscription = this.academicTrainingService.createMultiAcademicTraining(this.form).subscribe((academicTrainings: AcademicTraining[]) => {
            academicTrainings.forEach((item: AcademicTraining) => {
                this.declarationInteractionService.createAcademicTraining(item);
            });
            this.closeModelHandler();
            this.nzMessageService.success('Création réussie !');
            this.form = new AcademicTrainingDeclarationMultiForm();
        }, err => {
            if (err && err.messages) {
                this.nzMessageService.error(err.messages.join('\n'));
            }
        });
    }
}
