import { PropertyMapper } from '../../decorators';

export class ProjectIntervention {

    @PropertyMapper()
    id: number;

    @PropertyMapper()
    contributor: string;

    @PropertyMapper()
    comment: string;

    @PropertyMapper({ jsonProperty: 'date', typeOfObject: Date })
    date: Date;
}
