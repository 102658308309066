import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Lot } from '../../models';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class LotService {

    constructor(private httpRequestService: HttpRequestService) {
    }

    public getLotDetails(project_id: number, lot_id: number): Observable<any> {
        return this.httpRequestService.get(`project/${ project_id }/lot/${ lot_id }/detail`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Lot, result.details);
        }));
    }

    public generateNextLotForTA(project_id: number): Observable<any> {
        return this.httpRequestService.get(`project/${ project_id }/ta/lot/generate`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Lot, result.details);
        }));
    }
}
