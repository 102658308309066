import { PropertyMapper } from '../../decorators';
import { PermissionTypeEnum } from '../../enums';

export class Permission {

    @PropertyMapper()
    id: number;

    @PropertyMapper({ jsonProperty: 'name' })
    type: PermissionTypeEnum;
}
