import { PropertyMapper } from '../../decorators';

export class ActivityType {

    @PropertyMapper({ jsonProperty: 'id' })
    public id: number;

    @PropertyMapper({ jsonProperty: 'name' })
    public name: string;

}
