import { PropertyMapper } from '../../decorators';
import { MediaFile } from '../media/media-file.model';
import { ProjectOrderDownPayment } from './project-order-down-payment.model';

export class ProjectOrder {
    @PropertyMapper()
    public id: number;

    @PropertyMapper()
    public reference: string;

    @PropertyMapper({ jsonProperty: 'date', typeOfObject: Date })
    public date: Date;

    @PropertyMapper({ jsonProperty: 'ht_amount' })
    public htAmount: number;

    @PropertyMapper({ jsonProperty: 'ht_amount_optional' })
    public htAmountOptional: number;

    @PropertyMapper({ jsonProperty: 'ht_amount_assigned' })
    public htAmountAssigned: number;

    @PropertyMapper({ jsonProperty: 'ht_amount_remaining' })
    public htAmountRemaining: number;

    @PropertyMapper({ jsonProperty: 'vat_rate' })
    public vatRate: number;

    @PropertyMapper({ jsonProperty: 'ttc_amount' })
    public ttcAmount: number;

    @PropertyMapper({ jsonProperty: 'file', typeOfObject: MediaFile })
    public file: MediaFile;

    @PropertyMapper({ jsonProperty: 'down_payments', typeOfObject: ProjectOrderDownPayment })
    public downPayments: ProjectOrderDownPayment[];

    @PropertyMapper({ jsonProperty: 'down_payment_locked' })
    public downPaymentLocked: boolean;

    @PropertyMapper({ jsonProperty: 'deletable' })
    public deletable: boolean;
}
