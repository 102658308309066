import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserGuidePage } from '../../models';
import { __API_USER_GUIDE_PAGE__ } from '../../routes/api.route';
import { HelpService } from '../helper/help.service';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class UserGuideService {
    private userGuideMap = new Map<string, number[]>();
    private userGuideInitSubscription: Subscription = null;

    private userGuide: UserGuidePage[] = [];


    constructor(private httpRequestService: HttpRequestService,
                private readonly dompurifySanitizer: NgDompurifySanitizer,
                private sanitizer: DomSanitizer) {
        this.initUserGuide();
    }

    private initUserGuide() {
        this.userGuideMap.set('/home', [1, 2]);
        this.userGuideMap.set('/declaration', [1]);
    }

    public getUserGuideByUrl(url: string) {
        const pageIds = this.userGuideMap.get(url);
        this.userGuide = [];

        if (pageIds) {
            pageIds.forEach((pageId) => {
                this.userGuideInitSubscription = this.getUserGuideByPage(pageId).subscribe((result) => {
                    this.userGuide.push(result);
                });
            });
        }
        return this.userGuide;
    }

    public getUserGuideByPage(pageId: number): Observable<any> {
        const url = HelpService.format(__API_USER_GUIDE_PAGE__, pageId);
        return this.httpRequestService.get(url).pipe(map(result => {
            const page = result.page ? this.httpRequestService.mapToModel(UserGuidePage, result.page) as UserGuidePage : new UserGuidePage();
            page.html = this.sanitizer.bypassSecurityTrustHtml(this.dompurifySanitizer.sanitize(SecurityContext.HTML, page ? page.html : ''));
            return page;
        }));
    }
}
