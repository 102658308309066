import { PropertyMapper } from '../../decorators';
import { LotDisplayModeEnum } from '../../enums';
import { Delivery } from '../delivery/delivery.model';
import { ExpenseReportFeeInvoice } from '../expense-report/expense-report-fee-invoice.model';
import { Invoice } from '../invoice/invoice.model';
import { ProjectOrder } from '../project-order/project-order.model';
import { Project } from '../project/project.model';
import { LotStatus } from './lot-status.model';
import { LotType } from './lot-type.model';
import { LotUser } from './lotUser.model';

export class Lot {

    @PropertyMapper()
    public id: number;

    @PropertyMapper({ jsonProperty: 'project_id' })
    public projectId: number;

    @PropertyMapper({ jsonProperty: 'project', typeOfObject: Project })
    public project: Project;

    @PropertyMapper()
    public name: string;

    @PropertyMapper({ jsonProperty: 'type', typeOfObject: LotType })
    public type: LotType;

    @PropertyMapper({ jsonProperty: 'sub_type', typeOfObject: LotType })
    public subtype: LotType;

    @PropertyMapper({ jsonProperty: 'status', typeOfObject: LotStatus })
    public status: LotStatus;

    @PropertyMapper({ jsonProperty: 'start', typeOfObject: Date })
    public start: Date;

    @PropertyMapper({ jsonProperty: 'expected_end', typeOfObject: Date })
    public expectedEnd: Date;

    @PropertyMapper({ jsonProperty: 'end', typeOfObject: Date })
    public end: Date;

    @PropertyMapper({ jsonProperty: 'closing_date', typeOfObject: Date })
    public closingDate: Date;

    @PropertyMapper({ jsonProperty: 'assigned_fee' })
    public assignedFee: number;

    @PropertyMapper({ jsonProperty: 'reel_fee' })
    public reelFee: number;

    @PropertyMapper({ jsonProperty: 'assigned_workload' })
    public assignedWorkload: number;

    @PropertyMapper({ jsonProperty: 'reel_workload' })
    public reelWorkload: number;

    @PropertyMapper({ jsonProperty: 'expected_cost' })
    public expectedCost: number;

    @PropertyMapper({ jsonProperty: 'reel_cost' })
    public reelCost: number;

    @PropertyMapper()
    public comment: string;

    @PropertyMapper({ jsonProperty: 'ht_amount' })
    public HTAmount: number;

    @PropertyMapper({ jsonProperty: 'billable_fee' })
    public billableFee: boolean;

    @PropertyMapper({ jsonProperty: 'ht_amount_fee' })
    public HTAmountFee: number;

    @PropertyMapper({ jsonProperty: 'down_payment_total' })
    public downPaymentTotal: number;

    @PropertyMapper({ jsonProperty: 'ht_amount_after_down_payment' })
    public HTAmountAfterDownPayment: number;

    @PropertyMapper({ jsonProperty: 'ht_invoiced_amount_total' })
    public HTInvoicedAmountTotal: number;

    @PropertyMapper({ jsonProperty: 'ht_to_invoice_amount_total' })
    public HTToInvoiceAmountTotal: number;

    @PropertyMapper({ jsonProperty: 'order' })
    public order: ProjectOrder;

    @PropertyMapper({ jsonProperty: 'delivery', typeOfObject: Delivery })
    public delivery: Delivery;

    @PropertyMapper({ jsonProperty: 'invoice', typeOfObject: Invoice })
    public invoice: Invoice;

    @PropertyMapper({ jsonProperty: 'total_delivery_percent' })
    public totalDeliveryPercent: number;

    @PropertyMapper({ jsonProperty: 'total_delivery_percent_remaining' })
    public totalDeliveryPercentRemaining: number;

    @PropertyMapper({ jsonProperty: 'expense_reports', typeOfObject: ExpenseReportFeeInvoice })
    public expenseReports: ExpenseReportFeeInvoice[];

    @PropertyMapper({ jsonProperty: 'reel_profit' })
    public reelProfit;

    public displayMode = LotDisplayModeEnum.SIMPLE;

    /**
     * Used for activity declaration
     */
    @PropertyMapper({ jsonProperty: 'lot_user', typeOfObject: LotUser })
    public lotUser: LotUser;
}
