import { CustomerSite, Lot, MediaFile, UserCar } from '..';
import { PropertyMapper } from '../../decorators';
import { RequestTypeEnum } from '../../enums';
import { Project } from '../project/project.model';
import { RequestBase } from '../request-base/request-base.model';
import { ExpenseReportCategory } from './expense-report-category.model';
import { ExpenseReportType } from './expense-report-type.model';

export class ExpenseReportRequest extends RequestBase {

    @PropertyMapper()
    public amount: number;

    @PropertyMapper({ jsonProperty: 'category', typeOfObject: ExpenseReportCategory })
    public category: ExpenseReportCategory;

    @PropertyMapper()
    public description: string;

    @PropertyMapper({ jsonProperty: 'project', typeOfObject: Project })
    public project: Project;

    @PropertyMapper({ jsonProperty: 'lot', typeOfObject: Lot })
    public lot: Lot;

    @PropertyMapper({ jsonProperty: 'site', typeOfObject: CustomerSite })
    public site: CustomerSite;

    @PropertyMapper({ jsonProperty: 'type', typeOfObject: ExpenseReportType })
    public type: ExpenseReportType;

    @PropertyMapper({ jsonProperty: 'car', typeOfObject: UserCar })
    public car: UserCar;

    @PropertyMapper({ jsonProperty: 'mileage' })
    public mileage: number;

    @PropertyMapper({ jsonProperty: 'recoverable_vat' })
    public recoverableVat: number;

    @PropertyMapper({ jsonProperty: 'files', typeOfObject: MediaFile })
    public files: MediaFile[];

    @PropertyMapper({ jsonProperty: 'paid' })
    public paid: boolean;

    constructor() {
        super();
        this.requestType = RequestTypeEnum.EXPENSE_REPORT;
    }
}
