import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileUploadProjectAmendmentForm } from '../../../project/forms/file-upload-project-amendment.form';
import { FileUploadProjectPtfForm } from '../../../project/forms/file-upload-project-ptf.form';
import { ProjectInterventionCreateForm } from '../../../project/forms/project-intervention-create.form';
import { FileUploadForm } from '../../forms/file-upload.form';
import { MediaFile, ProjectAmendment } from '../../models';
import { ProjectIntervention } from '../../models/project/project-intervention.model';
import {
	__API_ABSENCE_FILE_DELETE__,
	__API_ABSENCE_FILE_UPLOAD__,
	__API_ADMIN_PROFILE_POST_DESCRIPTION_FILE_DELETE__,
	__API_ADMIN_PROFILE_POST_DESCRIPTION_FILE_UPLOAD__,
	__API_CONTACT_DATABASE_FILE_UPLOAD__,
	__API_CUSTOMER_FILE_DELETE__,
	__API_CUSTOMER_FILE_UPLOAD__,
	__API_EXPENSE_REPORT_FILE_DELETE__,
	__API_EXPENSE_REPORT_FILE_UPLOAD__,
	__API_EXPENSE_REPORT_REQUEST_FILE_DELETE__,
	__API_EXPENSE_REPORT_REQUEST_FILE_UPLOAD__,
	__API_MANAGE_USER_ANNUAl_ASSESSMENT_INTERVIEW_CREATE__,
	__API_MANAGE_USER_ANNUAl_ASSESSMENT_INTERVIEW_DELETE__,
	__API_PROJECT_AMENDMENT_CREATE__,
	__API_PROJECT_DELIVERY_FILE_UPLOAD__,
	__API_PROJECT_FILE_DELETE__,
	__API_PROJECT_FILE_UPLOAD__,
	__API_PROJECT_INTERVENTION_CREATE__,
	__API_PROJECT_ORDER_FILE_UPLOAD__,
	__API_PROJECT_PTF_UPLOAD__,
	__API_SUPPLIER_FILE_DELETE__,
	__API_SUPPLIER_FILE_UPLOAD__,
	__API_SUPPLIER_INVOICE_FILE_DELETE__,
	__API_SUPPLIER_INVOICE_FILE_UPLOAD__,
	__API_USER_FILE_DELETE__,
	__API_USER_FILE_UPLOAD__
} from '../../routes/api.route';
import { HelpService } from '../helper/help.service';
import { HttpRequestService } from '../http/http-request.service';
import { SupplierInvoice } from '../../models/supplier/supplier-invoice.model';
import { Supplier } from '../../models/supplier/supplier.model';

@Injectable()
export class FileService {

	constructor(private httpRequestService: HttpRequestService) {
	}

	/**
	 * CUSTOMERS FILES
	 */
	public uploadCustomerFile(customer_id, form: FileUploadForm): Observable<MediaFile[] | null> {
		const url = HelpService.format(__API_CUSTOMER_FILE_UPLOAD__, customer_id);
		return this.httpRequestService.post(url, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(MediaFile, result.details) as MediaFile[];
		}));
	}

	public deleteCustomerFile(customer_id, uuid): Observable<any | null> {
		const url = HelpService.format(__API_CUSTOMER_FILE_DELETE__, customer_id, uuid);
		return this.httpRequestService.delete(url).pipe(map((result) => {
			return result;
		}));
	}

	/**
	 * PROJECT FILES
	 */
	public uploadProjectFile(project_id, form: FileUploadForm): Observable<MediaFile[] | null> {
		const url = HelpService.format(__API_PROJECT_FILE_UPLOAD__, project_id);
		return this.httpRequestService.post(url, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(MediaFile, result.details) as MediaFile[];
		}));
	}

	public uploadProjectPtfFile(project_id, form: FileUploadProjectPtfForm): Observable<MediaFile | null> {
		const url = HelpService.format(__API_PROJECT_PTF_UPLOAD__, project_id);
		return this.httpRequestService.post(url, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(MediaFile, result.details) as MediaFile;
		}));
	}

	public uploadProjectAmendementFile(project_id, form: FileUploadProjectAmendmentForm): Observable<ProjectAmendment | null> {
		const url = HelpService.format(__API_PROJECT_AMENDMENT_CREATE__, project_id);
		return this.httpRequestService.post(url, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(ProjectAmendment, result.details) as ProjectAmendment;
		}));
	}

	public uploadProjectOrderFormFile(project_id, order_id, form: FileUploadForm): Observable<MediaFile | null> {
		const url = HelpService.format(__API_PROJECT_ORDER_FILE_UPLOAD__, project_id, order_id);
		return this.httpRequestService.post(url, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(MediaFile, result.details) as MediaFile;
		}));
	}

	public uploadProjectDeliveryFormFile(project_id, delivery_id, form: FileUploadForm): Observable<MediaFile | null> {
		const url = HelpService.format(__API_PROJECT_DELIVERY_FILE_UPLOAD__, project_id, delivery_id);
		return this.httpRequestService.post(url, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(MediaFile, result.details) as MediaFile;
		}));
	}

	public deleteProjectFile(project_id, uuid): Observable<any | null> {
		const url = HelpService.format(__API_PROJECT_FILE_DELETE__, project_id, uuid);
		return this.httpRequestService.delete(url).pipe(map((result) => {
			return result;
		}));
	}

	/**
	 * Project Intervention
	 */
	public createProjectIntervention(project_id, form: ProjectInterventionCreateForm): Observable<ProjectIntervention | null> {
		const url = HelpService.format(__API_PROJECT_INTERVENTION_CREATE__, project_id);
		return this.httpRequestService.post(url, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(ProjectIntervention, result.details) as ProjectIntervention;
		}));
	}

	/**
	 * USERS FILES
	 */
	public uploadUserFile(user_id, form: FileUploadForm): Observable<MediaFile[] | null> {
		const url = HelpService.format(__API_USER_FILE_UPLOAD__, user_id);
		return this.httpRequestService.post(url, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(MediaFile, result.details) as MediaFile[];
		}));
	}

	public deleteUserFile(user_id, uuid): Observable<any | null> {
		const url = HelpService.format(__API_USER_FILE_DELETE__, user_id, uuid);
		return this.httpRequestService.delete(url).pipe(map((result) => {
			return result;
		}));
	}

	public createUserAnnualAssessmentInterview(user_id, form: FileUploadForm): Observable<MediaFile[] | null> {
		const url = HelpService.format(__API_MANAGE_USER_ANNUAl_ASSESSMENT_INTERVIEW_CREATE__, user_id);
		return this.httpRequestService.post(url, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(MediaFile, result.details) as MediaFile[];
		}));
	}

	public deleteUserAnnualAssessmentInterview(user_id, user_annual_assessment_interview_id): Observable<any | null> {
		const url = HelpService.format(__API_MANAGE_USER_ANNUAl_ASSESSMENT_INTERVIEW_DELETE__, user_id, user_annual_assessment_interview_id);
		return this.httpRequestService.delete(url).pipe(map((result) => {
			return result;
		}));
	}

	/**
	 * Absence FILES
	 */
	public uploadAbsenceFile(absence_id, form: FileUploadForm): Observable<MediaFile[] | null> {
		const url = HelpService.format(__API_ABSENCE_FILE_UPLOAD__, absence_id);
		return this.httpRequestService.post(url, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(MediaFile, result.details) as MediaFile[];
		}));
	}

	public deleteAbsenceFile(absence_id, uuid): Observable<any | null> {
		const url = HelpService.format(__API_ABSENCE_FILE_DELETE__, absence_id, uuid);
		return this.httpRequestService.delete(url).pipe(map((result) => {
			return result;
		}));
	}

	/**
	 * Expense report FILES
	 */
	public uploadExpenseReportFile(expense_report_id, form: FileUploadForm): Observable<MediaFile[] | null> {
		const url = HelpService.format(__API_EXPENSE_REPORT_FILE_UPLOAD__, expense_report_id);
		return this.httpRequestService.post(url, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(MediaFile, result.details) as MediaFile[];
		}));
	}

	public deleteExpenseReportFile(expense_report_id, uuid): Observable<any | null> {
		const url = HelpService.format(__API_EXPENSE_REPORT_FILE_DELETE__, expense_report_id, uuid);
		return this.httpRequestService.delete(url).pipe(map((result) => {
			return result;
		}));
	}

	/**
	 * Expense report FILES
	 */
	public uploadExpenseReportRequestFile(expense_report_request_id, form: FileUploadForm): Observable<MediaFile[] | null> {
		const url = HelpService.format(__API_EXPENSE_REPORT_REQUEST_FILE_UPLOAD__, expense_report_request_id);
		return this.httpRequestService.post(url, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(MediaFile, result.details) as MediaFile[];
		}));
	}

	public deleteExpenseReportRequestFile(expense_report_request_id, uuid): Observable<any | null> {
		const url = HelpService.format(__API_EXPENSE_REPORT_REQUEST_FILE_DELETE__, expense_report_request_id, uuid);
		return this.httpRequestService.delete(url).pipe(map((result) => {
			return result;
		}));
	}

	/**
	 * Contacts database upload file
	 */
	public uploadContactDatabaseFile(form: FileUploadForm): Observable<any | null> {
		return this.httpRequestService.post(__API_CONTACT_DATABASE_FILE_UPLOAD__, form.toRequestForm())
			.pipe(map((result) => {
				return result;
			}));
	}

	/**
	 * Profile
	 */
	public uploadProfilePostDescriptionFile(profilePostDescriptionId, form: FileUploadForm): Observable<MediaFile[] | null> {
		const url = HelpService.format(__API_ADMIN_PROFILE_POST_DESCRIPTION_FILE_UPLOAD__, profilePostDescriptionId);
		return this.httpRequestService.post(url, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(MediaFile, result.details) as MediaFile[];
		}));
	}

	public deleteProfilePostDescriptionFile(user_id, uuid): Observable<any | null> {
		const url = HelpService.format(__API_ADMIN_PROFILE_POST_DESCRIPTION_FILE_DELETE__, user_id, uuid);
		return this.httpRequestService.delete(url).pipe(map((result) => {
			return result;
		}));
	}

	/**
	 * Supplier FILES
	 */
	public uploadSupplierFile(supplier_id, form: FileUploadForm): Observable<Supplier | null> {
		const url = HelpService.format(__API_SUPPLIER_FILE_UPLOAD__, supplier_id);
		return this.httpRequestService.post(url, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(Supplier, result.supplier) as Supplier;
		}));
	}

	public deleteSupplierFile(supplier_id, uuid): Observable<any | null> {
		const url = HelpService.format(__API_SUPPLIER_FILE_DELETE__, supplier_id, uuid);
		return this.httpRequestService.delete(url).pipe(map((result) => {
			return result;
		}));
	}


	/**
	 * Supplier INVOICES FILES
	 */
	public uploadSupplierInvoiceFile(supplier_invoice_id, form: FileUploadForm): Observable<SupplierInvoice | null> {
		const url = HelpService.format(__API_SUPPLIER_INVOICE_FILE_UPLOAD__, supplier_invoice_id);
		return this.httpRequestService.post(url, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(SupplierInvoice, result.supplier_invoice) as SupplierInvoice;
		}));
	}

	public deleteSupplierInvoiceFile(supplier_invoice_id, uuid): Observable<any | null> {
		const url = HelpService.format(__API_SUPPLIER_INVOICE_FILE_DELETE__, supplier_invoice_id, uuid);
		return this.httpRequestService.delete(url).pipe(map((result) => {
			return result;
		}));
	}


	/**
	 * UTILITIES FUNCTIONS
	 */
	public validatePdfOnly(file: File): boolean {
		const isPdf = file.type === 'application/pdf';
		if (!isPdf) {
			return false;
		}
		const isLt8M = file.size / 1024 / 1024 < 8;
		if (!isLt8M) {
			return false;
		}
	}

	public validateFileType(file: File): boolean {
		const isPdf = file.type === 'application/pdf';
		const isJpeg = file.type === 'image/jpeg';
		const isPng = file.type === 'image/png';
		if (!isPdf && !isJpeg && !isPng) {
			return false;
		}
		return file.size / 1024 / 1024 < 8;
	}
}
