import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EventNotification } from '../../models/notification/event-notification.model';

@Injectable()
export class NotificationInteractionService {

    // Observable sources
    private errorNotificationSource = new Subject<EventNotification>();
    private successNotificationSource = new Subject<EventNotification>();
    // Observable streams
    errorNotification$ = this.errorNotificationSource.asObservable();
    successNotification$ = this.successNotificationSource.asObservable();

    constructor() {
    }

    displayErrorNotification(notification: EventNotification) {
        this.errorNotificationSource.next(notification);
    }

    displaySuccessNotification(notification: EventNotification) {
        this.successNotificationSource.next(notification);
    }
}
