import { PropertyMapper } from '../../decorators';

export class LotSubtypeModel {

    @PropertyMapper()
    id: number;

    @PropertyMapper()
    name: string;
}
