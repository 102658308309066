import { PropertyMapper } from '../../decorators';

export class UserNotification {
    @PropertyMapper()
    id: number;

    @PropertyMapper({ jsonProperty: 'category' })
    category: number;

    @PropertyMapper({ jsonProperty: 'title' })
    title: string;

    @PropertyMapper({ jsonProperty: 'href' })
    href: string;

    @PropertyMapper({ jsonProperty: 'read' })
    read: number;

    @PropertyMapper({ jsonProperty: 'description' })
    description: string;

    @PropertyMapper({ jsonProperty: 'content' })
    content: string;

    @PropertyMapper({ jsonProperty: 'created_at', typeOfObject: Date })
    createdAt: Date;
}
