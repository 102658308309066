import { UserCar } from '..';
import { PropertyMapper } from '../../decorators';
import { DeclarationTypeEnum } from '../../enums';
import { Declaration } from '../declaration/declaration.model';
import { ExpenseReportCategory } from './expense-report-category.model';
import { ExpenseReportType } from './expense-report-type.model';

export class ExpenseReportFeeInvoice extends Declaration {

    @PropertyMapper()
    public amount: number;

    @PropertyMapper()
    public username: string;

    @PropertyMapper({ jsonProperty: 'category', typeOfObject: ExpenseReportCategory })
    public category: ExpenseReportCategory;

    @PropertyMapper()
    public description: string;

    @PropertyMapper({ jsonProperty: 'type', typeOfObject: ExpenseReportType })
    public type: ExpenseReportType;

    @PropertyMapper({ jsonProperty: 'car', typeOfObject: UserCar })
    public car: UserCar;

    @PropertyMapper({ jsonProperty: 'mileage' })
    public mileage: number;

    @PropertyMapper({ jsonProperty: 'recoverable_vat' })
    public recoverableVat: number;

    @PropertyMapper({ jsonProperty: 'paid' })
    public paid: boolean;

    constructor() {
        super();
        this.declarationType = DeclarationTypeEnum.EXPENSE_REPORT;
    }
}
