import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    CustomerBillingAddressCreateUpdateForm,
    CustomerContactCreateForm,
    CustomerCreateUpdateForm,
    CustomerListFilterForm
} from '../../../customer/forms';
import { CustomerSiteCreateUpdateForm } from '../../../customer/forms/customer-site-create-update.form';
import { Customer, CustomerBillingAddress, CustomerContact, CustomerSite } from '../../models';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class CustomerService {
    constructor(private httpRequestService: HttpRequestService) {
    }

    public getCustomerDetail(id: number): Observable<Customer | null> {
        return this.httpRequestService.get(`customer/${ id }/detail`).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Customer, result.customer) as Customer;
        }));
    }

    public getCustomerList(form: CustomerListFilterForm): Observable<Customer[] | null> {
        return this.httpRequestService.post('customer/list', form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(Customer, result.customers) as Customer[];
        }));
    }

    public createCustomer(form: CustomerCreateUpdateForm): Observable<Customer | null> {
        return this.httpRequestService.post('customer/create', form.toRequestForm()).pipe(map(result => {
            return this.httpRequestService.mapToModel(Customer, result.customer) as Customer;
        }));
    }

    public updateCustomer(id: number, form: CustomerCreateUpdateForm): Observable<Customer | null> {
        return this.httpRequestService.put(`customer/${ id }/update`, form.toRequestForm()).pipe(map(result => {
            return this.httpRequestService.mapToModel(Customer, result.customer) as Customer;
        }));
    }

    public deleteCustomer(customerId: number): Observable<any> {
        return this.httpRequestService.delete(`customer/${ customerId }/delete`);
    }

    /**
     * Customer contact
     */
    public createCustomerContact(customerId: number, form: CustomerContactCreateForm): Observable<CustomerContact | null> {
        return this.httpRequestService.post(`customer/${ customerId }/contact/create`, form.toRequestForm())
            .pipe(map(result => {
                return this.httpRequestService.mapToModel(CustomerContact, result.customer_contact) as CustomerContact;
            }));
    }

    public updateCustomerContact(customerId: number, contactId: number, form: CustomerContactCreateForm):
        Observable<CustomerContact | null> {
        return this.httpRequestService.put(`customer/${ customerId }/contact/${ contactId }/update`, form.toRequestForm())
            .pipe(map(result => {
                return this.httpRequestService.mapToModel(CustomerContact, result.customer_contact) as CustomerContact;
            }));
    }

    public deleteCustomerContact(customerId: number, contactId: number): Observable<any> {
        return this.httpRequestService.delete(`customer/${ customerId }/contact/${ contactId }/delete`);
    }

    public disableCustomerContact(customerId: number, contactId: number): Observable<any> {
        return this.httpRequestService.put(`customer/${ customerId }/contact/${ contactId }/disable`, {});
    }

    public enableCustomerContact(customerId: number, contactId: number): Observable<any> {
        return this.httpRequestService.put(`customer/${ customerId }/contact/${ contactId }/enable`, {});
    }

    /**
     * Customer site
     */
    public createCustomerSite(customerId: number, form: CustomerSiteCreateUpdateForm): Observable<CustomerSite | null> {
        return this.httpRequestService.post(`customer/${ customerId }/site/create`, form.toRequestForm())
            .pipe(map(result => {
                return this.httpRequestService.mapToModel(CustomerSite, result.site) as CustomerSite;
            }));
    }

    public updateCustomerSite(customerId: number, siteId: number, form: CustomerSiteCreateUpdateForm): Observable<CustomerSite | null> {
        return this.httpRequestService.put(`customer/${ customerId }/site/${ siteId }/update`, form.toRequestForm())
            .pipe(map(result => {
                return this.httpRequestService.mapToModel(CustomerSite, result.site) as CustomerSite;
            }));
    }

    public deleteCustomerSite(customerId: number, siteId: number): Observable<any> {
        return this.httpRequestService.delete(`customer/${ customerId }/site/${ siteId }/delete`);
    }

    public disableSiteContact(customerId: number, siteId: number): Observable<any> {
        return this.httpRequestService.put(`customer/${ customerId }/site/${ siteId }/disable`, {});
    }

    public enableSiteContact(customerId: number, siteId: number): Observable<any> {
        return this.httpRequestService.put(`customer/${ customerId }/site/${ siteId }/enable`, {});
    }

    /**
     * Customer billing address
     */
    public createCustomerBillingAddress(customerId: number, form: CustomerBillingAddressCreateUpdateForm): Observable<CustomerBillingAddress | null> {
        return this.httpRequestService.post(`customer/${ customerId }/billing-address/create`, form.toRequestForm())
            .pipe(map(result => {
                return this.httpRequestService.mapToModel(CustomerBillingAddress, result.billing_address) as CustomerBillingAddress;
            }));
    }

    public updateCustomerBillingAddress(customerId: number, billingAddressId: number,
                                        form: CustomerBillingAddressCreateUpdateForm): Observable<CustomerBillingAddress | null> {
        return this.httpRequestService.put(`customer/${ customerId }/billing-address/${ billingAddressId }/update`, form.toRequestForm())
            .pipe(map(result => {
                return this.httpRequestService.mapToModel(CustomerBillingAddress, result.billing_address) as CustomerBillingAddress;
            }));
    }

    public deleteCustomerBillingAddress(customerId: number, billingAddressId: number): Observable<any> {
        return this.httpRequestService.delete(`customer/${ customerId }/billing-address/${ billingAddressId }/delete`);
    }

    /**
     * Contact database list
     */
    public getContactDatabaseList(): Observable<CustomerContact[] | null> {
        return this.httpRequestService.post('contact/list', {}).pipe(map((result) => {
            return this.httpRequestService.mapToModel(CustomerContact, result.contacts) as CustomerContact[];
        }));
    }
}
