<form *ngIf="form" [formGroup]="form.validationForm" nz-form nzLayout="vertical">
	<nz-form-item *ngIf="!isCreateMode && status">
		<nz-form-control>
			<div [ngClass]="{   'validated': status.id === declarationStatusEnum.VALIDATED,
                                'denied': status.id === declarationStatusEnum.DENIED,
                                'waiting': status.id === declarationStatusEnum.WAITING_VALIDATION
                            }"
			     class="item-status">{{status.name}}</div>
		</nz-form-control>
	</nz-form-item>
	<div nz-row nzGutter="15">
		<div nz-col nzSpan="12">
			<nz-form-item>
				<nz-form-label nzRequired>Durée</nz-form-label>
				<nz-form-control>
					<nz-select [formControlName]="'duration'" [nzDisabled]="locked">
						<nz-option [nzLabel]="'0.50 jour'" [nzValue]="'0.50'"></nz-option>
						<nz-option [nzLabel]="'1.00 jour'" [nzValue]="'1.00'"></nz-option>
					</nz-select>
				</nz-form-control>
			</nz-form-item>
		</div>
	</div>
	<div nz-row nzGutter="15">
		<div nz-col nzSpan="24">
			<nz-form-item>
				<nz-form-label style="padding-top: 5px;">Description</nz-form-label>
				<nz-form-control>
					<textarea *ngIf="locked" [formControlName]="'description'" nz-input></textarea>
					<textarea *ngIf="!locked" [formControlName]="'description'" nz-input></textarea>
				</nz-form-control>
			</nz-form-item>
		</div>
	</div>
	<div nz-row nzGutter="15" nzJustify="end">
		<div class="action-buttons" nz-col nzSpan="24">
			<ng-container *ngIf="!locked">
				<ng-container *ngIf="!academicTraining.status">
					<button (click)="closeModalHandler();" *ngIf="!academicTraining.status" [nzType]="'default'" nz-button>
						Annuler
					</button>
					<button (click)="sendDeclaration();" [nzType]="'primary'"
					        nz-button style="margin-left: 15px;">
						Confirmer
					</button>
				</ng-container>
				<ng-container
						*ngIf="academicTraining.status && academicTraining.status.id === declarationStatusEnum.WAITING_VALIDATION">
					<button (click)="sendDeclaration();" [nzType]="'primary'"
					        nz-button style="margin-left: 15px;">
						Mettre à jour
					</button>
				</ng-container>
			</ng-container>
		</div>
	</div>
</form>
