import { PropertyMapper } from '../../core/decorators';

export class UserStatistics {

    /**
     * WARNING : PROPERTIES IN THIS MODEL DOES NO HAVE SAME MEANING THAT THOSE IN PROJECT MODEL
     */

    @PropertyMapper()
    public id: number;

    @PropertyMapper({ jsonProperty: 'first_name' })
    public firstName: string;

    @PropertyMapper({ jsonProperty: 'last_name' })
    public lastName: string;

    @PropertyMapper({ jsonProperty: 'project_activity_count' })
    public projectActivityCount;

    @PropertyMapper({ jsonProperty: 'internal_activity_count' })
    public internalActivityCount;

    @PropertyMapper({ jsonProperty: 'academic_activity_count' })
    public academicActivityCount;

    @PropertyMapper({ jsonProperty: 'absence_count' })
    public absenceCount;

    @PropertyMapper({ jsonProperty: 'project_expense' })
    public projectExpense;

    @PropertyMapper({ jsonProperty: 'internal_expense' })
    public internalExpense;
}
