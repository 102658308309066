import { User } from '..';
import { PropertyMapper } from '../../decorators';
import { RequestTypeEnum } from '../../enums';
import { RequestBase } from '../request-base/request-base.model';
import { AbsenceType } from './absence-type.model';

export class AbsenceRequest extends RequestBase {

    @PropertyMapper()
    public user: User;

    @PropertyMapper({ jsonProperty: 'start', typeOfObject: Date })
    public start: Date;

    @PropertyMapper({ jsonProperty: 'end', typeOfObject: Date })
    public end: Date;

    @PropertyMapper({ jsonProperty: 'start_period' })
    public start_period: number;

    @PropertyMapper({ jsonProperty: 'end_period' })
    public end_period: number;

    @PropertyMapper({ jsonProperty: 'type', typeOfObject: AbsenceType })
    public type: AbsenceType;

    @PropertyMapper()
    public description: string;

    constructor() {
        super();
        this.requestType = RequestTypeEnum.ABSENCE;
    }
}
