import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
    SurveyCustomerProjectSatisfactionV2Component
} from '../../../survey/components/survey/survey-customer-project-satisfaction-v2/survey-customer-project-satisfaction-v2.component';
import {
    SurveyCustomerProjectSatisfactionComponent
} from '../../../survey/components/survey/survey-customer-project-satisfaction/survey-customer-project-satisfaction.component';
import { SurveyItem } from '../../../survey/models/surveyItem';
import { SurveyEnum } from '../../enums/survey.enum';
import { Survey } from '../../models';
import { HttpRequestService } from '../http/http-request.service';
import {
    SurveyUserProjectSatisfactionV1Component
} from '../../../survey/components/survey/survey-user-project-satisfaction-v1/survey-user-project-satisfaction-v1.component';

@Injectable()
export class SurveyService {

    private surveyDict = [
        {
            reference: SurveyEnum.PUBLIC_CUSTOMER_PROJECT_SATISFACTION,
            component: SurveyCustomerProjectSatisfactionComponent
        },
        {
            reference: SurveyEnum.PUBLIC_CUSTOMER_PROJECT_SATISFACTION_V2,
            component: SurveyCustomerProjectSatisfactionV2Component
        },
        {
            reference: SurveyEnum.PUBLIC_USER_PROJECT_SATISFACTION_V1,
            component: SurveyUserProjectSatisfactionV1Component
        }
    ];

    constructor(private httpRequestService: HttpRequestService) {
    }

    public getSurvey(reference, data) {
        let survey = this.surveyDict.find((item, index) => {
            return item.reference === reference;
        });
        return new SurveyItem(survey.component, data);
    }

    public getSurveyByUuid(surveyUuid) {
        return this.httpRequestService.get(`survey/${ surveyUuid }`)
            .pipe(map(result => {
                return this.httpRequestService.mapToModel(Survey, result.survey) as Survey;
            }));
    }

    public fillSurvey(surveyUuid, data) {
        return this.httpRequestService.post(`survey/${ surveyUuid }`, data);
    }

    /**
     * @param data
     */
    public sendSurvey(data) {
        return this.httpRequestService.post(`survey/send/survey`, data);
    }
}
