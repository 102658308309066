import { PropertyMapper } from '../../decorators';
import { CustomerContact } from '../customer/customer-contact.model';
import { CustomerSite } from '../customer/customer-site.model';
import { Customer } from '../customer/customer.model';
import { Lot } from '../lot/lot.model';
import { MediaFileProjectPtf } from '../media/media-file-project-ptf.model';
import { MediaFile } from '../media/media-file.model';
import { Survey } from '../survey/survey.model';
import { User } from '../user/user.model';
import { ProjectAmendment } from './project-amendment.model';
import { ProjectIntervention } from './project-intervention.model';
import { ProjectStatus } from './project-status.model';
import { ProjectType } from './project-type.model';

export class Project {

    @PropertyMapper()
    public id: number;

    @PropertyMapper()
    public name: string;

    @PropertyMapper()
    public reference: string;

    @PropertyMapper({ jsonProperty: 'type', typeOfObject: ProjectType })
    public type: ProjectType;

    @PropertyMapper()
    public origin: string;

    @PropertyMapper({ jsonProperty: 'origin_specify' })
    public originSpecify: string;

    @PropertyMapper({ jsonProperty: 'first_contact', typeOfObject: Date })
    public firstContact: Date;

    @PropertyMapper({ jsonProperty: 'ptf_sending_date', typeOfObject: Date })
    public ptfSendingDate: Date;

    @PropertyMapper()
    public lots: Lot[];

    @PropertyMapper({ jsonProperty: 'customer_direct', typeOfObject: Customer })
    public customerDirect: Customer;

    @PropertyMapper({ jsonProperty: 'customer_final', typeOfObject: Customer })
    public customerFinal: Customer;

    @PropertyMapper({ jsonProperty: 'sites', typeOfObject: CustomerSite })
    public customerSites: CustomerSite[];

    @PropertyMapper({ jsonProperty: 'manager', typeOfObject: User })
    public manager: User;

    @PropertyMapper({ jsonProperty: 'status', typeOfObject: ProjectStatus })
    public status: ProjectStatus;

    @PropertyMapper({ jsonProperty: 'offer_date', typeOfObject: Date })
    public offerDate: Date;

    @PropertyMapper({ jsonProperty: 'offer_reference' })
    public offerReference: string;

    @PropertyMapper({ jsonProperty: 'expected_result' })
    public expectedResult: number;

    @PropertyMapper({ jsonProperty: 'expected_turnover' })
    public expectedTurnover: number;

    @PropertyMapper({ jsonProperty: 'expected_turnover_optional' })
    public expectedTurnoverOptional: number;

    @PropertyMapper({ jsonProperty: 'expected_workload' })
    public expectedWorkload: number;

    @PropertyMapper({ jsonProperty: 'expected_fee' })
    public expectedFee: number;

    @PropertyMapper({ jsonProperty: 'expected_cost' })
    public expectedCost: number;

    @PropertyMapper({ jsonProperty: 'resulted_workload' })
    public resultedAmount: number;

    @PropertyMapper({ jsonProperty: 'resulted_amount' })
    public resultedWorkload: number;

    @PropertyMapper({ jsonProperty: 'elongation_in_months' })
    public elongationInMonths: number;

    @PropertyMapper({ jsonProperty: 'start', typeOfObject: Date })
    public start: Date;

    @PropertyMapper({ jsonProperty: 'end', typeOfObject: Date })
    public end: Date;

    @PropertyMapper({ jsonProperty: 'production_date', typeOfObject: Date })
    public productionDate: Date;

    @PropertyMapper({ jsonProperty: 'closing_date', typeOfObject: Date })
    public closingDate: Date;

    @PropertyMapper({ jsonProperty: 'research_tax_credit_percent' })
    public researchTaxCreditPercent: number;

    @PropertyMapper({ jsonProperty: 'payment_deadline_in_days' })
    public paymentDeadlineInDays: number;

    @PropertyMapper()
    public feedback;

    @PropertyMapper({ jsonProperty: 'customer_satisfaction' })
    public customerSatisfaction: string;

    @PropertyMapper({ jsonProperty: 'customer_satisfaction_note' })
    public customerSatisfactionNote: number;

    @PropertyMapper({ jsonProperty: 'purchase_contacts', typeOfObject: CustomerContact })
    public purchaseContacts: CustomerContact[];

    @PropertyMapper({ jsonProperty: 'technical_contacts', typeOfObject: CustomerContact })
    public technicalContacts: CustomerContact[];

    @PropertyMapper({ jsonProperty: 'accounting_contacts', typeOfObject: CustomerContact })
    public accountingContacts: CustomerContact[];

    @PropertyMapper({ jsonProperty: 'files', typeOfObject: MediaFile })
    public files: MediaFile[];

    @PropertyMapper({ jsonProperty: 'ptf', typeOfObject: MediaFileProjectPtf })
    public ptf: MediaFileProjectPtf[];

    @PropertyMapper({ jsonProperty: 'amendments', typeOfObject: ProjectAmendment })
    public amendments: ProjectAmendment[];

    @PropertyMapper({ jsonProperty: 'interventions', typeOfObject: ProjectIntervention })
    public interventions: ProjectIntervention[];

    @PropertyMapper({ jsonProperty: 'reel_profit' })
    public reelProfit;

    @PropertyMapper({ jsonProperty: 'profit_indicator' })
    public profitIndicator;

    @PropertyMapper({ jsonProperty: 'survey', typeOfObject: Survey })
    public survey;

    /**
     * Internal project
     */
    @PropertyMapper({ jsonProperty: 'expected_budget' })
    public expectedBudget;
}
