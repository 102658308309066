import { PropertyMapper } from '../../core/decorators';
import { Credit, Customer, DeliveryStatus, DeliveryType, Invoice, MediaFile, Project } from '../../core/models';
import { ExpenseReport } from '../../core/models/expense-report/expense-report.model';

export class BillingDelivery {

    @PropertyMapper()
    public id: number;

    @PropertyMapper({ jsonProperty: 'type', typeOfObject: DeliveryType })
    public type: DeliveryType;

    @PropertyMapper({ jsonProperty: 'status', typeOfObject: DeliveryStatus })
    public status: DeliveryStatus;

    @PropertyMapper({ jsonProperty: 'internal_reference' })
    public internalReference: string;

    @PropertyMapper({ jsonProperty: 'customer_reference' })
    public customerReference: string;

    @PropertyMapper({ jsonProperty: 'expected_delivery_date', typeOfObject: Date })
    public expectedDeliveryDate: Date;

    @PropertyMapper({ jsonProperty: 'reel_delivery_date', typeOfObject: Date })
    public reelDeliveryDate: Date;

    @PropertyMapper({ jsonProperty: 'delivery_slip_sending_date', typeOfObject: Date })
    public deliverySlipSendingDate: Date;

    @PropertyMapper({ jsonProperty: 'delivery_slip_signature_date', typeOfObject: Date })
    public deliverySlipSignatureDate: Date;

    @PropertyMapper({ jsonProperty: 'to_bill_without_signature' })
    public toBillWithoutSignature: boolean;

    @PropertyMapper({ jsonProperty: 'expense_reports', typeOfObject: ExpenseReport })
    public expenseReports: ExpenseReport[];

    @PropertyMapper({ jsonProperty: 'ht_amount' })
    public HTAmount: number;

    @PropertyMapper({ jsonProperty: 'orders' })
    public orders;

    @PropertyMapper({ jsonProperty: 'invoice', typeOfObject: Invoice })
    public invoice: Invoice;

    @PropertyMapper({ jsonProperty: 'credit', typeOfObject: Credit })
    public credit: Credit;

    @PropertyMapper({ jsonProperty: 'file', typeOfObject: MediaFile })
    public file: MediaFile;

    @PropertyMapper({ jsonProperty: 'project', typeOfObject: Project })
    public project: Project;

    @PropertyMapper({ jsonProperty: 'customer_direct', typeOfObject: Customer })
    public customerDirect: Customer;

    @PropertyMapper({ jsonProperty: 'customer_final', typeOfObject: Customer })
    public customerFinal: Customer;

    @PropertyMapper({ jsonProperty: 'formatted' })
    public formatted: any;

    public expand = false;
}
