import { PropertyMapper } from '../../decorators';
import { Profile } from '../profile/profile.model';

export class UserProfilePreFill {

    @PropertyMapper({ jsonProperty: 'start', typeOfObject: Date })
    public start: Date;

    @PropertyMapper({ jsonProperty: 'profiles', typeOfObject: Profile })
    public profiles: Profile[];
}
