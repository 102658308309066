<div nz-row nzGutter="16" nzJustify="space-between" class="table-container">
    <nz-table #table [nzData]="statistics"
              nzSize="small"
              nzPageSize="50"
              nzPaginationPosition="both"
              class="project-list-table">
        <thead>
        <tr>
            <th>Client</th>
            <th>Projet</th>
            <th>Chef de projet</th>
            <th>Date de clôture</th>
            <th>{{ q1Question }}</th>
            <th>{{ q2Question }}</th>
            <th>{{ q3Question }}</th>
            <th>{{ q4Question }}</th>
            <th>{{ q5Question }}</th>
            <th>{{ q6Question }}</th>
            <th>{{ q7Question }}</th>
            <th>{{ q8Question }}</th>
            <th>{{ q9Question }}</th>
            <th>{{ q10Question }}</th>
            <th>{{ q11Question }}</th>
            <th>{{ q12Question }}</th>
            <th>{{ q13Question }}</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let data of table.data">
            <tr *ngIf="data.feedback.version === 3">
                <td>{{ data.customerName }}</td>
                <td>{{ data.projectName }}</td>
                <td>{{ data.projectManagerName }}</td>
                <td>{{ data.closingDate | date: 'dd/MM/yyyy'}}</td>
                <ng-container *ngIf="data.feedback.formattedAnswers">
                    <td>{{ data.feedback.formattedAnswers.q1 }}</td>
                    <td>{{ data.feedback.formattedAnswers.q2 }}</td>
                    <td>{{ data.feedback.formattedAnswers.q3 }}</td>
                    <td>{{ data.feedback.formattedAnswers.q4 }}</td>
                    <td>{{ data.feedback.formattedAnswers.q5 }}</td>
                    <td>{{ data.feedback.formattedAnswers.q6 }}</td>
                    <td>{{ data.feedback.formattedAnswers.q7 }}</td>
                    <td>{{ data.feedback.formattedAnswers.q8 }}</td>
                    <td>{{ data.feedback.formattedAnswers.q9 }}</td>
                    <td>{{ data.feedback.formattedAnswers.q10 }}</td>
                    <td>{{ data.feedback.formattedAnswers.q11 }}</td>
                    <td>{{ data.feedback.formattedAnswers.q12 }}</td>
                    <td>{{ data.feedback.formattedAnswers.q13 }}</td>
                </ng-container>
                <ng-container *ngIf="!data.feedback.formattedAnswers">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </ng-container>
            </tr>
        </ng-container>
        </tbody>
    </nz-table>
</div>

