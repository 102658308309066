import { Injectable, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { interval, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AuthStoreSelectors, AuthStoreState } from 'src/app/modules/root-store';
import { LoginForm, PasswordRemindForm } from '../../../auth/forms';
import { PasswordResetForm } from '../../../auth/forms/password-reset.form';
import { PermissionTypeEnum } from '../../enums';
import { AuthModel, Permission, Role } from '../../models';
import {
	__API_CURRENT_USER_PERMISSIONS__,
	__API_CURRENT_USER_ROLE_,
	__API_LOGIN__,
	__API_LOGOUT__,
	__API_PASSWORD_REMIND__,
	__API_PASSWORD_RESET__,
	__API_PASSWORD_TOKEN_VALIDATE__,
	__API_REFRESH_TOKEN__,
	__API_USER_SWITCH_PROFILE_
} from '../../routes/api.route';
import { HttpRequestService } from '../http/http-request.service';
import { LocalStorageService } from '../storage/local-storage.service';

@UntilDestroy()
@Injectable()
export class AuthService implements OnInit {

	constructor(private localStorageService: LocalStorageService,
	            private store: Store<AuthStoreState.State>,
	            private httpRequestService: HttpRequestService) {

	}

	ngOnInit() {
		interval(1000).pipe(untilDestroyed(this)).subscribe();
	}

	public getAuthToken() {
		return this.localStorageService.getToken();
	}

	public setAuthToken(token: string) {
		this.localStorageService.setToken(token);
	}

	public clearSession() {
		this.localStorageService.clear();
	}

	public refreshToken(): Observable<any> {
		return this.httpRequestService.get(__API_REFRESH_TOKEN__).pipe(map((result) => {
			return this.httpRequestService.mapToModel(AuthModel, result.user);
		}));
	}

	public login(form: LoginForm): Observable<any> {
		return this.httpRequestService.post(__API_LOGIN__, form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(AuthModel, result.user);
		}));
	}

	public logout(): Observable<any> {
		return this.httpRequestService.get(__API_LOGOUT__);
	}

	public validatePasswordResetToken(token): Observable<any> {
		return this.httpRequestService.post(__API_PASSWORD_TOKEN_VALIDATE__, { token: token });
	}

	public passwordRemind(form: PasswordRemindForm): Observable<any> {
		return this.httpRequestService.post(__API_PASSWORD_REMIND__, form.toRequestForm());
	}

	public passwordReset(form: PasswordResetForm): Observable<any> {
		return this.httpRequestService.post(__API_PASSWORD_RESET__, form.toRequestForm());
	}

	public getPermissions() {
		return this.httpRequestService.get(__API_CURRENT_USER_PERMISSIONS__).pipe(map(result => {
			return this.httpRequestService.mapToModel(Permission, result.permissions) as Permission[];
		}));
	}

	public getRole() {
		return this.httpRequestService.get(__API_CURRENT_USER_ROLE_).pipe(map(result => {
			return this.httpRequestService.mapToModel(Role, result.role) as Role;
		}));
	}

	public getProfile() {
		return this.httpRequestService.get(__API_CURRENT_USER_ROLE_).pipe(map(result => {
			return this.httpRequestService.mapToModel(Role, result.role) as Role;
		}));
	}

	public checkIfUserHavePermissionOf(expectedPermissions: PermissionTypeEnum[]): Observable<boolean> {
		return this.store.pipe(select(AuthStoreSelectors.selectPermissions),
			mergeMap((permissions: Permission[]) => {
				if (!permissions) {
					return this.getPermissions();
				}
				return of(permissions);
			}),
			mergeMap((permissions: Permission[]) => {
				const tmp = permissions.map(p => p.type);
				const hasPermissions: boolean = expectedPermissions.every(
					(val) => tmp.includes(val));
				return of(hasPermissions);
			}));
	}

	/**
	 * Profile switch
	 */
	public switchProfile(profileHistoryId: number) {
		return this.httpRequestService.post(__API_USER_SWITCH_PROFILE_, { profile_history_id: profileHistoryId }).pipe(map((result) => {
			return result;
		}));
	}
}
