import { PropertyMapper } from '../../decorators';

export class AbsenceType {

    @PropertyMapper()
    public id: number;

    @PropertyMapper()
    public name: string;
}
