import { PropertyMapper } from '../../decorators';
import { Profile } from '../profile/profile.model';

export class FiscalPeriod {

    @PropertyMapper()
    public id: number;

    @PropertyMapper()
    public status: number;

    @PropertyMapper()
    public name: string;

    @PropertyMapper({ jsonProperty: 'start', typeOfObject: Date })
    public start: Date;

    @PropertyMapper({ jsonProperty: 'end', typeOfObject: Date })
    public end: Date;

    @PropertyMapper({ jsonProperty: 'profiles', typeOfObject: Profile })
    public profiles: Profile[];

    /**
     * Used in manage fiscal period page to handle display edit
     */
    public editable: boolean;
}
