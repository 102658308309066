import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InternalActivityDeclarationForm, InternalActivityListGetRequestForm } from '../../../declaration/forms';
import { InternalActivityType } from '../../models/internal-activity/internal-activity-type.model';
import { InternalActivity } from '../../models/internal-activity/internal-activity.model';
import { HttpRequestService } from '../http/http-request.service';

@Injectable()
export class InternalActivityService {

    constructor(private httpRequestService: HttpRequestService) {
    }

    public getInternalActivityTypeList(): Observable<any> {
        return this.httpRequestService.get('internal-activity/type/list').pipe(map((result) => {
            return this.httpRequestService.mapToModel(InternalActivityType, result.internal_activity_type_list) as InternalActivityType[];
        }));
    }

    public getInternalActivityList(form: InternalActivityListGetRequestForm): Observable<any> {
        return this.httpRequestService.post('internal-activity/list', form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(InternalActivity, result.internal_activities) as InternalActivity[];
        }));
    }

    public getInternalActivityByUser(userId: number, form: InternalActivityListGetRequestForm): Observable<any> {
        return this.httpRequestService.post(`admin/internal-activity/user/${ userId }/list`, form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(InternalActivity, result.internal_activities) as InternalActivity[];
        }));
    }

    public createInternalActivity(form: InternalActivityDeclarationForm): Observable<InternalActivity> {
        return this.httpRequestService.post('internal-activity', form.toRequestForm()).pipe(map((result) => {
            return this.httpRequestService.mapToModel(InternalActivity, result.internal_activity) as InternalActivity;
        }));
    }

    public updateInternalActivity(id: number, form: InternalActivityDeclarationForm): Observable<any> {
        return this.httpRequestService.put(`internal-activity/${ id }/update`, form.toRequestForm()).pipe(map(result => {
            return this.httpRequestService.mapToModel(InternalActivity, result.internal_activity);
        }));
    }

    public deleteInternalActivity(id: number): Observable<any> {
        return this.httpRequestService.delete(`internal-activity/${ id }/delete`);
    }
}
