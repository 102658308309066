import { Validators } from '@angular/forms';
import { BaseForm } from '../../core/forms/base.form';

export class AbsenceDeclarationMultiForm extends BaseForm {

    constructor() {
        super();
        this.validationForm = this.formBuilder.group({
            range: [null, Validators.required],
            type: [null, Validators.required],
            duration: [null, Validators.required],
            description: [null]
        });
    }

    public toRequestForm() {
        return {
            start: this.validationForm.get('range').value[0],
            end: this.validationForm.get('range').value[1],
            type: this.validationForm.get('type').value,
            duration: this.validationForm.get('duration').value,
            description: this.validationForm.get('description').value,
        };
    }
}
