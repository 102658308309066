import { PropertyMapper } from '../../decorators';
import { SupplierContactTypeEnum } from '../../enums/supplier-contact-type.enum';

export class SupplierContact {
	@PropertyMapper()
	public id: number;

	@PropertyMapper({ jsonProperty: 'supplier_id' })
	public supplierId: number;

	@PropertyMapper({ jsonProperty: 'first_name' })
	public firstName: string;

	@PropertyMapper({ jsonProperty: 'last_name' })
	public lastName: string;

	@PropertyMapper()
	public email: string;

	@PropertyMapper({ jsonProperty: 'mobile_phone' })
	public mobilePhone: string;

	@PropertyMapper()
	public phone: string;

	@PropertyMapper()
	public type: SupplierContactTypeEnum;

	public searchValueFormatted: string;
}
