import { PropertyMapper } from '../../decorators';

export class CustomerSite {
    @PropertyMapper()
    public id: number;

    @PropertyMapper({ jsonProperty: 'customer_id' })
    public customerId: number;

    @PropertyMapper({ jsonProperty: 'name' })
    public name: number;

    @PropertyMapper({ jsonProperty: 'address_line_1' })
    public addressLine1: string;

    @PropertyMapper({ jsonProperty: 'address_line_2' })
    public addressLine2: string;

    @PropertyMapper({ jsonProperty: 'zipcode' })
    public zipcode: string;

    @PropertyMapper({ jsonProperty: 'city' })
    public city: string;

    @PropertyMapper({ jsonProperty: 'country' })
    public country: string;

    @PropertyMapper({ jsonProperty: 'mileage' })
    public mileage: string;

    @PropertyMapper()
    public disabled: boolean;

    @PropertyMapper()
    public histories: [];
}
